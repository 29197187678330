import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config.js");
// Customizable Area Start
const navigation = require("react-navigation");
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import storage from "../../../framework/src/StorageProvider";

export interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  token?: string;
  body?: object;
  type?: string;
}

export interface ShowWorkOrder {
  data: {
    id: string;
    type: string;
    attributes: {
      id: number;
      unit: {
        id: number;
        name: string;
        status: string;
        property_id: number;
      },
      work_needed: string;
      cost: boolean | number;
      description: string;
      status: string;
      scheduled_date: string;
      sub_category: {
        sub_category: string;
        sub_category_id: number;
        category_id: number;
      },
      assigned_by: string;
      property: {
        property_name: string;
        address: string;
        floor: string;
        entrance: string;
        code: string;
      },
      vendor_account: {
        id: number;
        name: string;
        phone_number: string;
        email_address: string;
      },
      documents: Array<Documents>;
      work_order_audits: Array<AuditingLogs>;
    }
  }
}

export interface AuditingLogs {
  date: string;
  updated_by: string;
  description: string;
  cost: boolean;
  status: string;
}

export interface Documents   {
  file_url: string;
  id: string;
  file_name: string;
  file_size: string;
}

export interface SortDirection {
  sortColumn: string;
  sortDirection: 'asc' | 'desc';
}
// Customizable Area End

export interface Props {
  navigation: typeof navigation;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  activeOrder: string;
  openOrder: boolean;
  orderId: number;
  showOpenWorks: ShowWorkOrder;
  openError: string;
  sortState: SortDirection;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}
export default class OpenWorkOrderController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getOpenWorkApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      activeOrder: configJSON.worksOrderlabel,
      openOrder: false,
      orderId: 0,
      showOpenWorks: {
        data: {
          id: "",
          type: "",
          attributes: {
            id: 0,
            unit: {
              id: 0,
              name: "",
              status: "",
              property_id: 0
            },
            work_needed: "",
            cost: false,
            description: "",
            status: "",
            scheduled_date: "",
            sub_category: {
              sub_category: "",
              sub_category_id: 0,
              category_id: 0
            },
            assigned_by: "",
            property: {
              property_name: "",
              address: "",
              floor: "",
              entrance: "",
              code: ""
            },
            vendor_account: {
              id: 0,
              name: "",
              phone_number: "",
              email_address: ""
            },
            documents: [],
            work_order_audits: []
          }
        }
      },
      openError: "",
      sortState: {
        sortColumn: '',
        sortDirection: 'asc',
      },
      // Customizable Area End
    };
  }
  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getOpenSingleDetails();
    // Customizable Area End
  }

  // Customizable Area Start
  
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiReqCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (!responseJson.errors && responseJson) {
        this.apiSuccessCallBacks(apiReqCallId, responseJson);
      }
    }
  };

  workOpenApiCall = async (data: APIPayloadType) => {
    let { contentType, method, endPoint } = data;
    const token = await storage.get("authToken");
    const header = {
      "Content-Type": contentType,
      token: token
    };

    let requestsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    requestsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    runEngine.sendMessage(requestsMessage.id, requestsMessage);
    return requestsMessage.messageId;
  };

  apiSuccessCallBacks = (apiRequestCallID: string, responseJson: ShowWorkOrder) => {
    if (apiRequestCallID === this.getOpenWorkApiCallId) {
      this.setState({
        showOpenWorks: responseJson
      });
    }

  };


  getOpenSingleDetails = async () => {
    const workId = await storage.get("openWorkId");
    const workStatus = await storage.get("openStatus");
    let endPointdata = "";
    if (workStatus === "open") {
      endPointdata = `${configJSON.getOpenWorkApiEndPoint}${workId}`
    } else {
      endPointdata = `${configJSON.showVendorApiEndPoint}${workId}`
    }
    this.getOpenWorkApiCallId = await this.workOpenApiCall({
      contentType: configJSON.appointmentApiContentType,
      method: configJSON.getAppointmentListAPiMethod,
      endPoint: endPointdata
    });
  };

  handleSideBarNav = (navKey: string) => {
    this.setState({ activeOrder: navKey }, () => {
      this.handleWorkOrderMenu();
    });
  };

  handleOpenDrawer = () => {
    this.setState({
      openOrder: !this.state.openOrder
    });
  };

  handleWorkOrderMenu = () => {
    const { activeOrder} = this.state;
    const toMsgOpenWork = new Message(getName(MessageEnum.NavigationMessage));
    toMsgOpenWork.addData(
      getName(MessageEnum.NavigationTargetMessage),
      activeOrder
    );
    toMsgOpenWork.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(toMsgOpenWork);
  };

  handleScrollContainer = (audits: AuditingLogs[]) => {
    return audits.length > 4 ? "scrollable" : "";
  };

  handleSortRequest = (property: string, direction: 'asc' | 'desc') => {
    this.setState((prevState) => ({
      sortState: {
        ...prevState.sortState,
        sortColumn: property,
        sortDirection: direction,
      },
    }));
  };

  sortAuditData = (newData: AuditingLogs[]) => {
    const { sortColumn, sortDirection } = this.state.sortState;
    return [...newData].sort((sortingA: AuditingLogs, sortingB: AuditingLogs) => {
      const aValue = sortingA[sortColumn as keyof AuditingLogs];
      const bValue = sortingB[sortColumn as keyof AuditingLogs];
      if (bValue === null || bValue === undefined) return -1;
      if (typeof aValue === "string" && typeof bValue === "string") {
        return aValue.localeCompare(bValue) * (sortDirection === "asc" ? 1 : -1);
      } else if (typeof aValue === "number" && typeof bValue === "number") {
        return (aValue - bValue) * (sortDirection === "asc" ? 1 : -1);
      } else {
        return 0;
      }
    });
  };

  handleAuditLog = (rowOrders: AuditingLogs[]) => {
    return rowOrders.length > 0 ? "" : "isUnitsDisabled";
  };

  handleEmptyAuditingLogs = (rowOrders: AuditingLogs[]) => {
    return rowOrders.length > 0 ? "isUnitsDisabled" : "";
  };

  priorityOpenStyleHandler = (newStatus: string) => {
    let styleObjPriority = {
      awaiting: {
        color: "#D97706",
        background: "#FEF3C7",
        borderColor: "#FEF3C7"
      },
      vendor: {
        background: "#D1FAE5",
        color: "#059669",
        borderColor: "#D1FAE5"
      },
      declined: {
        background: "#A3978F",
        borderColor: "#A3978F",
        color: "#413f3e"
      },
      pay_bill: {
        color: "#DC2626",
        background: "#FEE2E2",
        borderColor: "#FEE2E2"
      },
      completed: {
        color: "#00008B",
        background: "#ADD8E6",
        borderColor: "#ADD8E6"
      },
      upload: {
        borderColor: "#FEF3C7",
        color: "#D97706",
        background: "#FEF3C7",
      },
      renewal_needed: {
        background: "#FEE2E2",
        color: "#DC2626",
        borderColor: "#FEE2E2"
      },
      active: {
        borderColor: "#D1FAE5",
        background: "#D1FAE5",
        color: "#059669",
      },
      done: {
        color: "rgb(120,114,110)",
        background: "rgb(233,228,228)",
        borderColor: "#ADD8E6"
      },
      defaults: {
        background: "none",
        color: "#000000"
      }
    }
    switch (newStatus) {
      case "COMPLETED":
        return styleObjPriority.completed
      case "RENEWAL NEEDED":
        return styleObjPriority.renewal_needed
      case "WAITING LANDLORD APPROVAL":
        return styleObjPriority.awaiting
      case "ACTIVE":
        return styleObjPriority.active
      case "DECLINED":
        return styleObjPriority.declined
      case "PAY BILL":
        return styleObjPriority.pay_bill
      case "ASSIGNED TO VENDOR":
        return styleObjPriority.vendor
      case "UPLOAD NEW DOCUMENT":
        return styleObjPriority.upload
      case "DONE":
        return styleObjPriority.done
      default:
        return styleObjPriority.defaults
    }
  };

  goBackWorkOrder = () => {
    window.history.back();
  };

  handlePreview = (documents: Documents) => {
    window.open(documents.file_url, "_blank");
  };

  capitalizeFirstLetter = (capsValue: string) => {
    return capsValue.charAt(0).toUpperCase() + capsValue.slice(1);
  };
  // Customizable Area End
}
