import React from "react";

import {
  // Customizable Area Start
  Box,
  Button,
  Typography,
  createTheme,
  styled,
  // Customizable Area End
} from "@mui/material";

// Customizable Area Start
import LoginController, { Props, configJSON, } from "./LoginController.web";
import CustomDatePicker from "../../../components/src/CustomDatePicker.web";
import CustomTimePicker from "../../../components/src/CustomTimePicker.web";
import { Formik } from 'formik';
import * as Yup from 'yup';
import NavigationSidebar from "../../../components/src/NavigationSidebar.web";
export const keasyIcon = require("../../customform/assets/keasylogo.svg");
export const dashboardLandLogo = require("../../customform/assets/dashboard.png");
export const activeMainDashboard = require("../../customform/assets/activeDashboard.png");
export const accountsMain = require("../../customform/assets/profile.png");
import { activeOrderFinancial } from '../../appointmentmanagement/src/assets'
export const activeMainAccount = require("../../customform/assets/activeProfile.png");
export const ordersMain = require("../../customform/assets/order.png");
export const activeMainOrder = require("../../customform/assets/activeorder.png");
export const vendorMain = require("../../customform/assets/vendor.png");
export const activeMainVendor = require("../../customform/assets/activeSave.png");
export const remindersMain = require("../../customform/assets/reminder.png");
export const activeMainReminder = require("../../customform/assets/activeReminder.png");
export const propertyMain = require("../../customform/assets/property.png");
export const activePropertyMain = require("../../customform/assets/activeProperty.png");
export const financialMain = require("../../customform/assets/financial.png");
export const activeMainFinancial = require("../../customform/assets/activeFinancial.png");
export const reportMain = require("../../customform/assets/report.png");
export const activeMainReport = require("../../customform/assets/activeReport.png");
export const keyMainChain = require("../../customform/assets/keyChain.png");
export const activeKeyMain = require("../../customform/assets/activeKey.png");
export const settingsMain = require("../../customform/assets/settings.png");
export const activeMainSetting = require("../../customform/assets/activeSetting.png");
export const projects = require("../../customform/assets/projects.png");
export const activeProjects = require("../../customform/assets/activeprojects.png");
export const prefferedVendor = require("../../customform/assets/PrefferedVendors.png");
import  CustomNavbar from "../../../components/src/CustomNavbar.web";
import {documentActive, documents} from "./assets";

const theme = createTheme({
  palette: {
    primary: {
      main: '#0000ff',
      contrastText: '#fff',
    },
  },
});
const validationSchema = Yup.object().shape({
  category: Yup.number().required('Category is required'),
  startDate: Yup.date()
  .required('Start date is required')
  .nullable(),
  dueDate: Yup.date()
    .required('End date is required')
    .nullable()
    .min(Yup.ref('startDate'), 'End date must be after start date')
    .test('not-same-date', 'End date must not be the same as start date', function (value) {
      const { startDate } = this.parent;
      return startDate && value && startDate.getTime() !== value.getTime(); 
    }),
  dueHour: Yup.string().required('Due time is required').nullable(),
  dueFrequency: Yup.string().required('Select frequency'),
  duration: Yup.string().required('Select duration'),
  description: Yup.string().required('Comment is required'),
});
// Customizable Area End

export default class Login extends LoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <AddWorkOrderStyle>
        <NavArea>
        <NavigationSidebar
          data-test-id={"navId"}
          activeItem={this.state.activeMainItem}
          sidebarItems={[
            { label: configJSON.dashboardOrder, labelKey: configJSON.dashboardOrderlabel, icon: dashboardLandLogo, activeIcon: activeMainDashboard, pathName: configJSON.dashboardOrderlabel },
            { label: configJSON.myAccountOrder, labelKey: configJSON.settingOrderlabel, icon: accountsMain, activeIcon: activeMainAccount, pathName: configJSON.myAccountOrderabel },
            { label: configJSON.workOrder, labelKey: configJSON.worksOrderlabel, icon: ordersMain, activeIcon: activeMainOrder, pathName: configJSON.worksOrderlabel },
            { label: configJSON.preferredOrder, labelKey: configJSON.prevendorOrderlabel, icon: vendorMain, activeIcon: activeMainVendor, pathName: configJSON.prevendorOrderlabel },
            { label: configJSON.remindersOrder, labelKey: configJSON.reminOrderlabel, icon: remindersMain, activeIcon: activeMainReminder, pathName: configJSON.reminOrderlabel },
            { label: configJSON.mypropOrder, labelKey: configJSON.mypropOrderlabel, icon: propertyMain, activeIcon: activePropertyMain, pathName: configJSON.mypropOrderlabel },
            { label: configJSON.financiaOrder, labelKey: configJSON.financOrderlabel, icon: financialMain, activeIcon: activeOrderFinancial, pathName: configJSON.financOrderlabel },
            { label: configJSON.reportOrder, labelKey: configJSON.reportOrderlabel, icon: reportMain, activeIcon: activeMainReport, pathName: configJSON.reportOrderlabel },
            { label: configJSON.document, labelKey: configJSON.documentlabel, icon: documents, activeIcon: documentActive, pathName: configJSON.documentlabel },
            { label: configJSON.chatOrder, labelKey: configJSON.chatOrderlabel, icon: keyMainChain, activeIcon: activeKeyMain, pathName: configJSON.chatOrderlabel },
            { label: configJSON.settingOrder, labelKey: configJSON.settings, icon: settingsMain, activeIcon: activeMainSetting, pathName: configJSON.settings }
          ]}
          onClickSidebar={this.handleMainItemClick}
          keasylogo={keasyIcon}
          openDrawer={this.state.openLandlordDrawer}
          onClickDrawer={this.handleLandlordDrawer}
        />
        </NavArea>
        <WorkArea display={"flex"} flexDirection={"column"} justifyContent={"space-between"} width={"100%"}
         >
          <Box style={{marginLeft: "2rem",marginRight: "2rem",marginBottom: "20px"}}>
          <CustomNavbar
                  {...this.props}
                  showChatButton={true}
                  showPropertiesButton={true}
                />
          </Box>
          <Box className="heading" style={{display: "flex", justifyContent: "space-between"}}>
            <Box>{this.state.isEditReminder? (
              <Box>Edit Reminder</Box>
              ) : this.state.isReminder ? (
              <Box>Reminder</Box>
              ) : (
              <Box>New Reminders</Box>
              )}
            </Box>
            <Button className="goBackBtn" style={{ textTransform: 'none' }} data-test-id="backBtnTest" onClick={this.navigateToAppointments}>Go Back</Button>
          </Box>
          <Box className="formOuter">
            <Box className="workOrderForm">
            <Formik
                enableReinitialize={true}
                validateOnBlur={false}
                validateOnChange={false}
                initialValues={{
                  property: this.state.property || null,
                  unit: this.state.unit || null,
                  category: this.state.catagory || '',
                  startDate: this.state.startDate || null,
                  dueDate: this.state.dueDate || null,
                  dueHour: this.state.dueHour || null,
                  dueFrequency: this.state.dueDateFrequency || '',
                  duration: this.state.duration || '',
                  description: this.state.description || '',
                }}
                validationSchema={validationSchema}
                onSubmit={async (values, formikHelpers) => {
                  try {
                    this.addAppointment();
                  } catch (error) {
                  }
                }}
                data-test-id="Formik"
              >
                {({ errors, values, handleSubmit, setFieldValue }) => {
                  return (
              <form onSubmit={handleSubmit}>
                <Box className="InputWrapper">
                  <Box className="InputWrapperLeft">
                    Property
                  </Box>
                  <Box className="InputWrapperRight">
                    <Box style={{marginRight: "25px"}} className="selectBox">
                    <select 
                      className='select' 
                      onChange={(e) => {
                        setFieldValue("property", e.target.value);
                        this.setProperty(Number(e.target.value));
                      }}
                      data-test-id="propertyTest"
                      disabled={this.state.isReminder || this.state.isEditReminder}
                      value={this.state.property || values.property || ""}
                      style={{fontFamily: "Outfit"}}
                    >
                      <option value="" disabled>
                        Property
                      </option>
                        {this.state.allProperties?.map((property:any) => (
                          <option key={property.id} value={Number(property.id)}>
                            {property.attributes.property_name}
                          </option>
                        ))}
                    </select>
                    </Box>
                    <Box className="selectBox">
                    <select 
                      className='select marginTop'
                      value={this.state.unit || values.unit || ""}
                      onChange={(e) => {
                        setFieldValue("unit", e.target.value);
                        this.setUnit(Number(e.target.value));
                      }}
                      data-test-id="unitTest"
                      disabled={this.state.isReminder || this.state.isEditReminder}
                      style={{fontFamily: "Outfit"}}
                    >
                      <option value="" disabled>
                        Unit
                      </option>
                      {this.state.units.map((unit: any) => (
                        <option key={unit.id} value={unit.id}>
                          {unit.unit_name}
                        </option>
                      ))}
                    </select>
                    </Box>
                  </Box>
                </Box>
                <Box className="InputWrapper">
                  <Box className="InputWrapperLeft">
                    Category
                  </Box>
                  <Box style={{display: 'block'}} className="InputWrapperRight">
                    <select 
                      className='selectLong' 
                      value={this.state.catagory || values.category}
                      onChange={(e) => {
                        setFieldValue("category", e.target.value);
                        this.setCatagory(Number(e.target.value));
                      }}
                      data-test-id="catagoryTest"
                      disabled={this.state.isReminder}
                      style={{fontFamily: "Outfit"}}
                    >
                      <option value=""></option>
                      {this.state.allCatagory?.map((catagory:any) => (
                        <option key={catagory.id} value={catagory.id}>
                          {catagory.name}
                        </option>
                      ))}
                    </select>
                    {errors.category && (
                      <div className="error">{errors.category}</div>
                    )}
                  </Box>
                </Box>
                <Box className="InputWrapper" style={{paddingBottom: "17px"}}>
                  <Box className="InputWrapperLeft">
                    Due
                  </Box>
                  <Box className="InputWrapperRight">
                    <Box style={{marginRight: "30px"}} className="dateSelect">
                      <Typography
                        sx={{ marginBottom: "8px",fontFamily: "Outfit"}}
                      >
                        Start Date
                      </Typography>
                      <CustomDatePicker
                        value={this.state.startDate || values.startDate}
                        onChange={(newValue)=>{
                          setFieldValue("startDate", newValue);
                          this.handleDateChange(
                          "startDate", newValue
                          )}} 
                          data-test-id="startDateTest"
                          disabled={this.state.isReminder}
                        />
                        {errors.startDate && (
                          <div className="error">{errors.startDate}</div>
                        )}
                    </Box>
                    <Box  className="dateSelect marginTop">
                    <Typography
                      sx={{ marginBottom: "8px", fontFamily: "Outfit"}}
                    >
                      Time
                    </Typography>
                    <CustomTimePicker
                        value={this.state.dueHour || values.dueHour}
                        onChange={(newValue)=>{
                          setFieldValue("dueHour", newValue);
                          this.handleDateChange(
                          "dueHour",newValue
                          )}} 
                        data-test-id="dueHourTest"
                        disabled={this.state.isReminder}
                        />
                        {errors.dueHour && (
                          <div className="error">{errors.dueHour}</div>
                        )}
                    </Box>
                  </Box>
                </Box>
                <Box className="InputWrapper" style={{paddingTop: "0px"}}>
                  <Box className="InputWrapperLeft emptyClass" style={{width: "20%"}}>
                  </Box>
                  <Box className="frequencyWrapper">
                  <Box className="endDateSelect">
                      <Typography
                        sx={{ marginBottom: "8px",fontFamily: "Outfit" }}
                      >
                        End Date
                      </Typography>
                      <CustomDatePicker
                        value={this.state.dueDate || values.dueDate}
                        onChange={(newValue)=>{
                          setFieldValue("dueDate", newValue);
                          this.handleDateChange(
                          "dueDate", newValue
                          )}} 
                          data-test-id="dueDateTest"
                          disabled={this.state.isReminder}
                        />
                        {errors.dueDate && (
                          <div className="error">{errors.dueDate}</div>
                        )}
                    </Box>
                    <Box className="durationBox">
                    <Box>
                    <select className='frequency' data-test-id="durationTest" value={this.state.duration || values.duration} onChange={(e) => {
                        setFieldValue("duration", e.target.value);
                        this.setDuration(e.target.value);
                      }}
                      disabled={this.state.isReminder}
                      style={{fontFamily: "Outfit"}}
                      >
                      <option>Duration</option>
                      <option value="15">15 min</option>
                      <option value="30">30 min</option>
                      <option value="45">45 min</option>
                      <option value="60">1 hour</option>
                      <option value="120">2 hour</option>
                    </select>
                    {errors.duration && (
                      <div className="error">{errors.duration}</div>
                    )}
                    </Box>
                    <Box className="margin" >
                    <select className='frequency' data-test-id="dueFrequencyTest" value={this.state.dueDateFrequency || values.dueFrequency} onChange={(e) => {
                        setFieldValue("dueFrequency", e.target.value);
                        this.setDueFrequency(e.target.value);
                      }}
                      disabled={this.state.isReminder}
                      style={{fontFamily: "Outfit"}}
                      >
                      <option>Frequency</option>
                      <option value="never">Never</option>
                      <option value="daily">Daily</option>
                      <option value="Bi-weekly">Bi-Weekly</option>
                      <option value="monthly">Monthly</option>
                      <option value="yearly">Yearly</option>
                      <option value="weekdays">Weekdays</option>
                      <option value="weekend">Weekend</option>
                    </select>
                    {errors.dueFrequency && (
                      <div className="error">{errors.dueFrequency}</div>
                    )}
                    </Box>
                    </Box>
                  </Box>
                </Box>
                <Box className="InputWrapper">
                  <Box className="InputWrapperLeft">
                    Comments
                  </Box>
                  <Box style={{display: 'block'}} className="InputWrapperRight">
                    <textarea 
                      className='textArea'
                      maxLength={100}
                      rows={6}
                      disabled={this.state.isReminder}
                      value={this.state.description || values.description}
                      onChange={(e) => {
                        setFieldValue("description", e.target.value);
                        this.setDescription(e.target.value);
                      }}
                      data-test-id="descriptionTest"
                      style={{fontFamily: "Outfit", resize: "none"}}
                    />
                    {errors.description && (
                        <div className="error">{errors.description}</div>
                      )}
                  </Box>
                </Box>
                <Box className="InputWrapper">
                  <Box className="InputWrapperLeft">
                  </Box>
                  {!this.state.isReminder && 
                  <Box className="submitBtnWrapper">
                    <Button className="submitBtn" style={{ textTransform: 'none', backgroundColor: "#FFF1CC", width: "131px",marginRight: "20px", }} data-test-id="btnSubmit" type="submit">{this.state.isEditReminder ? "Edit Reminder" : "Set Reminder"}</Button>
                    {!this.state.isEditReminder && 
                      <Button className="submitBtn" data-test-id="workorderTestId" style={{ textTransform: 'none' }} onClick={() => this.navigateToWorkOrders()}>Create Work Order</Button>
                    }
                  </Box>
                  }
                  </Box>
              </form>
              );
            }}
            </Formik>
            </Box>
            <Box className="keasyOffer">
              <Box className="keasyOfferBox">
                <img src={keasyIcon} alt="keasy icon" className="keasyLogo"/>
                <Typography style={{fontSize: "20px", color: "#CC9200", fontWeight: 700, marginBottom: "15px" ,fontFamily: "Outfit",display:"flex",alignItems: "center"}}>Let us do it for you</Typography>
              </Box>
              <Typography style={{fontFamily: "Outfit"}}>Keasy can resolve task for you and save you the time and trouble thanks to our professional team of experts in the field.</Typography>
              <Typography className="keasyBox1">Simply assigned a task to Keasy and we will get it done!</Typography>
              <Typography style={{fontFamily: "Outfit"}}>For more details check our</Typography>
              <Typography className="offerPage">Offer Page</Typography>
              <Typography style={{fontFamily: "Outfit"}}>To check out more ways Keasy can help you work smarter,</Typography>
              <Typography style={{cursor: "pointer",fontFamily: "Outfit", color: "#CC9200", fontWeight: 700}}>Click Here</Typography>
            </Box>
          </Box>
          <Box className="backgroundImgWrapper">
            <Box className="backgroundImg">
              <img src={require('../../appointmentmanagement/assets/hexagon.svg')}/>
            </Box>
            <Box className="backgroundImg">
              <img src={require('../../appointmentmanagement/assets/hexagon.svg')}/>
            </Box>
            <Box className="backgroundImg">
              <img src={require('../../appointmentmanagement/assets/hexagon.svg')}/>
            </Box>
            <Box className="backgroundImg">
              <img src={require('../../appointmentmanagement/assets/hexagon.svg')}/>
            </Box>
          </Box>
          </WorkArea>
      </AddWorkOrderStyle> 
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const NavArea = styled(Box)({
  position: "fixed",
  top: 0,
  left: 0,
  height: "100%",
  overflowY: "auto",
  zIndex: 1000, 
  border: "1px solid rgb(208,203,199)",
  "&::-webkit-scrollbar": {
    display: "none"
  },
  "scrollbar-width": "none",
  "-ms-overflow-style": "none",
  "@media(max-width: 992px)": {
    position: "unset",
    height: "auto",
    overflowY: "hidden",
    border: "none",
  }
});
const WorkArea = styled(Box)({
  marginLeft: "50px", 
  paddingLeft: "75px",
  flexGrow: 1,
  overflowY: "auto",
  height: "100vh",
  "@media(max-width: 992px)": {
    marginLeft: "0px", 
    paddingLeft: "0px",
    height: "auto",
    overflowY: "unset"
  }
});
const AddWorkOrderStyle = styled(Box)({
  display: "flex",
  fontFamily: "Outfit, sans-serif",
  width: "100%",
  justifyContent: "space-between",
  "& .rightBox": {
    width: "100%",
    overflow: "hidden",
  },
  "& .heading": {
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '32px',
    marginLeft: "2rem",
    marginRight: "2rem",
    "@media (max-width:1000px)": {
      marginLeft: "1rem",
      marginRight: "1rem",
    },
    "@media (max-width:600px)": {
      width: "91%"
    },
  },
  "& .formOuter": {
    display: "flex",
    "@media (max-width:1000px)": {
      padding: "0.5rem",
    },
    "@media (max-width:600px)": {
      flexDirection: "column-reverse",
      padding: "0rem",
    },
  },
  "& .workOrderForm": {
    width: "70%",
    border: "1px solid #FFE299",
    borderRadius: "8px",
    margin: "2rem",
    padding: "2rem",
    "@media (max-width:1200px)": {
      width: "70%",
      padding: "0rem",
    },
    "@media (max-width:1000px)": {
      margin: "0.5rem",
    },
    "@media (max-width:600px)": {
      width: "90%",
      border: "none",
      margin: "1rem",
      boxSizing: "border-box",
    }
  },
  "& .margin": {
    marginTop: "15px",
    "@media (max-width:600px)": {
      marginTop: "0px",
    }
  },
  "& .keasyOffer": {
    width: "25%",
    border: "1px solid #FFE299",
    borderRadius: "8px",
    margin: "2rem 2rem 2rem -0.5rem",
    padding: "2rem",
    fontSize: "16px",
    "@media (max-width:1200px)": {
      width: "25%",
    },
    "@media (max-width:1000px)": {
      margin: "0.5rem",
    },
    "@media (max-width:600px)": {
      width: "90%",
      padding: "1rem",
      margin: "1rem",
      boxSizing: "border-box",
    }
  },
  "& .keasyOfferBox":{
    "@media (max-width:600px)": {
      display: "flex",
    }
  },
  "& .keasyLogo":{
    marginBottom: "30px",
    "@media (max-width:600px)": {
      width: "90px",
      height: "90px",
      marginBottom: "15px",
    }
  },
  "& .keasyBox1": {
    fontWeight: 700,
    fontFamily: "Outfit",
    marginBottom: "35px",
    marginTop: "35px",
    "@media (max-width:600px)": {
      display: "none"
    }
  },
  "& .offerPage":{
    cursor: "pointer",
    fontFamily: "Outfit", 
    color: "#CC9200", 
    fontWeight: 700,
    marginBottom: "35px",
    "@media (max-width:600px)": {
      display: "none"
    }
  },
  "& .select": {
    width: "100%",
    height: "44px",
    border: "1px solid #BAB1AB",
    borderRadius: "8px",
    backgroundColor: "#ffffff",
    paddingLeft: "10px",
  },
  "& .selectBox": {
    width: "322px",
    "@media (max-width:1200px)": {
      width: "48%",
    },
    "@media (max-width:600px)": {
      width: "100%",
    }
  },
  "& .selectLong": {
    width: "670px",
    height: "44px",
    border: "1px solid #BAB1AB",
    borderRadius: "8px",
    backgroundColor: "#ffffff",
    paddingLeft: "10px",
    "@media (max-width:1550px)": {
      width: "100%",
    }
  },
  "& .endDateSelect": {
    width: "322px",
    marginRight: "25px",
    "@media (max-width:1550px)": {
      width: "47%",
    },
    "@media (max-width:600px)": {
      width: "100%",
    }
  },
  "& .dateSelect": {
    width: "322px",
    "@media (max-width:1200px)": {
      width: "48%",
    },
    "@media (max-width:600px)": {
      width: "100%",
    }
  },
  "& .InputWrapper": {
    display: "flex",
    padding: "1rem 0rem 1rem 2rem",
    "@media (max-width:1200px)": {
      paddingRight: "2rem",
    },
    "@media (max-width:600px)": {
      display: "block",
      padding: "1rem 0rem 1rem 0rem",
    }
  },
  "& .emptyClass":{
    "@media (max-width:1000px)": {
      padding: "4px",
    }
  },
  "& .InputWrapperLeft": {
    width: "20%",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "17px",
    color: "#BAB1AB",
    "@media (max-width:600px)": {
      width: "100%",
    }
  },
  "& .InputWrapperRight": {
    display: "flex",
    width: "80%",
    "@media (max-width:600px)": {
      width: "100%",
      display: "block",
      marginTop: "1rem",
    }
  },
  "& .input": {
    width: "670px",
    height: "44px",
    border: "1px solid #BAB1AB",
    borderRadius: "8px",
    paddingLeft: "10px",
    "@media (max-width:1200px)": {
      width: "100%",
    }
  },
  "& .radioBtn": {
    accentColor: "#CC9200",
  },
  "& .radioOuterWrapper": {
    width: "80%", 
    marginRight: "25px",
    "@media (max-width:600px)": {
      width: "100%",
    }
  },
  "& .radioWrapper": {
    marginBottom: "1rem",
  },
  "& .textArea": {
    width: "670px",
    border: "1px solid #BAB1AB",
    borderRadius: "8px",
    padding: "10px",
    marginRight: "25px",
    "@media (max-width:1550px)": {
      width: "100%",
    }
  },
  "& .submitBtnWrapper": {
    width: "670px",
    display: "flex",
    justifyContent: "flex-end",
    cursor: "pointer",
    "@media (max-width:600px)": {
      width: "100%",
      justifyContent: "flex-start",
    }
  },
  "& .submitBtn": {
    width: "175px",
    height: "44px",
    backgroundColor: "#FFC123",
    borderRadius: "8px",
    color: "#000000",
    cursor: "pointer",
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '24px',
    fontFamily: "Outfit, sans-serif",
    "&:hover": {
      backgroundColor: "#FFD700", 
    },
    "@media (max-width:600px)": {
      marginRight: "0px",
    }
  },
  "& .backgroundImgWrapper": {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "2.5rem",
    "@media (max-width:600px)": {
      display: "none",
    }
  },
  "& .backgroundImg": {
    width: "250px",
  },
  "& .assignVenderCarouselWrapper": {
    "@media (max-width:1000px)": {
      width: "75%",
    },
    "@media (max-width:600px)": {
      width: "100%",
      marginTop: "1rem",
    }
  },
  "& .assignVenderCarousel": {
    display: "flex",
    justifyContent: "space-between",
  },
  "& .carouselBox": {
    marginLeft: "1rem",
    marginRight: "1rem",
    "@media (max-width:1000px)": {
      width: "80%",
    },
    "@media (max-width:600px)": {
      width: "100%",
      marginLeft: "-1rem",
      marginRight: "-1rem",
    }
  },
  "& .carouselText": {
    width: "530px",
    fontFamily: "Outfit, sans-serif",
    "@media (max-width:1000px)": {
      width: "100%",
    }
  },
  "& .assignVenderNavBtn": {
    width: "40px",
    height: "40px",
    backgroundColor: "#FFC123",
    borderRadius: "50%",
    marginTop: "100px",
    cursor: "pointer",
    "@media (max-width:1000px)": {
      marginTop: "70px",
    },
    "@media (max-width:600px)": {
      marginTop: "50px",
      zIndex: "10",
    },
  },
  "& .keaseSelected": {
    color: "#CC9200",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "17px",
  },
  "& .goBackBtn": {
    width: "80px",
    height: "32px",
    fontWeight: 700,
    backgroundColor: "#FFC123",
    borderRadius: "8px",
    color: "#000000", 
    fontSize: "14px",
    padding: "0px",
    letterSpacing: 0,
    cursor: "pointer",
    fontFamily: "Outfit, sans-serif",
    "&:hover": {
      backgroundColor: "#FFD700", 
    },
  },

  "& .durationBox": {
    "@media (max-width:600px)": {
      display: "flex",
      marginTop: "15px",
    }
  },
  "& .frequencyWrapper": {
    display: "flex",
    justifyContent: "space-between",
    width: "670px",
    "@media (max-width:1550px)": {
      width: "80%",
    },
    "@media (max-width:1000px)": {
      width: "86%",
    },
    "@media (max-width:600px)": {
      width: "100%",
      display: "block",
    }
  },
  "& .frequency": {
    width: "100px",
    height: "30px",
    border: "none",
    borderRadius: "8px",
    paddingLeft: "10px",
    backgroundColor: "#FFF1CC",
    "@media (max-width:600px)": {
      marginRight: "25px",
    }
  },
  "& .marginTop": {
    "@media (max-width:600px)": {
      marginTop: "1rem",
    }
  },
  "& .fileUpload": {
    display: "block",
    width: "670px",
    marginRight: "25px",
    "@media (max-width:600px)": {
      width: "100%",
    }
  },
  "& .error": {
    color: "#ff0000",
    fontSize: "12px",
    marginTop: '3px',
  }
});
// Customizable Area End
