// Customizable Area Start
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const searchSearch = require("../assets/Search.png");
export const closeClose = require("../assets/close.png");
export const filterFilter = require("../assets/filter.jpg");

export const homemyruch = require("../assets/home.jpg");
export const lockLock = require("../assets/lock.jpg");
export const downDown = require("../assets/down.jpg");
export const plusPlus = require("../assets/plus.jpg");
export const checkChecked = require("../assets/check.png");
export const check = require("../assets/check.png");
export const closeClasseimg = require("../assets/close.png");
export const left_img = require("../assets/left.png");
export const pdf_img = require("../assets/pdf.png");
export const search_img = require("../assets/Search.png");

export const dialogDialogimage = require("../assets/Dialogedit.png");
export const dashboard_g = require("../assets/dashboard.png");
export const myaccount = require("../assets/person.png");
export const workoreder = require("../assets/workprderr.png");
export const homeTwo = require("../assets/home2.png");


export const bookmark_b = require("../assets/bookmark.png");
export const bell_b = require("../assets/bell.png");
export const Home_h = require("../assets/home.png");

export const financial_f = require("../assets/financial.png");
export const pie_p = require("../assets/pie.png");
export const key_k = require("../assets/image_key_on_24px.png");
export const key_logo = require("../assets/key.png");

export const setting_s = require("../assets/settings.png");


export const dashbordDashboard = require("../assets/dashboard.png");
export const myaccontMyaccount = require("../assets/person.png");
export const workorederWorkorder = require("../assets/workprderr.png");
export const homeGolden = require("../assets/home2.png");


export const bookmarkBookmark = require("../assets/bookmark.png");
export const bellBell = require("../assets/bell.png");
export const homeHome = require("../assets/home.png");

export const financialFinancial = require("../assets/financial.png");
export const piePie = require("../assets/pie.png");
export const keyKey = require("../assets/image_key_on_24px.png");
export const keylogoLogo = require("../assets/key.png");

export const settingSetting = require("../assets/settings.png");
export const bottomImage = require("../assets/bottomImage.svg");

export const addIcon = require("../assets/add.jpg");
export const closeImage = require("../assets/closeImage.svg");
export const pencil = require("../assets/pencil.jpg");
export const imageIcon = require("../assets/image.jpg");
export const imageOne = require("../assets/img1.jpg");
export const imageTwo = require("../assets/img2.jpg");
export const imageThree= require("../assets/img3.jpg");
export const imageCloud = require("../assets/image_cloud.jpg");
export const downArrow = require("../assets/down_arrow.jpg");
export const plusIcon = require("../assets/plus.svg");
export const serviceInactive3 = require("../../appointmentmanagement/assets/serviceRequest.png");
export const activeMainFinancial = require("../../dashboard/assets/activeFinancial.png");
export const ServiceActive3 = require("../../appointmentmanagement/assets/service.png");
// Customizable Area End
