Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "scheduling";
exports.labelBodyText = "scheduling Body";
exports.dashboardOrder = "Dashboard";
exports.myAccountOrder = "My Account";
exports.workOrder = "Work Orders";
exports.preferredOrder = "Preferred Vendors";
exports.remindersOrder = "Reminders";
exports.mypropOrder = "My Properties";
exports.financiaOrder = "Financial";
exports.reportOrder = "Delinquency Report";
exports.chatOrder = "Chat with Keasy";
exports.settingOrder = "Settings";
exports.dashboardOrderlabel = "Dashboard";
exports.myAccountOrderabel = "landlordaccount";
exports.worksOrderlabel = "Appointments";
exports.prevendorOrderlabel = "TaskList";
exports.reminOrderlabel = "AutomatedPrioritization";
exports.mypropOrderlabel = "PortfolioManagement";
exports.financOrderlabel = "Analytics";
exports.reportOrderlabel = "VisualAnalytics";
exports.chatOrderlabel = "Chat";
exports.settingOrderlabel = "Settings2";

exports.btnExampleTitle = "CLICK ME";
exports.getApiMethodType = "GET";
exports.applicationJsonApiContentType = "application/json";
exports.serviceProviderAPiEndPoint = "scheduling/service_provider_schedulings/get_sp_details";
exports.getCalendarApiEndpoint = "bx_block_automaticreminders/reminders/calendar";
// Added for calendar view options
exports.calendarViewOptions = ["month", "week", "day"];
// Customizable Area End