import * as React from "react";
import { useEffect, useState, useRef, ChangeEvent } from "react";
import { ViewProps } from "./StripePayement.web";
import {
    Elements,
    useElements,
    useStripe,
    CardNumberElement, CardExpiryElement, CardCvcElement,
} from "@stripe/react-stripe-js";
import { Message } from "../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../framework/src/Messages/MessageEnum";
import { useRunEngine } from "../../blocks/utilities/src/hooks/useRunEngine";
import { useBlockHelpers } from "../../blocks/utilities/src/hooks/useBlockHelpers";
import { getStorageData,removeStorageData } from "../../framework/src/Utilities";
import { Box, Typography, Button, TextField, styled, Radio, Modal } from '@mui/material';
import { Close } from "@mui/icons-material";
import { toast } from "react-toastify";
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded';
const mastercardImage = require("../../blocks/stripepayments/assets/mastercard.png");
const visaImage = require("../../blocks/stripepayments/assets/visa.png");
const discoverImage = require("../../blocks/stripepayments/assets/discover.png");
const unionPayImage = require("../../blocks/stripepayments/assets/UnionPay.png");
const americanExpress = require("../../blocks/stripepayments/assets/american-express.png");
const jcb = require("../../blocks/stripepayments/assets/jcb.png");
const dinersClub = require("../../blocks/stripepayments/assets/dinersClub.png");

interface Card {
    id: string;
    brand: string;
    last4: string;
    exp_month: number;
    exp_year: number;
  }

const cardImages: { [key: string]: string } = {
    "mastercard": mastercardImage,
    "visa": visaImage,
    "discover": discoverImage,
    "unionpay": unionPayImage,
    "amex": americanExpress,
    "jcb": jcb,
    "dinersClub": dinersClub
};

const subscribedMessages = [
    MessageEnum.RestAPIResponceMessage,
    MessageEnum.SessionResponseMessage,
];

const StripePaymentsView: React.FC<ViewProps> = ({
    stripePromise,
    payAmount,
    payId,
    payType,
    payPlan,
    goToLoginScreen,
    email,
    payment_id,
    type,
    price,
    ids,
    cards,
    handleFailDialogBox,
    handleSuccessDialogBox
}) => {
    const Render: React.FC<ViewProps> = ({
    }) => {
        const {
            sendBlockMessage,
            sendNetworkRequest,
            setReceiveCallback,
            subscribe,
            unsubscribeFromMessage,
        } = useRunEngine();

        const { extractNetworkResponse } = useBlockHelpers();
        const stripe = useStripe();
        const stripeElements = useElements();
        const addstripeCallId = useRef<string>("");
        const [errorMessage, setErrorMessage] = useState(null);
        const [isProcessing, setProcessing] = useState(false);
        const [cardNumberComplete, setCardNumberComplete] = useState(false);
        const [cardExpiryComplete, setCardExpiryComplete] = useState(false);
        const [cardCvcComplete, setCardCvcComplete] = useState(false);
        const [checkoutError, setCheckoutError] = useState("");
        const [userAppAuthenticationToken, setUserAppAuthenticationToken] = useState<string | undefined>(undefined);
        const [cardholderName, setCardholderName] = useState("");
        const [newCardVisible, setNewCardVisible] = useState(false);
        const [selectedCard, setSelectedCard] = useState<Card | null>(null);
        const [openModal, setOpenModal] = useState(false);
        
        const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
            const { name, value } = event.target;
            if (name === "cardholderName") setCardholderName(value);
        };

        const getToken = () => {
            const message: Message = new Message(
                getName(MessageEnum.SessionRequestMessage)
            );
            sendBlockMessage(message);
        };

        const restoreSessionFromLocalStorage = async() => {
            const persistedAuthToken = await getStorageData("authToken");
            if (persistedAuthToken) {
                const messsage: Message = new Message(
                    getName(MessageEnum.SessionSaveMessage)
                );
                messsage.addData(
                    getName(MessageEnum.SessionResponseToken),
                    persistedAuthToken
                );
                sendBlockMessage(messsage);
            }
        };

        const receive = async (from: string, message: Message) => {
            if (getName(MessageEnum.SessionResponseMessage) === message.id) {
                let resToken = message.getData(getName(MessageEnum.SessionResponseToken));
                if (resToken) {
                    setUserAppAuthenticationToken(resToken);
                } else {
                    restoreSessionFromLocalStorage();
                    let resToken = message.getData(
                        getName(MessageEnum.SessionResponseToken)
                    );
                    setUserAppAuthenticationToken(resToken);
                }
            } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
                const { apiRequestCallId, responseJson } = extractNetworkResponse(message);
                if (responseJson.message === "Payment successful, document statuses updated.") {
                    setOpenModal(true);
                    setNewCardVisible(false);
                    setSelectedCard(null);
                    await removeStorageData("DocumentIds");
                    await removeStorageData("ActionType");
                } else if (responseJson.error) {
                    handleFailDialogBox()
                }
                if (apiRequestCallId === addstripeCallId) {
                }
            }
        };
        const handleSubmit = async (event: any) => {
            event.preventDefault();
            setProcessing(true);

            if (!stripe || !stripeElements) {
                // Stripe.js hasn't yet loaded.
                return;
            }
            // Retrieve card details from stripeElements
            const cardElement: any = stripeElements.getElement(CardNumberElement);
            try {
                const { token, error } = await stripe.createToken(cardElement);

                if (error) {
                    toast.error(error.message)
                    setProcessing(false);
                    return;
                }
                const stripeAuthToken = token?.id
                const authtoken = await getStorageData("authToken")
                if (stripeAuthToken) {
                    const headers = {
                        "Content-Type": "application/json",
                        token: authtoken,  
                    };
                    sendNetworkRequest(
                        addstripeCallId,
                        'POST',
                        'bx_block_stripe_integration/document_payments/create_and_checkout',
                        headers,
                        {
                            document_ids: ids,
                            action_type : type,
                            payment_token: stripeAuthToken
                        }
                    );
                }
            } catch (err) {
                //@ts-ignore
                toast.error(err.message)
            }

            setProcessing(false);
        };
        const handleSavedCardPayment = async () => {
            setProcessing(true);

            try {
                const authtoken = await getStorageData("authToken")
                if (selectedCard?.id) {
                    const headers = {
                        "Content-Type": "application/json",
                        token: authtoken,
                    };
                    sendNetworkRequest(
                        addstripeCallId,
                        'POST',
                        'bx_block_stripe_integration/document_payments/checkout_with_existing_payment_method',
                        headers,
                        {
                            document_ids: ids,
                            action_type : type,
                            payment_method_id: selectedCard?.id
                        }
                    );
                }
            } catch (err) {
                //@ts-ignore
                toast.error(err.message)
            }

            setProcessing(false);
        };
        const handleCardDetailChange = (event: any) => {
            if (event.error) {
                setErrorMessage(event.error.message);
            } else {
                setErrorMessage(null);
            }

            if (event.elementType === 'cardNumber') {
                setCardNumberComplete(event.complete);
            }

            if (event.elementType === 'cardExpiry') {
                setCardExpiryComplete(event.complete);
            }

            if (event.elementType === 'cardCvc') {
                setCardCvcComplete(event.complete);
            }
        };


        useEffect(() => {
            setReceiveCallback(receive);
            subscribedMessages.forEach((message) => subscribe(message));
            getToken();
            return () => {
                subscribedMessages.forEach((message) => unsubscribeFromMessage(message));
            };
        }, []);

        return (
            <StyledBox>
                <WorkOrderHead data-test-id="seeTextId">
                    PAYMENTS
                </WorkOrderHead>
                <Box className="addCardBox">
                    <Box>Credit card/Debit card</Box>
                    <Box onClick={() => { setNewCardVisible(!newCardVisible), setSelectedCard(null) }} className="addNewCard">Add New Card</Box>
                </Box>
                {newCardVisible &&
                    <YellowContainer>
                        <form onSubmit={handleSubmit}>
                            <ContainerPaytwo>
                                <Box className="formBox">
                                    <WorkOrderHead>NEW CARD</WorkOrderHead>
                                    <Box display="flex" justifyContent="space-between">
                                        <Box width="48%">
                                            <StyledLabel>Credit Card number</StyledLabel>
                                            <CardNumberElement
                                                onChange={handleCardDetailChange} />
                                        </Box>
                                        <Box width="48%">
                                            <StyledLabel>ZIP Code</StyledLabel>
                                            <StyledTextField
                                                type="text"
                                                name="cardholderName"
                                                value={cardholderName}
                                                onChange={handleInputChange}
                                                required
                                                fullWidth
                                                placeholder="12345"
                                            />
                                        </Box>
                                    </Box>
                                    <Box display="flex" justifyContent="space-between">
                                        <Box width="48%">
                                            <StyledLabel>Expiry On</StyledLabel>
                                            <CardExpiryElement
                                                onChange={handleCardDetailChange} />
                                        </Box>
                                        <Box width="48%">
                                            <StyledLabel>CVV</StyledLabel>
                                            <CardCvcElement
                                                onChange={handleCardDetailChange}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            </ContainerPaytwo>
                            <ContainerPaythree>
                                <Box className="buttonBox">
                                    <Button onClick={() => setNewCardVisible(false)} className="cancelBtn">Cancel</Button>
                                    <Button type="submit" disabled={!stripe || isProcessing} className="submitBtn">{isProcessing ? "Processing..." : "Save and Pay"}</Button>
                                </Box>
                            </ContainerPaythree>
                        </form>
                    </YellowContainer>
                }
                {
                    cards && cards.map((card:any) => (
                        <>
                            <GreyContainer isSelected={selectedCard?.brand === card.brand}>
                                <Box style={{ display: "flex" }}>
                                    <Radio
                                        sx={{ height: "25px" }}
                                        checked={selectedCard?.brand === card.brand}
                                        onChange={() => { setSelectedCard(card), setNewCardVisible(false) }}
                                    />
                                    <Box>
                                        <Typography style={{ fontFamily: "Outfit", marginBottom: "10px" }}> {card.brand} Ending with {card.last4}</Typography>
                                        <Typography style={{ fontFamily: "Outfit" }}>Credit Card</Typography>
                                    </Box>
                                </Box>
                                <img src={cardImages[card.brand]} alt={`${card.brand} logo`} className="cardImage" />
                            </GreyContainer>
                            {selectedCard?.brand === card.brand && !newCardVisible && (
                                <YellowContainer>
                                        <Box className="buttonBox">
                                            <Button onClick={() => setSelectedCard(null)}  className="cancelBtn">Cancel</Button>
                                            <Button onClick={() => handleSavedCardPayment()} disabled={isProcessing} className="submitBtn">{isProcessing ? "Processing..." : "Proceed to pay"}</Button>
                                        </Box>
                                </YellowContainer>
                            )}
                        </>
                    ))
                }
                <CustomModal
                    data-test-id="openModalId"
                    open={openModal}
                >
                    <ParagraphContainer>
                        <Box display={"flex"} flexDirection={"column"} gap={"10px"} height={"100%"} width={"100%"}>
                            <KeyContainer>
                                <TaskAltRoundedIcon style={{ color: "#FFC123", height: "160px", width: "160px" }} />
                                <Typography style={{ fontFamily: "Outfit", fontSize: "22px", margin: "25px" }}>Payment Complete!</Typography>
                                <Button onClick={goToLoginScreen} className="submitBtn">Back to Dashboard</Button>
                            </KeyContainer>
                        </Box>
                    </ParagraphContainer>
                </CustomModal>
            </StyledBox>
        );
    };

    const viewProps: ViewProps = {
        stripePromise: stripePromise,
        goToLoginScreen: goToLoginScreen,
        payAmount: payAmount,
        payId: payId,
        payType: payType,
        payPlan: payPlan,
        email: email,
        payment_id: payment_id,
        type: type,
        price: price,
        ids: ids,
        cards: cards,
        handleFailDialogBox: handleFailDialogBox,
        handleSuccessDialogBox: handleSuccessDialogBox
    };

    return (
        <Elements
            stripe={stripePromise || null}
        >
            <Render {...viewProps} />
        </Elements>
    );
};

const KeyContainer = styled(Box)({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "@media(max-width: 467px)": {
        flexDirection: "column",
        gap: "10px"
    },
    "& .yellowBox": {
        border: "1px solid #FFC123",
        padding: "1rem",
        borderRadius: "8px",
    },
    "& .summary": {
        fontFamily: "Outfit",
        fontWeight: 700,
        color: "#000000"
    },
    "& .paymentSummary": {
        display: "flex",
        marginBottom: "1.5rem"
    },
    "& .paymentSummaryHeading": {
        width: "40%",
        fontFamily: "Outfit",
        color: "#A3978F",
        marginRight: "2rem",
    },
    "& .buttonBox": {
        display: "flex",
        justifyContent: "center",
        gap: "1rem",
        marginTop: "1rem"
    },
    "& .cancelBtn": {
        width: "170px",
        height: "44px",
        color: "#ffffff",
        backgroundColor: "red",
        borderRadius: "8px",
        fontWeight: 700,
        fontFamily: "Outfit",
        textTransform: "none",
        "&:hover": {
            color: "#ffffff",
            backgroundColor: "red",
          },
    },
    "& .submitBtn": {
        width: "170px",
        height: "44px",
        color: "#000000",
        backgroundColor: "#FFC123",
        borderRadius: "8px",
        fontWeight: 700,
        fontFamily: "Outfit",
        textTransform: "none",
        "&:hover": {
            color: "#000000",
            backgroundColor: "#FFC123",
          },
    },
});
const CloseModal = styled(Box)({
    display: "flex",
    justifyContent: "flex-end",
    cursor: "pointer"
});
const CustomModal = styled(Modal)({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    "& .properties-dropdown": {
        width: "142px",
        height: "44px",
        backgroundColor: "rgb(253,242,208)",
        display: "flex",
        alignItems: "center",
        textTransform: "none",
        borderRadius: "8px",
        padding: "2px 10px 2px 10px",
        justifyContent: "space-around",
        cursor: "pointer",
        color: "#000000",
        "&:hover": {
            backgroundColor: "rgb(253,242,208)",
            color: "#000000"
        },
        "@media(max-width: 420px)": {
            fontSize: "12px",
            width: "105px",
            height: "30px"
        }
    },
});

const ParagraphContainer = styled(Box)({
    backgroundColor: "white",
    width: "30%",
    borderRadius: "8px 8px 30px 8px",
    lineHeight: "2rem",
    padding: "25px 20px 80px 20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "25px",
    "@media(max-width: 1260px)": {
        width: "50%"
    },
    "@media(max-width: 767px)": {
        width: "80%"
    },
    "@media(max-width: 600px)": {
        gap: "15px",
        height: "290px"
    },
    "@media(max-width: 475px)": {
        gap: "12px",
        height: "345px"
    },
    "@media(max-width: 466px)": {
        gap: "10px",
        height: "390px"
    },
    "@media(max-width: 400px)": {
        gap: "10px",
        height: "400px"
    }
});
const WorkOrderHead = styled(Typography)({
    fontWeight: 700,
    color: "#000000",
    marginBottom: "0.5rem",
    fontFamily: "Outfit",
    lineHeight: "32px",
    letterSpacing: "-0.5%",
});
const StyledBox = styled(Box)({
    "& .MuiDialog-paperWidthSm": {
        background: "#525252",
        width: "32vw",
        padding: "12px",
        height: "74vh",
        overflowX: "hidden"
    },
    "& .StripeElement": {
        height: "44px",
        color: "#000000",
        boxSizing: "border-box",
        display: "block",
        margin: "0px 0 20px 0",
        padding: "10px",
        fontSize: "14px",
        fontFamily: "Outfit",
        borderBottom: "2px solid #e2e8f0",
        outline: 0,
        background: "transparent",
        border: "1px solid rgba(211, 211, 211, 1)",
        borderRadius: "8px",
    },
    "& .InputElement.is-invalid.Input": {
        color: "white"
    },
    "& .addCardBox": {
        display: "flex",
        justifyContent: "space-between",
        fontFamily: "Outfit",
        marginBottom: "10px"
    },
    "& .addNewCard": {
        color: "#CC9200",
        fontFamily: 'Outfit',
        fontSize: "14px",
        fontWeight: 700,
        cursor: "pointer",
    },
    "& .buttonBox": {
        display: "flex",
        justifyContent: "end",
        gap: "4%",
        marginTop: "1rem"
    },
    "& .cancelBtn": {
        width: "22%",
        height: "44px",
        color: "#ffffff",
        backgroundColor: "red",
        borderRadius: "8px",
        fontWeight: 700,
        fontFamily: "Outfit",
        textTransform: "none",
    },
    "& .submitBtn": {
        width: "22%",
        height: "44px",
        color: "#000000",
        backgroundColor: "#FFC123",
        borderRadius: "8px",
        fontWeight: 700,
        fontFamily: "Outfit",
        textTransform: "none",
    }
});
const YellowContainer = styled(Box)({
    border: "1px solid #FFC123",
    padding: "1.5rem",
    borderRadius: "8px",
})
const GreyContainer = styled(Box)<{ isSelected: boolean }>(({ isSelected }) => ({
    display: "flex",
    border: `1px solid ${isSelected ? "#FFC123" : "#A3978F"}`,
    padding: "1rem",
    justifyContent: "space-between",
    borderRadius: "8px",
    margin: "1rem 0rem",
    "& .cardImage": {
        width: "70px",
        height: "40px"
    }
}));
const ContainerPaytwo = styled(Box)({
    alignItems: "center",
});

const ContainerPaythree = styled(Box)({
    "& .payNowButton": {
        height: "50px",
        backgroundColor: "rgba(0, 255, 0, 1)",
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
        margin: "15px",
    },
    "& .ButtonStyle": {
        fontFamily: "Outfit",
        color: "rgba(36, 36, 36, 1)",
        fontSize: "16px",
        lineHeight: "24px",
        fontWeight: 700,
    },
});

const StyledTextField = styled(TextField)({
    '& .MuiInputBase-root': {
        height: '44px', 
        fontSize: '14px',
        borderRadius: "8px",
    },
    '& .MuiInputBase-input': {
        padding: '10px', 
        fontFamily: 'Outfit',
        fontWeight: 600,
    },
    marginBottom: "20px",
    borderRadius: "8px",
    boxSizing: "border-box",
});

const StyledLabel = styled(Typography)({
    fontFamily: "Outfit",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "20px",
    marginBottom: "5px",
});

export default StripePaymentsView;