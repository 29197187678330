import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData ,removeStorageData} from "../../../../packages/framework/src/Utilities";
import { AlertColor } from "@mui/lab";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  anchorEl: null | HTMLElement;
  activeMainItem:string,
  openLandlordDrawer: boolean,
  isRejectModalOpen: boolean,
  messageOpen: boolean;
  messageType: AlertColor;
  message: string;
  requestId: string;
  workNeeded: string;
  createdBy: string;
  availability: string;
  description: string;
  status: string;
  dueDateTime: string;
  dueDateDate: string;
  remindMeDate: string;
  remindMeFrequency: string;
  tenantName: string;
  tenantEmail: string;
  tenantPhoneNumber: number;
  numberOfOccupants: number;
  propertyName: string;
  unitName: string;
  subCategory: string;
  remindMeTime:String
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ServiceRequestController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiGetDataCallId: any="";
  getServiceRequesApiCallID:string="";
  rejectServiceRequesApiCallID:string="";
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      anchorEl: null,
      activeMainItem: "LandlordServiceRequestsDashboard",
      openLandlordDrawer:false,
      isRejectModalOpen: false,
      messageOpen: false,
      messageType: "success",
      message: "",
      requestId: "",
      workNeeded: "",
      createdBy: "",
      availability: "",
      description: "",
      status: "",
      dueDateTime: "",
      dueDateDate: "",
      remindMeDate: "",
      remindMeFrequency: "",
      tenantName: "",
      tenantEmail: "",
      tenantPhoneNumber:0,
      numberOfOccupants:0,
      propertyName: "",
      unitName: "",
      subCategory: "",
      remindMeTime:"",
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start 
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getServiceRequesApiCallID) {    
        if (!responseJson.errors && responseJson) {
          const attributes = responseJson.data.attributes;
          const tenant = attributes.tenant;
          const property = attributes.property;
          const unit = attributes.unit;
          const subCategory = attributes.sub_category;
  
          this.setState({
            requestId: attributes.id.toString(),
            workNeeded: attributes.work_needed,
            createdBy: attributes.created_by,
            availability: attributes.availability,
            description: attributes.description,
            status: attributes.status,
            dueDateTime: attributes.due_date_time,
            dueDateDate: attributes.due_date_date,
            remindMeDate: attributes.remind_me_date,
            remindMeTime: attributes.remind_me_time,
            remindMeFrequency: attributes.remind_me_frequency,
            tenantName: tenant.name,
            tenantEmail: tenant.email_address,
            tenantPhoneNumber: tenant.phone_number,
            numberOfOccupants: tenant.number_of_occupants,
            propertyName: property.property_name,
            unitName: unit.name,
            subCategory: subCategory.sub_category,
          });
        } else if (responseJson && responseJson.errors) {
          this.setState({
            messageOpen: true,
            messageType: "error",
            message: responseJson.errors,
          });
        }
      }
      if (apiRequestCallId === this.rejectServiceRequesApiCallID) {    
        if (!responseJson.errors && responseJson) {
          this.setState({
            messageOpen: true,
            messageType: "success",
            message: "Service request rejected successfully",
          });
          this.closeRejectModal();
          this.handleGoBack();
        } else if (responseJson && responseJson.errors) {
          this.setState({
            messageOpen: true,
            messageType: "error",
            message: responseJson.errors,
          });
        }
      }
    }
    
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    this.getServiceRequestInfo();
  // Customizable Area End

  }
  

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  // Customizable Area Start
  handleMainItemClick = (labelKey: string) => {
    this.setState({ activeMainItem: labelKey }, () => {
      this.handleWorkOrderMenu();
    });
  };
  handleWorkOrderMenu = () => {
    const { activeMainItem } = this.state;
    const toMsgWork = new Message(getName(MessageEnum.NavigationMessage));
    toMsgWork.addData(
      getName(MessageEnum.NavigationTargetMessage),
      activeMainItem
    );
    toMsgWork.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(toMsgWork);
  };
  handleLandlordDrawer = () => {
    this.setState({
      openLandlordDrawer: !this.state.openLandlordDrawer,
    });
  };
  openRejectModal = () => {
    this.setState({ isRejectModalOpen: true });
  };
  goToWorkOrder = () => {  
    const navigationMessage = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    navigationMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    navigationMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "AddAppointment"
    );
    runEngine.sendMessage("MergeEngineUtilities", navigationMessage);
  };
  
  closeRejectModal = () => {
    this.setState({ isRejectModalOpen: false });
  };
  handleReject = async() => {
    const token = await getStorageData("authToken")
    const Id = await getStorageData("ServiceRequestId")
    removeStorageData("ServiceRequestId");
    const body = {
      tenant_service_request: {
        status: "Rejected"
      }
    };
    const header = {
      "Content-Type":configJSON.appointmentApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.rejectServiceRequesApiCallID = requestMessage.messageId;
    const endPoint = `${configJSON.getInfoServiceRequest}/${Id}`;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putAppointmentListAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
 
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleGoBack= () => {
    removeStorageData("ServiceRequestId");
    const navigationMessage = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    
    navigationMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );navigationMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "LandlordServiceRequestsDashboard"
    );
    runEngine.sendMessage("MergeEngineUtilities", navigationMessage);
  };
  getServiceRequestInfo = async () => { 
  const token = await getStorageData("authToken")
  const requestId = await getStorageData("ServiceRequestId")

  const header = {
    "Content-Type":configJSON.appointmentApiContentType,
    token: token,
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getServiceRequesApiCallID = requestMessage.messageId;
  const endPoint = `${configJSON.getInfoServiceRequest}/${requestId}`;  
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    endPoint
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.getAppointmentListAPiMethod
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
};
handlePopupMessageClose = () => {
  this.setState({
    messageOpen: false,
  });
};
  // Customizable Area End
}
