// Customizable Area Start
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const keasIcon = require("../../appointmentmanagement/assets/keasylogo.svg");
export const dashboardOrderLogo = require("../../appointmentmanagement/assets/dashboard.png");
export const activeOrderDashboard = require("../../appointmentmanagement/assets/activeDashboard.png");
export const accountsOrder = require("../../appointmentmanagement/assets/profile.png");
export const activeOrderAccount = require("../../appointmentmanagement/assets/activeProfile.png");
export const ordersIcon = require("../../appointmentmanagement/assets/order.png");
export const activeOrderIcon = require("../../appointmentmanagement/assets/activeorder.png");
export const vendorOrder = require("../../appointmentmanagement/assets/vendor.png");
export const activeOrderVendor = require("../../appointmentmanagement/assets/activeSave.png");
export const remindersOrder = require("../../appointmentmanagement/assets/reminder.png");
export const activeOrderReminder = require("../../appointmentmanagement/assets/activeReminder.png");
export const propertyOrder = require("../../appointmentmanagement/assets/property.png");
export const activePropertyOrder = require("../../appointmentmanagement/assets/activeProperty.png");
export const financialOrder = require("../../appointmentmanagement/assets/financial.png");
export const activeOrderFinancial = require("../../appointmentmanagement/assets/activeFinancial.png");
export const reportOrder = require("../../appointmentmanagement/assets/report.png");
export const activeOrderReport = require("../../appointmentmanagement/assets/activeReport.png");
export const keyOrderChain = require("../../appointmentmanagement/assets/keyChain.png");
export const activeKeyOrder = require("../../appointmentmanagement/assets/activeKey.png");
export const settingsOrder = require("../../appointmentmanagement/assets/settings.png");
export const activeOrderSetting = require("../../appointmentmanagement/assets/activeSetting.png");
export const searchIcon = require("../../appointmentmanagement/assets/searchIcon.svg");
export const filterIcon = require("../../appointmentmanagement/assets/filter.svg");
export const plusIcon = require("../../appointmentmanagement/assets/plus.svg");
export const scheduledDate = require("../../appointmentmanagement/assets/scheduleDate.svg");
export const waitingBill = require("../../appointmentmanagement/assets/waitingBill.svg");
export const findKeys = require("../../appointmentmanagement/assets/findKeys.svg");
export const editIcon = require("../../appointmentmanagement/assets/editor.svg");
export const workIcon = require("../../appointmentmanagement/assets/work.svg");
export const transferIcon = require("../../appointmentmanagement/assets/transfer.svg");
export const declineIcon = require("../../appointmentmanagement/assets/decline.svg");
export const doneIcon = require("../../appointmentmanagement/assets/done.svg");
export const hexagon = require("../../appointmentmanagement/assets/hexagon.svg");
export const halfHexagon = require("../../appointmentmanagement/assets/halfHexagon.svg");
export const keys = require("../../appointmentmanagement/assets/keys.svg");
export const keasy = require("../assets/keasy.svg")
export const serviceInactive = require("../../appointmentmanagement/assets/serviceRequest.png");
export const documentActive = require("../assets/documentActive.png")
export const documents = require("../assets/Documents.png")
export const Service = require("../../appointmentmanagement/assets/service.png");
// Customizable Area End
