import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import moment from "moment";
import { Moment } from "moment/moment";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities"

interface Vendor {
  account_id: number,
  address: string,
  business_insurance_exp_date: Date,
  business_license_exp_date: Date,
  comments: string,
  created_at: Date,
  email_address: string,
  epa_license_exp_date: Date,
  id: number,
  name: string,
  notes: string,
  online_payables: null,
  other_certificate_exp_date: Date,
  payment_type: null,
  phone_number: number,
  send_1099: boolean,
  state_insurance_exp_date: Date,
  state_license_exp_date: Date,
  taxpayer_id: number,
  taxpayer_name: string,
  updated_at: Date,
  upload_website_link: string,
  vendor_bank_account_number: number,
  vendor_bank_routing_number: number,
  vendor_trade: [],
  vendor_type: string,
  venmo_account_number: null,
  zelle_account_number: null,
 }
export interface FileWithPreview extends File {
  preview: string;
}
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  available_date: Moment;
  start_time: Moment;
  end_time: Moment;
  id: string | number;
  token: string;
  activeOrderItem: string;
  property: number | null;
  unit: number | null;
  catagory: number | null;
  workNeeded: string;
  dueDate: Date | null;
  dueHour: Date | null;
  dueDateFrequency: string,
  remindMeOnDate: Date | null;
  remindMeOnHour: Date | null;
  remindMeFrequency: string,
  permissionToEnter: boolean,
  permissionDescription: string,
  documents: [],
  cost: string,
  description: string,
  vendor_account_id: number | null,
  allProperties: any,
  allVendors: any,
  allCatagory: any
  currentVendorIndex: number;
  units: any,
  activeMainItem:string,
  openLandlordDrawer: boolean,
  // Customizable Area End
}

export interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AddAppointmentController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  addAppointmentApiCallId?: string;
  getPropertiesCallId?: string;
  getVendorCallId?: string;
  getCatagoryCallId?: string;
  getServiceRequesDataApiCallID?:string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    const endTime = new Date();
    endTime.setMinutes(endTime.getMinutes() + 30);

    this.state = {
      // Customizable Area Start
      id: 0,
      start_time: moment(new Date()),
      end_time: moment(endTime),
      available_date: moment(new Date()),
      token: "",
      activeOrderItem: configJSON.worksOrderlabel,
      property: null,
      unit: null,
      catagory: null,
      workNeeded: "",
      dueDate: null,
      dueHour: null,
      dueDateFrequency: "",
      remindMeOnDate: null,
      remindMeOnHour: null,
      remindMeFrequency: "",
      permissionToEnter: false,
      permissionDescription: "",
      documents: [],
      cost: "",
      description: "",
      vendor_account_id: null,
      allProperties: [],
      allVendors: [],
      allCatagory: [],
      currentVendorIndex: 0,
      units: [],
      activeMainItem: "Appointments",
      openLandlordDrawer:false,
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    await super.componentDidMount();
    this.getToken();
    if (!this.isPlatformWeb()) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    const response = await getStorageData("authToken")
    if (response) {
      this.setState({ token: response }, () => {
      })
    }
    this.getProperties();
    this.getVendor();
    this.getCatagory();
    this.getServiceRequest();
    const property = await getStorageData("ReminderProperty");
    const unit = await getStorageData("ReminderUnit");
    const catagory = await getStorageData("ReminderCatagory");
    const remindMeOnDate = await getStorageData("ReminderStartDate");
    const remindMeOnHour = await getStorageData("ReminderDueHour");
    const description = await getStorageData("ReminderDescription");
    if(property) {
      this.setProperty(property)
    }
    if(unit) {
      this.setUnit(unit)
    }
    if(catagory) {
      this.setCatagory(catagory)
    }
    if(remindMeOnDate) {
      this.setState({
        remindMeOnDate: remindMeOnDate,
      })
    }
    if(remindMeOnHour) {
      this.setState({
        remindMeOnHour: remindMeOnHour,
      })
    }
    if(description) {
      this.setDescription(description)
    }
    // Customizable Area End
  }

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  receive = async (from: String, message: Message) => {
    // Customizable Area Start
    runEngine.debugLog("Message Received", message);
    if (this.handleVendorsResponse(message)) {
      return;
    }
    if (this.handleServiceRequest(message)) {
      return;
    }
    if (this.handlePropertiesResponse(message)) {
      return;
    }

    // Handle category response
    if (this.handleCategoryResponse(message)) {
      return;
    }

    this.handleAddAppointmentResponse(message);

    // Customizable Area End
  };

  // Customizable Area Start
  
  getServiceRequest = async() => {
    const requestId = await getStorageData("ServiceRequestId");
    if (!requestId) {
      return;
    }
    const token = await getStorageData("authToken");
    const header = {
      "Content-Type": configJSON.appointmentApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getServiceRequesDataApiCallID = requestMessage.messageId;
    const endPoint = `${configJSON.getInfoServiceRequest}/${requestId}`;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAppointmentListAPiMethod
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  
  handleMainItemClick = (labelKey: string) => {
    this.setState({ activeMainItem: labelKey }, () => {
      this.handleWorkOrderMenu();
    });
  };
  handleWorkOrderMenu = () => {
    const { activeMainItem } = this.state;
    const toMsgWork = new Message(getName(MessageEnum.NavigationMessage));
    toMsgWork.addData(
      getName(MessageEnum.NavigationTargetMessage),
      activeMainItem
    );
    toMsgWork.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(toMsgWork);
  };
  handleLandlordDrawer = () => {
    this.setState({
      openLandlordDrawer: !this.state.openLandlordDrawer,
    });
  };
  private handleVendorsResponse(message: Message): boolean {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getVendorCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson && !responseJson.errors && responseJson.data) {
        this.setAllVendors(responseJson.data.attributes.preferred_vendors);
      }
      return true;
    }
    return false;
  }

  private handlePropertiesResponse(message: Message): boolean {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getPropertiesCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson && !responseJson.errors && responseJson.data) {
        this.setAllProperty(responseJson.data);
      }
      return true;
    }
    return false;
  }
   handleServiceRequest(message: Message): boolean {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getServiceRequesDataApiCallID === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson && !responseJson.errors && responseJson.data) {
          const attributes = responseJson.data.attributes;
          const property = attributes.property;
          const unit = attributes.unit;
          const subCategory = attributes.sub_category;
          if (!this.state.allProperties.length) { 
            setTimeout(() => {
              this.handleServiceRequest(message);
            }, 100);
            return false;
          }
          this.setProperty(property.property_id)
          this.setState({
            property: property.property_id,
            unit:unit.id,
            catagory: subCategory.sub_category_id,
            remindMeOnDate: attributes.remind_me ? moment(attributes.remind_me, "YYYY-MM-DDTHH:mm:ss.SSSZ").toDate() : null,
            remindMeOnHour: attributes.remind_me_time? moment(attributes.remind_me_time, "HH:mm").toDate() : null,
            description: attributes.description,
            dueDate: attributes.due_date ? moment(attributes.due_date, "YYYY-MM-DDTHH:mm:ss.SSSZ").toDate() : null,
            dueHour: attributes.due_date_time ? moment(attributes.due_date_time, "HH:mm").toDate() : null,
            workNeeded: attributes.work_needed,
            dueDateFrequency:attributes.due_date_frequency,
            remindMeFrequency:attributes.remind_me_frequency,
          });
      }
      return true;
    }
    return false;
  }
  private handleCategoryResponse(message: Message): boolean {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getCatagoryCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson && !responseJson.errors && responseJson.data) {
        this.setAllCatagory(responseJson.data[8].attributes.sub_categories);
      }
      return true;
    }
    return false;
  }

  private handleAddAppointmentResponse(message: Message): void {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.addAppointmentApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson && !responseJson.errors && responseJson.data) {
        removeStorageData("ReminderProperty");
        removeStorageData("ReminderUnit");
        removeStorageData("ReminderCatagory");
        removeStorageData("ReminderStartDate");
        removeStorageData("ReminderDueHour");
        removeStorageData("ReminderDescription");
        removeStorageData("ServiceRequestId");
        this.props.navigation.navigate("Appointments");
      }
    }
  }

  getProperties = async () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPropertiesCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPropertiesAcceptanceApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAppointmentListAPiMethod
    );
   
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getVendor = async () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getVendorCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getVendorsAcceptanceApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAppointmentListAPiMethod
    );
   
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getCatagory = async () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCatagoryCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCatagoryAcceptanceApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAppointmentListAPiMethod
    );
   
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  setProperty = (id: number) => {
    this.setState(prevState => ({
      property: id,
      units: prevState.allProperties.find((property: any) => Number(property?.id) === id)?.attributes.units || []
    }));
  };

  setUnit = (text: number) => {
    this.setState({
      unit: text,
    });
  };

  setCatagory = (id: number) => {
    this.setState({
      catagory: id,
    });
  };

  setWorkNeeded = (text: string) => {
    this.setState({
      workNeeded: text,
    });
  };

  setDueFrequency = (frequency: string) => {
    this.setState({
      dueDateFrequency: frequency,
    });
  };

  setRemindMeFrequency = (frequency: string) => {
    this.setState({
      remindMeFrequency: frequency,
    });
  };

  setPermissionToEnter = (value: boolean) => {
    this.setState({
      permissionToEnter: value,
    });
  };

  setPermissionDescription = (value: string) => {
    this.setState({
      permissionDescription: value,
    });
  };

  handleFilesChange = (name: string, files: FileWithPreview[]) => {
    this.setState({
      [name]: files,
    } as unknown as Pick<S, keyof S>
    );
  };

  handleDateChange = (key: keyof S, newValue: Date | null) => {
    this.setState({ [key]: newValue } as unknown as Pick<S, keyof S>);
  };
  setCost = (value: string) => {
    this.setState({
      cost: value,
    });
  };

  setDescription = (value: string) => {
    this.setState({
      description: value,
    });
  };

  setVendorId = (id: number) => {
    this.setState({
      vendor_account_id: id,
    });
  }

  setAllVendors = (vendor: Vendor[]) => {
    this.setState({
      allVendors: vendor,
    });
  };

  setAllProperty = (property: []) => {
    this.setState({
      allProperties: property,
    });
  };

  setAllCatagory = (catagory: []) => {
    this.setState({
      allCatagory: catagory,
    });
  };

  addAppointment=async()=> {

    const header = {
      "Content-Type": configJSON.appointmentApiContentType,
      token: this.state.token,
    };

    const dueDate = this.state.dueDate ? moment(this.state.dueDate).format("DD-MM-YYYY") : null;
    const dueHour = this.state.dueHour ? moment(this.state.dueHour).format("HH:mm") : null;
    const remindMeOnDate = this.state.remindMeOnDate ? moment(this.state.remindMeOnDate).format("DD-MM-YYYY") : null;
    const remindMeOnHour = this.state.remindMeOnHour ? moment(this.state.remindMeOnHour).format("HH:mm") : null;
    const serviceRequestID= await getStorageData("ServiceRequestId");
    const body: { [key: string]: any } = {
      property_id: this.state.property,
      unit_id: this.state.unit,
      sub_category_id: this.state.catagory,
      work_needed: this.state.workNeeded,
      vendor_account_id: this.state.vendor_account_id,
      documents: this.state.documents,
      due_date: dueDate,
      due_date_time: dueHour,
      remind_me: remindMeOnDate,
      remind_me_time: remindMeOnHour,
      cost: this.state.cost,
      description: this.state.description,
      due_date_frequency: this.state.dueDateFrequency,
      remind_me_frequency: this.state.remindMeFrequency,
      permission_to_enter: this.state.permissionToEnter,
      permission_description: this.state.permissionDescription,
    };
    if (serviceRequestID) {
      body.tenant_service_request_id = serviceRequestID;
    }
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addAppointmentApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.appointmentAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addAppointmentAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  navigateToAppointments = () => {
    removeStorageData("ReminderProperty");
    removeStorageData("ReminderUnit");
    removeStorageData("ReminderCatagory");
    removeStorageData("ReminderStartDate");
    removeStorageData("ReminderDueHour");
    removeStorageData("ReminderDescription");
    this.props.navigation.goBack();
  };

  update(value: Partial<{ [K in keyof S]: S[K] }>) {
    this.setState((state) => ({ ...state, ...value }));
  }

  toMomentDate(value: string | Date | null, format?: string) {
    return moment(value, format);
  }

  toDateFromMoment(value: Moment) {
    return value.toDate();
  }

  // Customizable Area End
}
