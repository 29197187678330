import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import moment, { Moment } from "moment";
import { getStorageData } from "../../../../packages/framework/src/Utilities";
import { AlertColor } from "@mui/lab";

import storage from "../../../framework/src/StorageProvider";
export interface Category {
  id: string;
  attributes: {
    id: number;
    name: string;
    created_at: string;
    updated_at: string;
    category_id: number;
  };
}
interface ServiceRequestAttributes {
  id: number;
  work_needed: string;
  description: string;
  created_by:string;
  status: string;
  due_date_date: string;
  sub_category: {
    sub_category: string;
    sub_category_id:number;
  };
  service_request_audits: Array<{
    date: string;
    updated_by: string;
    description: string;
    status: string;
  }>;
}
interface ServiceRequest {
  id: string;
  attributes: ServiceRequestAttributes;
}
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  available_date: Moment;
  start_time: Moment | null;
  end_time: Moment | null;
  id: string | number;
  token: string;
  showPicker: boolean;
  activeOrderItem: string;
  openWorkOrder: boolean;
  expanded: number | null;
  selected: {[unitId: number]: number[]};
  selectedUnit: string | null;
  subMenuAnchorEl: null | HTMLElement;
  selectedMenuItemIndex: null | number;
  approveDetails:  string[];
  openApprove:{[key: number]: boolean };
  selectedApprove: {[unitId: number]: string};
  selecteValueApprove: {[unitId: number]: string};
  anchorElement: { [key: number]: HTMLElement | null };
  showAllItems: boolean;
  itemsToShow: number;
  sortColumn: string;
  sortDirection: "asc" | "desc";
  anchorNewElement: { [key: number]: HTMLElement | null };
  sortState: {
    [key: number]: {
      sortColumn: string;
      sortDirection: "asc" | "desc";
    };
  },
  reloadLoader: boolean;
  messagePopup: boolean;
  messageForPopup: string;
  activeMainNewrequestdash:string;
  openTenentDrawersdash:boolean;
  messageOpen: boolean;
  messageType: AlertColor;
  message: string;
  serviceRequest: ServiceRequest[];
  allCatagory: Category[];
  selectedCategoryNames :any;
  selectedStatus: string[];
  propertyDetails:any;
  currentPage: number;
  totalPages: number;
  totalCount: number;
  itemsPerPage: number;
  // Customizable Area End
}

export interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ServicerequestController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAppointmentsListApiCallId?: string;
  getWorkOrderApiCallId: string = "";
  getPropertiesApiCallId: string = "";
  postApprovedApiCallId: string = "";
  postWaitingApiCallId: string = "";
  getServiceRequestApiCallId: string = "";
  getCatagoryCallId: string = "";
  getPropertyCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    let endTime = new Date();
    endTime.setMinutes(endTime.getMinutes() + 30);

    this.state = {
      // Customizable Area Start
      id: 0,
      start_time: moment(new Date()),
      end_time: moment(endTime),
      available_date: moment(new Date()),
      token: "",
      showPicker: false,
      activeOrderItem:"servicerequestdashboard",
      openWorkOrder: false,
      expanded: null,
      selected: {},
      selectedUnit: null,
      subMenuAnchorEl: null,
      selectedMenuItemIndex: null,
      approveDetails: ["Assign a vendor","Complete Work", "Decline"],
      openApprove: {},
      selectedApprove: {},
      selecteValueApprove: {},
      anchorElement: {},
      showAllItems: false,
      itemsToShow: 4,
      sortColumn: "id",
      sortDirection: "asc",
      anchorNewElement: {},
      sortState: {},
      reloadLoader: false,
      messagePopup: false,
      messageForPopup: "",
      activeMainNewrequestdash: "servicerequestdashboard",
      openTenentDrawersdash:false,
      selectedStatus:[],
      messageOpen: false,
      messageType: "success",
      message: "",
      serviceRequest:[],
      allCatagory:[],
      selectedCategoryNames:[],
      propertyDetails:{},
      currentPage: 1,
      totalPages: 0,
      totalCount: 0,
      itemsPerPage: 10,
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    await super.componentDidMount();
    this.getToken();
    if (!this.isPlatformWeb()) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.getServiceRequestlist(this.state.currentPage);
    this.getCatagory();
    this.getPropertyDetails();
    // Customizable Area End
  }

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  receive = async (from: String, message: Message) => {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    runEngine.debugLog("Message Received", message);
    if (apiRequestCallId === this.getServiceRequestApiCallId) {      
      if (!responseJson.errors && responseJson) {
        this.setState({
          serviceRequest: responseJson.data,
          currentPage: responseJson.meta.current_page,
          totalPages: responseJson.meta.total_pages,
          totalCount: responseJson.meta.total_count,
        });
      } else if (responseJson && responseJson.errors) {
        this.setState({
          messageOpen: true,
          messageType: "error",
          message: responseJson.errors
        });
      }
      };
       
    if (apiRequestCallId === this.getCatagoryCallId) {   
        
      if (!responseJson.errors && responseJson) {
        this.setState({
          allCatagory:responseJson.data
         });
      } else if (responseJson && responseJson.errors) {
        this.setState({
          messageOpen: true,
          messageType: "error",
          message: responseJson.errors
        });
      }
      };
      if (apiRequestCallId === this.getPropertyCallId) {      
        if (!responseJson.errors && responseJson) {
          this.setState({
            propertyDetails:responseJson.data
           });
        } else if (responseJson && responseJson.errors) {
          this.setState({
            messageOpen: true,
            messageType: "error",
            message: responseJson.errors
          });
        }
        };
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getAppointmentsListApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors && responseJson.data) {
        this.setState({
        });
      } else {
        const errorResponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        this.parseApiCatchErrorResponse(errorResponse);

       
      }
    }
   
    // Customizable Area End
  };

  // Customizable Area Start
  toMomentDate(value?: string | Date | null, format?: string) {
    return moment(value, format);
  }

  navigateToAddAppointment() {
    // Merge Engine - Navigation - Start
    const navigationMessage = new Message(getName(MessageEnum.NavigationMessage));
    navigationMessage.addData(getName(MessageEnum.NavigationTargetMessage), "AddAppointment");
    navigationMessage.addData(
      getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigationMessage);
    // Merge Engine - Navigation - End
  }
  
  handleExpandClick = (rowId: number) => {
    this.setState((prevState) => ({
      expanded: prevState.expanded === rowId ? null : rowId
    }));
  };
  
  handleMainItemClicknewrequest = (navKey: string) => {
    this.setState({ activeMainNewrequestdash: navKey }, () => {
      this.handleWorkOrderMenu();
    });
  };
  handleWorkOrderMenu = () => {
    const { activeMainNewrequestdash } = this.state;
    const toMsgWork = new Message(getName(MessageEnum.NavigationMessage));
    toMsgWork.addData(
      getName(MessageEnum.NavigationTargetMessage),
      activeMainNewrequestdash
    );
    toMsgWork.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(toMsgWork);
  };
  handleMenuClosesd = (rowId: number) => {
    this.setState({
      anchorNewElement: {
        ...this.state.anchorNewElement,
        [rowId]: null
      },
    });
  };
  handleChangeColor = (itemIndex: number) =>{
    return this.state.selectedMenuItemIndex === itemIndex ? "changeColor" : "";
  };
  handlePageChange = (newPage: number) => {
    this.setState({ currentPage: newPage }, () => {
      this.getServiceRequestlist(newPage); 
    });
  };
  
  handleTenentrequestDrawers = () => {
    this.setState({
      openTenentDrawersdash: !this.state.openTenentDrawersdash
    });
  };
  navigateToDashboard = () => {
    const navigationMessage = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    navigationMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "Automatedworkflow"
    );
    navigationMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    runEngine.sendMessage("MergeEngineUtilities", navigationMessage);
  };
  getServiceRequestlist = async (page_no:number) => {
    const token = await getStorageData("authToken")

    const header = {
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getServiceRequestApiCallId = requestMessage.messageId;
    const endpoint = `${configJSON.getServiceRequestAPIEndPoint}?page_no=${page_no}&limit=${this.state.itemsPerPage}`;  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getCatagory = async () => {
    const authToken = await getStorageData("authToken")
      const authHeader = {
        token: authToken
      };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCatagoryCallId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(authHeader)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCatagorApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleDeleteCategory = (selectedId: any) => {
    this.setState((prevState) => ({
      selectedCategoryNames: prevState.selectedCategoryNames.filter((id:number) => id !== selectedId)
    }), () => {
      this.applyFilters();
    });
  };
  
  handleDeleteDescription = (status: string) => {
    this.setState((prevState) => ({
      selectedStatus: prevState.selectedStatus.filter((item) => item !== status)
    }), () => {
      this.applyFilters();
    });
  };
  getPropertyDetails = async () => {  
    const token = await getStorageData("authToken")
      const header = {
        token: token
      };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPropertyCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPropertyDetailsAPIEndPoint
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleChangePage = (event: React.ChangeEvent<unknown>, newPage: number) => {
    this.setState({ currentPage: newPage }, () => {
      this.getServiceRequestlist(newPage); 
    });
  };
  applyFilters = async () => {
    const { selectedCategoryNames, selectedStatus } = this.state;
    const statusParam = selectedStatus.length > 0 ? selectedStatus.join(',') : '';
    const categoryParam = selectedCategoryNames.length > 0 ? selectedCategoryNames.join(',') : '';
    const token = await getStorageData("authToken");
    const header = {
      token: token,
    };
    const apiEndpoint = `${configJSON.getServiceRequestAPIEndPoint}?status=${statusParam}&sub_category_id=${categoryParam}`;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getServiceRequestApiCallId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleSortRequest = (property: string, direction: "asc" | "desc", unitIndex: number) => {
    const sortedRequests = [...this.state.serviceRequest].sort((a: any, b: any) => {
      let valueA, valueB;
      if (property === "id") {
        valueA = parseInt(a.attributes.id);
        valueB = parseInt(b.attributes.id);
      } else if (property === "category") {
        valueA = a.attributes.sub_category.sub_category;
        valueB = b.attributes.sub_category.sub_category;
      } else if (property === "due_date_date") {
        valueA = new Date(a.attributes.due_date_date).getTime();
        valueB = new Date(b.attributes.due_date_date).getTime();
      } else {
        valueA = a.attributes[property];
        valueB = b.attributes[property];
      }
  
      if (typeof valueA === "string" && typeof valueB === "string") {
        return direction === "asc" ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
      }
      return direction === "asc" ? valueA - valueB : valueB - valueA;
    });
  
    this.setState({
      serviceRequest: sortedRequests,
    });
  };
  
  
  // Customizable Area End
}
