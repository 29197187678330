import React from "react";

import { Input, Button, Typography } from '@builder/component-library';

// Customizable Area Start
import { Box, Button as MuiButton, Typography as MuiTypography,
  TextField,
  Grid,
  styled,
  Checkbox,
  Table,
  TableBody,
  ClickAwayListener,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ListItemText,
  Menu,
  ListItemIcon,
  MenuItem,
  IconButton,
  Divider
} from '@mui/material';
import Modal from '@mui/material/Modal';
import Close from '@mui/icons-material/Close';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import ScannerOutlinedIcon from '@mui/icons-material/ScannerOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import NavigationSidebar from "../../../components/src/NavigationSidebar.web";
import { dashboardOrderLogo, activeOrderDashboard, accountsOrder, activeOrderAccount, ordersIcon, activeOrderIcon, vendorOrder, activeOrderVendor, remindersOrder, activeOrderReminder, propertyOrder, activePropertyOrder, financialOrder, activeOrderFinancial, reportOrder, activeOrderReport, keyOrderChain, activeKeyOrder, settingsOrder, activeOrderSetting, keasIcon, hexagon, halfHexagon} from '../../analytics/src/assets';
import {documentActive,documents } from '../../automaticreminders/src/assets';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
export const reverse = require("../../analytics/assets/reverse.svg");
export const eye = require("../../analytics/assets/visibility.svg");
import { MoreVert, ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import PopupMessage from "../../../components/src/PopupMessage.web";
import dayjs from 'dayjs';

import {serviceActive,servicelogoInactive} from './assets';
import  CustomNavbar from "../../../components/src/CustomNavbar.web";

// Customizable Area End

import Cfdocumentforwarding4Controller, {
  Props,
  configJSON,
} from "./Cfdocumentforwarding4Controller";

export default class Cfdocumentforwarding4 extends Cfdocumentforwarding4Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderSortableTableCell = (property: string, label: string, unitIndex: number) => {
    return (
      <CustomTableCell key={property}>
        <Box display="flex" alignItems="center" justifyContent={"center"} fontFamily={"Outfit"} fontSize={"12px"} fontWeight={"700"}>
          {label}
          <Box display="flex" flexDirection="column" marginLeft={"10px"}>
            <CustomIconButtonUp
              data-test-id={`sortUpId,${unitIndex}`}
              onClick={() => this.handleSortRequest(property, "asc", unitIndex)}
            >
              <ArrowDropUp/>
            </CustomIconButtonUp>
            <CustomIconButtonDown
              data-test-id={`sortDownId,${unitIndex}`}
              onClick={() => this.handleSortRequest(property, "desc", unitIndex)}
            >
              <ArrowDropDown
              />
            </CustomIconButtonDown>
          </Box>
        </Box>
      </CustomTableCell>
    );
  };
  screenProps = {
    navigation: this.props.navigation,
    id: "Cfdocumentforwarding4",
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
      <WorkOrderStyle>
        <NavArea>
        <NavigationSidebar
          data-test-id={"navId"}
          activeItem={this.state.activeOrderItem}
          sidebarItems={[
            { label: configJSON.dashboardOrder, labelKey: configJSON.dashboardOrderlabel, icon: dashboardOrderLogo, activeIcon: activeOrderDashboard, pathName: configJSON.dashboardOrderlabel },
            { label: configJSON.myAccountOrder, labelKey: configJSON.settingOrderlabel, icon: accountsOrder, activeIcon: activeOrderAccount, pathName: configJSON.myAccountOrderabel },
            { label: configJSON.workOrder, labelKey: configJSON.worksOrderlabel, icon: ordersIcon, activeIcon: activeOrderIcon, pathName: configJSON.worksOrderlabel },
            { label: configJSON.preferredOrder, labelKey: configJSON.prevendorOrderlabel, icon: vendorOrder, activeIcon: activeOrderVendor, pathName: configJSON.prevendorOrderlabel },
            { label: configJSON.service1, labelKey: configJSON.servicelabel1, icon: servicelogoInactive, activeIcon:serviceActive , pathName: configJSON.servicelabel1 },
            { label: configJSON.remindersOrder, labelKey: configJSON.reminOrderlabel, icon: remindersOrder, activeIcon: activeOrderReminder, pathName: configJSON.reminOrderlabel },
            { label: configJSON.mypropOrder, labelKey: configJSON.mypropOrderlabel, icon: propertyOrder, activeIcon: activePropertyOrder, pathName: configJSON.mypropOrderlabel },
            { label: configJSON.financiaOrder, labelKey: configJSON.financOrderlabel, icon: financialOrder, activeIcon: activeOrderFinancial, pathName: configJSON.financOrderlabel },
            { label: configJSON.reportOrder, labelKey: configJSON.reportOrderlabel, icon: reportOrder, activeIcon: activeOrderReport, pathName: configJSON.reportOrderlabel },
            { label: configJSON.document, labelKey: configJSON.documentlabel, icon: documents, activeIcon: documentActive, pathName: configJSON.documentlabel },
            { label: configJSON.chatOrder, labelKey: configJSON.chatOrderlabel, icon: keyOrderChain, activeIcon: activeKeyOrder, pathName: configJSON.chatOrderlabel },
          ]}
          onClickSidebar={this.handleSideNav}
          keasylogo={keasIcon}
          openDrawer={this.state.openWorkOrder}
          onClickDrawer={this.handleWordDrawer}

        />
        </NavArea>
         <PopupMessage
          open={this.state.messageOpen}
          type={this.state.messageType}
          message={this.state.message}
          handleClose={this.handlePopupMessageClose}
        />
        <WorkArea display={"flex"} flexDirection={"column"} justifyContent={"space-between"} width={"100%"}
         >     
          <MainWorkContainer>
            <WorkStyles>
            <CustomNavbar 
              {...this.screenProps}
              showChatButton={true}   
              showPropertiesButton={true} 
            />
              <WorkOrderHead data-test-id="seeTextId">
                Documents
              </WorkOrderHead>
              <Grid item spacing={2}>
                <Grid item xs={12} md={6} justifyContent="flex-start">
                <ClickAwayListener  data-test-id="click-away"  onClickAway={() => this.setState({ startDateOpen: false,endDateOpen:false })}  mouseEvent="onMouseDown">
                  <Box className="search-main-content">
                      <Box className="left">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Box sx={{ marginBottom: 2 }}>
                              <DesktopDatePicker
                                  data-test-id="desktopDatePicker"
                                  value={this.state.startDate}
                                  onChange={(date) => this.handleDateChange(date, 'startDate')}
                                  onClose={() => this.setState({ startDateOpen: false })}
                                  open={this.state.startDateOpen} 
                                  maxDate={dayjs().toDate()}
                                  renderInput={(params) =>
                                    <TextField
                                    data-test-id="startDateDatePicker"
                                    {...params}
                                    label="Start date"
                                    onClick={()=>{this.handleOpen("startDate")}}
                                    InputProps={{
                                      ...params.InputProps,
                                      style: {
                                        fontSize: '16px',
                                        borderRadius: '8px',
                                        lineHeight: '18px',
                                        padding: '1rem 1rem',
                                        height: '44px', 
                                        fontFamily: 'Outfit, sans-serif',
                                        maxWidth:"180px",
                                        fontWeight: 400,
                                      },
                                    }}
                                    InputLabelProps={{
                                      style: {
                                        fontWeight: 400,
                                        fontSize: '16px',
                                        color: '#A3978F',
                                        fontFamily: 'Outfit',
                                        lineHeight: '13px',
                                      },
                                    }}
                                    sx={{
                                      '& .MuiSvgIcon-root': {
                                        color: '#BAB1AB',
                                      },
                                      '& .MuiInputBase-root': {
                                        paddingRight: '8px',
                                      },
                                    }}
                                  />
                                    }
                                  label={"start date"}
                                />
                            </Box>
                          </LocalizationProvider>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Box sx={{ marginBottom: 2 }}>
                              <DesktopDatePicker
                                  data-test-id="endDesktopDatePicker"
                                  value={this.state.endDate}
                                  onChange={(date) => this.handleDateChange(date, 'endDate')}
                                  onClose={() => this.setState({ endDateOpen: false })}
                                  open={this.state.endDateOpen}
                                  maxDate={dayjs().toDate()}
                                  renderInput={(params) =>
                                    <TextField
                                    data-test-id="endDesktopDatePickerField"
                                    {...params}
                                    label="End date"
                                    onClick={()=>{this.handleOpen("endDate")}}
                                    sx={{
                                      '& .MuiInputBase-root': {
                                        paddingRight: '8px',
                                      },
                                      '& .MuiSvgIcon-root': {
                                        color: '#BAB1AB',
                                      },
                                    }}
                                    InputProps={{
                                      ...params.InputProps,
                                      style: {
                                        lineHeight: '18px',
                                        fontWeight: 400,
                                        borderRadius: '8px',
                                        fontFamily: 'Outfit, sans-serif',
                                        maxWidth:"180px",
                                        fontSize: '16px',
                                        height: '44px', 
                                        padding: '1rem 1rem',
                                      },
                                    }}
                                    InputLabelProps={{
                                      style: {
                                        fontSize: '16px',
                                        lineHeight: '13px',
                                        fontFamily: 'Outfit',
                                        fontWeight: 400,
                                        color: '#A3978F',
                                      },
                                    }}
                                  />
                                    }
                                />
                            </Box>
                          </LocalizationProvider>
                         <MuiButton
                            data-test-id="apply-btn"
                            className="apply-btn apply-btn-desktop" 
                            onClick={this.handleFilter}
                          >
                            Apply
                         </MuiButton>
                      </Box>
                  </Box>
                </ClickAwayListener>
               
                </Grid>

                <BorderContainer key={1}>

                  <Grid container mt={2} spacing={2}>
                    <CustomApproveGrid >
                                    {this.state.selectedTransactions.length > 0 ? (
                              <SelectedCounts>
                                <SelectedOrder> ({this.state.selectedTransactions.length}) documents selected</SelectedOrder>
                              </SelectedCounts>
                            ) : (
                              <SelectedCounts>
                                <SelectedOrder> ({0}) documents selected</SelectedOrder>
                              </SelectedCounts>
                            )}
                            
                      <ScheduledNewWaiting  data-test-id="multiplecomplete" onClick={() => this.handleMultipleAction("scan")}> 
                            <ScannerOutlinedIcon style={{color: "#CC9200", height:"16px",width: "16px"}} />
                            <ScheduledDate>Scan</ScheduledDate>
                      </ScheduledNewWaiting>
                      <ScheduledNewWaiting  data-test-id="multiplereverse" onClick={() => this.handleMultipleShred()}>
                        <DeleteOutlineOutlinedIcon style={{color: "#CC9200", height:"16px",width: "16px"}}  />
                        <ScheduledDate>Shred</ScheduledDate>
                      </ScheduledNewWaiting>
                      <ScheduledNewWaiting 
                      data-test-id = "eye" 
                      onClick={() => this.handleMultipleAction("forward")}
                      >
                        <ArrowForwardOutlinedIcon style={{color: "#CC9200", height:"16px",width: "16px"}} />
                        <ScheduledDate data-test-id="waitingId">Forward</ScheduledDate>
                      </ScheduledNewWaiting>
                  </CustomApproveGrid>
                </Grid>
                <TableContainer >
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell padding="checkbox">
                        <CustomCheckbox
                          data-test-id="selectAllId"
                          indeterminate={this.state.numSelected > 0 && this.state.numSelected < this.state.rowCount}
                          checked={this.state.rowCount > 0 && this.state.numSelected === this.state.rowCount}
                          onChange={this.handleSelectAllClick}
                        />
                        </TableCell>
                        {this.renderSortableTableCell("Document name", "Document name", 1)}
                        {this.renderSortableTableCell("Upload date", "Upload date", 3)}
                        {this.renderSortableTableCell("Property", "Property", 5)}
                        <CustomTableCell>Details</CustomTableCell>
                        <TableCell />
                        <TableCell />
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                    {
                    this.state.transactions.map((rowTrans: any, workIndex: number) => {
                      const isExpanded = this.state.expanded === rowTrans.id;
                      const isSelected = this.isSelected(rowTrans.id);
                          return (
                            <React.Fragment key={`workOrder-${rowTrans.id}`}>
                              <TableRow 
                             >
                                <CustomDetailTableCell isExpanded={isExpanded} padding="checkbox">
                                <CustomCheckbox
                                  data-test-id="checkBoxTest"
                                  checked={isSelected}
                                  onChange={() => this.handleSelectClick(rowTrans.id)}
                                />
                                </CustomDetailTableCell>
                                <CustomDetailTableCell isExpanded={isExpanded}>{rowTrans.attributes.document_name}</CustomDetailTableCell>
                                <CustomDetailTableCell isExpanded={isExpanded}>{new Date(rowTrans.attributes.transaction_date).toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric' })}</CustomDetailTableCell>
                                <CustomDetailTableCell isExpanded={isExpanded}>{rowTrans.attributes.property}-{rowTrans.attributes.unit_name}</CustomDetailTableCell>
                                <CustomDetailTableCell isExpanded={isExpanded}>{rowTrans.attributes.details}</CustomDetailTableCell>
                                <CostTableCell>
                                  <ScheduledNewWaiting style={{marginRight: "0rem"}} data-test-id="multiplecomplete" onClick={() => window.open(rowTrans.attributes.file_attachments?.[0]?.file_url, "_blank")}> 
                                    <img
                                      className="image-icon"
                                      src={eye} alt="bill" />
                                    <ScheduledDate>See document</ScheduledDate>
                                  </ScheduledNewWaiting>
                                </CostTableCell>
                                <CostTableCell>
                                  <ScheduledNewWaiting style={{marginRight: "0rem"}} data-test-id="multiplecomplete" onClick={()=>this.handleDownload(rowTrans.attributes.file_attachments?.[0]?.file_url)}> 
                                    <FileDownloadOutlinedIcon style={{color: "#CC9200", height:"16px",width: "16px"}}/>
                                    <ScheduledDate>Download document</ScheduledDate>
                                  </ScheduledNewWaiting>
                                </CostTableCell>
                                <TableCell>
                                  <MoreArrow>
                                    <IconButton data-test-id="menuIconId" onClick={(menus) => { this.handleMenuIconClick(menus, rowTrans.id) }}>
                                      <MoreVert />
                                    </IconButton>
                                    <Menu
                                      data-test-id="menuIconCloseId"
                                      anchorEl={this.state.anchorNewElement[rowTrans.id]}
                                      keepMounted
                                      open={Boolean(this.state.anchorNewElement[rowTrans.id])}
                                      onClose={() => this.handleMenuClose(rowTrans.id)}
                                      elevation={0}
                                      anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right"
                                      }}
                                      transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right"
                                      }}
                                      slotProps={{
                                        paper: {
                                          style: { width: "210px", border: "1px solid #E2E8F0", borderRadius: "8px", boxShadow: "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)" }
                                        }
                                      }}
                                    >
                                      <CustomNewMenuItem data-test-id="completedId" onClick={() => {this.handleAction(rowTrans.id, "scan");}}>
                                        <ListItemIcon>
                                          <ScannerOutlinedIcon style={{color: "#000000", height:"16px",width: "16px"}} />
                                        </ListItemIcon>
                                        <ListItemText primaryTypographyProps={{fontSize: "14px"}} primary="Scan" />
                                      </CustomNewMenuItem>
                                      <CustomNewMenuItem data-test-id = "vendorStatusId" onClick={()=>{this.handleOneShred(rowTrans.id);}}>
                                        <ListItemIcon>
                                          <DeleteOutlineOutlinedIcon style={{color: "#000000", height:"16px",width: "16px"}} />
                                        </ListItemIcon>
                                        <ListItemText primaryTypographyProps={{ fontSize: "14px" }} primary="Shred" />
                                      </CustomNewMenuItem>
                                      <CustomNewMenuItem data-test-id="openWorkId" onClick={() => { this.handleAction(rowTrans.id,"forward");}}>
                                        <ListItemIcon>
                                          <ArrowForwardOutlinedIcon style={{color: "#000000", height:"16px",width: "16px"}} />
                                        </ListItemIcon>
                                        <ListItemText data-test-id="openWorksId" primaryTypographyProps={{ fontSize: "14px" }} primary="Forward" />
                                      </CustomNewMenuItem>
                                    </Menu>
                                  </MoreArrow>
                                </TableCell>
                              </TableRow>
                            </React.Fragment>
                          );
                        })}
                    </TableBody>
                    <TableRow className={this.handleEmptyContainer(this.state.transactions)}>
                      <TableCell colSpan={11} align="center" style={{fontFamily: "Outfit"}}>
                        No document found
                      </TableCell>
                      <TableCell style={{width: "70px"}}/>
                    </TableRow>
                  </Table>
                </TableContainer>
                </BorderContainer>
              </Grid>
          
            </WorkStyles>
          </MainWorkContainer>
          <Box className="bgImgWrapper" data-test-id="bgImgId">
            <Box className="backgroundImage">
              <img src={hexagon} alt="hexagon-one" className="hexImg" />
            </Box>
            <Box className="backgroundImage">
              <img src={hexagon} alt="hexagon-two" className="hexImg" />
            </Box>
            <Box className="backgroundImage">
              <img src={hexagon} alt="hexagon-three" className="hexImg" />
            </Box>
            <Box className="backgroundImage">
              <img src={halfHexagon} alt="half-hexagon" className="hexImg" />
            </Box>
          </Box>
        </WorkArea>
      </WorkOrderStyle>
      <CustomModal
        data-test-id="openModalId"
        open={this.state.openFinKey}
      >
        <ParagraphContainer>
          <Box display={"flex"} flexDirection={"column"} gap={"10px"} height={"100%"} width={"100%"}>
            <CloseModal onClick={this.handleModalClose}>
              <Close />
            </CloseModal>
            <KeyContainer>
              <MuiTypography className="summary">Document Actions</MuiTypography>
              <Divider style={{backgroundColor: "#000000", marginBottom: "1rem"}} />
              <Box className="yellowBox">
                <MuiTypography className="summary" style={{marginBottom:"1.5rem"}}>SUMMARY</MuiTypography>
                <Box className="paymentSummary">
                  <MuiTypography className="paymentSummaryHeading">Document {this.state.action == "scan" ? "Scanned" : "Forwarded"} </MuiTypography>
                  <MuiTypography className="summary">{this.state.action == "scan" ? this.state.prizingDetails.documents_scanned : this.state.prizingDetails.documents_forwarded}</MuiTypography>
                </Box>
                <Divider style={{backgroundColor: "#BAB1AB", marginBottom: "1rem"}}/>
                <Box className="paymentSummary">
                  <MuiTypography className="paymentSummaryHeading summary" style={{color: "#000000"}}>Total Amout Payable</MuiTypography>
                  <MuiTypography className="summary">${this.state.action == "scan" ? this.state.prizingDetails.total_price_for_scan : this.state.prizingDetails.total_price_for_forward}</MuiTypography>
                </Box>
              </Box>
              <Box className="buttonBox">
                <MuiButton data-test-id="cancelBtnTest" className="cancelBtn" onClick={() => this.handleCancel()}>Cancel</MuiButton>
                <MuiButton data-test-id="submitBtnTest" className="submitBtn" onClick={() => this.handlePayment()}>Proceed to payment</MuiButton>
              </Box>
            </KeyContainer>
          </Box>
        </ParagraphContainer>
      </CustomModal>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const KeyContainer = styled(Box)({
  "@media(max-width: 467px)": {
    flexDirection: "column",
    gap: "10px"
  },
  "& .yellowBox":{
    border: "1px solid #FFC123",
    padding: "1rem",
    borderRadius: "8px",
  },
  "& .summary":{
    fontFamily:"Outfit",
    fontWeight: 700,
    color: "#000000"
  },
  "& .paymentSummary":{
    display: "flex",
    marginBottom: "1.5rem"
  },
  "& .paymentSummaryHeading": {
    width: "40%",
    fontFamily:"Outfit",
    color: "#A3978F",
    marginRight: "2rem",
  },
  "& .buttonBox": {
    display: "flex",
    justifyContent: "center",
    gap: "1rem",
    marginTop: "1rem"
  },
  "& .cancelBtn": {
    width: "170px",
    height: "44px",
    color: "#ffffff",
    backgroundColor: "red",
    borderRadius: "8px",
    fontWeight: 700,
    fontFamily: "Outfit",
    textTransform: "none",
  },
  "& .submitBtn": {
    width: "170px",
    height: "44px",
    color: "#000000",
    backgroundColor: "#FFC123",
    borderRadius: "8px",
    fontWeight: 700,
    fontFamily: "Outfit",
    textTransform: "none",
  }
});
const CloseModal = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  cursor: "pointer"
});
const CustomModal = styled(Modal)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "20px",
  "& .properties-dropdown": {
    width: "142px",
    height: "44px",
    backgroundColor: "rgb(253,242,208)",
    display: "flex",
    alignItems: "center",
    textTransform: "none",
    borderRadius: "8px",
    padding: "2px 10px 2px 10px",
    justifyContent: "space-around",
    cursor: "pointer",
    color: "#000000",
    "&:hover": {
      backgroundColor: "rgb(253,242,208)",
      color: "#000000"
    },
    "@media(max-width: 420px)": {
      fontSize: "12px",
      width: "105px",
      height: "30px"
    }
  },
});

const ParagraphContainer = styled(Box)({
  backgroundColor: "white",
  width: "30%",
  borderRadius: "8px 8px 30px 8px",
  lineHeight: "2rem",
  padding: "25px 20px 80px 20px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "25px",
   "@media(max-width: 1260px)": {
    width: "50%"
  },
  "@media(max-width: 767px)": {
    width: "80%"
  },
  "@media(max-width: 600px)": {
    gap: "15px",
    height: "290px"
  },
  "@media(max-width: 475px)": {
    gap: "12px",
    height: "345px"
  },
  "@media(max-width: 466px)": {
    gap: "10px",
    height: "390px"
  },
  "@media(max-width: 400px)": {
    gap: "10px",
    height: "400px"
  }
});
const CustomNewMenuItem = styled(MenuItem)({
  fontSize: "12px",
  fontWeight: 400,
  color: "#000000",
  "&.changeColor": {
    backgroundColor: "rgb(250,227,162)",
    borderRadius: "10px"
  },
  "&:hover": {
    backgroundColor: "rgb(250,227,162)",
    color: "#000000"
  }
});
const MoreArrow = styled(Box)({
  display: "flex"
});
const WorkArea = styled(Box)({
  marginLeft: "90px", 
  flexGrow: 1,
  overflowY: "auto",
  height: "100vh",
  "@media(max-width: 992px)": {
    marginLeft: "0px", 
    height: "auto",
    overflowY: "unset"
  }
});
const WorkOrderStyle = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  "@media(max-width: 992px)": {
    flexDirection: "column"
  },
  "&.webStyle": {
    backgroundColor: "red"
  },
  "& .bgImgWrapper": {
    display: "flex",
    justifyContent: "flex-end",
    width: "97%",
    marginTop: "2rem",
    "@media (max-width:1220px)": {
      width: "88%"
    },
    "@media (max-width:992px)": {
      display: "none",
    }
  },
  "& .backgroundImage": {
    width: "250px",
    "@media (max-width:1220px)": {
      width: "180px"
    },
  },
  "& .hexImg": {
    objectFit: "cover"
  }
});
const BorderContainer = styled(Box)({
  border: "1px solid #FFD466",
  borderRadius: "8px 8px 32px 8px",
  padding: "1rem",
  marginTop: "1rem",
  display: "flex",
  flexDirection: "column",
  "@media(max-width: 900px)": {
    marginTop:"0rem",
  },
  "& .scrollable-container": {
    height: "300px",
    overflowY: "auto",
    scrollbarWidth: "none",
  },
  "& .isUnitsDisabled": {
    display: "none"
  }
});
const CustomIconButtonUp = styled(MuiTypography)({
  marginBottom: "-14px",
  cursor: "pointer"
});

const DetailsTableCell = styled(TableCell)({
  color: "#A3978F",
  fontSize: "12px",
  textAlign: "center",
  fontWeight: 700,
  whiteSpace: "nowrap",
  fontFamily:"Outfit",
});
const TransectionTableCell = styled(TableCell)({
  fontSize: "12px",
  fontFamily:"Outfit",
  fontWeight: 400,
  color: "#000000",
  textAlign: "center",
  whiteSpace: "nowrap",
  borderBottom:"none" 
});
const StatusTableCell = styled(TableCell)<{ status: string }>(({ status }) => ({
  fontFamily: "Outfit",
  fontWeight: 700,
  fontSize: "12px",
  lineHeight:"18px",
  padding:"0.5rem",
  color: status === configJSON.completed ? "#059669" : status === configJSON.failed ? "FF0000" : "#FFA500",
  backgroundColor: status === configJSON.completed  ? "#D1FAE5" : status === configJSON.failed ? "#FFE5E5" : "#FFF3E0",
  borderRadius: "40px",
  textAlign: "center",
  textTransform: "capitalize",
  borderBottom:"none" 
}));
const OrderUnitContainer = styled(Box)({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: "20px"
});
const SelectedCounts = styled(Box)({
  display: "flex",
  "@media(max-width: 900px)": {
    
  }
});
const CustomCheckbox = styled(Checkbox)({
  color: "rgba(0, 0, 0, 0.54)",
  marginLeft:"10px",
  "&.Mui-checked": {
    color: "rgb(195,149,49)"
  },
  "&.MuiCheckbox-indeterminate": {
    color: "rgb(195,149,49)"
  }
});
const ScheduledNewWaiting = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap:"0.5rem", 
  cursor: "pointer",
  justifyContent: "center",
  marginRight: "2rem",
});
const OrderUnitName = styled(MuiTypography)({
  fontSize: "16px",
  fontWeight: 700,
  color: "#000000"
});
const CustomApproveGrid = styled(Grid)({
  display: "flex",
  marginLeft:"1.5rem",
  marginBottom:"1rem",
  "@media(max-width: 900px)": {
    flexWrap: "wrap",
    alignItems: "start",
    gap: "1rem",
    marginBottom:"1rem",
  }
});
const CustomIconButtonDown = styled(MuiTypography)({
  marginTop: "0px",
  cursor: "pointer"
});
const CustomTableCell = styled(TableCell)({
  color: "#A3978F",
  textAlign: "center",
  fontSize: "12px",
  fontWeight: 700,
  padding: "0px 10px 0px 10px !important",
  whiteSpace: "nowrap",
  fontFamily: "Outfit",
});
const CustomTableContainer = styled(TableBody)({
  backgroundColor: "#FFFFFF"
});
const ScheduledDate = styled(MuiTypography)({
  color: "#CC9200",
  fontFamily: 'Outfit', 
  fontSize: "14px",
  fontWeight: 700
});
const InnerTableBg = styled(Box)({
  borderRadius: "10px",
  backgroundColor: "#F8FAFC",
  paddingLeft:"5rem",
  marginTop:"1rem",
  marginBottom:"1rem",
});
const CustomDetailTableCell = styled(TableCell)<{ isExpanded: boolean }>(({ isExpanded }) => ({
  fontFamily:"Outfit",
  fontSize: "12px",
  color: "#000000",
  fontWeight: 400,
  textAlign:"center",
  lineHeight:"12px",
  borderBottom: isExpanded ? "none" : "1px solid #e0e0e0" 
}));
const CostTableCell = styled(TableCell)({
    fontFamily:"Outfit",
    fontSize: "12px",
    fontWeight: 700,
    color: "#CC9200",
  });
const FilterContentBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row', 
  alignItems: 'center',
  gap: '1rem',
  marginTop: '1rem',
  marginBottom: '1rem',
  flexWrap: 'wrap',
  "@media (max-width: 600px)": {
    flexDirection: 'column',
    alignItems: 'flex-start', 
    gap: '0.5rem', 
  },
});

const SelectedOrder = styled(MuiTypography)({
  color: "#A3978F",
  fontSize: "14px",
  fontWeight: 700,
  marginRight:"2rem",
  fontFamily:"Outfit"
});
const NavArea = styled(Box)({
  position: "fixed",
  top: 0,
  left: 0,
  height: "100%",
  overflowY: "auto",
  zIndex: 1000, 
  border: "1px solid rgb(208,203,199)",
  "&::-webkit-scrollbar": {
    display: "none"
  },
  "scrollbar-width": "none",
  "-ms-overflow-style": "none",
  "@media(max-width: 992px)": {
    position: "unset",
    height: "auto",
    overflowY: "hidden",
    border: "none",
  }
});
const commonButtonStyles = {
  width: "76px",
  height: "44px",
  fontFamily: "Outfit",
  fontSize: "16px",
  fontWeight: 700,
  color: "#000000",
  textAlign: "center",
  backgroundColor: "#FFC123",
  borderRadius: "8px",
  textTransform: "none",
  cursor: "pointer",
  boxShadow: "none",
  "&:hover": {
    backgroundColor: "#FFC123", 
  },
};
const WorkStyles = styled(Box)({
  width: "90%",
  "& .search-main-content": {
    display: "flex",
    justifyContent: "space-between",
    "@media(max-width:600px)": {
      width: "100%",
      position: "relative",
      "@media(max-width: 420px)": {
        gap: "5px"
      }
    },
  },
  "& .left": {
    display: "flex",
    gap: "1rem",
    "@media(max-width: 600px)": {
      gap: "0.5rem",     },
  },
  "& .right": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "start",
    gap: "1rem",
    "@media(max-width: 600px)": {
      display: "grid", 
      gridTemplateColumns: "1fr 1fr", 
      gap: "0.5rem", 
    },
  },
  "& .apply-btn": {
    ...commonButtonStyles, 
  },
  "& .apply-btn-desktop": {
    display: "flex", 
  },

  "& .search-contents": {
    border: "1px solid #CBD5E1",
    borderRadius: "50px",
    height: "44px",
    display: "flex",
    paddingLeft: "15px",
    alignItems: "center",
    gap: "13px",
  },
  "& .search-icons": {
    height: "24px",
    width: "24px"
  },
  "& .search-text": {
    fontWeight: 400,
    fontSize: "16px",
    color: "#A3978F",
    "@media(max-width: 420px)": {
      fontSize: "12px"
    }
  },
  "& .search-inputs": {
    width: "100%"
  },
  "& .icon": {
    mixBlendMode: "multiply"
  },
  "& .image-icon": {
    width: "16px",
    height: "16px"
  },
  "& .text-data": {
    fontSize: "16px",
    fontWeight: 700,
    "@media(max-width: 420px)": {
      fontSize: "12px"
    }
  },
  "& .new-data": {
    fontSize: "18px",
    color: "#CC9200",
    fontWeight: 700,
    "@media(max-width: 420px)": {
      fontSize: "12px"
    }
  },
  "& .filter-content": {
    display: "flex",
    alignItems: "center",
    paddingRight: "20px"
  },
  "& .filter": {
    display: "flex",
    width:"100%",
    alignItems: "center",
    gap: "1rem",
    flexDirection: 'row', 
    "@media (max-width: 900px)": {
      flexDirection: "column",
      alignItems: "start",
    }
  },
  "& .filter-data": {
    fontFamily: "Outfit",
    fontSize: "12px",
    fontWeight: 700,
    color: "#000000",
    lineHeight: "10px",
  },
  "& .clear-data": {
    fontSize: "12px",
    fontWeight: 700,
    color: "#CC9200",
    cursor: "pointer"
  },
  "& .isUnitsDisabled": {
    display: "none"
  }
});
const MainWorkContainer = styled(Box)({
  width: "100%",
  display: "flex",
  fontFamily: "Outfit",
  alignItems: "center",
  flexDirection: "column",
  "@media(max-width: 992px)": {
    padding: "0px 0px 0px 0px"

  }
});
const WorkOrderHead = styled(MuiTypography)({
  fontSize: "24px",
  fontWeight: 700,
  color: "#000000",
  marginBottom: "20px",
  fontFamily:"Outfit",
  lineHeight:"32px",
  letterSpacing:"-0.5%",
});

// Customizable Area End
