import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import moment from "moment";
import { EventContentArg } from '@fullcalendar/react';
import { getStorageData } from "framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedTime: number;
  selectedDate: string;
  timeSlots: object[];
  serviceProviderId: string;
  serviceProviderSchedule: any;
  details: any;
  token: any;
  calendarView:string;
  activeOrderItem: string;
  openWorkOrder: boolean;
  selectedEvent: EventContentArg | null;
  calendarData: any;
  currentView: any,
  viewAll: boolean,
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class SchedulingController extends BlockComponent<Props, S, SS> {
  
  // Customizable Area Start
  serviceProviderDetailApiId:any;
  serviceProviderScheduleApiId:any;
  getCalendarApiCallId:any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage), 
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      selectedTime: 0,
      selectedDate: moment().format("YYYY-MM-DD"),
      timeSlots: [],
      serviceProviderId: "",
      serviceProviderSchedule: "",
      details: {},
      calendarView:"",
      token: null,
      activeOrderItem: configJSON.reminOrderlabel,
      openWorkOrder: false,
      selectedEvent: null,
      calendarData: [],
      currentView: "timeGridDay",
      viewAll: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {

    // Customizable Area Start
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getCalendarApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson && !responseJson.errors && responseJson.data) {
        this.setCalendarData(responseJson);
      }
      return true;
    }
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
      this.getServiceProviderDetails({
        setApiCallId: 'serviceProviderScheduleApiId',
        serviceProviderId: this.state.serviceProviderId,
        availableDate: this.state.selectedDate,
        token
      })
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if(responseJson && responseJson.errors) {
        
        this.setState({serviceProviderSchedule: []});

        if ( typeof responseJson.errors === 'string' ) {
          this.showAlert("", responseJson.errors)
        } else {
          this.parseApiErrorResponse(responseJson);
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
       else if (apiRequestCallId != null) {

        if (apiRequestCallId === this.serviceProviderScheduleApiId) {
          this.setState({
            serviceProviderSchedule: responseJson && responseJson.data ? responseJson.data : ''
          });
        }
        if (apiRequestCallId === this.serviceProviderDetailApiId) {
          let msg = new Message(getName(MessageEnum.NavigationCalendarMessage));
          msg.addData(
            getName(MessageEnum.CalendarProviderDetailsResponseMessage),
            responseJson
          );
          this.send(msg);
          this.unsubscribeMessages();
          return;
        }
      }
    } else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      runEngine.debugLog("Availability Message Received", message);
      const serviceProviderIdMessage = message.getData(
        getName(MessageEnum.ServiceProviderIdMessage)
      );
      if (serviceProviderIdMessage) {
        this.setState({
          serviceProviderId: serviceProviderIdMessage
        });
      }

      const CalendarProviderDetailsApiMessage = message.getData(
        getName(MessageEnum.CalendarProviderDetailsApiMessage)
      );
      if (CalendarProviderDetailsApiMessage) {
        this.getServiceProviderDetails({...CalendarProviderDetailsApiMessage, setApiCallId: 'serviceProviderDetailApiId'});
        return;
    
      }
    }
    
    // Customizable Area End
  }

  // Customizable Area Start
  handleSideNav = (navKey: string) => {
    this.setState({ activeOrderItem: navKey }, () => {
      this.handleWorkOrderMenu();
    });
  };
  handleWorkOrderMenu = () => {
    const { activeOrderItem } = this.state;
    const toMsgWork = new Message(getName(MessageEnum.NavigationMessage));
    toMsgWork.addData(
      getName(MessageEnum.NavigationTargetMessage),
      activeOrderItem
    );
    toMsgWork.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(toMsgWork);
  };
  handleWordDrawer = () => {
    this.setState({
      openWorkOrder: !this.state.openWorkOrder
    });
  };

  async componentDidMount() {
    super.componentDidMount();
    const token = await getStorageData('authToken');
    this.setState({ token: token});
    this.getCalendarData();
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  onSelectDate = (selectedDateStr: string) => {
    const {details} = this.state;
    this.setState({
      selectedDate: selectedDateStr,
      timeSlots: [],
      selectedTime: 0
    });
    this.getServiceProviderAvailability(selectedDateStr, details);
  };

  calendarProps = {
    minDate: moment(),
    onSelectDate: (selectedDate: string) => this.onSelectDate(selectedDate)
  };

  async getServiceProviderAvailability(selectedDateStr: any, profileData: any){
    const token = this.state.token || '';
    if(profileData && profileData.id && selectedDateStr){
      this.getServiceProviderDetails({
        setApiCallId: 'serviceProviderScheduleApiId',
        serviceProviderId: profileData.id,
        availableDate: moment(selectedDateStr).format("YYYY/MM/DD"),
        token
      })
    } else if(this.state.serviceProviderId && selectedDateStr){
      this.getServiceProviderDetails({
        setApiCallId: 'serviceProviderScheduleApiId',
        serviceProviderId: this.state.serviceProviderId,
        availableDate: moment(selectedDateStr).format("YYYY/MM/DD"),
        token
      })
    }
  }

  async getServiceProviderDetails(dataObj: any) {
    const { setApiCallId, serviceProviderId, availableDate, token } = dataObj;

    const header = {
      "Content-Type": configJSON.applicationJsonApiContentType,
      token,
    };
    
    this.apiCall({
      setApiCallId,
      header,
      method: configJSON.getApiMethodType,
      endPoint: `${configJSON.serviceProviderAPiEndPoint}?availability_date=${availableDate}&service_provider_id=${serviceProviderId}`,
      body: null
    });
    return true;
  }

  apiCall = async (data: any) => {

    const { setApiCallId, header, endPoint, method, body } = data;
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    if(setApiCallId === 'serviceProviderDetailApiId'){
      this.serviceProviderDetailApiId = requestMessage.messageId;
    }else if(setApiCallId === 'serviceProviderScheduleApiId'){
      this.serviceProviderScheduleApiId = requestMessage.messageId;
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    body && requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  unsubscribeMessages = () => {
    runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
  };
  setEventInfo = (eventInfo: any) => {
    this.setState({ selectedEvent: eventInfo });
  };
  handleViewChange = (event:any, newView:any) => {
    if (newView) {
      this.setState({ currentView: newView });
    }
  };
  calculateRecurrence = (startDate:any, startTime:any, duration:any, dueDate:any, frequency:any) => {
    const events = [];
    let currentStart = moment(startDate); 

    const timeParts = moment(startTime, ["h:mm A"]);  
    currentStart.set({
      hour: timeParts.hours(),
      minute: timeParts.minutes()
  });
    const endMoment = moment(dueDate); 
    const durationParts = duration.split(" ");  
    const durationValue = parseInt(durationParts[0], 10);  
    const durationUnit = durationParts[1];  
  
  const validDurationUnit = (() => {
    switch (durationUnit) {
      case 'min':
        return 'minutes';
      case 'hour':
      case 'hours':
        return 'hours';
      case 'day':
      case 'days':
        return 'days';
      case 'week':
      case 'weeks':
        return 'weeks';
      default:
        return 'minutes'; 
    }
  })();

    while (currentStart.isBefore(endMoment)) {
      const eventEnd = currentStart.clone().add(durationValue, validDurationUnit);
      
      events.push({
        start: currentStart.format("YYYY-MM-DDTHH:mm:ss"), 
        end: eventEnd.format("YYYY-MM-DDTHH:mm:ss"), 
      });
  
      switch (frequency) {
        case 'daily':
          currentStart.add(1, 'days');
          break;
        case 'weekly':
          currentStart.add(1, 'weeks');
          break;
        case 'biweekly':
          currentStart.add(2, 'weeks');
          break;
        case 'monthly':
          currentStart.add(1, 'months');
          break;
          case 'yearly':
            currentStart.add(1, 'years');
            break;
          case 'weekdays':
            do {
              currentStart.add(1, 'days');
            } while (currentStart.isoWeekday() > 5); 
            break;
          case 'weekend':
            do {
              currentStart.add(1, 'days');
            } while (currentStart.isoWeekday() < 6); 
            break;
          case 'never':
            currentStart = endMoment.clone(); 
            break;
          default:
            currentStart.add(1, 'days');
      }
      
    }
  
    return events;
  };
  mapCalendarDataToEvents = (calendarData:any) => {
    let allEvents:any = [];
    calendarData?.forEach((event:any) => {
      const startDate = event.attributes.start_date; 
      const duration = event.attributes.duration;
      const dueDate = event.attributes.due_date; 
      const frequency = event.attributes.frequency; 
      const startTime = event.attributes.start_time;
      const recurringEvents = this.calculateRecurrence(startDate,startTime, duration, dueDate, frequency);

      recurringEvents.forEach((recurringEvent:any) => {
        allEvents.push({
          id: event.id, 
          title: `${event.attributes.sub_category?.name}`, 
          start: recurringEvent.start, 
          end: recurringEvent.end, 
          allDay: false, 
          propertyName: event.attributes.property?.property_name,
          unitName: event.attributes.unit.name,
          status: event.attributes.status,
          assignedTo: event.attributes.assigned_to?.first_name,
        });
      });
    });
  
    return allEvents;
  };
  getHighlightedDates = (calendarData:any) => {
    const highlightedDates = new Set();
  
    calendarData?.forEach((event:any) => {
      const { start_date, start_time, duration, due_date, frequency } = event.attributes;
      const eventDates = this.calculateRecurrence(start_date,start_time, duration, due_date, frequency);
      eventDates.forEach(event => highlightedDates.add(moment(event.start).format('YYYY-MM-DD')));
    });
  
    return [...highlightedDates]; 
  };
  handleViewAll = () => {
    this.setState({viewAll:!this.state.viewAll});
  };
  navigateToNewReminder = () => {
    const navigationMessage = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    navigationMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "NewReminder"
    );
    navigationMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    runEngine.sendMessage("MergeEngineUtilities", navigationMessage);
  };
  priorityStyleHandler = (status: string) => {
    let styleObjPriority = {
      not_Started: {
        color: "#70645C",
        background: "#E8E5E3",
        borderColor: "#E8E5E3"
      },
      in_progress: {
        color: "#059669",
        background: "#D1FAE5",
        borderColor: "#D1FAE5"
      },
      review: {
        color: "#059669",
        background: "#D1FAE5",
        borderColor: "#D1FAE5"
      },
      upcoming: {
        color: "#059669",
        background: "#D1FAE5",
        borderColor: "#D1FAE5"
      },
      rejected: {
        color: "#DC2626",
        background: "#FEE2E2",
        borderColor: "#FEE2E2"
      },
      deferred: {
        color: "#DC2626",
        background: "#FEE2E2",
        borderColor: "#FEE2E2"
      },
      contact_tenant: {
        color: "#DC2626",
        background: "#FEE2E2",
        borderColor: "#FEE2E2"
      },
      requires_action: {
        color: "#DC2626",
        background: "#FEE2E2",
        borderColor: "#FEE2E2"
      },
      overdue: {
        background: "#FEF3C7",
        borderColor: "#FEF3C7",
        color: "#D97706"
      },
      completed: {
        color: "#059669",
        background: "#D1FAE5",
        borderColor: "#D1FAE5"
      },
    }
  
    switch (status) {
      case "Not Started":
        return styleObjPriority.not_Started;
      case "In Progress":
        return styleObjPriority.in_progress;
      case "Review":
        return styleObjPriority.review;
      case "Upcoming":
        return styleObjPriority.upcoming;
      case "Rejected":
        return styleObjPriority.rejected;
      case "Deferred":
        return styleObjPriority.deferred;
      case "Contact Tenant":
        return styleObjPriority.contact_tenant;
      case "Requires Action":
        return styleObjPriority.requires_action;
      case "Overdue":
        return styleObjPriority.overdue;
      case "Completed":
        return styleObjPriority.completed;
      default:
        return styleObjPriority.review;
    }
  };

  getCalendarData = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCalendarApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCalendarApiEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  setCalendarData = (data: any) => {
    this.setState({
      calendarData: data,
    });
  };
  
  // Customizable Area End
}