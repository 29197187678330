import React from "react";

// Customizable Area Start
import { 
  Box,
  Typography ,
  styled, 
  Button,
  Modal,
 } from "@mui/material";
import NavigationSidebar from "../../../components/src/NavigationSidebar.web";
export const Service = require("../../appointmentmanagement/assets/service.png");
export const keasyIcon = require("../../customform/assets/keasylogo.svg");
export const activeMainOrder = require("../../customform/assets/activeorder.png");

export const accountsMain = require("../../customform/assets/profile.png");
export const activeMainAccount = require("../../customform/assets/activeProfile.png");
export const activeMainReminder = require("../../customform/assets/activeReminder.png");
export const ordersMain = require("../../customform/assets/order.png");
export const remindersMain = require("../../customform/assets/reminder.png");
export const propertyMain = require("../../customform/assets/property.png");
export const activeMainFinancial = require("../../customform/assets/activeFinancial.png");
export const activePropertyMain = require("../../customform/assets/activeProperty.png");
export const activeMainDashboard = require("../../customform/assets/activeDashboard.png");
export const financialMain = require("../../customform/assets/financial.png");
export const vendorMain = require("../../customform/assets/vendor.png");
export const activeMainVendor = require("../../customform/assets/activeSave.png");
export const dashboardLandLogo = require("../../customform/assets/dashboard.png");
export const reportMain = require("../../customform/assets/report.png");
export const activeMainReport = require("../../customform/assets/activeReport.png");
export const keyMainChain = require("../../customform/assets/keyChain.png");
export const settingsMain = require("../../customform/assets/settings.png");
export const activeMainSetting = require("../../customform/assets/activeSetting.png");
export const activeKeyMain = require("../../customform/assets/activeKey.png");
export const projects = require("../../customform/assets/projects.png");
export const checkButton = require("../../appointmentmanagement/assets/checklist.png");
export const prefferedVendor = require("../../customform/assets/PrefferedVendors.png");
export const activeOrderFinancial = require("../../customform/assets/activeFinancial.png");
import  CustomNavbar from "../../../components/src/CustomNavbar.web";
import PopupMessage from "../../../components/src/PopupMessage.web";
export const activeProjects = require("../../customform/assets/activeprojects.png");

import {documentActive,documents } from '../../automaticreminders/src/assets';
// Customizable Area End
import ServiceRequestController, {
  Props,
  configJSON,
} from "./ServiceRequestController.web";

export default class ServiceRequest extends ServiceRequestController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  screenProps = {
    navigation: this.props.navigation,
    id: "ServiceRequest",
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
        <>
           <Box className="header" sx={{width:"86.3%", marginLeft: { xs: "1rem", sm: "10rem" }}}>
      <CustomNavbar 
                {...this.screenProps}
                showChatButton={true}   
                showPropertiesButton={false} />
      </Box>
       <TenantLeaseStyle>
       <NavAreaAround>
        <NavigationSidebar
          activeItem={this.state.activeMainItem}
          sidebarItems={[
            { label: configJSON.dashboardOrder, labelKey: configJSON.dashboardOrderlabel, icon: dashboardLandLogo, activeIcon: activeMainDashboard, pathName: configJSON.dashboardOrderlabel },
            { label: configJSON.myAccountOrder, labelKey: configJSON.settingOrderlabel, icon: accountsMain, activeIcon: activeMainAccount, pathName: configJSON.myAccountOrderabel },
            { label: configJSON.workOrder, labelKey: configJSON.worksOrderlabel, icon: ordersMain, activeIcon: activeMainOrder, pathName: configJSON.worksOrderlabel },
            { label: configJSON.preferredOrder, labelKey: configJSON.prevendorOrderlabel, icon: vendorMain, activeIcon: activeMainVendor, pathName: configJSON.prevendorOrderlabel },
            { label: configJSON.serviceOrder, labelKey: configJSON.serviceOrderlabel, icon: Service, activeIcon:Service , pathName: configJSON.serviceOrderlabel },
            { label: configJSON.remindersOrder, labelKey: configJSON.reminOrderlabel, icon: remindersMain, activeIcon: activeMainReminder, pathName: configJSON.reminOrderlabel },
            { label: configJSON.mypropOrder, labelKey: configJSON.mypropOrderlabel, icon: propertyMain, activeIcon: activePropertyMain, pathName: configJSON.mypropOrderlabel },
            { label: configJSON.financiaOrder, labelKey: configJSON.financOrderlabel, icon: financialMain, activeIcon: activeOrderFinancial, pathName: configJSON.financOrderlabel },
            { label: configJSON.reportOrder, labelKey: configJSON.reportOrderlabel, icon: reportMain, activeIcon: activeMainReport, pathName: configJSON.reportOrderlabel },
            { label: configJSON.documentLable, labelKey: configJSON.documentLandlord, icon: documents, activeIcon: documentActive, pathName: configJSON.documentLandlord },
            { label: configJSON.chatOrder, labelKey: configJSON.chatOrderlabel, icon: keyMainChain, activeIcon: activeKeyMain, pathName: configJSON.chatOrderlabel },
            { label: configJSON.settingOrder, labelKey: configJSON.settings, icon: settingsMain, activeIcon: activeMainSetting, pathName: configJSON.settings }
          ]}
          data-test-id={"navId"}
          onClickDrawer={this.handleLandlordDrawer}
          keasylogo={keasyIcon}
          onClickSidebar={this.handleMainItemClick}
          openDrawer={this.state.openLandlordDrawer}
        />
        </NavAreaAround>
        <PopupMessage
          open={this.state.messageOpen}
          type={this.state.messageType}
          message={this.state.message}
          handleClose={this.handlePopupMessageClose}
        />
      <Box className="rightBox">
        <Box sx={{ display: "flex", justifyContent: "space-between", width: "96.5%", marginTop: "3rem", alignItems: "center" }}>
          <Typography className="heading">Service Requests</Typography>
          <Button
            sx={{
              width: "80px",
              height: "32px",
              fontWeight: 700,
              backgroundColor: "#FFC123",
              borderRadius: "8px",
              color: "#000000", 
              fontSize: "14px",
              padding: "0px",
              cursor: "pointer",
              fontFamily: "Outfit, sans-serif",
              "&:hover": {
                backgroundColor: "#FFD700", 
              },
            }}
            style={{ textTransform: 'none' }}
            data-test-id="backBtnTest"
            onClick={this.handleGoBack}
          >
            Go Back
          </Button>
        </Box>
  
        <Box className="lease-wrapper">
          <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: "1rem" }}>
            <Box sx={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "space-between", gap: "1rem" }}>
              <Typography className="Title" sx={{ width: "80%", marginBottom: "2rem" }}>
                {this.state.propertyName} - {this.state.unitName}
              </Typography>
            </Box>
          </Box>

          <Box sx={{ borderBottom: "1px solid #D1CBC7", my: { xs: "15px", md: "30px" } }} />

          <Box>
            <Typography className="startHeading">TENANT INFORMATION</Typography>
            <Box sx={{ display: "flex", flexDirection: { xs: "row", md: "row" } }}>
              <Box>
                <Typography className="labelTitle">Name</Typography>
                <Typography className="labelTitle">Email</Typography>
                <Typography className="labelTitle">Phone Number</Typography>
                <Typography className="labelTitle">Number of occupants</Typography>
              </Box>
              <Box>
                <Typography sx={{ fontFamily: "Outfit", fontWeight: 700, fontSize: "16px", lineHeight: "24px", marginTop: "2rem",marginLeft:"2rem" }}>
                  {this.state.tenantName}
                  <span
                    style={{
                      width: "75px",
                      height: "75px",
                      color: "#059669",
                      padding: "2px 8px",
                      gap: "2px",
                      borderRadius: "40px",
                      background: "#D1FAE5",
                      marginLeft: "1rem",
                      fontFamily: "Outfit",
                      fontSize: "12px",
                    }}
                  >
                   CURRENT
                  </span>
                </Typography>
                <Typography className="details">{this.state.tenantEmail}</Typography>
                <Typography className="details">{this.state.tenantPhoneNumber}</Typography>
                <Typography className="details">{this.state.numberOfOccupants}</Typography>
              </Box>
            </Box>
          </Box>

          <Box sx={{ borderBottom: "1px solid #D1CBC7", my: { xs: "15px", md: "30px" } }} />

          <Box> 
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography className="startHeading">REQUEST INFORMATION</Typography>
              <Typography sx={{
                fontFamily: "Outfit",
                fontSize: "12px",
                fontWeight: 700,
                display: 'flex',
                alignItems: "center",
                justifyContent: "center",
                background: "#FEF3C7",
                color: "#D97706",
                width: "90px",
                height: "22px",
                borderRadius: "40px",
              }}>
                PENDING
              </Typography>
            </Box>

            <Box sx={{ display: "flex", flexDirection: { xs: "row", md: "row" } }}>
              <Box>
                <Typography className="labelTitle">Request ID</Typography>
                <Typography className="labelTitle">Category</Typography>
                <Typography className="labelTitle">Work needed</Typography>
                <Typography className="labelTitle">Due Date</Typography>
                <Typography className="labelTitle">Description</Typography>
                <Typography className="labelTitle">Reminder On</Typography>
                <Typography className="labelTitle">Request By</Typography>
                <Typography className="labelTitle">Availability</Typography>
              </Box>
              <Box>
                <Typography className="details">{this.state.requestId}</Typography>
                <Typography className="details">{this.state.subCategory}</Typography>
                <Typography className="details">{this.state.workNeeded}</Typography>
                <Typography className="details">
                {this.state.dueDateDate} | {this.state.dueDateTime}
              </Typography>
                <Typography className="details">{this.state.description}</Typography>
                <Typography className="details">
                  {this.state.remindMeDate} | {this.state.remindMeTime}
                </Typography>
                <Typography className="details">{this.state.createdBy}</Typography>
                <Typography className="details">{this.state.availability}</Typography>
              </Box>
            </Box>

            <Box className="buttonContainer">
              <Button className="reject-button" onClick={this.openRejectModal}>Reject</Button>
              <Button className="download-button"  onClick={this.goToWorkOrder}>Approve</Button>
            </Box>
          </Box>
        </Box>
      </Box>
      </TenantLeaseStyle>
        <Modal open={this.state.isRejectModalOpen} onClose={this.closeRejectModal}>
          <Box sx={modalStyle}>
            <Typography sx={{
                fontFamily:"Outfit",
                fontWeight:700,
                fontSize:"24px",
                lineHeightL:"32px",
            }}>
              Are you sure you want to reject this service request?
            </Typography>
            <Typography sx={{  fontFamily:"Outfit",
                fontWeight:400,
                fontSize:"18px",
                lineHeightL:"24px", }}>
              You are about to reject this service request and this action can't be undone.
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'end',gap:"2rem", width:"100%",mt: 3 }}>
                <Button 
                  sx={{
                    width: "120px",
                    height: "56px",  
                    borderRadius: "8px",
                    fontFamily: "Outfit",
                    fontSize: "16px",
                    fontWeight: 700,
                    lineHeight: "24px",
                    color:"#000000",
                    background:"#FFF1CC",
                    "&:hover": {
                      backgroundColor: "#FFF1CC",
                    },
                  }} 
                  onClick={this.closeRejectModal}
                >
                  Cancel
                </Button>
                <Button 
                  sx={{
                    lineHeight: "24px",
                    fontWeight: 700,
                    borderRadius: "8px",
                    width: "120px",
                    fontFamily: "Outfit",
                    height: "56px",
                    textAlign: "left",
                    gap: "8px",
                    backgroundColor: "#FFC123",
                    fontSize: "16px",
                    color:"#000000",
                    "&:hover": {
                      backgroundColor: "#FFC123",
                    },
                  }} 
                  onClick={this.handleReject}
                >
                  Done
                </Button>

            </Box>
          </Box>
        </Modal>
        </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: '8px',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    alignItems: 'center'
  };
const TenantLeaseStyle = styled(Box)({
    display: "flex",
    fontFamily: "Outfit, sans-serif",
    width: "100%",
  "& .rightBox": {
    display:"flex",
    flexDirection:"column",
    width:"90%",
    marginLeft:"8rem",
    "@media(max-width: 900px)": {
      marginLeft:"0px",
   
    }
  },
  "& .Title": {
    fontFamily: 'Outfit',
    fontWeight: 700,
    color:"#CC9200",
    fontSize: '24px',
    lineHeight: '32px',
    "@media (max-width:1000px)": {
      marginLeft: "0.5rem",
    },
  },
  "& .Title1": {
    display:"flex",
    alignItems:"center",
    gap:"1rem",
    fontFamily: 'Outfit',
    color:"#CC9200",
    fontWeight: 700,
    fontSize: '22px',
    lineHeight: '32px',
    "@media (max-width:1000px)": {
      marginLeft: "0.5rem",
    },
  },
  "& .heading": {
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '32px',
    marginLeft: "2rem",
    fontFamily:"Outfit",

    "@media (max-width:1000px)": {
      marginLeft: "0.5rem",
    },
  },
  "& .lease-wrapper": {
    display:"flex",
    flexDirection:"column",
    border: "1px solid #FFE299",
    borderRadius: "8px",
    margin: "2rem",
    padding: "2rem",
    backroundColor:"red",
  "@media (max-width:600px)": {
      width:"70%",
    }
  },
  "& .labelTitle": {
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '18px',
    marginTop: "2rem",
    fontFamily: 'Outfit',
    color:"#BAB1AB",
  },
  "& .carousel": {
     flex:1,
    "@media (max-width:1000px)": {
      marginLeft: "3rem",
    },
  },
  "& .details": {
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '17px',
    marginLeft: "2rem",
    marginTop: "2rem",
    fontFamily: 'Outfit',
    color:"#000000",
    "@media (max-width:1000px)": {
     
    },
  },
  "& .startHeading": {
    fontWeight: 700,
    fontFamily: 'Outfit',
    fontSize:"16px",
    lineHeight: '24px',
    color:"black",

    "@media (max-width:1000px)": {
      marginLeft: "0.5rem",
    },
  },
  "& .buttonContainer": {
    marginTop:"5rem",
    width:"100%",
    gap:"1rem",
    height:"5%",
    display:"flex",
    justifyContent:"end",
    alignItems:"center",
    "@media (max-width:1400px)": {
      width:"100%",
    }, 
    "@media (max-width:600px)": {
      width:"100%",
    },

  },
  "& .download-button": {
    backgroundColor: "#FFC123",
    borderRadius: "8px",
    width: "184px",
    color: "#000000",
    fontWeight: "bold",
    textTransform: "none",
    fontFamily: "Outfit",
    "&:hover": {
      backgroundColor: "#FFD700",
    },
    "@media (max-width:600px)": {
      width:"60%",
    },
},
"& .reject-button": {
    backgroundColor: "#FFF1CC",
    borderRadius: "8px",
    width: "184px",
    color: "#000000",
    fontWeight: "bold",
    textTransform: "none",
    fontFamily: "Outfit",
    "&:hover": {
      backgroundColor: "#FFF1CC",
    },
    "@media (max-width:600px)": {
      width:"60%",
    },
}
  
});
const NavAreaAround = styled(Box)({
  position: "fixed",
  top: 0,
  left: 0,
  height: "100%",
  overflowY: "auto",
  zIndex: 1000, 
  border: "1px solid rgb(208,203,199)",
  "&::-webkit-scrollbar": {
    display: "none"
  },
  "scrollbar-width": "none",
  "-ms-overflow-style": "none",
  "@media(max-width: 992px)": {
    position: "unset",
    height: "auto",
    overflowY: "hidden",
    border: "none",
  }
});
// Customizable Area End
