import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";

import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config.js");
export const webConfigJSON = require("./config.js");

interface Props {
  navigation: any;
}

interface ResponseJson {
  id: string;
  type: string;
  attributes: {
    name: string;
    emoji_flag: string;
    country_code: string;
  };
}

export interface FileWithPreview extends File {
  preview: string;
}

export interface S {
  activeStep: number;
  mendatInfo: boolean;
  showBankInfo: boolean;
  insuranceRenewalDate: Date | null;
  rentalLicenceRenewalDate: Date | null;
  fireSafetyNextInspectionDate: Date | null;
  pStreetAddress: string;
  pStreetAddress2: string;
  pCity: string;
  pState: string;
  pZip: string;
  rentalUnit: string;
  utilities: string[];
  ownerName: string;
  primaryContactName: string;
  primaryContactEmail: string;
  primaryContactNumberAreaCode: string;
  primaryContactNumberPhone: string;
  primaryStreetAddress: string;
  primaryStreetAddress2: string;
  primaryCity: string;
  primaryState: string;
  primaryZip: string;
  secondaryContactName: string;
  secondaryContactEmail: string;
  secondaryContactNumberAreaCode: string;
  secondaryContactNumberPhone: string;
  preferredCommunicationMethod: string[];
  bankName: string;
  bankRoutingNumber: string;
  bankAccountNumber: string;
  confirmBankAccountDetails: boolean;
  keys: string;
  addtionalInfo: string;
  preferredVendorFor: string[];
  additionalComments: string;
  signature: string;
  currentTenant: FileWithPreview[];
  moveInInspection: FileWithPreview[];
  proofOfOwnership: FileWithPreview[];
  voidedCheck: FileWithPreview[];
  copyOfCurrentInsurance: FileWithPreview[];
  copyOfLocalRentalLicenece: FileWithPreview[];
  fireSafetyInspection: FileWithPreview[];
  otherComplianceDocuments: FileWithPreview[];
  fileUploadError: { [key: string]: string | null };
  countries: Array<{ name: string; emoji_flag: string; country_code: string }>;
  oStreetAddress: string;
  oStreetAddress2: string;
  oCity: string;
  oState: string;
  oZip: string;
  oCountry: string;
  isPrimaryEmailValid: boolean;
  isSecondaryEmailValid: boolean;
  isPrimaryNumberValid: boolean;
  isSecondaryNumberValid: boolean;
  activeMainItem: string;
  openLandlordDrawer: boolean;
  isCountryDropActive: boolean;
}

interface SS {}

export default class LandlordFirstLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  getContriesListApiCallId: string = "";
  addLandlordDetailsApiId: string = "";
  addLandlordDetailsAndGoToDashboardId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      activeStep: 0,
      mendatInfo: false,
      showBankInfo: true,
      insuranceRenewalDate: null,
      activeMainItem: "landlordashboard",
      openLandlordDrawer: false,
      pStreetAddress: "",
      pStreetAddress2: "",
      pCity: "",
      pState: "",
      pZip: "",
      rentalUnit: "",
      utilities: ["Laundry room on-site managed by landlord"],
      ownerName: "",
      primaryContactName: "",
      primaryContactEmail: "",
      primaryContactNumberAreaCode: "",
      primaryContactNumberPhone: "",
      primaryStreetAddress: "",
      primaryStreetAddress2: "",
      primaryCity: "",
      primaryState: "",
      primaryZip: "",
      secondaryContactName: "",
      secondaryContactEmail: "",
      secondaryContactNumberAreaCode: "",
      secondaryContactNumberPhone: "",
      preferredCommunicationMethod: [],
      bankName: "",
      bankRoutingNumber: "",
      bankAccountNumber: "",
      confirmBankAccountDetails: false,
      rentalLicenceRenewalDate: null,
      fireSafetyNextInspectionDate: null,
      keys: "",
      addtionalInfo: "",
      preferredVendorFor: [],
      additionalComments: "",
      signature: "",
      currentTenant: [],
      moveInInspection: [],
      proofOfOwnership: [],
      voidedCheck: [],
      copyOfCurrentInsurance: [],
      copyOfLocalRentalLicenece: [],
      fireSafetyInspection: [],
      otherComplianceDocuments: [],
      fileUploadError: {},
      countries: [],
      oStreetAddress: "",
      oStreetAddress2: "",
      oCity: "",
      oState: "",
      oZip: "",
      oCountry: "",
      isPrimaryEmailValid: true,
      isSecondaryEmailValid: true,
      isPrimaryNumberValid: true,
      isSecondaryNumberValid: true,
      isCountryDropActive: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.getCountries();
  }

  getCountries = () => {
    const header = {
      "Content-Type": configJSON.getCountriesApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getContriesListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCountriesAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getCountriesApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getContriesListApiCallId) {
        let countries = responseJson?.data?.map((item: any) => {
          return {
            name: item.attributes.name,
            emoji_flag: item.attributes.emoji_flag,
            country_code: item.attributes.country_code,
          };
        });

        this.setState({ countries }, () => {});
      } else if (
        apiRequestCallId === this.addLandlordDetailsAndGoToDashboardId
      ) {
        this.props.navigation.navigate("Dashboard");
      }
       else if(apiRequestCallId=== this.addLandlordDetailsApiId){
        this.props.navigation.navigate("Dashboard");

       }
    }
  }

  disableNextButton(step: number) {
    switch (step) {
      case 0:
        if (
          this.state.currentTenant.length !== 0
        ) {
          return false;
        } else {
          return true;
        }
      case 1:
        if (this.state.proofOfOwnership.length !== 0) {
          return false;
        } else {
          return true;
        }
      case 2:
        if (
          this.state.isPrimaryEmailValid &&
          this.state.isPrimaryNumberValid
        ) {
          return false;
        } else {
          return true;
        }
      case 3:
          return false;
      case 4:
        return false;
      case 5:
        return false;
      case 6:
        return false;

      default:
        return true;
    }
  }

  handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  handleFileChange = (stateKey: string, file: FileWithPreview[]) => {
    
    this.setState({ [stateKey]: file } as unknown as Pick<S, keyof S>,
      () => {
        this.validateFiles(stateKey as keyof S);
      }
    );
  };

  validateFiles = (stateKey: keyof S) => {

    const files = Array.isArray(this.state[stateKey]) && this.state[stateKey] !== null
                  ? (this.state[stateKey] as FileWithPreview[])
                  : [];

    if (files.length === 0) {
      this.setState((prevState) => 
        ({
          fileUploadError: {
            ...prevState.fileUploadError,
            [stateKey]: true,
          },
        } as unknown as Pick<S, keyof S>));
    } else {
      this.setState((prevState) => 
        ({
          fileUploadError: {
            ...prevState.fileUploadError,
            [stateKey]: false,
          },
        } as unknown as Pick<S, keyof S>));
    }
  };

  validateField = (field: keyof S) => {
    if(field in this.state.fileUploadError){
      return this.state.fileUploadError[field];
    }
  };

  validateEmail = (email: string) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  clearSignature = () => {
    this.setState({ signature: "" });
  };

  validateNumber = (number: string) => {
    const regex = /^[0-9]*$/; 
    return regex.test(number);
  };

  handleChange = (key: keyof S, value: any) => {
    let isValid = true;
    const updateState = (key: keyof S, value: any, validationKey?: string, isValid?: boolean) => {
      this.setState({
        [key]: value,
        ...(validationKey && { [validationKey]: isValid }),
      } as Pick<S, keyof S>);
    };
  
    const filterAlpha = (value: string) => value.replace(/[^a-zA-Z\s]/g, "").replace(/^\s+/, "");
    const filterNumeric = (value: string, maxLength?: number) => value.replace(/\D/g, "").replace(/^\s+/, "").substring(0, maxLength);

    switch (key) {
      case "primaryContactEmail":
      case "secondaryContactEmail":
        isValid = this.validateEmail(value);
        updateState(key, value, key === "primaryContactEmail" ? "isPrimaryEmailValid" : "isSecondaryEmailValid", isValid);
        break;
        
      case "primaryContactNumberPhone":
      case "secondaryContactNumberPhone":
        isValid = this.validateNumber(value);
        updateState(key, filterNumeric(value, 10), key === "primaryContactNumberPhone" ? "isPrimaryNumberValid" : "isSecondaryNumberValid", isValid);
        break;
        
      case "primaryContactName":
      case "bankName":
      case "oCity":  
      case "oState": 
      case "pState":
      case "ownerName":
      case "secondaryContactName":
        const maxLength = key === "bankName" ? 34 : undefined;
        updateState(key, filterAlpha(value).substring(0, maxLength));
        break;
  
      case "bankRoutingNumber":
        updateState(key, filterNumeric(value, 9));
        break;
        
      case "bankAccountNumber":
        updateState(key, value.replace(/[^a-zA-Z0-9]/g, "").replace(/^\s+/, "").substring(0, 14));
        break;
        
      case "primaryContactNumberAreaCode":
        this.setState({isCountryDropActive: false });
        updateState(key, filterNumeric(value, 4));
        break;
      case "secondaryContactNumberAreaCode":
        updateState(key, filterNumeric(value, 4));
        break;

      case "oZip":
      case "pZip":
      case "primaryZip":
        updateState(key, filterNumeric(value, 6));
        break;
  
      default:
        updateState(key, value.replace(/^\s+/, ""));
    }
  };
  

  handleCheckboxChange =
    (key: keyof S) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, checked } = event.target;
      this.setState((prevState) => {
        const list = prevState[key] as string[];
        if (checked) {
          // Add the checked item to the state
          return { [key]: [...list, name] } as unknown as Pick<S, keyof S>;
        } else {
          // Remove the unchecked item from the state
          return {
            [key]: list.filter((item) => item !== name),
          } as unknown as Pick<S, keyof S>;
        }
      });
      console.log(this.state.preferredVendorFor);
      
    };

  handlePreferredCommunicationChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    this.setState((prevState) => {
      const newPreferredCommunication = [
        ...prevState.preferredCommunicationMethod,
      ];
      if (newPreferredCommunication.includes(value)) {
        const index = newPreferredCommunication.indexOf(value);
        newPreferredCommunication.splice(index, 1);
      } else {
        if (newPreferredCommunication.length < 2) {
          newPreferredCommunication.push(value);
        }
      }
      return {
        preferredCommunicationMethod: newPreferredCommunication,
      } as Pick<S, keyof S>;
    });
  };

  isCheckboxDisabled = (value: string) => {
    return (
      this.state.preferredCommunicationMethod.length >= 2 &&
      !this.state.preferredCommunicationMethod.includes(value)
    );
  };

  handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    this.setState((prevState) => {
      const utilities = prevState.utilities;
      // Remove any previous laundry related values and add the new one
      const updatedUtilities = utilities.filter(
        (utility) =>
          ![
            "Laundry room on-site managed by landlord",
            "Laundry room on-site managed by vendor",
            "Washer and dryer in unit owned by tenant",
            "Washer and dryer in unit owned by landlord",
          ].includes(utility)
      );
      return { utilities: [...updatedUtilities, value] };
    });
  };

  handleNext = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (this.state.activeStep === 3 && !this.state.mendatInfo) {
      this.setState({ mendatInfo: true, showBankInfo: false });
      return;
    }

    window.scrollTo(0, 500);
    this.setState((prevState) => ({
      activeStep: prevState.activeStep + 1,
      mendatInfo: false,
    }));
  };

  handleNext2 = () => {
    if (this.state.activeStep === 3 && !this.state.mendatInfo) {
      this.setState({ mendatInfo: true, showBankInfo: false });
      return;
    }

    this.setState((prevState) => ({
      activeStep: prevState.activeStep + 1,
      mendatInfo: false,
    }));
  };
  handleBack = () => {
    if (this.state.activeStep === 3 && this.state.mendatInfo) {
      this.setState({ mendatInfo: false, showBankInfo: true });
      return;
    }

    this.setState((prevState) => ({
      showBankInfo: true,
      activeStep: prevState.activeStep - 1,
    }));
  };

  handleGoToDashboard = () => {
    const header = {
      token: localStorage.getItem("authToken"),
    };
   
    const formData = this.formDataAppend();

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addLandlordDetailsAndGoToDashboardId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addLandlordDetailsApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addLandlordDetailsApiApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
  handleDateChange = (key: keyof S) => (newValue: Date | null) => {
    this.setState({ [key]: newValue } as unknown as Pick<S, keyof S>);
  };

  onAddTask = (event: React.FormEvent<HTMLFormElement>) => {
    event?.preventDefault();
    const header = {
      token: localStorage.getItem("authToken"),
    };

    const formData = this.formDataAppend();

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addLandlordDetailsApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addLandlordDetailsApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addLandlordDetailsApiApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleMainItemClick = (labelKey: string) => {
    this.setState({ activeMainItem: labelKey });
  };

  handleLandlordDrawer = () => {
    this.setState({
      openLandlordDrawer: !this.state.openLandlordDrawer,
    });
  };

  handleCountryCodeDropdown = () => {
    this.setState({ isCountryDropActive: true });
  };

  handleCloseCountry = () => {
    this.setState({ isCountryDropActive: false });
  };

  handleCodeArea = ()=> {
    return this.state.isCountryDropActive ? "blockId" : "noneId"
  };

  handleAreaCode = ()=> {
    return this.state.isCountryDropActive ? "noneId" : "blockId"
  };

  formDataAppend = () =>{
   
    const formData = new FormData();
    formData.append(
      "landlord_account[street_address]",
      this.state.pStreetAddress
    );
    formData.append(
      "landlord_account[street_address_line2]",
      this.state.pStreetAddress2
    );
    formData.append("landlord_account[city]", this.state.pCity);
    formData.append("landlord_account[state]", this.state.pState);
    formData.append("landlord_account[zip_code]", String(this.state.pZip));
    formData.append(
      "landlord_account[number_of_rental_units]",
      String(this.state.rentalUnit)
    );

    this.state.utilities.forEach((utility) => {
      formData.append("landlord_account[utilities][]", utility);
    });
    formData.append(
      "landlord_account[current_tenant]",
      this.state.currentTenant[0] as Blob
    );
    formData.append(
      "landlord_account[move_in_inspection]",
      this.state.moveInInspection[0] as Blob
    );
    formData.append("landlord_account[owner_name]", this.state.ownerName);
    formData.append(
      "landlord_account[owner_address][]",
      this.state.oStreetAddress
    );
    formData.append(
      "landlord_account[owner_address][]",
      this.state.oStreetAddress2
    );
    formData.append("landlord_account[owner_address][]", this.state.oCity);
    formData.append("landlord_account[owner_address][]", this.state.oState);
    formData.append("landlord_account[owner_address][]", this.state.oZip);
    formData.append("landlord_account[owner_address][]", this.state.oCountry);
    formData.append(
      "landlord_account[proof_of_ownership]",
      this.state.proofOfOwnership[0] as Blob
    );
    formData.append(
      "landlord_account[primary_contact_name]",
      this.state.primaryContactName
    );
    formData.append(
      "landlord_account[primary_contact_email]",
      this.state.primaryContactEmail
    );
    formData.append(
      "landlord_account[primary_contact_number][]",
      this.state.primaryContactNumberAreaCode
    );
    formData.append(
      "landlord_account[primary_contact_number][]",
      this.state.primaryContactNumberPhone
    );
    formData.append(
      "landlord_account[primary_contact_address][]",
      this.state.primaryStreetAddress
    );
    formData.append(
      "landlord_account[primary_contact_address][]",
      this.state.primaryStreetAddress2
    );
    formData.append(
      "landlord_account[primary_contact_address][]",
      this.state.primaryCity
    );
    formData.append(
      "landlord_account[primary_contact_address][]",
      this.state.primaryState
    );
    formData.append(
      "landlord_account[primary_contact_address][]",
      this.state.primaryZip
    );
    this.state.preferredCommunicationMethod.forEach((method) => {
      formData.append("landlord_account[primary_way_of_communication]", method);
    });
    formData.append(
      "landlord_account[secondary_contact_name]",
      this.state.secondaryContactName
    );
    formData.append(
      "landlord_account[secondary_contact_email]",
      this.state.secondaryContactEmail
    );
    formData.append(
      "landlord_account[secondary_contact_number][]",
      this.state.secondaryContactNumberAreaCode
    );
    formData.append(
      "landlord_account[secondary_contact_number][]",
      this.state.secondaryContactNumberPhone
    );
    formData.append("landlord_account[bank_name]", this.state.bankName);
    formData.append(
      "landlord_account[bank_routing_number]",
      String(this.state.bankRoutingNumber)
    );
    formData.append(
      "landlord_account[bank_account_number]",
      this.state.bankAccountNumber
    );
    formData.append(
      "landlord_account[voided_check]",
      this.state.voidedCheck[0] as Blob
    );
    if (this.state.insuranceRenewalDate) {
      formData.append(
        "landlord_account[insurance_renewal_date]",
        this.state.insuranceRenewalDate?.toISOString().split("T")[0]
      );
    }
    formData.append(
      "landlord_account[copy_of_current_insurance]",
      this.state.copyOfCurrentInsurance[0] as Blob
    );
    if (this.state.rentalLicenceRenewalDate) {
      formData.append(
        "landlord_account[rental_license_renewal_date]",
        this.state.rentalLicenceRenewalDate?.toISOString().split("T")[0]
      );
    }
    formData.append(
      "landlord_account[copy_of_rental_license]",
      this.state.copyOfLocalRentalLicenece[0] as Blob
    );
    if (this.state.fireSafetyNextInspectionDate) {
      formData.append(
        "landlord_account[fire_safety_next_inspection_date]",
        this.state.fireSafetyNextInspectionDate?.toISOString().split("T")[0]
      );
    }
    formData.append(
      "landlord_account[fire_safety_inspection_copy]",
      this.state.fireSafetyInspection[0] as Blob
    );
    formData.append(
      "landlord_account[other_compliance]",
      this.state.otherComplianceDocuments[0] as Blob
    );
    formData.append("landlord_account[where_are_the_keys]", this.state.keys);
    formData.append(
      "landlord_account[additional_info]",
      this.state.addtionalInfo
    );
    formData.append("landlord_account[preferred_vendor_for]", JSON.stringify(this.state.preferredVendorFor));
    formData.append(
      "landlord_account[additional_comments_or_request]",
      this.state.additionalComments
    );
    formData.append("landlord_account[signature]", this.state.signature);

    return formData;
  }

  getHelperText = (field: string) => {

    if(field === "primaryEmail") {
      if(this.state.isPrimaryEmailValid){
        return "example@example.com";
      } else {
        return "Please enter a valid email address.";
      }
    }
    if(field === "secondaryEmail") {
      if(this.state.isSecondaryEmailValid){
        return "example@example.com";
      } else {
        return "Please enter a valid email address.";
      }
    }
    return null;
  };
}
