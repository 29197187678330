Object.defineProperty(exports, "__esModule", {
    value: true,
  });
  
  // Customizable Area Start
  exports.apiContentType = "application/json";
  exports.confirmPaymentMethod = "POST";
  exports.validationApiMethodType = "GET";
  exports.stripePaymentIntent = "stripe_integration/payment_intents"
  
  exports.stripePublishableKey = ""
  exports.stripeMerchantDisplayName = "Example.com"
  exports.stripeMerchantIdentifier = "stripeMerchantIdentifier" //used for apple pay
  exports.urlScheme = "rnbuildingblockmasterapp" //app deep linking url
  exports.dashboardOrder = "Dashboard";
exports.myAccountOrder = "My Account";
exports.workOrder = "Work Orders";
exports.preferredOrder = "Preferred Vendors";
exports.remindersOrder = "Reminders";
exports.mypropOrder = "My Properties";
exports.financiaOrder = "Financial";
exports.reportOrder = "Delinquency Report";
exports.document = "Documents"
exports.chatOrder = "Chat with Keasy";
exports.settingOrder = "Settings";
exports.dashboardOrderlabel = "Dashboard";
exports.myAccountOrderabel = "landlordaccount";
exports.worksOrderlabel = "Appointments";
exports.prevendorOrderlabel = "TaskList";
exports.reminOrderlabel = "AutomatedPrioritization";
exports.mypropOrderlabel = "PortfolioManagement";
exports.financOrderlabel = "Analytics";
exports.reportOrderlabel = "VisualAnalytics";
exports.documentlabel = "Cfdocumentforwarding4"
exports.chatOrderlabel = "Chat";
exports.settingOrderlabel = "Settings2";
exports.propertiesHead = "Properties";
exports.newWorkOrders = "New work order";
exports.appointmentAPiEndPoint = "bx_block_automaticreminders/reminders";
exports.calendar = "Calendar";
exports.filtersHead = "Filters";
exports.serviceOrder5 = "Service Requests";
exports.serviceLable5 = "LandlordServiceRequestsDashboard";
exports.getForwardingPrizeAPIEndPoint = "account_block/documents/show_forward_price";
exports.getScanningPrizeAPIEndPoint = "account_block/documents/show_scan_price";
exports.getCardlistAPIEndPoint = "bx_block_stripe_integration/document_payments/fetch_existing_card";
  // Customizable Area End
  