import React from "react";

// Customizable Area Start
import { Box, Typography as MuiTypography,
  styled,
  Checkbox,
  Divider,
  Button
} from '@mui/material';
import NavigationSidebar from "../../../components/src/NavigationSidebar.web";
import { dashboardOrderLogo, activeOrderDashboard, accountsOrder, activeOrderAccount, ordersIcon, activeOrderIcon, vendorOrder, activeOrderVendor, remindersOrder, activeOrderReminder, propertyOrder, activePropertyOrder, financialOrder, activeOrderFinancial, reportOrder, activeOrderReport, keyOrderChain, activeKeyOrder, settingsOrder, activeOrderSetting, keasIcon, hexagon, halfHexagon} from '../../analytics/src/assets';
import {documentActive,documents } from '../../automaticreminders/src/assets';
import PopupMessage from "../../../components/src/PopupMessage.web";
export const serviceActive4 = require("../../appointmentmanagement/assets/service.png");
export const serviceInactive4 = require("../../appointmentmanagement/assets/serviceRequest.png");
import  CustomNavbar from "../../../components/src/CustomNavbar.web";

// Customizable Area End

import StripeTestController, {
  Props,
  configJSON,
} from "./StripTestController";
import StripePayments from "../../../components/src/StripePayement.web";

export default class StripeTest extends StripeTestController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  screenProps = {
    navigation: this.props.navigation,
    id: "Cfdocumentforwarding4",
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
      <WorkOrderStyle>
        <NavArea>
        <NavigationSidebar
          data-test-id={"navId"}
          activeItem={this.state.activeOrderItem}
          sidebarItems={[
            { label: configJSON.dashboardOrder, labelKey: configJSON.dashboardOrderlabel, icon: dashboardOrderLogo, activeIcon: activeOrderDashboard, pathName: configJSON.dashboardOrderlabel },
            { label: configJSON.myAccountOrder, labelKey: configJSON.settingOrderlabel, icon: accountsOrder, activeIcon: activeOrderAccount, pathName: configJSON.myAccountOrderabel },
            { label: configJSON.workOrder, labelKey: configJSON.worksOrderlabel, icon: ordersIcon, activeIcon: activeOrderIcon, pathName: configJSON.worksOrderlabel },
            { label: configJSON.preferredOrder, labelKey: configJSON.prevendorOrderlabel, icon: vendorOrder, activeIcon: activeOrderVendor, pathName: configJSON.prevendorOrderlabel },
            { label: configJSON.serviceOrder5, labelKey: configJSON.serviceLable5, icon: serviceInactive4, activeIcon:serviceActive4 , pathName: configJSON.serviceLable5 },
            { label: configJSON.remindersOrder, labelKey: configJSON.reminOrderlabel, icon: remindersOrder, activeIcon: activeOrderReminder, pathName: configJSON.reminOrderlabel },
            { label: configJSON.mypropOrder, labelKey: configJSON.mypropOrderlabel, icon: propertyOrder, activeIcon: activePropertyOrder, pathName: configJSON.mypropOrderlabel },
            { label: configJSON.financiaOrder, labelKey: configJSON.financOrderlabel, icon: financialOrder, activeIcon: activeOrderFinancial, pathName: configJSON.financOrderlabel },
            { label: configJSON.reportOrder, labelKey: configJSON.reportOrderlabel, icon: reportOrder, activeIcon: activeOrderReport, pathName: configJSON.reportOrderlabel },
            { label: configJSON.document, labelKey: configJSON.documentlabel, icon: documents, activeIcon: documentActive, pathName: configJSON.documentlabel },
            { label: configJSON.chatOrder, labelKey: configJSON.chatOrderlabel, icon: keyOrderChain, activeIcon: activeKeyOrder, pathName: configJSON.chatOrderlabel },
            { label: configJSON.settingOrder, labelKey: configJSON.workOrder, icon: settingsOrder, activeIcon: activeOrderSetting, pathName: configJSON.settingOrderlabel }
          ]}
          onClickSidebar={this.handleSideNav}
          keasylogo={keasIcon}
          openDrawer={this.state.openWorkOrder}
          onClickDrawer={this.handleWordDrawer}

        />
        </NavArea>
        <PopupMessage
          open={this.state.messageOpen}
          type={this.state.messageType}
          message={this.state.message}
          handleClose={this.handlePopupMessageClose}
        />
        <WorkArea display={"flex"} flexDirection={"column"} justifyContent={"space-between"} width={"100%"}
         >     
          <MainWorkContainer>
            <WorkStyles>
            <CustomNavbar 
              {...this.screenProps}
              showChatButton={true}   
              showPropertiesButton={true} 
            />
            <Box sx={{display: "flex",justifyContent: "flex-end", marginTop: "2rem"}}>
            <Button className="goBackBtn" style={{ textTransform: 'none' }} data-test-id="backBtnTest" onClick={this.navigateToAppointments}>Go Back</Button>
            </Box>
            <Box className="MainBox">
                <Box className="stripeBox">
                    {this.state.cardList && ( 
                  <StripePayments
                    navigation={this.props.navigation}
                    data-test-id="stripeTestId"
                    payAmount="33"
                    handleFailDialogBox={this.handlePopupMessageClose}
                    handleSuccessDialogBox={this.handlePopupMessageClose}
                    payType=""
                    payPlan=""
                    cards={this.state.cardList} 
                  />
                )}
                </Box>
                <KeyContainer>
                    <Box className="yellowBox">
                        <MuiTypography className="summary" style={{marginBottom:"1.5rem"}}>BILLING SUMMARY</MuiTypography>
                        <Box className="paymentSummary">
                        <MuiTypography className="paymentSummaryHeading">Document Forwarded</MuiTypography>
                        <MuiTypography className="summary">{this.state.action == "scan" ? this.state.prizingDetails.documents_scanned : this.state.prizingDetails.documents_forwarded}</MuiTypography>
                        </Box>
                        <Divider style={{backgroundColor: "#BAB1AB", marginBottom: "1rem"}}/>
                        <Box className="paymentSummary">
                        <MuiTypography className="paymentSummaryHeading summary" style={{color: "#000000"}}>Total Amout Payable</MuiTypography>
                        <MuiTypography className="summary">${this.state.action == "scan" ? this.state.prizingDetails.total_price_for_scan : this.state.prizingDetails.total_price_for_forward}</MuiTypography>
                        </Box>
                    </Box>
                </KeyContainer>
            </Box>
            
            </WorkStyles>
          </MainWorkContainer>
          <Box className="bgImgWrapper" data-test-id="bgImgId">
            <Box className="backgroundImage">
              <img src={hexagon} alt="hexagon-one" className="hexImg" />
            </Box>
            <Box className="backgroundImage">
              <img src={hexagon} alt="hexagon-two" className="hexImg" />
            </Box>
            <Box className="backgroundImage">
              <img src={hexagon} alt="hexagon-three" className="hexImg" />
            </Box>
            <Box className="backgroundImage">
              <img src={halfHexagon} alt="half-hexagon" className="hexImg" />
            </Box>
          </Box>
        </WorkArea>
      </WorkOrderStyle>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const KeyContainer = styled(Box)({
    width: "40%",
  "@media(max-width: 467px)": {
    flexDirection: "column",
    gap: "10px"
  },
  "& .yellowBox":{
    border: "1px solid #FFC123",
    padding: "1rem",
    borderRadius: "8px",
    paddingBottom: "3rem",
  },
  "& .summary":{
    fontFamily:"Outfit",
    fontWeight: 700,
    color: "#000000"
  },
  "& .paymentSummary":{
    display: "flex",
    marginBottom: "1.5rem"
  },
  "& .paymentSummaryHeading": {
    width: "40%",
    fontFamily:"Outfit",
    color: "#A3978F",
    marginRight: "2rem",
  },
});

const WorkArea = styled(Box)({
  marginLeft: "90px", 
  flexGrow: 1,
  overflowY: "auto",
  height: "100vh",
  "@media(max-width: 992px)": {
    marginLeft: "0px", 
    height: "auto",
    overflowY: "unset"
  }
});
const WorkOrderStyle = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  "@media(max-width: 992px)": {
    flexDirection: "column"
  },
  "&.webStyle": {
    backgroundColor: "red"
  },
  "& .bgImgWrapper": {
    display: "flex",
    justifyContent: "flex-end",
    width: "97%",
    marginTop: "2rem",
    "@media (max-width:1220px)": {
      width: "88%"
    },
    "@media (max-width:992px)": {
      display: "none",
    }
  },
  "& .backgroundImage": {
    width: "250px",
    "@media (max-width:1220px)": {
      width: "180px"
    },
  },
  "& .hexImg": {
    objectFit: "cover"
  }
});
const CustomCheckbox = styled(Checkbox)({
  color: "rgba(0, 0, 0, 0.54)",
  marginLeft:"10px",
  "&.Mui-checked": {
    color: "rgb(195,149,49)"
  },
  "&.MuiCheckbox-indeterminate": {
    color: "rgb(195,149,49)"
  }
});
const NavArea = styled(Box)({
  position: "fixed",
  top: 0,
  left: 0,
  height: "100%",
  overflowY: "auto",
  zIndex: 1000, 
  border: "1px solid rgb(208,203,199)",
  "&::-webkit-scrollbar": {
    display: "none"
  },
  "scrollbar-width": "none",
  "-ms-overflow-style": "none",
  "@media(max-width: 992px)": {
    position: "unset",
    height: "auto",
    overflowY: "hidden",
    border: "none",
  }
});
const commonButtonStyles = {
  width: "76px",
  height: "44px",
  fontFamily: "Outfit",
  fontSize: "16px",
  fontWeight: 700,
  color: "#000000",
  textAlign: "center",
  backgroundColor: "#FFC123",
  borderRadius: "8px",
  textTransform: "none",
  cursor: "pointer",
  boxShadow: "none",
  "&:hover": {
    backgroundColor: "#FFC123", 
  },
};
const WorkStyles = styled(Box)({
  width: "90%",
  "& .search-main-content": {
    display: "flex",
    justifyContent: "space-between",
    "@media(max-width:600px)": {
      width: "100%",
      position: "relative",
      "@media(max-width: 420px)": {
        gap: "5px"
      }
    },
  },
  "& .goBackBtn": {
    width: "80px",
    height: "32px",
    fontWeight: 700,
    backgroundColor: "#FFC123",
    borderRadius: "8px",
    color: "#000000", 
    fontSize: "14px",
    padding: "0px",
    letterSpacing: 0,
    cursor: "pointer",
    fontFamily: "Outfit, sans-serif",
    "&:hover": {
      backgroundColor: "#FFD700", 
    },
  },
  "& .MainBox": {
    display: "flex",
    gap: "1rem",
    marginTop: "2rem",
  },
  "& .stripeBox": {
    width: "60%",
    marginRight: "2rem",
  },
  
  "& .left": {
    display: "flex",
    gap: "1rem",
    "@media(max-width: 600px)": {
      gap: "0.5rem",     },
  },
  "& .right": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "start",
    gap: "1rem",
    "@media(max-width: 600px)": {
      display: "grid", 
      gridTemplateColumns: "1fr 1fr", 
      gap: "0.5rem", 
    },
  },
  "& .apply-btn": {
    ...commonButtonStyles, 
  },
  "& .apply-btn-desktop": {
    display: "flex", 
  },

  "& .search-contents": {
    border: "1px solid #CBD5E1",
    borderRadius: "50px",
    height: "44px",
    display: "flex",
    paddingLeft: "15px",
    alignItems: "center",
    gap: "13px",
  },
  "& .search-icons": {
    height: "24px",
    width: "24px"
  },
  "& .search-text": {
    fontWeight: 400,
    fontSize: "16px",
    color: "#A3978F",
    "@media(max-width: 420px)": {
      fontSize: "12px"
    }
  },
  "& .search-inputs": {
    width: "100%"
  },
  "& .icon": {
    mixBlendMode: "multiply"
  },
  "& .image-icon": {
    width: "16px",
    height: "16px"
  },
  "& .text-data": {
    fontSize: "16px",
    fontWeight: 700,
    "@media(max-width: 420px)": {
      fontSize: "12px"
    }
  },
  "& .new-data": {
    fontSize: "18px",
    color: "#CC9200",
    fontWeight: 700,
    "@media(max-width: 420px)": {
      fontSize: "12px"
    }
  },
  "& .filter-content": {
    display: "flex",
    alignItems: "center",
    paddingRight: "20px"
  },
  "& .filter": {
    display: "flex",
    width:"100%",
    alignItems: "center",
    gap: "1rem",
    flexDirection: 'row', 
    "@media (max-width: 900px)": {
      flexDirection: "column",
      alignItems: "start",
    }
  },
  "& .filter-data": {
    fontFamily: "Outfit",
    fontSize: "12px",
    fontWeight: 700,
    color: "#000000",
    lineHeight: "10px",
  },
  "& .clear-data": {
    fontSize: "12px",
    fontWeight: 700,
    color: "#CC9200",
    cursor: "pointer"
  },
  "& .isUnitsDisabled": {
    display: "none"
  }
});
const MainWorkContainer = styled(Box)({
  width: "100%",
  display: "flex",
  fontFamily: "Outfit",
  alignItems: "center",
  flexDirection: "column",
  "@media(max-width: 992px)": {
    padding: "0px 0px 0px 0px"

  }
});

// Customizable Area End
