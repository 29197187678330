import React from "react";

import {
    Container,
    Box,
    Grid,
    Button,
    
    Typography,
    // Customizable Area Start
    StepLabel,
    Stepper,
    Step,
    Divider,
    FormControl,
    MenuItem,
    TextField,
    RadioGroup,
    FormControlLabel,
    FormGroup,
    FormLabel,
    styled,
    Select,
    IconButton,
    Chip
    // Customizable Area End
} from "@mui/material";

// Customizable Area Start
import {
    createTheme,
    ThemeProvider,
  
} from "@mui/material/styles";
import NavigationSidebar from "../../../components/src/NavigationSidebar.web";
import {AddCircleOutline, Close} from '@mui/icons-material';
import {documentActive,documents } from '../../automaticreminders/src/assets';
import { activeMainDashboard, activeMainSetting, activeMainAccount, activeMainOrder, activeMainVendor, activeMainReminder, activePropertyMain, activeMainFinancial, activeMainReport, keyMainChain, activeKeyMain, settingsMain, keasyIcon, accountsMain, dashboardLandLogo, financialMain, ordersMain, propertyMain, remindersMain, reportMain, vendorMain, bottomImageIcon } from "./assets";
import PopupMessage from "../../../components/src/PopupMessage.web";
import FileUpload from "../../accountgroups/src/FileUpload";
import { CatType, FileWithPreview, SubCategory } from "./VendorEditDashboardController.web";
import CustomDatePicker from "../../../components/src/CustomDatePicker.web";
import { CustomRadioButton } from "../../../components/src/CustomRadioButton.web";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import  CustomNavbar from "../../../components/src/CustomNavbar.web";
const getStepsedit = () => {
    return ['Contact information', 'Taxes and payments', 'Compliance'];
}
const theme = createTheme({
    palette: {
        primary: {
            main: "#6200ee",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});

// Customizable Area End

import VendorEditDashboardController, {
    Props,
    configJSON,

} from "./VendorEditDashboardController.web";

export default class VendorDashboardEdit extends VendorEditDashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
  renderDividerEnrolfirst() {
    return (
      <>
        <Grid container justifyContent="flex-end" sx={{ margin: "2rem 0rem" }}>
          <Grid item md={12} sm={12} xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <CustomDivider />
            </Box>
          </Grid>
        </Grid>
      </>
    )
  }

  getStepContentEdit(activeStep: number){
    const {
      vendorType, ownerName, vendorTrade,
      taxpayerName, taxpayerID, send1099,
      businessLicense, licenseExpDate, businessInsurance,
      insuranceExpDate, epaCertificationLicense, otherCertificate,
      email,
      address,
      epaCerLicenseExpDate,
      otherCertificateExpDate,
      notes,
      useOnlinePayables,
      paymentType,
      vendorBankRoutingNumber,
      vendorBankAccountNumber,
      portalActivated,
      companyName,
      stateLicense,
      stateLicenseExpDate,
      stateInsurance,
      stateInsuranceExpDate
    } = this.state;

    return(
        <ThemeProvider theme={theme}>
            <Box sx={{ width: "100%" }}>
                {activeStep === 0 && (
                    <Box>
                        <FirstGrid container>
                            <Grid item md={3} xs={12}>
                                <VendorInfo>
                                    <Typography style={webStyle.labeltextsyle2}>{configJSON.vendorInformation}</Typography>
                                </VendorInfo>
                            </Grid>
                            <Grid item md={7} xs={12}>
                                <Box>
                                    <label style={webStyle.labeltextsyle}>{configJSON.companyName}</label>
                                    <TextField
                                        data-test-id="companyNameId"
                                        variant="outlined"
                                        fullWidth
                                        name="companyName"
                                        value={companyName}
                                        onChange={this.handleChange}
                                        FormHelperTextProps={{
                                            sx: {
                                                margin: "5px 0px 0px 0px",
                                            },
                                        }}
                                        sx={webStyle.marginManage}
                                        error={this.state.errors.companyName}
                                        helperText={this.getHelperText("companyName")}
                                    />
                                </Box>
                                <Box style={webStyle.marginBorderstyle}>
                                    <label style={webStyle.labeltextsyle}>{configJSON.vendorType}</label>
                                    <FormControl fullWidth sx={webStyle.marginManage}>
                                        <Select
                                            data-test-id = "vendorTypeId"
                                            labelId="vendor-type-label"
                                            name="vendorType"
                                            value={vendorType}
                                            onChange={this.handleVendorType}
                                            IconComponent={KeyboardArrowDownIcon} 
                                      >
                                            {this.state.vendorsItem.map((items: CatType) => {
                                                return (
                                                    items.attributes.name === "Vendor Types" && (
                                                    items.attributes.sub_categories.map((vendorTypes: SubCategory, vendorIndex: number) => {
                                                return(
                                                    <MenuItem key={vendorIndex} value={vendorTypes.name}>{vendorTypes.name}</MenuItem>
                                                )
                                                        })
                                                    )
                                                )
                                            })
                                            }
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box style={webStyle.marginBorderstyle}>
                                    <label style={webStyle.labeltextsyle}>{configJSON.ownerName}</label>
                                    <TextField
                                        data-test-id = "ownerNameId"
                                        variant="outlined"
                                        fullWidth
                                        name="ownerName"
                                        value={ownerName}
                                        onChange={this.handleChange}
                                        sx={webStyle.marginManage}
                                        error={this.state.errors.ownerName}
                                        helperText={this.getHelperText("ownerName")}
                                        FormHelperTextProps={{
                                            sx: {
                                                margin: "5px 0px 0px 0px",
                                            },
                                        }}
                                    />
                                </Box>
                                <Box style={webStyle.marginBorderstyle}>
                                    <FormLabel htmlFor="vendorTrade" sx={webStyle.input_label}>
                                        {configJSON.venTrade}
                                    </FormLabel>
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        sx={{
                                            border: 1,
                                            borderColor: "grey.400",
                                            borderRadius: "8px",
                                            "&:hover": {
                                                borderColor: "black",
                                            },
                                        }}
                                    >
                                        <TextField
                                            data-test-id="vendorTradeId"
                                            name="vendorTrade"
                                            fullWidth
                                            placeholder="Separate with comma"
                                            size="medium"
                                            value={this.state.trades}
                                            onChange={this.handleTradeInput}
                                            onKeyPress={this.handleKeyPress}
                                            sx={{
                                                flex: 1,
                                                mr: 1,
                                                "& fieldset": { border: "none" },
                                            }}
                                        />
                                        <IconButton
                                            data-test-id="addIconButtonId"
                                            onClick={this.handleAddTrade}
                                            sx={{ color: this.state.trades !== "" ? "black" : "#BAB1AB" }}
                                        >
                                            <AddCircleOutline />
                                        </IconButton>
                                    </Box>
                                    <Box mt={2}>
                                        {vendorTrade.map((trade: string, index: number) => (
                                            <Chip
                                                key={index}
                                                label={trade}
                                                variant="outlined"
                                                icon={
                                                    <IconButton
                                                        data-test-id="deleteTradeId"
                                                        aria-label="delete"
                                                        size="small"
                                                        onClick={() => this.handleDelete(trade)}
                                                        sx={{ mr: -1 }}
                                                    >
                                                        <Close fontSize="small" />
                                                    </IconButton>
                                                }
                                                sx={{ mr: 1, mb: 1, border: 2, borderRadius: 1 }}
                                            />
                                        ))}
                                    </Box>
                                </Box>
                            </Grid>
                        </FirstGrid>
                        {this.renderDividerEnrolfirst()}
                        <FirstGrid container marginTop= "1rem">
                            <Grid item md={3} xs={12}>
                                <CustomContact>
                                    <Typography style={webStyle.labeltextsyle2}>{configJSON.contact}</Typography>
                                </CustomContact>
                            </Grid>
                            <Grid item md={7} xs={12}>
                            <Grid container spacing={2}>
                                    <Grid item xs={3} sm={3}>
                                        <FormControl fullWidth>
                                        <label style={webStyle.textstylechange12}>{configJSON.areaCode}</label>
                                            <Select
                                                required
                                                data-test-id="countryCodeId"
                                                name="countryCode"
                                                variant="outlined"
                                                size="medium"
                                                value={this.state.areaCode}
                                                onClose={() => { this.setState({ isCountryDropdown: false }) }}
                                                onChange={this.handleCountryChange}
                                                onOpen={this.handleCountryCodeDropdown}
                                                sx={{
                                                    border: "1px solid #e0e0e0",
                                                    borderRadius: "8px",
                                                    height: "57px"
                                                }}
                                            >
                                                <MenuItem value="">Select a country</MenuItem>
                                                {this.state.countriesCode?.map((country) => (
                                                    <MenuItem key={country.name} value={country.country_code}>
                                                        <span style={{ display: this.state.isCountryDropdown ? "block" : "none" }}>
                                                            {country.emoji_flag} {country.name} (+{country.country_code})
                                                        </span>
                                                        <span style={{ display: this.state.isCountryDropdown ? "none" : "block" }}>+{country.country_code}</span>
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={9} sm={9}>
                                    <label style={webStyle.textstylechange12}>{configJSON.phoneNumber}</label>
                                        <TextField
                                            required
                                            data-test-id="phoneId"
                                            name="phoneNumber"
                                            fullWidth
                                            variant="outlined"
                                            size="medium"
                                            error={this.state.errors.phone}
                                            helperText={this.getHelperText("phoneNumber")}
                                            value={this.state.phoneNumber}
                                            onChange={this.handleChange}
                                            sx={webStyle.marginManage}
                                            FormHelperTextProps={{
                                                sx: {
                                                    margin: "5px 0px 0px 0px",
                                                },
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Box style={webStyle.marginBorderstyle}>
                                    <label style={webStyle.labeltextsyle}>{configJSON.email}</label>
                                    <TextField
                                        data-test-id="emailId"
                                        variant="outlined"
                                        fullWidth
                                        name="email"
                                        value={email}
                                        onChange={this.handleChange}
                                        sx={webStyle.marginManage}
                                        error={this.state.errors.email}
                                        helperText={this.getHelperText("email")}
                                        FormHelperTextProps={{
                                            sx: {
                                                margin: "5px 0px 0px 0px",
                                            },
                                        }}
                                    />
                                </Box>

                                <Box style={webStyle.marginBorderstyle}>
                                    <label style={webStyle.labeltextsyle}>{configJSON.address}</label>
                                    <TextField
                                        data-test-id="addressId"
                                        variant="outlined"
                                        fullWidth
                                        name="address"
                                        value={address}
                                        onChange={this.handleChange}
                                        sx={webStyle.marginManage}
                                    />
                                </Box>
                                <Box style={webStyle.marginBorderstyle}>
                                    <FormControl sx={{ marginBottom: "16px" }}>
                                        <FormLabel htmlFor="send"
                                            sx={{
                                                ...webStyle.input_label,
                                                '&.Mui-focused': {
                                                    color: 'rgba(0, 0, 0, 0.6)',
                                                },
                                            }}
                                        >
                                            {configJSON.portalActivated}
                                        </FormLabel>
                                        <RadioGroup
                                            data-test-id="vendorPortalId"
                                            defaultValue={portalActivated}
                                            aria-labelledby="portal"
                                            name="portal"
                                            onChange={this.handlePortalChange}
                                        >
                                            <FormControlLabel
                                                value="yes"
                                                id="yes"
                                                control={<CustomRadioButton />}
                                                label={
                                                    <Typography 
                                                        variant="body1" 
                                                        fontSize="16px" 
                                                        fontFamily="Outfit"
                                                    >
                                                        {configJSON.radioYes}
                                                    </Typography>
                                                }
                                                
                                            />
                                            <FormControlLabel id="no" data-test-id="no-radio" value="no" control={<CustomRadioButton />} label={
                                                <Typography
                                                    variant="body1"
                                                    fontSize="16px"
                                                    fontFamily="Outfit"
                                                >
                                                 {configJSON.radioNo}
                                                </Typography>
                                            } />
                                        </RadioGroup>
                                    </FormControl>
                                </Box>
                            </Grid>
                        </FirstGrid>
                    </Box>
                )}

                {activeStep === 1 && (
                    <Box>
                        <Box display= "flex">
                            <FirstGrid container>
                                <Grid item md={3} xs={12}>
                                    <VendorInfo>
                                        <Typography style={webStyle.Marketingtext}>{configJSON.federalTax}</Typography>
                                    </VendorInfo>
                                </Grid>
                                <Grid item md={7} xs={12}>
                                    <Box sx={webStyle.marginTopBorderstyle}>
                                        <label style={webStyle.textstylechange}>{configJSON.taxpayer}</label>
                                        <TextField
                                            data-test-id="taxpayerNameId"
                                            variant="outlined"
                                            fullWidth
                                            sx={webStyle.textfieldmanage}
                                            name="taxpayerName"
                                            value={taxpayerName}
                                            onChange={this.handleChange}
                                            error={this.state.errors.taxpayerName}
                                            helperText={this.getHelperText("taxpayerName")}
                                            FormHelperTextProps={{
                                                sx: {
                                                    margin: "5px 0px 0px 0px",
                                                },
                                            }}
                                        />
                                    </Box>
                                    <Box style={webStyle.marginBorderstyle}>
                                        <label style={webStyle.textstylechange}>{configJSON.taxpayerID}</label>
                                        <TextField
                                            data-test-id="taxpayersId"
                                            variant="outlined"
                                            fullWidth
                                            sx={webStyle.textfieldmanage}
                                            name="taxpayerID"
                                            value={taxpayerID}
                                            onChange={this.handleChange}
                                            error={this.state.errors.taxpayerId}
                                            helperText={this.getHelperText("taxpayerID")}
                                            FormHelperTextProps={{
                                                sx: {
                                                    margin: "5px 0px 0px 0px",
                                                },
                                            }}
                                        />
                                    </Box>

                                    <Grid container marginTop= "3rem" justifyContent="center" alignItems="center">
                                        <Grid item md={12} xs={12}>
                                            <Box>
                                                <FormControl>
                                                    <FormLabel htmlFor="send"
                                                        sx={{
                                                            ...webStyle.input_label,
                                                            '&.Mui-focused': {
                                                                color: 'rgba(0, 0, 0, 0.6)',
                                                            },
                                                        }}
                                                    >
                                                        {configJSON.send1099}
                                                    </FormLabel>
                                                    <RadioGroup
                                                        data-test-id="sendId"
                                                        defaultValue={send1099}
                                                        aria-labelledby="send1099"
                                                        name="send1099"
                                                        onChange={this.handleRadioChange}
                                                    >
                                                        <FormControlLabel
                                                            value="yes"
                                                            id="yes"
                                                            control={<CustomRadioButton />}
                                                            label={
                                                                <Typography
                                                                    fontSize="16px"
                                                                    variant="body1"
                                                                    fontFamily="Outfit"
                                                                >
                                                                    {configJSON.radioYes}
                                                                </Typography>
                                                            }
                                                        />
                                                        <FormControlLabel id="no" data-test-id="no-radio" value="no" control={<CustomRadioButton />}   label={
                                                            <Typography
                                                                fontSize="16px"
                                                                variant="body1"
                                                                fontFamily="Outfit"
                                                            >
                                                                {configJSON.radioNo}
                                                            </Typography>
                                                        } />
                                                    </RadioGroup>
                                                </FormControl>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid container marginTop= "2rem"justifyContent="center" alignItems="center">
                                        <Grid item md={12} xs={12}>
                                            <Box>
                                                <label style={webStyle.labeltextsyles12}>{configJSON.formW}</label>
                                            </Box>
                                            <Box>
                                                <FileUpload
                                                    label=""
                                                    name="filesDocuments"
                                                    files={this.state.filesDocuments}
                                                    onFilesChange={(names: string, files: FileWithPreview[]) => {
                                                        this.handleFileDocChange(names, files);
                                                    }}
                                                    data-test-id="fileUploadTestId"
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </FirstGrid>
                        </Box>

                        {this.renderDividerEnrolfirst()}

                        <Box>
                            <FirstGrid container>
                                <Grid item md={3} xs={12}>
                                    <Box>
                                        <Typography style={webStyle.Marketingtext}>{configJSON.onlinePayable}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item md={7} xs={12}>
                                    <Grid container justifyContent="center" alignItems="center">
                                        <Grid item md={12} xs={12}>
                                            <Box>
                                                <FormControl sx={{ marginBottom: "16px" }}>
                                                    <FormLabel htmlFor="send"
                                                        sx={{
                                                            ...webStyle.input_label,
                                                            '&.Mui-focused': {
                                                                color: 'rgba(0, 0, 0, 0.6)',
                                                            },
                                                        }}
                                                    >
                                                        {configJSON.useOnline}
                                                    </FormLabel>
                                                    <RadioGroup
                                                        defaultValue={useOnlinePayables}
                                                        aria-labelledby="useOnlinePayables"
                                                        name="useOnlinePayables"
                                                        onChange={this.handleChange}
                                                    >
                                                        <FormControlLabel
                                                            value="yes"
                                                            id="yes"
                                                            control={<CustomRadioButton />}
                                                            label={
                                                                <Typography 
                                                                    fontFamily="Outfit"
                                                                    variant="body1" 
                                                                    fontSize="16px" 
                                                                >
                                                                    {configJSON.radioYes}
                                                                </Typography>
                                                            }
                                                        />
                                                        <FormControlLabel id="no" data-test-id="no-radio" value="no" control={<CustomRadioButton />} label={
                                                            <Typography
                                                                fontFamily="Outfit"
                                                                variant="body1"
                                                                fontSize="16px"
                                                            >
                                                                {configJSON.radioNo}
                                                            </Typography>
                                                        } />
                                                    </RadioGroup>
                                                </FormControl>
                                            </Box>
                                        </Grid>
                                    </Grid>

                                    <Box sx={webStyle.marginBorderstylethree}>
                                        <label style={webStyle.labeltextsylethree}>{configJSON.paymentType}</label>
                                        <FormControl fullWidth sx={webStyle.marginManagethree}>
                                            <Select
                                                data-test-id = "paymentId"
                                                labelId="payment-type-label"
                                                name="paymentType"
                                                value={paymentType}
                                                onChange={this.handlePaymentType}
                                            >
                                                {this.state.payments.map((payment: string, payIndex: number)=>{
                                                    return(
                                                        <MenuItem key={payIndex} value={payment}>{payment}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Box>

                                    <Box sx={webStyle.marginBorderstylethree}>
                                        <label style={webStyle.textstylechange}>{configJSON.routingNumber}</label>
                                        <TextField
                                            data-test-id = "vendorBankRoutingNumberId"
                                            variant="outlined"
                                            fullWidth
                                            sx={webStyle.textfieldmanage}
                                            name="vendorBankRoutingNumber"
                                            value={vendorBankRoutingNumber}
                                            onChange={this.handleChange}
                                            FormHelperTextProps={{
                                                sx: {
                                                    margin: "5px 0px 0px 0px",
                                                },
                                            }}
                                            error={this.state.errors.bankRoutingNumber}
                                            helperText={this.getHelperText("vendorBankRoutingNumber")}
                                        />
                                    </Box>

                                    <Box sx={webStyle.marginBorderstylethree}>
                                        <label style={webStyle.textstylechange}>{configJSON.accountNumber}</label>
                                        <TextField
                                            data-test-id="accountNumberId"
                                            variant="outlined"
                                            fullWidth
                                            sx={webStyle.textfieldmanage}
                                            name="vendorBankAccountNumber"
                                            value={vendorBankAccountNumber}
                                            FormHelperTextProps={{
                                                sx: {
                                                    margin: "5px 0px 0px 0px",
                                                },
                                            }}
                                            onChange={this.handleChange}
                                            error={this.state.errors.bankAccountNumber}
                                            helperText={this.getHelperText("vendorBankAccountNumber")}
                                        />
                                    </Box>
                                    <Grid container marginTop= "3rem" justifyContent="center" alignItems="center">
                                        <Grid item md={12} xs={12}>
                                            <Box>
                                                <FileUpload
                                                    label=""
                                                    name="documents"
                                                    files={this.state.filesAccountType}
                                                    onFilesChange={(names: string, files: FileWithPreview[]) => {
                                                        this.handleAccFileChange(names, files);
                                                    }}
                                                    data-test-id="fileTestId"
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </FirstGrid>
                        </Box>
                    </Box>
                )}

                {activeStep === 2 && (
                    <Box display="flex">
                        <FirstGrid container padding="2rem" >
                            <Grid container spacing={2} sx={{ px: 4 }}>
                                <Grid item xs={12} sm={4}>
                                    <Typography sx={webStyle.form_section_label}>{configJSON.generalLiable}</Typography>
                            </Grid>
                                <Grid item xs={12} sm={8}>
                                    <FileUpload
                                        data-test-id="fileId"
                                        label="Upload business license"
                                        name="businessLicense"
                                        files={businessLicense}
                                        onFilesChange={this.handleFileDocChange}
                                    />
                                    <FormGroup sx={{ marginBottom: "16px" }}>
                                        <FormLabel htmlFor="licenseExpDate" sx={webStyle.input_label}>
                                            {configJSON.expirationDate}
                                        </FormLabel>
                                    <CustomDatePicker
                                        data-test-id="workerId"
                                        onChange={
                                            this.handleDateChange("licenseExpDate")
                                        }
                                        required={true}
                                        value={licenseExpDate}>
                                    </CustomDatePicker>
                                    </FormGroup>
                                    <FileUpload
                                        data-test-id="fileInsuranceId"
                                        label="Upload business insurance"
                                        name="businessInsurance"
                                        files={businessInsurance}
                                        onFilesChange={this.handleFileDocChange}
                                    />
                                    <FormGroup sx={{ marginBottom: "42px" }}>
                                        <FormLabel htmlFor="insuranceExpDate" sx={webStyle.input_label}>
                                            {configJSON.expirationDate}
                                        </FormLabel>
                                    <CustomDatePicker
                                        value={insuranceExpDate}
                                        data-test-id = "insuranceExpDateId"
                                        required={true}
                                        onChange={
                                            this.handleDateChange("insuranceExpDate")
                                        }
                                    />
                                    </FormGroup>
                                </Grid>
                            </Grid>
                            {this.renderDividerEnrolfirst()}
                            <Grid container spacing={2} sx={{ px: 4 }}>
                                <Grid item xs={12} sm={4}>
                                    <Typography sx={webStyle.form_section_label}>
                                        {configJSON.epaCertificates}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <FileUpload
                                        data-test-id = "epaCertificationLicenseId"
                                        label="Upload EPA Certification license"
                                        name="epaCertificationLicense"
                                        files={epaCertificationLicense}
                                        onFilesChange={this.handleFileDocChange}
                                    />
                                    <FormGroup sx={{ marginBottom: "16px" }}>
                                        <FormLabel
                                            htmlFor="epaCerLicenseExpDate"
                                            sx={webStyle.input_label}
                                        >
                                             {configJSON.expirationDate}
                                        </FormLabel>
                                        <CustomDatePicker
                                            onChange={this.handleDateChange("epaCerLicenseExpDate")}
                                            value={epaCerLicenseExpDate}
                                            required={false}
                                        ></CustomDatePicker>
                                    </FormGroup>

                                    <FileUpload
                                        data-test-id = "otherCertificateId"
                                        label="Upload other certificate"
                                        name="otherCertificate"
                                        files={otherCertificate}
                                        onFilesChange={this.handleFileDocChange}
                                    />
                                    <FormGroup sx={{ marginBottom: "42px" }}>
                                        <FormLabel
                                            htmlFor="otherCertificateExpDate"
                                            sx={webStyle.input_label}
                                        >
                                             {configJSON.expirationDate}
                                        </FormLabel>
                                        <CustomDatePicker
                                            value={otherCertificateExpDate}
                                            onChange={
                                                this.handleDateChange(
                                                    "otherCertificateExpDate")
                                            }
                                            required={false}></CustomDatePicker>
                                    </FormGroup>
                                </Grid>
                            </Grid>
                            {this.renderDividerEnrolfirst()}
                            <Grid container spacing={2} sx={{ px: 4 }}>
                                <Grid item xs={12} sm={4}>
                                    <Typography sx={webStyle.form_section_label}>
                                        {configJSON.stateLicense}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <FileUpload
                                        data-test-id = "stateLicenseId"
                                        label="Upload state license"
                                        name="stateLicense"
                                        files={stateLicense}
                                        onFilesChange={this.handleFileDocChange}
                                    />
                                    <FormGroup sx={{ marginBottom: "16px" }}>
                                        <FormLabel
                                            htmlFor="stateLicenseExpDate"
                                            sx={webStyle.input_label}
                                        >
                                             {configJSON.expirationDate}
                                        </FormLabel>
                                        <CustomDatePicker
                                            required={false}
                                            value={stateLicenseExpDate}

                                            onChange={this.handleDateChange("stateLicenseExpDate")}

                                        />
                                    </FormGroup>

                                    <FileUpload
                                        data-test-id = "stateInsuranceId"
                                        label="Upload state insurance"
                                        name="stateInsurance"
                                        files={stateInsurance}
                                        onFilesChange={this.handleFileDocChange}
                                    />
                                    <FormGroup sx={{ marginBottom: "42px" }}>
                                        <FormLabel
                                            htmlFor="stateInsuranceExpDate"
                                            sx={webStyle.input_label}
                                        >
                                             {configJSON.expirationDate}
                                        </FormLabel>
                                        <CustomDatePicker
                                            value={stateInsuranceExpDate}
                                            onChange={
                                                this.handleDateChange(
                                                    "stateInsuranceExpDate"
                                                )}>
                                                </CustomDatePicker>
                                    </FormGroup>
                                </Grid>
                            </Grid>
                            {this.renderDividerEnrolfirst()}
                              <Grid container spacing={2} sx={{ px: 4 }}>
                                <Grid item xs={12} sm={4}>
                                    <Typography sx={webStyle.form_section_label}>{configJSON.notes}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <FormGroup>
                                        <TextField
                                            id="notes"
                                            name="notes"
                                            multiline
                                            rows={3}
                                            variant="outlined"
                                            helperText={
                                                <span style={{ float: "right" }}>
                                                    {notes.length}/100
                                                </span>
                                            }
                                            inputProps={{ maxLength: 100 }}
                                            value={notes}
                                            onChange={this.handleChange}
                                        />
                                    </FormGroup>
                                </Grid>
                            </Grid>
                          </FirstGrid>
                      </Box>
                  )}
              </Box>
          </ThemeProvider>
      )
  };
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start


            <>

                <CustomThemesProvider theme={theme}>
                      <NavsArea>
                            <NavigationSidebar
                                data-test-id={"sidebarMenuIds"}
                                sidebarItems={[
                                    { label: configJSON.dashboardLandlabel, icon: dashboardLandLogo, labelKey: configJSON.dashboardLandlabel, pathName: configJSON.dashboardLandlabel, activeIcon: activeMainDashboard },
                                    { label: configJSON.myAccountlandloard, icon: accountsMain, labelKey: configJSON.myAccountlandlabel, pathName: configJSON.myAccountlandlabel, activeIcon: activeMainAccount },
                                    { label: configJSON.worklandloard, icon: ordersMain, labelKey: configJSON.workOrderslandlabel, pathName: configJSON.workOrderslandlabel, activeIcon: activeMainOrder },

                                    { label: configJSON.preferredlandloard, icon: vendorMain, labelKey: configJSON.prevendorlandlabel, pathName: configJSON.preferredlandloard, activeIcon: activeMainVendor },
                                    { label: configJSON.reminderslandloard, icon: remindersMain, labelKey: configJSON.reminlandlabel, pathName: configJSON.reminlandlabel, activeIcon: activeMainReminder },
                                    { label: configJSON.myproplandloard, icon: propertyMain, labelKey: configJSON.myproplandlabel, pathName: configJSON.myproplandlabel, activeIcon: activePropertyMain },

                                    { label: configJSON.financialandloard, icon: financialMain, labelKey: configJSON.financlandlabel, pathName: configJSON.financialandloard, activeIcon: activeMainFinancial },
                                    { label: configJSON.reportlandloard, icon: reportMain, labelKey: configJSON.reportlandlabel, pathName: configJSON.reportlandloard, activeIcon: activeMainReport },
                                    { label: configJSON.docLandlord, labelKey: configJSON.docLandlordLabel, icon: documents, activeIcon: documentActive, pathName: configJSON.docLandlord },
                                    { label: configJSON.chatLandloard, icon: keyMainChain, labelKey: configJSON.chatTenantabel, pathName: configJSON.chatlandlabel, activeIcon: activeKeyMain },

                                    { label: configJSON.settingLandlord, icon: settingsMain, labelKey: configJSON.settinglandlabel, pathName: configJSON.settinglandlabel, activeIcon: activeMainSetting }
                                ]}
                                onClickSidebar={this.handleMainItemClicksEdit}

                                openDrawer={this.state.openLandlordDrawersedit}
                                onClickDrawer={this.handleLandlordDrawersEdit} 
                                activeItem={this.state.activeMainItemsedit} 
                                keasylogo={keasyIcon} 
                                />
                    </NavsArea>
                          <WorksArea display={"flex"} flexDirection={"column"} justifyContent={"space-between"} width={"100%"}>
                        <Box width={"95%"} marginLeft={"37px"}>
                            <CustomNavbar
                                {...this.props}
                                showChatButton={true}
                                showPropertiesButton={true}
                            />
                        </Box>
                            <Box width={"95%"}>
                            <Grid container p={2}>
                                <Grid item xs={12}>
                                    <Box>
                                   <Typography data-test-id="venId" style={webStyle.newproperty}>{configJSON.newVendor}</Typography>
                                    </Box>


                                </Grid>
                            </Grid>
                            <Grid container spacing={7}>
                                <Grid item xs={12}  m={"2rem 0rem"}>
                                    <Stepper 
                                    activeStep={this.state.activeStepedit} 
                                    alternativeLabel connector={null}
                                        sx={{
                                            flexWrap: "wrap",
                                            justifyContent: "space-between",
                                            gap: "1rem",
                                            "& .MuiStepLabel-root.MuiStepLabel-alternativeLabel": {
                                                alignItems: "end",
                                                flexDirection: "row",
                                                gap: "1rem"

                                            },
                                        }}
                                    >
                                        {getStepsedit().map((label, index) => (
                                            <Step key={index}
                                                sx={{
                                                    "& .css-1q0s5d2-MuiStepLabel-root.MuiStepLabel-alternativeLabel": {
                                                        flexDirection: "revert"
                                                    },
                                                    "& .MuiStepLabel-root .Mui-completed": {
                                                        color: "#34D399"

                                                    },
                                                    "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel": {
                                                        color: "#000000",

                                                    },
                                                    "& .MuiStepLabel-root .Mui-active": {
                                                        color: "#CC9200"
                                                    },
                                                    "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel": {
                                                        color: "#000000",

                                                    },
                                                    "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                                                        fill: "#ffffff"
                                                    },
                                                    ".css-ea49we-MuiStep-root .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel": {
                                                        color: "#000000",

                                                    },
                                                    "& .css-109el20-MuiStepLabel-root.MuiStepLabel-alternativeLabel": {


                                                    },
                                                    "& .MuiStepLabel-labelContainer.MuiStepLabel-alternativeLabel": {
                                                        textAlign: "left"
                                                    },
                                                    "& .css-1hv8oq8-MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
                                                        marginTop: "3px",
                                                        marginLeft: "1rem"
                                                    }
                                                }}

                                            >
                                                <StepLabel
                                                    sx={{
                                                        "& .css-109el20-MuiStepLabel-root.MuiStepLabel-alternativeLabel": {
                                                            flexDirection: "revert",


                                                        },

                                                        color: "#000000",
                                                        fontWeight: 700

                                                    }}
                                                >

                                                    <span style={webStyle.stepperstyle}>   {label}</span>

                                                </StepLabel>
                                            </Step>
                                        ))}
                                    </Stepper>
                                </Grid>
                            </Grid>
                            <Container maxWidth="lg">
                                {this.getStepContentEdit(this.state.activeStepedit)}

                            </Container>
                            <Grid container spacing={4}>
                                <CustomGrid item xs={10}>
                                    <BackButton
                                        data-test-id="backbuttonIdone"
                                        variant="contained"
                                        className={this.state.activeStepedit === 0 ? "isButtonDisabled" : ""}
                                        onClick={this.handleBackMainEdit}
                                    >
                                            {configJSON.backButton}
                                        </BackButton>
                                        {this.state.activeStepedit === getStepsedit().length - 1 ? (
                                            <NextButton variant="contained"
                                                data-test-id="submitId"
                                                onClick={this.handleSubmit}>
                                                {configJSON.finish}
                                            </NextButton>
                                        ) : (
                                            <NextButton
                                                data-test-id="nextbuttonIdone"
                                                variant="contained"
                                            onClick={this.handleNextMainEdit}
                                        >
                                            {configJSON.nextButton}
                                        </NextButton>
                                    )}
                                </CustomGrid>
                            </Grid>
                            </Box>
                            <Box className="bgImgWrapper" data-test-id="bgImgsId">
                                <Box className="backgroundImage">
                                    <img src={bottomImageIcon} alt="hexagon-add" className="hexImg" />
                                </Box>
                            </Box>
                        </WorksArea>
                    <PopupMessage
                        data-test-id="popupId"
                        open={this.state.openSnack}
                        type={this.state.venMessage}
                        message={this.state.errorMessage}
                        handleClose={this.handlePopupClose}
                    />

                </CustomThemesProvider>




            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    stepperstyle: {
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        fontfamily: "outfit"
    },
    form_section_label: {
        fontFamily: "Outfit, sans-serif",
        fontSize: "18px",
        fontWeight: 700,
        lineHeight: "17px",
        color: "#BAB1AB",
    },
    input_label: {
        fontFamily: "Outfit, sans-serif",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "22px",
    },
    newproperty: {
        fontSize: "24px",
        fontWeight: 800,
        lineHeight: "32px",
       
    },
    childWrapper: {
        paddingTop: '2rem',
    },
    inputStyle: {
        marginTop: 20,
        marginBottom: 20,
    },
    fileWrapper: {
        marginTop: 10,
        display: "flex",
        alignItems: "center",
    },
    fileName: {
        flex: 1,
    },
    submitBtnStyle: {
        marginTop: 20,
    },
    uploadBtnWrapper: {
        display: "flex",
        marginTop: 20,
    },
    uploadBtn: {
        padding: 7,
        paddingLeft: 15,
        paddingRight: 15,
        border: "none",
        backgroundColor: "rgb(98, 0, 238)",
        color: "#fff",
        borderRadius: 5,
        cursor: "pointer",
        marginRight: 15,
    },
    clearBtnStyle: {
        marginRight: 15,
    },
    bulkFileWrapper: {
        border: "1px solid #ccc",
        padding: 20,
        borderRadius: 5,
        marginBottom: 20,
    },
    bulkFileTopWrapper: {
        display: "flex",
        alignItems: "center",
    },
    textWrapper: {
        flex: 1,
    },
    fileItemWrapper: {
        display: "flex",
        border: "1px solid #ccc",
        padding: 10,
        marginTop: 15,
        borderRadius: 5,
        alignItems: "center",
    },
    textfieldmanage12: {
        '& .MuiOutlinedInput-root': {
            borderRadius: 2,
            marginTop: "1rem"
        }
    },
    textstylechange12: {
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "22px",
        fontFamily: "Outfit",
        color: 'rgba(0, 0, 0, 0.6)',
        whiteSpace: "nowrap"
    },
    marginBorderstyle12: {
        borderRadius: "8px", marginTop: "2rem"
    },
    marginManage: {
        '& .MuiOutlinedInput-root': {
            borderRadius: 2
        }
    },
    sitemaneger: {
        '& .MuiOutlinedInput-root': {
            borderRadius: 2,
            marginTop: "2.3rem"
        }
    },
    marginBorderstyle: {
        borderRadius: "8px", marginTop: "2rem"
    },
    marginTopBorderstyle: {
        borderRadius: "8px"
    },
    labeltextsyle: {
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "22px",
        fontFamily: "Outfit",
        marginBottom: "2rem",
        color: 'rgba(0, 0, 0, 0.6)'
    },
    labeltextsyle2: {
        fontSize: "18px",
        fontWeight: 700,
        lineHeight: "17px",
        fontFamily: "Outfit",
        color: 'rgb(186, 177, 171)'
    },
    addstyle: {
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "22px",
        fontFamily: "Outfit",
        color: '#CC9200'
    },
    labeltextsyles: {
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "22px",
        fontFamily: "Outfit",
        color: 'rgba(0, 0, 0, 0.6)'
    },
    mainWrapper: {
        display: "flex",
        fontFamily: "Roboto-Medium",
        flexDirection: "column",
        paddingBottom: "30px",
        background: "#fff",
    },
    radiocolorstyle: {
        color: "#FFC123"
    },
    marginBorderstylethree: {
        borderRadius: "8px", marginTop: "2rem"
    },
    aretextstyle: {
        height: "132px",
        marginTop: "1rem"
    },
    textfieldmanage: {
        '& .MuiOutlinedInput-root': {
            borderRadius: 2,
        }
    },
    radiocolorstyle12: {
        color: "#FFC123"
    },
    marginBorderstyle1: {
        borderRadius: "8px", marginTop: "2rem"
    },
    labeltextsylethree: {
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "22px",
        fontFamily: "Outfit",
        marginBottom: "2rem",
        color: 'rgba(0, 0, 0, 0.6)'
    },
    marginManagethree: {
        '& .MuiOutlinedInput-root': {
            borderRadius: 2
        }
    },
    labeltextsyles12: {
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "22px",
        fontFamily: "Outfit",
        color: 'rgba(0, 0, 0, 0.6)'
    },
    textstylechange: {
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "22px",
        fontFamily: "Outfit",
        color: 'rgba(0, 0, 0, 0.6)'
    },
    Marketingtext: {
        fontSize: "18px",
        fontWeight: 700,
        lineHeight: "17px",
        fontFamily: "Outfit",
        color: "#BAB1AB"
    },
    enroll3manage: {
        '& .MuiOutlinedInput-root': {
            borderRadius: 2,
            marginTop: "1rem"
        }
    },
    colorradio: {
        color: "#FFC123"
    },
    textstyleArea: {
        height: "132px",
    },
    flexradio: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "1rem"
    },
    marginBorderstyle3: {
        borderRadius: "8px", marginTop: "2rem"
    },
    marginBorderstyle2: {
        borderRadius: "8px", marginTop: "1rem"
    },

    pettextchange: {
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "22px",
        fontFamily: "Outfit",
        marginBottom: "2rem"
    },
    keyssectiontext: {
        fontSize: "18px",
        fontWeight: 700,
        lineHeight: "17px",
        fontFamily: "Outfit",
        color: "#BAB1AB"

    },
    bcforguytext: {
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "22px",
        fontFamily: "Outfit",
        color: 'rgba(0, 0, 0, 0.6)'
    }
};

const NavsArea = styled(Box)({
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 1000,
    height: "100%",
    overflowY: "auto",
    border: "1px solid rgb(208,203,199)",
    "&::-webkit-scrollbar": {
        display: "none"
    },
    "scrollbar-width": "none",
    "-ms-overflow-style": "none",
    "@media(max-width: 992px)": {
        position: "unset",
        overflowY: "hidden",
        border: "none",
        height: "auto",
    }
});

const WorksArea = styled(Box)({
    marginLeft: "155px",
    flexGrow: 1,
    overflowY: "auto",
    height: "100vh",
    "&::-webkit-scrollbar": {
        display: "none"
    },
    "scrollbar-width": "none",
    "-ms-overflow-style": "none",
    "@media(max-width: 992px)": {
        marginLeft: "0px",
        height: "auto",
        overflowY: "unset"
    }
});

const CustomThemesProvider = styled(Box)({
    fontFamily: "Outfit",
    justifyContent: "space-between",
    display: "flex",
    width: "100%",
    "@media(max-width: 992px)": {
        flexDirection: "column"
    },
    "&.webStyle": {
        backgroundColor: "red"
    },
    "& .bgImgWrapper": {
        display: "flex",
        marginTop: "2rem",
        width: "100%",
        justifyContent: "flex-end",
        fontFamily: "Outfit",
        "@media (max-width:1220px)": {
            width: "88%"
        },
        "@media (max-width:992px)": {
            display: "none",
        }
    },
    "& .backgroundImage": {
        width: "100%",
        justifyContent: "flex-end",
        display: "flex",
        "@media (max-width:1220px)": {
            width: "100%"
        },
    },
    "& .hexImg": {
        objectFit: "cover"
    }
});

const BackButton = styled(Button)({
    width: "184px",
    height: "44px",
    fontFamily: "outfit",
    fontSize: "16px",
    color: "#000000",
    fontWeight: 600,
    textAlign: "center",
    borderRadius: "10px",
    backgroundColor: "#FFF1CC",
    textTransform: "none",
    "&:hover": {
        backgroundColor: "#FFF1CC"
    },
    "&.isButtonDisabled": {
        display: "none"
    },
    "@media(max-width: 992px)": {
        width: "20%"
    }
});

const NextButton = styled(Button)({
    width: "184px",
    height: "44px",
    fontFamily: "outfit",
    fontSize: "16px",
    fontWeight: 700,
    color: "#000000",
    textAlign: "center",
    backgroundColor: "#fab03d",
    borderRadius: "10px",
    marginLeft: '1em',
    textTransform: "none",
    cursor: "pointer",
    "&:hover": {
        backgroundColor: "#fab03d"
    },
    "@media(max-width: 992px)": {
        width: "20%"
    }
});

const AddVendor = styled(Box)({
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    "@media(max-width: 992px)": {
      flexDirection: "column"
    },
    "&.webStyle": {
      backgroundColor: "red"
    },
    "& .hexImg": {
        objectFit: "cover"
    },
    "& .backgroundImage": {
      textAlign: "end",
      width: "100%"
      },
    "& .bgImgWrapper": {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: "2rem",
      width: "100%",
      "@media (max-width:992px)": {
        display: "none"
      }
    }
  });

const CustomGrid = styled(Grid)({
    marginTop: '80px',
    textAlign: "right",
    "@media(max-width: 992px)": {
        display: "flex",
        marginBottom: "80px",
        paddingLeft: "3.5rem"
    }
});

const FirstGrid = styled(Grid)({
    padding: "2rem",
    "@media(max-width: 992px)": {
        padding: "0rem"
    }
});

const CustomContact = styled(Box)({
    "@media(max-width: 992px)": {
        margin: "2rem 0rem"
    }
});

const CustomDivider = styled(Divider)({
    width: "97%",
    "@media(max-width: 992px)": {
        width: "100%"
    }
});

const VendorInfo = styled(Box)({
    "@media(max-width: 992px)": {
        marginBottom: "2rem"
    }
});
// Customizable Area End