export const keasyIcon = require("../../tasks/assets/keasylogo.svg");
export const profileMain = require("../../tasks/assets/profile.png");
export const activeProfile = require("../../tasks/assets/activeProfile.png");
export const projects = require("../../tasks/assets/projects.png");
export const activeProjects = require("../../tasks/assets/activeprojects.png");
export const hexagon = require("../../appointmentmanagement/assets/hexagon.svg");
export const halfHexagon = require("../../appointmentmanagement/assets/halfHexagon.svg");
export const download = require("../assets/download.png");
export const invoice = require("../assets/invoice.png");
export const key = require("../assets/key.png");
export const approved = require("../assets/approved.png");
export const activekey = require("../assets/activeKey.png");
