Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.apiContentType = "application/json";
exports.getApiMethod = "GET";
exports.postApiMethod = "POST";
exports.putApiMethod = "PUT";
exports.deleteApiMethod = "DELETE";

exports.getCountriesApiMethod = "GET";
exports.getCountriesAPIEndPoint = "/account_block/landlord_accounts/countries_listing";
exports.getUserListApiContentType = "application/json";

exports.getVendorTypesAPIEndPoint = "/account_block/vendor_accounts/filter_types";

exports.addVendorDetailsApiMethod = "POST";
exports.addVendorDetailsApiEndPoint = "/account_block/vendor_accounts";

exports.groupsApiEndPoint = "account_groups/groups";
exports.tasksAccountsApiEndPoint = "roles_permissions/accounts/list_users";

exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "* All fields are mandatory.";

exports.textClose = "Close";
exports.textSave = "Save";
exports.textUpdate = "Update";
exports.textAddGroup = "Add Group";
exports.textShowGroup = "Show Group";
exports.textEdit = "Edit";
exports.textDelete = "Delete";
exports.textAccounts = "Accounts";
exports.textAddAcc = "Add Acc.";
exports.textDeleteAcc = "Delete Acc.";
exports.textId = "Id";
exports.textStatus = "Status";
exports.textActions = "Actions";
exports.textAssign = "Assign";
exports.textAssignedTo = "Assigned To";
exports.textName = "Name";
exports.textCreatedAt = "Created At";
exports.textUpdatedAt = "Updated At";
exports.textSelectAccounts = "Select accounts";
exports.vendorProfile  = "Vendor Profile";
exports.projects = "Project History";
exports.vendorLabelProfile = "UserProfileBasicBlock";
exports.vendorProjects = "Tasks";
exports.invoiceLable = "Sent Invoices";
exports.invoiceBlock="InvoiceBilling";
exports.ApprovedOwnersLable="Approved Owners"
exports.ApprovedOwnersBlock = "AutomaticFormCreation";
exports.chatOrder = "Chat with Keasy";
exports.chatOrderLabel = "Chat";
// Customizable Area End
