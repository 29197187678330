Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.jsonApiContentType = "application/json";
exports.getApiMethodType = "GET";
exports.audienceFollowersAPiEndPoint = "visual_analytics/audience_followers";
exports.userStatisticAPiEndPoint = "visual_analytics/user_statistic";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "visualanalytics";
exports.labelBodyText = "visualanalytics Body";
exports.propertiesHead = "Properties";
exports.filtersHead = "Filters";
exports.clearFilters = "Clear all";
exports.propertiesApiEndPoint = "bx_block_statisticsreports2/delinquency_reports";
exports.commentAPiEndPoint = "bx_block_comments/comments";
exports.commentGetAPiEndPoint = "bx_block_comments/comments/get_tenant_comments";
exports.dashboardOrder = "Dashboard";
exports.myAccountOrder = "My Account";
exports.workOrder = "Work Orders";
exports.preferredOrder = "Preferred Vendors";
exports.remindersOrder = "Reminders";
exports.mypropOrder = "My Properties";
exports.financiaOrder = "Financial";
exports.reportOrder = "Delinquency Report";
exports.chatOrder = "Chat with Keasy";
exports.settingOrder = "Settings";
exports.dashboardOrderlabel = "Dashboard";
exports.myAccountOrderabel = "landlordaccount";
exports.worksOrderlabel = "Appointments";
exports.prevendorOrderlabel = "TaskList";
exports.reminOrderlabel = "AutomatedPrioritization";
exports.mypropOrderlabel = "PortfolioManagement";
exports.documentlabel = "Cfdocumentforwarding4";
exports.document = "Documents";
exports.financOrderlabel = "Analytics";
exports.reportOrderlabel = "VisualAnalytics";
exports.chatOrderlabel = "Chat";
exports.settingOrderlabel = "Settings2";
exports.settings = "Settings";
exports.service = "Service Requests";
exports.PropertyEndPoint = "account_block/properties";
exports.servicelabel = "LandlordServiceRequestsDashboard";
// Customizable Area End