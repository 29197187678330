export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const checkBox = require("../assets/check_box.png");
export const profileMain = require("../../tasks/assets/profile.png");
export const activeProfile = require("../../tasks/assets/activeProfile.png");
export const projects = require("../../tasks/assets/projects.png");
export const activeProjects = require("../../tasks/assets/activeprojects.png");
export const hexagon = require("../../appointmentmanagement/assets/hexagon.svg");
export const halfHexagon = require("../../appointmentmanagement/assets/halfHexagon.svg");
export const download = require("../../invoicebilling/assets/download.png");
export const invoice = require("../../invoicebilling/assets/invoice.png");
export const key = require("../../invoicebilling/assets/key.png");
export const approved = require("../../invoicebilling/assets/approved.png");
export const activekey = require("../../invoicebilling/assets/activeKey.png");
export const keasyIcon = require("../../tasks/assets/keasylogo.svg");
export const arrow = require("../assets/arrow.png")

