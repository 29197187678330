import React from "react";
import Grid from "@mui/material/Grid";
import {
  Box,
  Container,
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepIconProps,
  Radio,
  RadioGroup,
  RadioProps,
  Button,
  TextField,
  FormControlLabel,
  Divider,
  FormGroup,
  FormLabel,
  FormControl,
  Chip,
  IconButton,
  Alert,
  Select,
  MenuItem,
} from "@mui/material";
import NavigationSidebar from "../../../components/src/NavigationSidebar.web";
import { activeProfile, keasyIcon, profileMain, projects, activeProjects } from "./assets";
import Check from "@mui/icons-material/Check";
import { styled } from "@mui/material/styles";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Close from "@mui/icons-material/Close";
import FileUpload from "./FileUpload";
import VendorOnboardingController from "./VendorOnboardingController.web";
import CustomDatePicker from "../../../components/src/CustomDatePicker.web";
import { CustomRadioButton } from "../../../components/src/CustomRadioButton.web";
import PopupMessage from "../../../components/src/PopupMessage.web";
export const invoice = require("../../invoicebilling/assets/invoice.png");
export const approved = require("../../invoicebilling/assets/approved.png");
export const keyMainChain = require("../../invoicebilling/assets/key.png");
export const activeKeyMain = require("../../invoicebilling/assets/activeKey.png");
import  CustomNavbar from "../../../components/src/CustomNavbar.web";
export const configJSON = require("./config.js");

const steps = ["Vendor information", "Online payables", "Compliance"];
const timeArray = [
  "00:00", "01:00", "02:00", "03:00", "04:00", "05:00", "06:00", "07:00", 
  "08:00", "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", 
  "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00"
]

// Custom Step Icon
export const CustomStepIcon = (props: StepIconProps) => {
  const { active, completed, className } = props;
  const iconStyles = {
    backgroundColor: completed ? "#34D399" : active ? "#CC9200" : "#ccc",
    fontFamily: "Outfit, sans-serif",
    color: "#fff",
    borderRadius: "50%",
    width: 24,
    height: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  return (
    <div className={className} style={iconStyles}>
      {completed ? (
        <Check style={{ color: "#fff" }} />
      ) : (
        <span className="icon">{props.icon}</span>
      )}
    </div>
  );
};

export default class VendorOnboarding extends VendorOnboardingController
 {

  render() {
    return (
        <Grid container direction="row" wrap="nowrap" 
            sx={{ flexDirection: {xs: "column", sm: "column", md: "row"}}}
        >
          <Grid item sx={webStyles.navBarItem}>
            <NavigationSidebar
                data-test-id={"vendorId"}
                activeItem={this.state.activeVendorItem}
                sidebarItems={[
                  { label: configJSON.vendorProfile, labelKey: configJSON.vendorLabelProfile, icon: profileMain, activeIcon: activeProfile, pathName: configJSON.vendorLabelProfile },
                  { label: configJSON.projects, labelKey: configJSON.vendorProjects, icon: projects, activeIcon: activeProjects, pathName: configJSON.vendorProjects },
                  { label: configJSON.invoiceLable, labelKey: configJSON.invoiceBlock, icon: invoice, activeIcon: invoice,pathName:configJSON.invoiceBlock },
                  { label: configJSON.ApprovedOwnersLable, labelKey: configJSON.ApprovedOwnersBlock, icon: approved, activeIcon: approved,pathName:configJSON.ApprovedOwnersBlock },
                  { label: configJSON.chatOrder, labelKey: configJSON.chatOrderLabel, icon: keyMainChain, activeIcon: activeKeyMain, pathName: configJSON.chatOrderLabel },
                ]}
                onClickSidebar={this.handleVendoritemClick}
                keasylogo={keasyIcon}
                openDrawer={this.state.openVendorDrawer}
                onClickDrawer={this.handleVendorDrawer}
            />
          </Grid>
          <Grid item sx={{ 
            flexGrow: 1, 
            borderLeft: "1px solid rgb(208,203,199)", 
            height: "100%", 
            marginLeft: {md: "130px", sm: "0px", xs: "0px"}}}>
            <Container
              maxWidth={false}
              sx={{
                paddingRight: {
                  xs: "0 !important", // Remove padding on extra small screens
                  sm: "168px !important", // Apply padding on small and larger screens
                }
              }}
            >
              <Box sx={webStyles.welcome_container}>
                  <CustomNavbar 
                    navigation={this.props.navigation}
                    id="AccountGroups"
                    showChatButton={true}   
                    showPropertiesButton={false} 
                  />
                <Typography sx={webStyles.vendor_onboarding_header} paragraph>
                  Welcome to Keasy
                </Typography>
                <Typography
                  sx={webStyles.vendor_onboarding_bold_text}
                  paragraph
                >
                  Before we start we need you to complete few details...
                </Typography>
                <Typography sx={webStyles.vendor_onboarding_text} paragraph>
                  Once you finish all the mandatory details you may be able to
                  see your dashboard.
                </Typography>
                <Typography sx={webStyles.vendor_onboarding_text} paragraph>
                  Thank you for signing up for our property management concierge
                  subscription. We sincerely appreciate your trust and look
                  forward to the opportunity of working with you. To ensure a
                  smooth account setup, we kindly request that you provide the
                  requested information on this form.
                </Typography>
                <Typography sx={webStyles.vendor_onboarding_text}>
                  The estimated time to complete this form is approximately 15
                  minutes. You have the option to save your progress and
                  continue at a later time if needed. We encourage you to
                  provide as much detailed information as possible, as it will
                  enable us to provide you with the best service tailored to
                  your needs. Please feel free to reach out to us with any
                  questions you may have while completing the form. We are here
                  to assist you every step of the way. Once your form is
                  submitted, please allow us up to 7 business days to review
                  your information, set up your account, and initiate the
                  provision of our services.
                </Typography>
              </Box>
              <Box sx={webStyles.stepper_container}>
                <Typography sx={webStyles.mandatory_text}>
                  Mandatory fields to get started with Keasy
                </Typography>
                <Stepper
                  activeStep={this.returnCurrentStep()}
                  sx={webStyles.horizontal_stepper}
                  data-test-id="stepper"
                >
                  {steps.map((label, index) => (
                    <Step key={label}>
                      <StepLabel
                        StepIconComponent={(stepIconProps) => (
                          <CustomStepIcon
                            {...stepIconProps}
                            number={index + 1}
                          />
                        )}
                        sx={{
                          "& .MuiStepLabel-label": {
                            fontWeight: 700,
                          },
                        }}
                      >
                        <Typography sx={{ fontWeight: 700, fontSize: "16px", fontFamily: "Outfit" }}>
                          {label}
                        </Typography>
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
                <Stepper
                  activeStep={this.returnCurrentStep()}
                  sx={webStyles.vertical_stepper}
                  orientation="vertical"
                  data-test-id="stepper"
                >
                  {steps.map((label, index) => (
                    <Step key={label}>
                      <StepLabel
                        StepIconComponent={(stepIconProps) => (
                          <CustomStepIcon
                            {...stepIconProps}
                            number={index + 1}
                          />
                        )}
                        sx={{
                          "& .MuiStepLabel-label": {
                            fontWeight: 700,
                          },
                        }}
                      >
                        <Typography sx={{ fontWeight: 700, fontSize: "18px", fontFamily: "Outfit" }}>
                          {label}
                        </Typography>
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Box>
              <Box sx={webStyles.form_container}>
              <PopupMessage
                open={this.state.messageOpen}
                type={this.state.messageType}
                message={this.state.message}
                handleClose={this.handlePopupMessageClose}
              />
                {this.returnCurrentStep() === 0 && (
                  <form id="form1" onSubmit={this.nextStep}>
                  <Divider sx={{ marginBottom: "46px" }} />
          
                  <Grid container spacing={2} sx={{ px: 4, pr: 8 }}>
                    <Grid item xs={12} sm={4}>
                      <Typography sx={webStyles.form_section_label}>
                        Vendor Information
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <FormGroup sx={{ marginBottom: "16px" }}>
                        <FormLabel htmlFor="companyName" sx={webStyles.input_label}>
                          Company Name
                        </FormLabel>
                        <TextField
                          required
                          id="companyName"
                          name="companyName"
                          fullWidth
                          autoComplete="name"
                          variant="outlined"
                          size="small"
                          error={this.state.errors.companyName}
                          helperText={this.getHelperText("companyName")}
                          value={this.state.companyName}
                          onChange={this.handleInputChange}
                        />
                      </FormGroup>

                      <FormGroup sx={{ marginBottom: "16px" }}>
                        <FormLabel htmlFor="vendorName" sx={webStyles.input_label}>
                          Vendor Name
                        </FormLabel>
                        <TextField
                          required
                          id="vendorName"
                          name="vendorName"
                          fullWidth
                          autoComplete="name"
                          variant="outlined"
                          InputProps={{
                            readOnly: true,
                          }}
                          size="small"
                          error={this.state.errors.vendorName}
                          helperText={this.getHelperText("vendorName")}
                          value={this.state.vendorName}
                          onChange={this.handleInputChange}
                        />
                      </FormGroup>
          
                      <FormGroup sx={{ marginBottom: "16px" }}>
                        <FormLabel htmlFor="vendorType" sx={webStyles.input_label}>
                          Vendor Type
                        </FormLabel>
                        <TextField
                          required
                          id="vendorType"
                          name="vendorType"
                          fullWidth
                          select
                          SelectProps={{ native: true }}
                          variant="outlined"
                          size="small"
                          value={this.state.vendorType}
                          onChange={this.handleInputChange}
                        >
                          <option value="">Please Select</option>
                          {this.state.allVendorTypes?.map((vendorType) => (
                            <option key={vendorType} value={vendorType}>{vendorType}</option>
                          ))}
                        </TextField>
                      </FormGroup>
          
                      <FormLabel htmlFor="vendorTrade" sx={webStyles.input_label}>
                        Vendor Trade
                      </FormLabel>
                      <Box
                        display="flex"
                        alignItems="center"
                        sx={{
                          border: 1,
                          borderColor: "grey.400",
                          borderRadius: 1,
                          "&:hover": {
                            borderColor: "black",
                          },
                        }}
                      >
                        <TextField
                          id="vendorTrade"
                          name="vendorTrade"
                          fullWidth
                          placeholder="Separate with comma"
                          size="small"
                          value={this.state.trade}
                          onChange={this.handleTradeInput}
                          onKeyPress={this.handleKeyPress}
                          sx={{
                            flex: 1,
                            mr: 1,
                            "& fieldset": { border: "none" },
                          }}
                        />
                        <IconButton
                          id="addIconButton"
                          onClick={this.handleAddTrade}
                          sx={{ color: this.state.trade !== ""? "black" : "#BAB1AB" }}
                        >
                          <AddCircleOutlineIcon />
                        </IconButton>
                      </Box>
                      {this.validateField("vendorTrade") && (
                        <Alert id="tradeAlert" severity="error" sx={{ mt: 2 }}>
                          Please add atleast 1 trade
                        </Alert>
                      )}
                      <Box mt={2}>
                        {this.state.vendorTrade.map((trade, index) => (
                          <Chip
                            key={index}
                            label={trade}
                            variant="outlined"
                            icon={
                              <IconButton
                                aria-label="delete"
                                data-test-id="deleteIconButton"
                                size="small"
                                onClick={() => this.handleDelete(trade)}
                                sx={{ mr: -1 }}
                              >
                                <Close fontSize="small" />
                              </IconButton>
                            }
                            sx={{ mr: 1, mb: 1, border: 2, borderRadius: 1 }}
                          />
                        ))}
                      </Box>

                      {
                        this.state.vendorType !== "" && (
                          <>
                            <FormGroup>
                              <FormLabel htmlFor="minCost" sx={webStyles.input_label}>
                                Minimum Price
                              </FormLabel>
                              <TextField
                                required
                                id="minCost"
                                name="minCost"
                                autoComplete="name"
                                variant="outlined"
                                size="small"
                                error={this.state.errors.minCost}
                                helperText={this.getHelperText("minCost")}
                                value={this.state.minCost}
                                onChange={this.handleInputChange}
                              />
                            </FormGroup>
                            <Box display="flex" alignItems="center" sx={{marginBottom: "16px", marginTop: "16px"}}>
                              <Typography variant="body1" sx={webStyles.input_label} style={{ marginRight: 16, minWidth: "11%", color: "rgba(0, 0, 0, 0.6)" }}>Mon-Fri</Typography>
                              <FormControl variant="outlined" style={{ marginRight: 8 }}>
                                <TextField
                                  required
                                  key="monFriStartTime"
                                  id="monFriStartTime"
                                  name="monFriStartTime"
                                  select
                                  SelectProps={{ native: true }}
                                  variant="outlined"
                                  size="small"
                                  value={this.state.monFriStartTime}
                                  onChange={this.handleInputChange}
                                >
                                  <option key="monFriStartTime" value="">--:--</option>
                                  {timeArray.map((time) => (
                                    <option key={`monFriStartTime-${time}`} value={time}>{this.convertTo12HourFormat(time)}</option>
                                  ))}
                                </TextField>
                              </FormControl>
                              <Typography variant="body1" sx={webStyles.input_label} style={{ margin: '0 8px', color: "rgba(0, 0, 0, 0.6)" }}>to</Typography>
                              <FormControl variant="outlined">
                                <TextField
                                  required
                                  id="monFriEndTime"
                                  name="monFriEndTime"
                                  select
                                  SelectProps={{ native: true }}
                                  variant="outlined"
                                  size="small"
                                  value={this.state.monFriEndTime}
                                  onChange={this.handleInputChange}
                                >
                                  <option key="monFriEndTime" value="">--:--</option>
                                  {timeArray.map((time) => (
                                    <option key={`monFriEndTime-${time}`} value={time}>{this.convertTo12HourFormat(time)}</option>
                                  ))}
                                </TextField>
                              </FormControl>
                            </Box>
                            {this.validateField("monFri") && (
                              <Alert id="monFriAlert" severity="error" sx={{ mt: 2 }}>
                                End time must be later than the start time.
                              </Alert>
                            )}
                            <Box display="flex" alignItems="center" sx={{marginBottom: "16px", marginTop: "16px"}}>
                              <Typography variant="body1" sx={webStyles.input_label} style={{ marginRight: 16, minWidth: "11%", color: "rgba(0, 0, 0, 0.6)" }}>Sat</Typography>
                              <FormControl variant="outlined" style={{ marginRight: 8 }}>
                                <TextField
                                  required
                                  id="satStartTime"
                                  name="satStartTime"
                                  select
                                  SelectProps={{ native: true }}
                                  variant="outlined"
                                  size="small"
                                  value={this.state.satStartTime}
                                  onChange={this.handleInputChange}
                                >
                                  <option key="satStartTime" value="">--:--</option>
                                  {timeArray.map((time) => (
                                    <option key={`satStartTime-${time}`} value={time}>{this.convertTo12HourFormat(time)}</option>
                                  ))}
                                </TextField>
                              </FormControl>
                              <Typography variant="body1" sx={webStyles.input_label} style={{ margin: '0 8px', color: "rgba(0, 0, 0, 0.6)" }}>to</Typography>
                              <FormControl variant="outlined">
                                <TextField
                                  required
                                  id="satEndTime"
                                  name="satEndTime"
                                  select
                                  SelectProps={{ native: true }}
                                  variant="outlined"
                                  size="small"
                                  value={this.state.satEndTime}
                                  onChange={this.handleInputChange}
                                >
                                  <option key="satEndTime" value="">--:--</option>
                                  {timeArray.map((time) => (
                                    <option key={`satEndTime-${time}`} value={time}>{this.convertTo12HourFormat(time)}</option>
                                  ))}
                                </TextField>
                              </FormControl>
                            </Box>
                            {this.validateField("sat") && (
                              <Alert id="satAlert" severity="error" sx={{ mt: 2 }}>
                                End time must be later than the start time.
                              </Alert>
                            )}
                            <Box display="flex" alignItems="center" sx={{marginBottom: "16px", marginTop: "16px"}}>
                              <Typography variant="body1" sx={webStyles.input_label} style={{ marginRight: 16, minWidth: "11%", color: "rgba(0, 0, 0, 0.6)" }}>Sun</Typography>
                              <FormControl variant="outlined" style={{ marginRight: 8 }}>
                                <TextField
                                  required
                                  id="sunStartTime"
                                  name="sunStartTime"
                                  select
                                  SelectProps={{ native: true }}
                                  variant="outlined"
                                  size="small"
                                  value={this.state.sunStartTime}
                                  onChange={this.handleInputChange}
                                >
                                  <option key="sunStartTime" value="">--:--</option>
                                  {timeArray.map((time) => (
                                    <option key={`sunStartTime-${time}`} value={time}>{this.convertTo12HourFormat(time)}</option>
                                  ))}
                                </TextField>
                              </FormControl>
                              <Typography variant="body1" sx={webStyles.input_label} style={{ margin: '0 8px', color: "rgba(0, 0, 0, 0.6)" }}>to</Typography>
                              <FormControl variant="outlined">
                                <TextField
                                  required
                                  id="sunEndTime"
                                  name="sunEndTime"
                                  select
                                  SelectProps={{ native: true }}
                                  variant="outlined"
                                  size="small"
                                  value={this.state.sunEndTime}
                                  onChange={this.handleInputChange}
                                >
                                  <option key="sunEndTime" value="">--:--</option>
                                  {timeArray.map((time) => (
                                    <option key={`sunEndTime-${time}`} value={time}>{this.convertTo12HourFormat(time)}</option>
                                  ))}
                                </TextField>
                              </FormControl>
                            </Box>
                            {this.validateField("sun") && (
                              <Alert id="sunAlert" severity="error" sx={{ mt: 2 }}>
                                End time must be later than the start time.
                              </Alert>
                            )}
                          </>
                        )
                      }
                    </Grid>
                  </Grid>
          
                  <Divider sx={{ marginBottom: "46px", marginTop: "42px" }} />
          
                  <Grid container spacing={2} sx={{ px: 4, pr: 8 }}>
                    <Grid item xs={12} sm={4}>
                      <Typography sx={webStyles.form_section_label}>
                        Contact Information
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <FormGroup sx={{ marginBottom: "16px" }}>
                        <FormLabel htmlFor="phone" sx={webStyles.input_label}>
                          Phone Number
                        </FormLabel>
                        <Grid container spacing={2}>
                          <Grid item xs={5} sm={3}>
                            <FormControl fullWidth>
                              <Select
                                required
                                id="countryCode"
                                name="countryCode"
                                variant="outlined"
                                size="small"
                                value={this.state.countryCode}
                                onOpen={this.handleCountryCodeDropdown}
                                onClose={() => {this.setState({ isCountryDropdownActive: false })}}
                                onChange={this.handleCountryChange}
                                sx={{
                                  border: "1px solid #e0e0e0",
                                  borderRadius: "4px",
                                }}
                              >
                                <MenuItem value="">Select a country</MenuItem>
                                {this.state.countries?.map((country) => (
                                  <MenuItem key={country.name} value={country.country_code}>
                                    <span style={{ display: this.state.isCountryDropdownActive? "block" : "none"}}>
                                      {country.emoji_flag} {country.name} (+{country.country_code})
                                      </span>
                                    <span style={{ display: this.state.isCountryDropdownActive? "none" : "block"}}>+{country.country_code}</span>
                                  </MenuItem>
                                ))}
                            </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={9}>
                            <TextField
                              required
                              id="phone"
                              name="phone"
                              fullWidth
                              variant="outlined"
                              size="small"
                              error={this.state.errors.phone}
                              helperText={this.getHelperText("phone")}
                              value={this.state.phone}
                              onChange={this.handleInputChange}
                            />
                          </Grid>
                        </Grid>
                      </FormGroup>
                      <FormGroup sx={{ marginBottom: "16px" }}>
                        <FormLabel htmlFor="email" sx={webStyles.input_label}>
                          Email Address
                        </FormLabel>
                        <TextField
                          required
                          id="email"
                          name="email"
                          fullWidth
                          variant="outlined"
                          size="small"
                          error={this.state.errors.email}
                          helperText={this.getHelperText("email")}
                          value={this.state.email}
                          onChange={this.handleInputChange}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </FormGroup>
          
                      <FormGroup sx={{ marginBottom: "16px" }}>
                        <FormLabel htmlFor="address" sx={webStyles.input_label}>
                          Address
                        </FormLabel>
                        <TextField
                          required
                          id="address"
                          name="address"
                          fullWidth
                          autoComplete="name"
                          variant="outlined"
                          size="small"
                          value={this.state.address}
                          onChange={this.handleInputChange}
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>
          
                  <Divider sx={{ marginBottom: "46px", marginTop: "42px" }} />
          
                  <Grid container spacing={2} sx={{ px: 4 }}>
                    <Grid item xs={12} sm={4}>
                      <Typography sx={webStyles.form_section_label}>
                        Federal Tax
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <FormGroup sx={{ marginBottom: "16px" }}>
                        <FormLabel htmlFor="taxpayerName" sx={webStyles.input_label}>
                          Taxpayer Name
                        </FormLabel>
                        <TextField
                          required
                          id="taxpayerName"
                          name="taxpayerName"
                          fullWidth
                          variant="outlined"
                          size="small"
                          error={this.state.errors.taxpayerName}
                          helperText={this.getHelperText("taxpayerName")}
                          value={this.state.taxpayerName}
                          onChange={this.handleInputChange}
                        />
                      </FormGroup>
          
                      <FormGroup sx={{ marginBottom: "16px" }}>
                        <FormLabel htmlFor="taxpayerId" sx={webStyles.input_label}>
                          Taxpayer ID
                        </FormLabel>
                        <TextField
                          required
                          id="taxpayerId"
                          name="taxpayerId"
                          fullWidth
                          variant="outlined"
                          size="small"
                          error={this.state.errors.taxpayerId}
                          helperText={this.getHelperText("taxpayerId")}
                          value={this.state.taxpayerId}
                          onChange={this.handleInputChange}
                        />
                      </FormGroup>
          
                      <FormControl sx={{ marginBottom: "16px" }}>
                        <FormLabel htmlFor="send" 
                          sx={{
                            ...webStyles.input_label,
                            '&.Mui-focused': {
                              color: 'rgba(0, 0, 0, 0.6)',
                            },
                          }}
                        >
                          Send 1099?
                        </FormLabel>
                        <RadioGroup
                          defaultValue={this.state.send1099}
                          aria-labelledby="send1099"
                          name="send1099"
                          onChange={this.handleInputChange}
                        >
                          <FormControlLabel
                            value="yes"
                            id="yes"
                            control={<CustomRadioButton />}
                            label="Yes"
                          />
                          <FormControlLabel id="no" data-test-id="no-radio" value="no" control={<CustomRadioButton />} label="No" />
                        </RadioGroup>
                      </FormControl>
          
                      <FileUpload
                        label="Form W-9"
                        name="formW9Files"
                        files={this.state.formW9Files}
                        onFilesChange={this.handleFilesChange}
                      />
                      {this.validateField("formW9Files") && (
                        <Alert severity="error" sx={{ mt: 2 }}>
                          Please attach a file
                        </Alert>
                      )}
                    </Grid>
                  </Grid>
                  <Box display={"flex"} justifyContent={"end"} sx={{ padding: "10px" }}>
                    <Button
                      size="medium"
                      id="nextButton"
                      type="submit"
                      sx={webStyles.form_button}
                    >
                      Next
                    </Button>
                  </Box>
                </form>
                )}
                {this.returnCurrentStep() === 1 && (
                    <form id="form2" onSubmit={this.nextStep}>
                    <Divider sx={{ marginBottom: "46px" }} />
            
                    <Grid container spacing={2} sx={{ px: 4 }}>
                      <Grid item xs={12} sm={4}>
                        <Typography sx={webStyles.form_section_label}>
                          Online Payables
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <FormControl sx={{ marginBottom: "16px" }}>
                          <FormLabel htmlFor="onlinePayables"
                            sx={{
                              ...webStyles.input_label,
                              '&.Mui-focused': {
                                color: 'rgba(0, 0, 0, 0.6)',
                              },
                            }}
                          >
                            Use online payables?
                          </FormLabel>
                          <RadioGroup
                            defaultValue={this.state.onlinePayables}
                            aria-labelledby="onlinePayables"
                            id="onlinePayables"
                            name="onlinePayables"
                            onChange={this.handleInputChange}
                          >
                            <FormControlLabel
                              value="yes"
                              id="yes"
                              control={<CustomRadioButton />}
                              label="Yes"
                            />
                            <FormControlLabel id="no" data-test-id="no-radio" value="no" control={<CustomRadioButton />} label="No" />
                          </RadioGroup>
                        </FormControl>
            
                        <FileUpload
                          label="Upload voided check (optional)"
                          name="voidedCheckFiles"
                          files={this.state.voidedCheckFiles}
                          onFilesChange={this.handleFilesChange}
                        />
            
                        <FormGroup sx={{ marginBottom: "16px" }}>
                          <FormLabel htmlFor="bankRoutingNumber" sx={webStyles.input_label}>
                            Vendor Bank Routing Number (optional)
                          </FormLabel>
                          <TextField
                            id="bankRoutingNumber"
                            name="bankRoutingNumber"
                            fullWidth
                            variant="outlined"
                            size="small"
                            error={this.state.errors.bankRoutingNumber}
                            helperText={this.getHelperText("bankRoutingNumber")}
                            value={this.state.bankRoutingNumber}
                            onChange={this.handleInputChange}
                          />
                        </FormGroup>
            
                        <FormGroup sx={{ marginBottom: "16px" }}>
                          <FormLabel htmlFor="bankAccountNumber" sx={webStyles.input_label}>
                            Vendor Bank Account Number (optional)
                          </FormLabel>
                          <TextField
                            id="bankAccountNumber"
                            name="bankAccountNumber"
                            fullWidth
                            variant="outlined"
                            size="small"
                            error={this.state.errors.bankAccountNumber}
                            helperText={this.getHelperText("bankAccountNumber")}
                            value={this.state.bankAccountNumber}
                            onChange={this.handleInputChange}
                          />
                        </FormGroup>
            
                        <FormControl sx={{ marginBottom: "16px", display: "flex" }}>
                          <FormLabel htmlFor="send" sx={webStyles.input_label}>
                            Payment type
                          </FormLabel>
                          <RadioGroup
                            defaultValue={this.state.paymentType}
                            aria-labelledby="paymentType"
                            id="paymentType"
                            name="paymentType"
                            onChange={this.handleInputChange}
                          >
                            <FormControlLabel
                              value="eCheck"
                              id="eCheck"
                              control={<CustomRadioButton />}
                              label="eCheck"
                            />
                            <FormControlLabel
                              value="payViaCheckInMail"
                              id="payViaCheckInMail"
                              control={<CustomRadioButton />}
                              label="Pay via check in mail"
                            />
                            <FormControlLabel
                              value="payOnlineViaVendorsWebsite"
                              id="payOnlineViaVendorsWebsite"
                              control={<CustomRadioButton />}
                              label="Pay online via vendor's website"
                            />
                            <FormGroup sx={{ marginBottom: "12px", ml: 3 }}>
                              <FormLabel htmlFor="websiteLink" sx={webStyles.input_label}>
                                Upload website link
                              </FormLabel>
                              <TextField
                                id="websiteLink"
                                name="websiteLink"
                                fullWidth
                                variant="outlined"
                                size="small"
                                error={this.state.errors.websiteLink}
                                helperText={this.getHelperText("websiteLink")}
                                value={this.state.websiteLink}
                                onChange={this.handleInputChange}
                              />
                            </FormGroup>
            
                            <FormControlLabel
                              value="payWithVenmo"
                              id="payWithVenmo"
                              control={<CustomRadioButton />}
                              label="Pay with Venmo"
                            />
                            <FormGroup sx={{ marginBottom: "12px", ml: 3 }}>
                              <FormLabel
                                htmlFor="venmoAccountNumber"
                                sx={webStyles.input_label}
                              >
                                Upload Venmo account number
                              </FormLabel>
                              <TextField
                                id="venmoAccountNumber"
                                name="venmoAccountNumber"
                                fullWidth
                                variant="outlined"
                                size="small"
                                value={this.state.venmoAccountNumber}
                                onChange={this.handleInputChange}
                              />
                            </FormGroup>
            
                            <FormControlLabel
                              value="payWithZelle"
                              id="payWithZelle"
                              control={<CustomRadioButton />}
                              label="Pay with Zelle"
                            />
                            <FormGroup sx={{ ml: 3 }}>
                              <FormLabel
                                htmlFor="zelleAccountNumber"
                                sx={webStyles.input_label}
                              >
                                Upload Zelle account number
                              </FormLabel>
                              <TextField
                                id="zelleAccountNumber"
                                name="zelleAccountNumber"
                                fullWidth
                                variant="outlined"
                                size="small"
                                value={this.state.zelleAccountNumber}
                                onChange={this.handleInputChange}
                              />
                            </FormGroup>
                          </RadioGroup>
                        </FormControl>
            
                        <FormGroup>
                          <FormLabel htmlFor="comments" sx={webStyles.input_label}>
                            Comments
                          </FormLabel>
                          <TextField
                            id="comment"
                            name="comment"
                            multiline
                            rows={3}
                            variant="outlined"
                            helperText={
                              <span style={{ float: "right" }}>
                                {this.state.comment.length}/100
                              </span>
                            }
                            inputProps={{ maxLength: 100 }}
                            value={this.state.comment}
                            onChange={this.handleInputChange}
                          />
                        </FormGroup>
                      </Grid>
                    </Grid>
                    <Box display={"flex"} justifyContent={"end"} sx={{ padding: "10px" }}>
                      <Button
                        size="medium"
                        id="prevButton"
                        sx={{...webStyles.form_back_button, mr: 2}}
                        onClick={this.prevStep}
                      >
                        Back
                      </Button>
                      <Button
                        size="medium"
                        id="nextButton"
                        type="submit"
                        sx={webStyles.form_button}
                      >
                        Next
                      </Button>
                    </Box>
                  </form>
                )}
                {this.returnCurrentStep() === 2 && (
                    <form id="form3" onSubmit={this.handleFormSubmit}>
                    <Divider sx={{ marginBottom: "46px" }} />
            
                    <Grid container spacing={2} sx={{ px: 4 }}>
                      <Grid item xs={12} sm={4}>
                        <Typography sx={webStyles.form_section_label}>
                          General liability
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <FileUpload
                          label="Upload business license"
                          name="businessLicense"
                          files={this.state.businessLicense}
                          onFilesChange={this.handleFilesChange}
                        />
                        {this.validateField("businessLicense") && (
                        <Alert severity="error" sx={{ mt: 2 }}>
                          Please attach a file
                        </Alert>
                        )}
                        <FormGroup sx={{ marginBottom: "16px" }}>
                          <FormLabel htmlFor="licenseExpDate" sx={webStyles.input_label}>
                            Exp. Date
                          </FormLabel>
                          <CustomDatePicker
                            onChange={
                              this.handleDateChange("licenseExpDate")
                            }
                            required={true}
                            value = {this.state.licenseExpDate}>
                            </CustomDatePicker>
                        </FormGroup>
            
                        <FileUpload
                          label="Upload business insurance"
                          name="businessInsurance"
                          files={this.state.businessInsurance}
                          onFilesChange={this.handleFilesChange}
                        />
                        {this.validateField("businessInsurance") && (
                        <Alert severity="error" sx={{ mt: 2 }}>
                          Please attach a file
                        </Alert>
                        )}
                        <FormGroup sx={{ marginBottom: "42px" }}>
                          <FormLabel htmlFor="insuranceExpDate" sx={webStyles.input_label}>
                            Exp. Date
                          </FormLabel>
                          <CustomDatePicker
                            value={this.state.insuranceExpDate}

                            required={true}
                            onChange={
                              this.handleDateChange
                              (
                              "insuranceExpDate"
                             )
                          }
                             />
                        </FormGroup>
                      </Grid>
                    </Grid>
            
                    <Divider sx={{ marginBottom: "46px" }} />
            
                    <Grid container spacing={2} sx={{ px: 4 }}>
                      <Grid item xs={12} sm={4}>
                        <Typography sx={webStyles.form_section_label}>
                          EPA Certification (optional)
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <FileUpload
                          label="Upload EPA Certification license"
                          name="EpaCertificationLicense"
                          files={this.state.EpaCertificationLicense}
                          onFilesChange={this.handleFilesChange}
                        />
                        <FormGroup sx={{ marginBottom: "16px" }}>
                          <FormLabel
                            htmlFor="EpaCertificationLicenseExpDate"
                            sx={webStyles.input_label}
                          >
                            Exp. Date
                          </FormLabel>
                          <CustomDatePicker
                          onChange={this.handleDateChange("EpaCertificationLicenseExpDate")} 
                          value={this.state.EpaCertificationLicenseExpDate}
                          required={false}
                          ></CustomDatePicker>
                        </FormGroup>
            
                        <FileUpload
                          label="Upload other certificate"
                          name="otherCertificate"
                          files={this.state.otherCertificate}
                          onFilesChange={this.handleFilesChange}
                        />
                        <FormGroup sx={{ marginBottom: "42px" }}>
                          <FormLabel
                            htmlFor="otherCertificateExpDate"
                            sx={webStyles.input_label}
                          >
                            Exp. Date
                          </FormLabel>
                          <CustomDatePicker
                            value={this.state.otherCertificateExpDate}
                          onChange={
                            this.handleDateChange(
                            "otherCertificateExpDate")
                           }
                            required={false}></CustomDatePicker>
                        </FormGroup>
                      </Grid>
                    </Grid>
            
                    <Divider sx={{ marginBottom: "46px" }} />
            
                    <Grid container spacing={2} sx={{ px: 4 }}>
                      <Grid item xs={12} sm={4}>
                        <Typography sx={webStyles.form_section_label}>
                          State license (optional)
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <FileUpload
                          label="Upload state license"
                          name="stateLicense"
                          files={this.state.stateLicense}
                          onFilesChange={this.handleFilesChange}
                        />
                        <FormGroup sx={{ marginBottom: "16px" }}>
                          <FormLabel
                            htmlFor="stateLicenseExpDate"
                            sx={webStyles.input_label}
                          >
                            Exp. Date
                          </FormLabel>
                          <CustomDatePicker
                            required={false}
                            value={this.state.stateLicenseExpDate}

                            onChange={this.handleDateChange("stateLicenseExpDate")} 
                            
                           />
                        </FormGroup>
            
                        <FileUpload
                          label="Upload state insurance"
                          name="stateInsurance"
                          files={this.state.stateInsurance}
                          onFilesChange={this.handleFilesChange}
                        />
                        <FormGroup sx={{ marginBottom: "42px" }}>
                          <FormLabel
                            htmlFor="stateInsuranceExpDate"
                            sx={webStyles.input_label}
                          >
                            Exp. Date
                          </FormLabel>
                          <CustomDatePicker
                            value={this.state.stateInsuranceExpDate}
                          onChange={
                            this.handleDateChange(
                            "stateInsuranceExpDate"
                          )}
                          ></CustomDatePicker>
                        </FormGroup>
                      </Grid>
                    </Grid>
            
                    <Divider sx={{ marginBottom: "46px" }} />
            
                    <Grid container spacing={2} sx={{ px: 4 }}>
                      <Grid item xs={12} sm={4}>
                        <Typography sx={webStyles.form_section_label}>Notes</Typography>
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <FormGroup>
                          <TextField
                            id="notes"
                            name="notes"
                            multiline
                            rows={3}
                            variant="outlined"
                            helperText={
                              <span style={{ float: "right" }}>
                                {this.state.notes.length}/100
                              </span>
                            }
                            inputProps={{ maxLength: 100 }}
                            value={this.state.notes}
                            onChange={this.handleInputChange}
                          />
                        </FormGroup>
                      </Grid>
                    </Grid>
            
                    <Box display={"flex"} justifyContent={"end"} sx={{ padding: "10px" }}>
                    <Button
                        size="medium"
                        id="prevButton"
                        sx={{...webStyles.form_back_button, mr: 2}}
                        onClick={this.prevStep}
                      >
                        Back
                      </Button>
                      <Button
                        size="medium"
                        id="done"
                        type="submit"
                        sx={webStyles.form_button}
                      >
                        Done
                      </Button>
                    </Box>
                  </form>
                )}
              </Box>
            </Container>
          </Grid>
        </Grid>
    );
  }
}

const webStyles = {
  navBarItem: {
    position: "fixed",
    top: 0,
    left: 0,
    height: "100%",
    overflowY: "auto",
    zIndex: 1000,
    "@media(max-width: 992px)": {
      position: "unset",
      height: "auto",
      overflowY: "hidden",
      border: "none",
    },
    scrollbarWidth: "none", 
    msOverflowStyle: "none",
    '&::-webkit-scrollbar': {
      display: 'none',
    }
  },
  welcome_container: {
    paddingLeft: "16px",
    paddingBottom: "55px",
    '@media (max-width: 600px)': {
        paddingLeft: "10px",
      },
      '@media (max-width: 960px)': {
        paddingLeft: "10px",
      }
  },
  vendor_onboarding_header: {
    fontFamily: "Outfit",
    fontSize: "30px",
    fontWeight: 700,
    color: "#CC9200",
  },
  vendor_onboarding_bold_text: {
    fontFamily: "Outfit",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "17px",
    color: "#70645C",
  },
  vendor_onboarding_text: {
    fontFamily: "Outfit",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "26px",
    color: "#70645C",
  },
  horizontal_stepper: {
    justifyContent: "space-between",
    '@media (max-width: 600px)': {
        display: "none",
      },
      '@media (max-width: 960px)': {
        display: "none",
      }
  },
  vertical_stepper: {
    justifyContent: "space-between",
    display: "none",
    '@media (max-width: 600px)': {
        display: "flex",
      },
      '@media (max-width: 960px)': {
        display: "flex",
      }
  },
  stepper_container: {
    paddingLeft: "70px",
    paddingBottom: "98px",
    '@media (max-width: 600px)': {
        paddingLeft: "10px",
      },
      '@media (max-width: 960px)': {
        paddingLeft: "10px",
      }
  },
  mandatory_text: {
    fontFamily: "Outfit",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
    letterSpacing: "-0.005em",
    color: "#000000",
    paddingBottom: "26px",
  },
  form_container: {
    paddingLeft: "152px",
    '@media (max-width: 600px)': {
        paddingLeft: "10px",
      },
      '@media (max-width: 960px)': {
        paddingLeft: "10px",
      }
  },
  form_section_label: {
    fontFamily: "Outfit, sans-serif",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "17px",
    color: "#BAB1AB",
  },
  input_label: {
    fontFamily: "Outfit, sans-serif",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
  },
  form_button: {
    backgroundColor: "#FFC123",
    fontFamily: "Outfit, sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    textTransform: "capitalize",
    color: "#000000",
    marginTop: "72px",
    height: "44px",
    width: "184px",
    "&:hover": {
      backgroundColor: "#FFC123",
    },
    "&.Mui-disabled": {
      backgroundColor: "#FFF1CC",
    },
  },
  form_back_button: {
    backgroundColor: "#FFF1CC",
    fontFamily: "Outfit, sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    textTransform: "capitalize",
    color: "#000000",
    marginTop: "72px",
    height: "44px",
    width: "184px",
    "&:hover": {
      backgroundColor: "#FFF1CC",
    },
  },
  "&.Mui-disabled": {
    backgroundColor: "#FFF1CC",
  },
  addServicesButton: {
    fontFamily: "Outfit, sans-serif",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    color: "#CC9200",
    textTransform: "capitalize",
    paddingTop: 0,
  },
  addServicesIcon: {
    marginRight: "10px",
    color: "#CC9200",
  },
};
