import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData ,setStorageData,removeStorageData} from "../../../../packages/framework/src/Utilities";
import { AlertColor } from "@mui/lab";

export interface APIPayloadType {
  contentType: string;
  method: string;
  endPoint: string;
  token?: string;
}
export interface Category {
  id: string;
  attributes: {
    id: number;
    name: string;
    created_at: string;
    updated_at: string;
    category_id: number;
  };
}
export interface TenantServiceRequests {
  id: string;
  work_needed: string;
  created_by: string;
  availability: string;
  description: string;
  status: string;
  due_date_time: string;
  due_date: string;
  due_date_frequency: string;
  sub_category?: {
    id: number;
    name: string;
    category_id: number;
  };
}


export interface UnitDetails {
  unit_id: string;
  name: string;
  status: string;
  tenant_service_requests: TenantServiceRequests[];
}

export interface PropertiesDetails {
  property_id: string;
  type: string;
  property_type: string;
  property_name: string;
  address: string | null;
  units: UnitDetails[];
}
export interface PropertiesDropdown {
  id: string;
  type: string;
  attributes: {
    property_type: string;
    property_name: string;
    address: string;
    units: any;
  }
}


export interface Properties {
  data: Array<PropertiesDetails>;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  info: any;
  data: any;
  googleChartData: any;
  sortColumn: string;
  sortDirection: "asc" | "desc";
  selected: number[];
  selectedProperty: any;
  selectedProperties: any,
  selectedUnit: string | null;
  expanded: number | null;
  anchorElement: { [key: number]: HTMLElement | null };
  subMenuAnchorEl: null | HTMLElement;
  anchorEl: null | HTMLElement;
  showAllItems: boolean;
  selectedMenuItemIndex: null | number;
  properties: PropertiesDetails[];
  selectedOptions: { title: string }[];
  selectedUnits: any,
  isOpenDialog: boolean,
  isOpenDialog2: boolean,
  sortState: {
    [key: number]: {
      sortColumn: string;
      sortDirection: "asc" | "desc";
    };
  },
  selectedTanentId: number | null,
  comment: string | null,
  selectedComments: any,
  allComments: any,
  selectedCommentProperty: string,
  selectedCommentUnit: string,
  activeMainItem:string,
  openLandlordDrawer: boolean,
  showAllComments: boolean,
  email: any,
  searchQuery: any,
  selectedPropertyIds: any,
  selectedUnitIds: any,
  originalProperties: any,
  selectedCategoryNames :any;
  allCatagory: Category[];
  selectedStatus: string[];
  messageOpen: boolean;
  messageType: AlertColor;
  message: string;
  chips: { propertyId: number; unitId: number; propertyName: string; unitName: string }[];
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandlordServiceRequestsDashboardController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiGetDataCallId: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      info: {
        labels: [],
        data: [],
        barColors: [],
      },
      data: {
      
      },
      googleChartData: ["Title", "Value"],
      sortColumn: "id",
      sortDirection: "asc",
      selected: [],
      selectedProperty: null,
      selectedProperties: [],
      selectedUnit: null,
      expanded: null,
      anchorElement: {},
      subMenuAnchorEl: null,
      anchorEl: null,
      showAllItems: false,
      selectedMenuItemIndex: null,
      properties: [],
      selectedOptions:[],
      selectedUnits: [],
      isOpenDialog: false,
      isOpenDialog2: false,
      sortState: {},
      selectedTanentId: null,
      comment: null,
      selectedComments: [],
      allComments: [],
      selectedCommentProperty: "",
      selectedCommentUnit: "",
      activeMainItem: "LandlordServiceRequestsDashboard",
      openLandlordDrawer:false,
      showAllComments: false,
      email: "",
      searchQuery: "",
      selectedPropertyIds: [],
      selectedUnitIds: [],
      originalProperties: [],
      selectedCategoryNames:[],
      selectedStatus:[],
      allCatagory:[],
      messageOpen: false,
      messageType: "success",
      message: "",
      chips:[],
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start   
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.GetServiceRequstId) {    
      
      if (!responseJson.errors && responseJson) {
        this.setState({
          properties: responseJson.data
        });
      } else if (responseJson && responseJson.errors) {
      }
      };
    if (apiRequestCallId === this.apiGetpropertyForDropDownCallId) {    
        if (!responseJson.errors && responseJson) {
          this.setState({
            originalProperties: responseJson.data
          });
        } else if (responseJson && responseJson.errors) {
        }
        };
    if (apiRequestCallId === this.getCatagoryCallId) {   
        
          if (!responseJson.errors && responseJson) {
            this.setState({
              allCatagory:responseJson.data
             });
          } else if (responseJson && responseJson.errors) {
            this.setState({
              messageOpen: true,
              messageType: "error",
              message: responseJson.errors
            });
          }
          };
    if (apiRequestCallId === this.getServiceRequestFilterApiCallId) {    
      if (!responseJson.errors && responseJson) {
        this.setState({
          properties: responseJson.data
        });
      } else if (responseJson && responseJson.errors) {
      }
      };
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    // this.getPropertiesDetails();
    this.getPropertiesDetails();
    this.getDropdownPropertiesDetails();
    this.getCatagory();
  }
  

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  // Customizable Area Start
  apiPostCommentCallId: any;
  apiGetCommentCallId: any;
  apiGetpropertyForDropDownCallId: any;
  GetServiceRequstId: any;
  getCatagoryCallId:any="";
  getServiceRequestFilterApiCallId:string="";

  handleNavClick = (labelKey: string) => {
    this.setState({ activeMainItem: labelKey }, () => {
      this.handleWorkOrderMenu();
    });
  };
 
  handleWorkOrderMenu = () => {
    const toMsgWork = new Message(getName(MessageEnum.NavigationMessage));
    const { activeMainItem } = this.state;

    toMsgWork.addData(
      getName(MessageEnum.NavigationTargetMessage),
      activeMainItem
    );
    toMsgWork.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(toMsgWork);
  };
  convertTime = (time: any) => {
    const date = new Date(time);
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', options).replace(',', '');
    return formattedDate;
  }

  handleDrawerClick = () => {
    this.setState({
      openLandlordDrawer: !this.state.openLandlordDrawer,
    });
  };
 
  handleSortRequest = (property: string, direction: "asc" | "desc", unitIndex: number) => {
    this.setState(prevState => ({
      sortState: {
        ...prevState.sortState,
        [unitIndex]: {
          sortColumn: property,
          sortDirection: direction
        }
      }
    }));
  };

  handleUnitClick = (unit: string, unitId: number) => {
    const { selectedProperty , chips} = this.state;
    if (selectedProperty) {
      const newChip = {
        propertyId: parseInt(selectedProperty.id),
        unitId: unitId,
        propertyName: selectedProperty.attributes.property_name,
        unitName: unit
      };
      }
   
      if (selectedProperty) {
        const newChip = {
          propertyId: parseInt(selectedProperty.id),
          unitId: unitId,
          propertyName: selectedProperty.attributes.property_name,
          unitName: unit
        };
  
        const chipExists = chips.some(
          chip => chip.propertyId === newChip.propertyId && chip.unitId === newChip.unitId
        );
  
        if (!chipExists) {
          this.setState((prevState) => ({
            ...prevState,
            chips: [...prevState.chips, newChip],
            selectedUnit: unit,
            anchorEl: null,
            subMenuAnchorEl: null,
            selectedProperty: null,
            messagePopup: false
          }), () => {
            this.getPropertiesFilter("","");
          });
          
        } else {
          this.setState({
            message: configJSON.filteredMessage,
            messageType: configJSON.errorMessage,
            messageOpen: true
          });
        }
      }
    
  };

  handlePropertyClick = (propEvent: React.MouseEvent<HTMLElement>, property: PropertiesDropdown, indexItem: number) => {
    this.setState({
      selectedProperty: property,
      subMenuAnchorEl: propEvent.currentTarget,
      selectedMenuItemIndex: indexItem
    });
  };


  handleMenuIconClick = (eventIcon: React.MouseEvent<HTMLElement>, rowId: number) => {
    this.setState({
      anchorElement: {
        ...this.state.anchorElement,
        [rowId]: eventIcon.currentTarget
      },
    });
  };

 
  handleClose = () => {
    this.setState({
      anchorEl: null,
      subMenuAnchorEl: null,
      selectedProperty: null,
      selectedUnit: null
    });
  };

  clearFilters = () => {
    this.setState({
      selectedProperties: [],
      selectedUnits: [],
      searchQuery: "",
      selectedPropertyIds: [],
      selectedUnitIds: [],
    });
    this.getPropertiesDetails();
  };

  handleDelete = (property: any, unit: any) => {
  };

 

  handleCheckRequest= (rqst:any) => { 
    setStorageData("ServiceRequestId", rqst.id)
    const navigationMessage = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    navigationMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "ServiceRequest"
    );
    navigationMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    runEngine.sendMessage("MergeEngineUtilities", navigationMessage);
  };
  handlePropertyData = () =>{
    return this.state.originalProperties.slice(0, this.state.showAllItems ? this.state.originalProperties.length : 4);
  };
  handleChangeColor = (itemIndex: number) =>{
    return this.state.selectedMenuItemIndex === itemIndex ? "changeColor" : "";
  };
  handlePropertySlice = () =>{
    return !this.state.showAllItems && this.state.originalProperties.length > 4;
  };
  toggleShowAll = () => {
    this.setState(prevState => ({
      showAllItems: !prevState.showAllItems
    }));
  };

  handleCloseMenu = () => {
    this.setState({ subMenuAnchorEl: null });
  };
  getDropdownPropertiesDetails = async () => {
    const token = await getStorageData("authToken") 
    const header = {
      "Content-Type":configJSON.appointmentApiContentType,
      token:token
    };

    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetpropertyForDropDownCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.propertiesApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     configJSON.getAppointmentListAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
  handleProperClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };
  formatPropertyName = (name: any) => {
    if (!name) return '';
    const truncatedName = name.length > 13 ? name.slice(0, 13) + '...' : name;
    return truncatedName.toUpperCase();
  };
  getCatagory = async () => {
    const authToken = await getStorageData("authToken")
      const authHeader = {
        token: authToken
      };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCatagoryCallId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(authHeader)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCatagorApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAppointmentListAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleDeleteCategory = (selectedId: any) => {
    this.setState((prevState) => ({
      selectedCategoryNames: prevState.selectedCategoryNames.filter((id:number) => id !== selectedId)
    }), () => {
      this.applyAllFilters();
    });
  };
  removeChip = (index: number) => {
    this.setState((prevState) => {
      const chips = [...prevState.chips];
      chips.splice(index, 1);
      return { chips };
    }, () => {
      this.getPropertiesFilter("", "");
    });
  };
  
  handleDeleteDescription = (status: string) => {
    this.setState((prevState) => ({
      selectedStatus: prevState.selectedStatus.filter((item) => item !== status)
    }), () => {
      this.applyAllFilters();
    });
  };
  generateChipsParam = (): { propertyIds: string; unitIds: string } => {
    const { chips } = this.state;
    const propertyIds = chips.map(chip => chip.propertyId).join(',');
    const unitIds = chips.map(chip => chip.unitId).join(',');
  
    return { propertyIds, unitIds };
  };
  
  getPropertiesDetails = async (
    searchQuery: string = "",
    propertyIds: string[] = [],
    unitIds: string[] = []) => {
    const endPoint = `${configJSON.getServiceRequest}`;
    const token = await getStorageData("authToken")
  
    const header = {
      "Content-Type":configJSON.appointmentApiContentType,
      token:token
    };

    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    this.GetServiceRequstId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     configJSON.getAppointmentListAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
   
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
  getPropertiesFilter = async (propertiesId: string, searchParams: string) => {
    const { propertyIds, unitIds } = this.generateChipsParam();
    const endPoint = `${configJSON.getServiceRequest}?property_id=${propertyIds}&unit_id=${unitIds}`;
    const token = await getStorageData("authToken");
    
    const authHeader = {
      "Content-Type":configJSON.appointmentApiContentType,
      token:token
    };

    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetServiceRequstId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(authHeader)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     configJSON.getAppointmentListAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
   
  };
  applyAllFilters = async () => {
    const { selectedCategoryNames, selectedStatus, searchQuery } = this.state;
    const categoryParam = selectedCategoryNames.length > 0 ? `&sub_category_id=${selectedCategoryNames.join(',')}` : '';
    const { propertyIds, unitIds } = this.generateChipsParam();
    const token = await getStorageData("authToken");
    const statusParam = selectedStatus.length > 0 ? `&status=${selectedStatus.join(',')}` : '';
    const propertyParam = propertyIds ? `&property_id=${propertyIds}` : '';
    const unitParam = unitIds ? `&unit_id=${unitIds}` : '';
    const endPoint = `${configJSON.getServiceRequest}?${propertyParam}${unitParam}${categoryParam}${statusParam}`;
    
    const header = {
      "Content-Type": configJSON.appointmentApiContentType,
      token: token
    };
  
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetServiceRequstId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAppointmentListAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
  clearChips = () => {
    this.setState({
      selectedCategoryNames: [],
      selectedStatus: [],
      selectedPropertyIds: [],
      selectedUnitIds: [],
      chips: [],
      searchQuery: "",
      selectedProperties: [],
      selectedUnits: [],
      selectedProperty: null,
      selectedUnit: null
    }, () => {
      this.applyAllFilters();
    });
  };
  
  // Customizable Area End
}
