Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Analytics";
exports.labelBodyText = "Analytics Body";
exports.dashboardOrder = "Dashboard";
exports.myAccountOrder = "My Account";
exports.workOrder = "Work Orders";
exports.preferredOrder = "Preferred Vendors";
exports.remindersOrder = "Reminders";
exports.mypropOrder = "My Properties";
exports.financiaOrder = "Financial";
exports.reportOrder = "Delinquency Report";
exports.chatOrder = "Chat with Keasy";
exports.settingOrder = "Settings";
exports.dashboardOrderlabel = "Dashboard";
exports.myAccountOrderabel = "landlordaccount";
exports.worksOrderlabel = "Appointments";
exports.reminOrderlabel = "AutomatedPrioritization";
exports.mypropOrderlabel = "PortfolioManagement";
exports.reportOrderlabel = "VisualAnalytics";
exports.chatOrderlabel = "Chat";
exports.settingOrderlabel = "Settings2";
exports.btnExampleTitle = "CLICK ME";

exports.dashboardOrder = "Dashboard";
exports.myAccountOrder = "My Account";
exports.workOrder = "Work Orders";
exports.preferredOrder = "Preferred Vendors";
exports.remindersOrder = "Reminders";
exports.mypropOrder = "My Properties";
exports.financiaOrder = "Financial";
exports.reportOrder = "Delinquency Report";
exports.chatOrder = "Chat with Keasy";
exports.settingOrder = "Settings";
exports.dashboardOrderlabel = "Dashboard";
exports.myAccountOrderabel = "landlordaccount";
exports.worksOrderlabel = "Appointments";
exports.prevendorOrderlabel = "TaskList";
exports.reminOrderlabel = "AutomatedPrioritization";
exports.mypropOrderlabel = "PortfolioManagement";
exports.financOrderlabel = "Analytics";
exports.reportOrderlabel = "VisualAnalytics";
exports.chatOrderlabel = "Chat";
exports.settingOrderlabel = "Settings2";
exports.settings = "Settings";
exports.getPropertiesApiEndPoint = "/account_block/properties";
exports.getLedgerTypesApiEndPoint = "/bx_block_visual_analytics/account_transactions/get_ledger_type";
exports.addTransactionApiEndPoint = "/bx_block_visual_analytics/account_transactions";
exports.descriptionOptions = ["Income", "Expense", "Credit"];
exports.getTransactionlistAPIEndPoint="/bx_block_visual_analytics/account_transactions";
exports.getPropertyListAPIEndPoint = "/account_block/properties";
exports.reversePaymentIdAPIEndPoint="/bx_block_visual_analytics/account_transactions/change_transaction_status"
exports.searchAPIEndPoint="/bx_block_visual_analytics/account_transactions/search_transactions"
exports.multipleStatusChangeEndPoint="/bx_block_visual_analytics/account_transactions/change_multiple_transaction_status"
exports.getApiDataMethod="GET";
exports.putApiDataMethod="PUT";
exports.failed="failed";
exports.completed="completed";
exports.pending="pending"
exports.serviceRequests = "Service Requests";
exports.lease = "Lease";
exports.paymentHistory = "Payment History";
exports.tenantDashboard = "TenantDashboard";
exports.servicelabel = "ServicerequestDashboard";
exports.leaselabel = "DownloadOptions";
exports.paymentHistorylabel = "BaselineReporting";
exports.vendorProfile  = "Vendor Profile";
exports.projects = "Project History";
exports.vendorLabelProfile = "UserProfileBasicBlock";
exports.vendorProjects = "Tasks";
exports.invoiceLable = "Sent Invoices";
exports.invoiceBlock="InvoiceBilling";
exports.ApprovedOwnersLable="Approved Owners"
exports.serviceOrderlabel = "LandlordServiceRequestsDashboard";
exports.ApprovedOwnersBlock = "AutomaticFormCreation";
exports.documentlabel = "Cfdocumentforwarding4";
exports.document = "Documents";
exports.getTenantPropertiesApiEndPoint = "/account_block/tenants/tenant_info";
exports.serviceOrder = "Service Requests";
// Customizable Area End