import { FormEvent } from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";

import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { AlertColor } from "@mui/material";
export const configJSON = require("./config.js");
export const webConfigJSON = require("./config.js");
const navigation = require("react-navigation");
import { SelectChangeEvent } from '@mui/material/Select';

export interface Props {
  navigation: typeof navigation;
}

export interface S {
  step: number;
  trade: string;
  companyName: string;
  vendorName: string;
  vendorType: string;
  vendorTrade: string[];
  countryCode: string;
  phone: string;
  email: string;
  address: string;
  taxpayerName: string;
  taxpayerId: string;
  send1099: string;
  formW9Files: FileWithPreview[];
  onlinePayables: string;
  voidedCheckFiles: FileWithPreview[];
  bankRoutingNumber: string;
  bankAccountNumber: string;
  paymentType: string;
  websiteLink: string;
  venmoAccountNumber: string;
  zelleAccountNumber: string;
  comment: string;
  businessLicense: FileWithPreview[];
  licenseExpDate: Date | null;
  businessInsurance: FileWithPreview[];
  insuranceExpDate: Date | null;
  EpaCertificationLicense: FileWithPreview[];
  EpaCertificationLicenseExpDate: Date | null;
  otherCertificate: FileWithPreview[];
  otherCertificateExpDate: Date | null;
  stateLicense: FileWithPreview[];
  stateLicenseExpDate: Date | null;
  stateInsurance: FileWithPreview[];
  stateInsuranceExpDate: Date | null;
  notes: string;
  currentStep: number;
  activeVendorItem: string;
  openVendorDrawer: boolean;
  isVertical: boolean;
  messageOpen: boolean;
  messageType: AlertColor;
  message: string;
  isCountryDropdownActive: boolean;
  errors: {
    companyName: boolean;
    vendorName: boolean;
    vendorTrade: boolean;
    minCost: boolean;
    monFriTime: boolean;
    satTime: boolean;
    sunTime: boolean;
    phone: boolean;
    email: boolean;
    formW9Files: boolean;
    bankRoutingNumber: boolean;
    bankAccountNumber: boolean;
    businessLicense: boolean;
    licenseExpDate: boolean;
    businessInsurance: boolean;
    insuranceExpDate: boolean;
    taxpayerName: boolean;
    taxpayerId: boolean;
    websiteLink: boolean;
  };
  countries: Array<{ name: string; emoji_flag: string; country_code: string }>;
  allVendorTypes: string[];
  minCost: string;
  monFriStartTime: string;
  monFriEndTime: string;
  satStartTime: string;
  satEndTime: string;
  sunStartTime: string;
  sunEndTime: string;
}

export interface SS {}

interface FileWithPreview extends File {
  preview: string;
}

interface UserEmail {
  id: string;
}
export default class VendorOnboardingController extends BlockComponent<
  Props,
  S,
  SS
> {
  getVendorTypesApiCallId: string = "";
  getContriesListApiCallId: string = "";
  addVendorDetailsApiId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      step: 0,
      trade: "",
      companyName: "",
      vendorName: "",
      vendorType: "",
      vendorTrade: [],
      countryCode: "",
      phone: "",
      email: "",
      address: "",
      taxpayerName: "",
      taxpayerId: "",
      send1099: "yes",
      formW9Files: [],
      onlinePayables: "yes",
      voidedCheckFiles: [],
      bankRoutingNumber: "",
      bankAccountNumber: "",
      paymentType: "eCheck",
      websiteLink: "",
      venmoAccountNumber: "",
      zelleAccountNumber: "",
      comment: "",
      businessLicense: [],
      licenseExpDate: null,
      businessInsurance: [],
      insuranceExpDate: null,
      EpaCertificationLicense: [],
      EpaCertificationLicenseExpDate: null,
      otherCertificate: [],
      otherCertificateExpDate: null,
      stateLicense: [],
      stateLicenseExpDate: null,
      stateInsurance: [],
      stateInsuranceExpDate: null,
      notes: "",
      currentStep: 0,
      activeVendorItem: "UserProfileBasicBlock",
      openVendorDrawer: false,
      isVertical: false,
      messageOpen: false,
      messageType: "success",
      message: "Form submitted successfully.",
      isCountryDropdownActive: false,
      errors: {
        companyName: false,
        vendorName: false,
        vendorTrade: false,
        minCost: false,
        monFriTime: false,
        satTime: false,
        sunTime: false,
        phone: false,
        email: false,
        formW9Files: false,
        bankRoutingNumber: false,
        bankAccountNumber: false,
        businessLicense: false,
        licenseExpDate: false,
        businessInsurance: false,
        insuranceExpDate: false,
        taxpayerName: false,
        taxpayerId: false,
        websiteLink: false,
      },
      countries: [],
      allVendorTypes: [],
      minCost: "",
      monFriStartTime: "",
      monFriEndTime: "",
      satStartTime: "",
      satEndTime: "",
      sunStartTime: "",
      sunEndTime: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.getCountries();
    this.getVendorTypes();
    this.getUserDetails();
  }

  getVendorTypes = () => {
    const header = {
      token: localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getVendorTypesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getVendorTypesAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getCountries = () => {
    const header = {
      token: localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getContriesListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCountriesAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getCountriesApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleVendoritemClick = (vendorKey: string) => {
    this.setState({ activeVendorItem: vendorKey }, () => {
      this.handleWorkOrderMenu();
    });
  };

  handleVendorDrawer = () => {
      this.setState({
          openVendorDrawer: !this.state.openVendorDrawer
      });
  };

  handleWorkOrderMenu = () => {
    const { activeVendorItem } = this.state;
    const toMsgWork = new Message(getName(MessageEnum.NavigationMessage));
    toMsgWork.addData(
      getName(MessageEnum.NavigationTargetMessage),
      activeVendorItem
    );
    toMsgWork.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(toMsgWork);
  };

  // Method to move to the next step
  nextStep = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (this.validateForm(this.state.currentStep)) {
      window.scrollTo(0, 500);
      this.setState((prevState) => ({
        currentStep: prevState.currentStep + 1,
      }));
    }
  };

  // Method to move to the previous step (if needed)
  prevStep = () => {
    window.scrollTo(0, 500);
    this.setState((prevState) => ({
      currentStep: prevState.currentStep - 1,
    }));
  };

  handlePopupMessageClose = () => {
    this.setState({
      messageOpen: false,
    });
  };

  returnCurrentStep = () => {
    return this.state.currentStep;
  }

  handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    const phoneRegex = /^\d{0,10}$/;
    const accountNumberRegex = /^\d{0,25}$/;
    const taxpayerNameRegex = /^[a-zA-Z]*(?: [a-zA-Z]+)* ?$/;
    const taxpayerIdRegex = /^[0-9]*$/;

    if(name === "phone" && (!phoneRegex.test(value))) {
      return;
    }
    if(name === "venmoAccountNumber" && (!accountNumberRegex.test(value))) {
      return;
    }
    if(name === "zelleAccountNumber" && (!accountNumberRegex.test(value))) {
      return;
    }
    if(name === "taxpayerName" && (!taxpayerNameRegex.test(value))) {
      return;
    }
    if(name === "taxpayerId" && (!taxpayerIdRegex.test(value))) {
      return;
    }

    this.setState({
      [name]: value,
    } as unknown as Pick<S, keyof S>);
    if (name === "vendorName") {
      this.validateName(value);
    } else if (name === "phone") {
      this.validatePhone(value);
    } else if (name === "bankRoutingNumber") {
      this.validateBankRoutingNumber(value);
    } else if (name === "email") {
      this.validateEmail(value);
    } else if (name === "bankAccountNumber") {
      this.validateBankAccountNumber(value);
    } else if(name === "minCost") {
      this.validateMinCost(value);
    } else if(name === "monFriStartTime") {
      this.compareTimes(value, this.state.monFriEndTime, "monFri");
    } else if(name === "monFriEndTime") {
      this.compareTimes(this.state.monFriStartTime, value, "monFri");
    } else if(name === "satStartTime") {
      this.compareTimes(value, this.state.satEndTime, "sat");
    } else if(name === "satEndTime") {
      this.compareTimes(this.state.satStartTime, value, "sat");
    }  else if(name === "sunStartTime") {
      this.compareTimes(value, this.state.sunEndTime, "sun");
    } else if(name === "sunEndTime") {
      this.compareTimes(this.state.sunStartTime, value, "sun");
    }  else if(name === "taxpayerName") {
      this.validateTaxpayerName(value);
    }  else if(name === "taxpayerId") {
      this.validateTaxpayerID(value);
    }  else if(name === "websiteLink") {
      this.validateWebsiteLink(value);
    } else if (name === "companyName") {
      this.validateCompanyName(value);
    }
  };

  handleTradeInput = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = event.target;
    this.setState({
      trade: value,
    } as unknown as Pick<S, keyof S>);
  };

  handleAddTrade = () => {
    // Split the input trades string by commas, trim whitespace, and remove empty strings
    const newTrades = this.state.trade
      .split(",")
      .map((trade) => trade.trim())
      .filter((trade) => trade !== "");

    // Combine the new trades with the existing trades
    const combinedTrades = [...this.state.vendorTrade, ...newTrades];

    // Remove duplicate values
    const uniqueTrades = combinedTrades.filter(
      (item, index) => combinedTrades.indexOf(item) === index
    );

    this.setState({ vendorTrade: uniqueTrades, trade: "" }, () => {
      this.validateTrade();
    });
  };

  handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      this.handleAddTrade();
    }
  };

  handleDelete = (trade: string) => {
    const updatedTrade = this.state.vendorTrade.filter(
      (item) => item !== trade
    );
    this.setState({ vendorTrade: updatedTrade });
  };

  handleCountryCodeDropdown = () => {
    this.setState({ isCountryDropdownActive: true })
  }

  handleCountryChange = (event: SelectChangeEvent<string>) => {
    this.setState({ countryCode: event.target.value, isCountryDropdownActive: false });
  }

  handleFilesChange = (name: string, files: FileWithPreview[]) => {
    this.setState({
      [name]: files,
    } as unknown as Pick<S, keyof S>,
    () => {
      this.validateFiles(name);
    });
  };

  handleDateChange = (key: keyof S) => (newValue: Date | null) => {
    this.setState({ [key]: newValue } as unknown as Pick<S, keyof S>);
  };

  convertTo12HourFormat(time24: string) {
    // Split the input time string into hours and minutes
    let [hours, minutes] = time24.split(':');
    
    // Convert hours to a number
    let parsedHours = parseInt(hours, 10);
    
    // Determine AM/PM
    const period = parsedHours >= 12 ? 'PM' : 'AM';
    
    // Convert hours from 24-hour to 12-hour format
    parsedHours = parsedHours % 12 || 12;
    
    // Pad minutes with leading zero if necessary
    minutes = minutes.padStart(2, '0');
    
    // Return the formatted time string
    return `${parsedHours}:${minutes} ${period}`;
  }

  compareTimes = (start: string, end: string, slot: string) => {
    if(slot === "monFri" && start !== "" && end !== "") {
      const startTime = new Date(`1970-01-01T${start}:00`);
      const endTime = new Date(`1970-01-01T${end}:00`);

      if (endTime <= startTime) {
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            monFriTime: true,
          },
        }));
      } else {
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            monFriTime: false,
          },
        }));
      }

    } else if (slot === "sat" && start !== "" && end !== "") {
      const startTime = new Date(`1970-01-01T${start}:00`);
      const endTime = new Date(`1970-01-01T${end}:00`);

      if (endTime <= startTime) {
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            satTime: true,
          },
        }));
      } else {
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            satTime: false,
          },
        }));
      }
    } else if (slot === "sun" && start !== "" && end !== "") {
      const startTime = new Date(`1970-01-01T${start}:00`);
      const endTime = new Date(`1970-01-01T${end}:00`);

      if (endTime <= startTime) {
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            sunTime: true,
          },
        }));
      } else {
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            sunTime: false,
          },
        }));
      }
    }
  };

  validateCompanyName = (name: string) => {
    const regex = /^[a-zA-Z]+(?: [a-zA-Z]+)* ?$/;
    if (!regex.test(name)) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          companyName: true,
        },
      }));
    } else {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          companyName: false,
        },
      }));
    }
  }

  validateName = (name: string) => {
    const regex = /^[a-zA-Z]+(?: [a-zA-Z]+)* ?$/;
    if (!regex.test(name)) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          vendorName: true,
        },
      }));
    } else {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          vendorName: false,
        },
      }));
    }
  }

  validateMinCost = (cost: string) => {
    const costRegex = /^\d+$/;
    if (!costRegex.test(cost)) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          minCost: true,
        },
      }));
    } else {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          minCost: false,
        },
      }));
    }
  }

  validatePhone = (phone: string) => {
    const phoneRegex = /^\d{10}$/;
    if (!phoneRegex.test(phone)) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          phone: true,
        },
      }));
    } else {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          phone: false,
        },
      }));
    }
  }

  validateBankRoutingNumber = (number: string) => {
    const routingRegex = /^[a-zA-Z0-9]{9,34}$/;
    if (!routingRegex.test(number)) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          bankRoutingNumber: true,
        },
      }));
    } else {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          bankRoutingNumber: false,
        },
      }));
    }
  }

  validateTrade = () => {
    if (this.state.vendorTrade.length === 0) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          vendorTrade: true,
        },
      }));
    } else {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          vendorTrade: false,
        },
      }));
    }
  }

  validateEmail = (email: string) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(email)) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          email: true,
        },
      }));
    } else {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          email: false,
        },
      }));
    }
  };

  validateBankAccountNumber = (number: string) => {
    const accountRegex = /^[a-zA-Z0-9]{9,34}$/;
    if (!accountRegex.test(number)) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          bankAccountNumber: true,
        },
      }));
    } else {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          bankAccountNumber: false,
        },
      }));
    }
  }

  validateTaxpayerName = (name: string) => {
    const regex = /^[a-zA-Z]+(?: [a-zA-Z]+)* ?$/;
    if (!regex.test(name)) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          taxpayerName: true,
        },
      }));
    } else {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          taxpayerName: false,
        },
      }));
    }
  };

  validateTaxpayerID = (id: string) => {
    const regex = /^[a-zA-Z0-9]+$/;
    if (!regex.test(id)) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          taxpayerId: true,
        },
      }));
    } else {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          taxpayerId: false,
        },
      }));
    }
  };

  validateWebsiteLink = (link: string) => {
    const websiteRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d{1,5})?(\/\S*)?$/;
    if (!websiteRegex.test(link)) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          websiteLink: true,
        },
      }));
    } else {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          websiteLink: false,
        },
      }));
    }
  }

  validateFiles = (name: string) => {
    if(name === "formW9Files") {
      if (this.state.formW9Files.length === 0) {
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            formW9Files: true,
          },
        }));
      } else {
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            formW9Files: false,
          },
        }));
      }
    }
    else if(name === "businessLicense") {
      if (this.state.businessLicense.length === 0) {
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            businessLicense: true,
          },
        }));
      } else {
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            businessLicense: false,
          },
        }));
      }
    }
    else if(name === "businessInsurance") {
      if (this.state.businessInsurance.length === 0) {
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            businessInsurance: true,
          },
        }));
      } else {
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            businessInsurance: false,
          },
        }));
      }
    } 
  }

  validateForm = (step: number) => {
    switch (step) {
      case 0:
        this.validateFiles("formW9Files");
        this.validateTrade();
        if (
          this.state.vendorTrade.length == 0 ||
          this.state.formW9Files.length == 0 ||
          this.state.errors.companyName ||
          this.state.errors.vendorName ||
          this.state.errors.phone ||
          this.state.errors.email ||
          this.state.errors.minCost ||
          this.state.errors.monFriTime ||
          this.state.errors.satTime ||
          this.state.errors.sunTime ||
          this.state.errors.taxpayerName ||
          this.state.errors.taxpayerId
        ) {
          return false;
        }
        return true
      case 1:
        if (this.state.errors.bankRoutingNumber || this.state.errors.websiteLink) {
          return false;
        }
        return true
      case 2:
        this.validateFiles("businessLicense");
        this.validateFiles("businessInsurance");
        if (
          this.state.businessLicense.length == 0 ||
          this.state.businessInsurance.length == 0
        ) {
          return false;
        }
        return true;
      }
  }

  getHelperText = (field: string) => {
    const { errors } = this.state;

    if(field === "vendorName" && errors.vendorName) {
        return "Please enter a valid name using letters and spaces only.";
    }
    if(field === "phone" && errors.phone) {
      return "Please enter a valid phone number.";
    }
    if(field === "bankRoutingNumber" && errors.bankRoutingNumber) {
      return "The input must be between 9 and 34 characters long and contain only letters and numbers.";
    }
    if(field === "email") {
      if(errors.email){
        return "Please enter a valid email address.";
      } else {
        return "example@example.com";
      }
    }
    if(field === "bankAccountNumber" && errors.bankAccountNumber) {
      return "The input must be between 9 and 34 characters long and contain only letters and numbers.";
    }
    if(field === "minCost" && errors.minCost) {
      return "Please enter a valid minimum cost.";
    }
    if(field === "taxpayerName" && errors.taxpayerName) {
      return "Please enter a valid taxpayer name using letters and spaces only.";
    }
    if(field === "taxpayerId" && errors.taxpayerId) {
      return "Please enter a valid taxpayer Id.";
    }
    if(field === "websiteLink" && errors.websiteLink) {
      return "Please enter a valid website link.";
    }
    if(field === "companyName" && errors.companyName) {
      return "Please enter a valid company name using letters and spaces only.";
  }
    return null;
  };

  getWorkingHours = (slot: string) => {
    if(slot === "mon-fri" && this.state.monFriStartTime !== "" && this.state.monFriEndTime !== ""){
      return `${this.state.monFriStartTime} - ${this.state.monFriEndTime}`
    }
    else if(slot === "sat" && this.state.satStartTime !== "" && this.state.satEndTime !== ""){
      return `${this.state.satStartTime} - ${this.state.satEndTime}`
    }
    else if(slot === "sun" && this.state.sunStartTime !== "" && this.state.sunEndTime !== ""){
      return `${this.state.sunStartTime} - ${this.state.sunEndTime}`
    }
    return ""
  }

  getUserDetails = () => {
    let userName = localStorage.getItem("username");
    let userEmail = localStorage.getItem("userEmail");
      this.setState({
        vendorName: userName ? userName : "-",
        email: userEmail ? userEmail : "-"
      });
  };

  validateField = (field: string) => {
    switch (field) {
      case "vendorTrade":
        return this.state.errors.vendorTrade;
      case "formW9Files":
        return this.state.errors.formW9Files;
      case "businessLicense":
        return this.state.errors.businessLicense;
      case "businessInsurance":
        return this.state.errors.businessInsurance;
      case "monFri":
        return this.state.errors.monFriTime;
      case "sat":
        return this.state.errors.satTime;
      case "sun":
        return this.state.errors.sunTime;
      default:
        return false;
    }
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getVendorTypesApiCallId) {
        this.setState({ allVendorTypes: responseJson?.vendor_types});
      } 

      if (apiRequestCallId === this.getContriesListApiCallId) {
        let countries = responseJson?.data?.map((item: any) => {
          return {
            name: item.attributes.name,
            emoji_flag: item.attributes.emoji_flag,
            country_code: item.attributes.country_code,
          };
        });

        this.setState({ countries }, () => {});
      } 
      else if (
        apiRequestCallId === this.addVendorDetailsApiId
      ) {
        if (
          responseJson &&
          !responseJson.errors &&
          responseJson.data &&
          responseJson.data.attributes
        ) {
          //On Vendor Onboarding Successfully

          this.setState({
            messageOpen: true,
            messageType: "success",
            message: "Form submitted successfully."
          });
          this.setState({currentStep: 3});
          const toSendMessage = new Message(getName(MessageEnum.NavigationMessage));
          toSendMessage.addData(
            getName(MessageEnum.NavigationTargetMessage),
            "UserProfileBasicBlock"
          );
          toSendMessage.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
          );
          setTimeout(() => {
            this.send(toSendMessage);
          }, 2000);
        } else {
          //Check Error Response
          if (
            responseJson.errors &&
            responseJson.errors.length > 0 &&
            responseJson.errors[0].token
          ) {
            this.setState({
              messageOpen: true,
              messageType: "error",
              message: "Session Expired, Please Log in again."
            });
          } else {
            this.setState({
              messageOpen: true,
              messageType: "error",
              message: "An error occurred while processing your request. Please try again later."
            });
          }
        }
      }
    }
  }

  handleFileFormSubmit = (formData: FormData) => {
    if(this.state.voidedCheckFiles.length) {
      this.state.voidedCheckFiles.forEach((file) => {
        formData.append("vendor_account[voided_checks][]", file as Blob);
      });
    }
    if(this.state.businessLicense.length) {
      this.state.businessLicense.forEach((file) => {
        formData.append("vendor_account[business_licenses][]", file as Blob);
      });
      // formData.append("vendor_account[business_license]", this.state.businessLicense[0] as Blob);
    }
    if (this.state.businessInsurance.length) {
      this.state.businessInsurance.forEach((file) => {
        formData.append("vendor_account[business_insurances][]", file as Blob);
      });
    }
    if(this.state.EpaCertificationLicense.length) {
      this.state.EpaCertificationLicense.forEach((file) => {
        formData.append("vendor_account[epa_certification_licenses][]", file as Blob);
      });
    }
    if(this.state.otherCertificate.length) {
      this.state.otherCertificate.forEach((file) => {
        formData.append("vendor_account[epa_other_certificates][]", file as Blob);
      });
    }
    if(this.state.stateLicense.length) {
      this.state.stateLicense.forEach((file) => {
        formData.append("vendor_account[state_licenses][]", file as Blob);
      });
    }
    if(this.state.stateInsurance.length) {
      this.state.stateInsurance.forEach((file) => {
        formData.append("vendor_account[state_insurances][]", file as Blob);
      });
    }
  };

  handleDateFormSubmit = (formData: FormData) => {
    formData.append("vendor_account[business_license_exp_date]", this.state.licenseExpDate ? this.state.licenseExpDate.toISOString().split("T")[0] : "");
    formData.append("vendor_account[business_insurance_exp_date]", this.state.insuranceExpDate ? this.state.insuranceExpDate.toISOString().split("T")[0] : "");
    formData.append("vendor_account[epa_license_exp_date]", this.state.EpaCertificationLicenseExpDate ? this.state.EpaCertificationLicenseExpDate.toISOString().split("T")[0] : "");
    formData.append("vendor_account[other_certificate_exp_date]", this.state.otherCertificateExpDate ? this.state.otherCertificateExpDate.toISOString().split("T")[0] : "");
    formData.append("vendor_account[state_license_exp_date]", this.state.stateLicenseExpDate ? this.state.stateLicenseExpDate.toISOString().split("T")[0] : "");
    formData.append("vendor_account[state_insurance_exp_date]", this.state.stateInsuranceExpDate ? this.state.stateInsuranceExpDate.toISOString().split("T")[0] : "");
  }

  handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (this.validateForm(this.state.currentStep)) {
      const header = {
        token: localStorage.getItem("authToken"),
      };
  
      const formData = new FormData();

      formData.append(
        "vendor_account[company_name]",
        this.state.companyName
      );
      formData.append(
        "vendor_account[name]",
        this.state.vendorName
      );
      formData.append(
        "vendor_account[vendor_type]",
        this.state.vendorType
      );
      formData.append(
        "vendor_account[minimum_cost]",
        String(this.state.minCost)
      );
      formData.append(
        "vendor_account[mon_fri_working_hours]",
        this.getWorkingHours("mon-fri")
      );
      formData.append(
        "vendor_account[sat_working_hours]",
        this.getWorkingHours("sat")
      );
      formData.append(
        "vendor_account[sun_working_hours]",
        this.getWorkingHours("sun")
      );
      this.state.vendorTrade.forEach((trade) => {
        formData.append("vendor_account[vendor_trade][]", trade);
      });
      formData.append("vendor_account[country_code]", this.state.countryCode);
      formData.append("vendor_account[phone_number]", this.state.phone);
      formData.append("vendor_account[email_address]", this.state.email);
      formData.append("vendor_account[address]", this.state.address);
      formData.append("vendor_account[taxpayer_name]", this.state.taxpayerName);
      formData.append("vendor_account[taxpayer_id]", String(this.state.taxpayerId));
      formData.append("vendor_account[send_1099]", this.state.send1099 === "yes" ? '1' : '0' );
      this.state.formW9Files.forEach((file) => {
        formData.append("vendor_account[form_w9][]", file as Blob);
      });

      formData.append("vendor_account[online_payables]", this.state.onlinePayables === "yes" ? '1' : '0' );
      formData.append("vendor_account[vendor_bank_routing_number]", String(this.state.bankRoutingNumber));
      formData.append("vendor_account[vendor_bank_account_number]", String(this.state.bankAccountNumber));
      formData.append("vendor_account[payment_type]", this.state.paymentType);
      formData.append("vendor_account[upload_website_link]", this.state.websiteLink);
      formData.append("vendor_account[venmo_account_number]", this.state.venmoAccountNumber);
      formData.append("vendor_account[zelle_account_number]", String(this.state.zelleAccountNumber));
      formData.append("vendor_account[comments]", this.state.comment);

      this.handleFileFormSubmit(formData);
      this.handleDateFormSubmit(formData);
      formData.append("vendor_account[notes]", this.state.notes);
      
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.addVendorDetailsApiId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.addVendorDetailsApiEndPoint
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.addVendorDetailsApiMethod
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  }

}
