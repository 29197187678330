import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities"
export interface APIPayloadType {
  contentType: string;
  method: string;
  endPoint: string;
  token?: string;
}

export interface PropertiesDetails {
  property_id: string;
  type: string;
    property_type: string;
    property_name: string;
}

export interface Properties {
  data: Array<PropertiesDetails>;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  info: any;
  data: any;
  token: any;
  googleChartData: any;
  sortColumn: string;
  sortDirection: "asc" | "desc";
  selected: number[];
  selectedProperty: any;
  selectedProperties: any,
  selectedUnit: string | null;
  expanded: number | null;
  anchorElement: { [key: number]: HTMLElement | null };
  subMenuAnchorEl: null | HTMLElement;
  anchorEl: null | HTMLElement;
  showAllItems: boolean;
  selectedMenuItemIndex: null | number;
  properties: PropertiesDetails[];
  selectedOptions: { title: string }[];
  selectedUnits: any,
  isOpenDialog: boolean,
  isOpenDialog2: boolean,
  sortState: {
    [key: number]: {
      sortColumn: string;
      sortDirection: "asc" | "desc";
    };
  },
  selectedTanentId: number | null,
  comment: string | null,
  selectedComments: any,
  allComments: any,
  selectedCommentProperty: string,
  selectedCommentUnit: string,
  activeMainItem:string,
  openLandlordDrawer: boolean,
  showAllComments: boolean,
  email: any,
  searchQuery: any,
  selectedPropertyIds: any,
  selectedUnitIds: any,
  propertyForDropDown: any,
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class VisualAnalyticsController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiGetDataCallId: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: null,
      info: {
        labels: [],
        data: [],
        barColors: [],
      },
      data: {
        weekly: {
          labels: ["week1", "week2", "week3", "week4", "week5"],
          data: [[5], [9], [3], [6], [2]],
          barColors: ["#7db6b0"],
        },
        monthly: {
          labels: [
            "Jun",
            "Fab",
            "Mar",
            "Apr",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nom",
            "Dec",
          ],
          data: [[9], [5], [6], [3], [2], [7], [1], [4], [2], [6], []],
          barColors: ["#7db6b0"],
        },
      },
      googleChartData: ["Title", "Value"],
      sortColumn: "id",
      sortDirection: "asc",
      selected: [],
      selectedProperty: null,
      selectedProperties: [],
      selectedUnit: null,
      expanded: null,
      anchorElement: {},
      subMenuAnchorEl: null,
      anchorEl: null,
      showAllItems: false,
      selectedMenuItemIndex: null,
      properties: [],
      selectedOptions:[],
      selectedUnits: [],
      isOpenDialog: false,
      isOpenDialog2: false,
      sortState: {},
      selectedTanentId: null,
      comment: null,
      selectedComments: [],
      allComments: [],
      selectedCommentProperty: "",
      selectedCommentUnit: "",
      activeMainItem: "VisualAnalytics",
      openLandlordDrawer:false,
      showAllComments: false,
      email: "",
      searchQuery: "",
      selectedPropertyIds: [],
      selectedUnitIds: [],
      propertyForDropDown: [],
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start 
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson) {
        this.apiSuccessCallBacks(apiRequestCallId, responseJson);
      }
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiGetCommentCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson && !responseJson.errors && responseJson.data) {
        this.setAllComments(responseJson.data);
      }
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiGetpropertyForDropDownCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson && !responseJson.errors && responseJson.data) {
        this.setPropertyForDropDown(responseJson.data);
      }
    }
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    const response = await getStorageData("authToken")
    if (response) {
      this.setState({ token: response }, () => {
      })
    }
    this.getPropertiesDetails();
    this.getpropertyForDropDown();
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  // Customizable Area Start
  apiPostCommentCallId: any;
  apiGetCommentCallId: any;
  apiGetpropertyForDropDownCallId: any;
  getpropertyForDropDown = () => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetpropertyForDropDownCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.propertiesApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  setPropertyForDropDown = (value: any) => {
    this.setState({propertyForDropDown: value})
  }
  handleMainItemClick = (labelKey: string) => {
    this.setState({ activeMainItem: labelKey }, () => {
      this.handleWorkOrderMenu();
    });
  };
  handleWorkOrderMenu = () => {
    const { activeMainItem } = this.state;
    const toMsgWork = new Message(getName(MessageEnum.NavigationMessage));
    toMsgWork.addData(
      getName(MessageEnum.NavigationTargetMessage),
      activeMainItem
    );
    toMsgWork.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(toMsgWork);
  };
  handleLandlordDrawer = () => {
    this.setState({
      openLandlordDrawer: !this.state.openLandlordDrawer,
    });
  };
  convertTime = (time: any) => {
    const date = new Date(time);
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', options).replace(',', '');
    return formattedDate;
  }
  handleCommentChange = (value: string) => {
    this.setState({
        comment: value,
      })
  }
  handleClickOpendialog = (id: number,comment: any,property: any,unitName: any) => {
    this.handleGetComments(id);
    this.setState(
      {
        isOpenDialog:true,
        selectedTanentId: id,
        selectedComments: comment,
        selectedCommentProperty: property.property_name,
        selectedCommentUnit: unitName,
      })
  }
  handleClickOpendialog2 = (email: any) => {
    this.setState(
      {
        isOpenDialog2:true,
        email: email,
      })
  }
  handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchQuery = event.target.value;
    this.getPropertiesDetails(searchQuery, this.state.selectedPropertyIds, this.state.selectedUnitIds);
  };
  
  formatPropertyName = (name: any) => {
    if (!name) return '';
    const truncatedName = name.length > 13 ? name.slice(0, 13) + '...' : name;
    return truncatedName.toUpperCase();
  };
  

  handleGetComments = async (id: number) => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetCommentCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.commentGetAPiEndPoint}?tenant_id=${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleCloseDialog = () =>{
    this.setState({isOpenDialog:false})
  }
  handleCloseDialog2 = () =>{
    this.setState({isOpenDialog2:false})
  }
  handleClosePopup = () =>{
    this.setState({isOpenDialog:false, showAllComments: false})
  }
  handleClosePopup2 = () =>{
    this.setState({isOpenDialog2:false})
  }
  setAllComments = (value: any) => {
    this.setState({allComments: value})
  }

  handleCommentSubmit(id: number | null) {
    
    if (this.state.comment === null || this.state.comment === "") {
      return;
    }
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.state.token
    };
    const body = {
      comment: 
      {
        comment: this.state.comment,
        tenant_id: id,
      }
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPostCommentCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.commentAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.handleCloseDialog();
    this.getPropertiesDetails();
    this.setState({
      comment: null,
    })
  }
  handleSortRequest = (property: string, direction: "asc" | "desc", unitIndex: number) => {
    this.setState(prevState => ({
      sortState: {
        ...prevState.sortState,
        [unitIndex]: {
          sortColumn: property,
          sortDirection: direction
        }
      }
    }));
  };

  handleEmptyContainer = (tenant: any) => {
    return tenant.length ?? [] ? "isTanentDisabled" : "";
  };

  goToProperties = () => {
    const navigationMessage = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    navigationMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "PortfolioManagement"
    );
    navigationMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    runEngine.sendMessage("MergeEngineUtilities", navigationMessage);
  }

  toggleShowAll = () => {
    this.setState(prevState => ({
      showAllItems: !prevState.showAllItems
    }));
  };

  handleUnitClick = (propertyId: string, unit: any) => {
    const { selectedUnits } = this.state;

  
  const unitExists = selectedUnits.some(
    (selectedUnit: any) => 
      selectedUnit.propertyId === propertyId && 
      selectedUnit.unitData.unit_id === unit.unit_id
  );

  const updatedSelectedUnits = unitExists
    ? selectedUnits.filter(
        (selectedUnit: any) => 
          selectedUnit.propertyId !== propertyId || 
          selectedUnit.unitData.unit_id !== unit.unit_id
      )
    : [
        ...selectedUnits,
        {
          propertyId,
          unitName: unit.name,
          unitData: unit,
        },
      ];

    this.setState(
      { selectedUnits: updatedSelectedUnits, selectedUnitIds: updatedSelectedUnits.map((unit:any) => unit.unitData.unit_id)},
      () => {
        this.getPropertiesDetails(
          this.state.searchQuery, 
          this.state.selectedPropertyIds, 
          updatedSelectedUnits.map((unit:any) => unit.unitData.unit_id) 
        );
      }
    );
    this.handleCloseMenu();
    this.handleClose();
  };

  handleToggleComments = () => {
    this.setState((prevState) => ({
      showAllComments: !prevState.showAllComments,
    }));
  };

  handleCloseMenu = () => {
    this.setState({ subMenuAnchorEl: null });
  };

  handleMenuIconClick = (eventIcon: React.MouseEvent<HTMLElement>, rowId: number) => {
    this.setState({
      anchorElement: {
        ...this.state.anchorElement,
        [rowId]: eventIcon.currentTarget
      },
    });
  };

  handleMenuClose = (rowId: number) => {
    this.setState({
      anchorElement: {
        ...this.state.anchorElement,
        [rowId]: null
      },
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
      subMenuAnchorEl: null,
      selectedProperty: null,
      selectedUnit: null
    });
  };

  clearFilters = () => {
    this.setState({
      selectedProperties: [],
      selectedUnits: [],
      searchQuery: "",
      selectedPropertyIds: [],
      selectedUnitIds: [],
    });
    this.getPropertiesDetails();
  };

  handleDelete = (property: any, unit: any) => {
    const updatedSelectedUnits = this.state.selectedUnits.filter(
      (selectedUnit: any) => selectedUnit.unitData.unit_id !== unit.unitData.unit_id
    );
  
    const updatedSelectedProperties = this.state.selectedProperties.reduce(
      (acc: any[], selectedProperty: any) => {
        if (selectedProperty.property_id === property.property_id) {
          const updatedUnits = selectedProperty.units.filter(
            (propertyUnit: any) => updatedSelectedUnits.some(
              (selectedUnit: any) => selectedUnit.unitData.unit_id === propertyUnit.unit_id
            )
          );
  
          if (updatedUnits.length > 0) {
            acc.push({
              ...selectedProperty,
              units: updatedUnits,
            });
          }
        } else {
          acc.push(selectedProperty);
        }
        return acc;
      },
      []
    );
  
    this.setState({
      selectedUnits: updatedSelectedUnits,
      selectedProperties: updatedSelectedProperties,
    }, () => {
      this.getPropertiesDetails(
        this.state.searchQuery, 
        updatedSelectedProperties.map((property: any) => property.property_id), 
        updatedSelectedUnits.map((unit: any) => unit.unitData.unit_id) 
      );
    });
  };
  
  handlePropertyClick = (propEvent: React.MouseEvent<HTMLElement>, property: PropertiesDetails, indexItem: number) => {
    this.setState({
      selectedProperty: property,
      subMenuAnchorEl: propEvent.currentTarget,
      selectedMenuItemIndex: indexItem
    });
    let selectedProperties = this.state.selectedProperties || [];
    const isSelected = selectedProperties.some(
      (p: any) => p.property_id === property.property_id
    );
  
    if (!isSelected) {
      selectedProperties = [...selectedProperties, property];
    }

    this.setState({ selectedProperties , selectedPropertyIds: selectedProperties.map((p:any) => p.property_id)}, () => {
      
      this.getPropertiesDetails(
        this.state.searchQuery, 
        selectedProperties.map((p:any) => p.property_id), 
        this.state.selectedUnitIds 
      );
    });
  };

  handlePropertySlice = () =>{
    return !this.state.showAllItems && this.state.propertyForDropDown.length > 4;
  };

  handlePropertyData = () =>{
    return this.state.propertyForDropDown.slice(0, this.state.showAllItems ? this.state.propertyForDropDown.length : 4);
  };

  handleProperClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  delinquencyReportApiCall = async (data: APIPayloadType) => {
    let { contentType, method, endPoint } = data;
    const header = {
      "Content-Type": contentType,
      token: this.state.token
    };

    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  getPropertiesDetails = async (
    searchQuery: string = "",
    propertyIds: string[] = [],
    unitIds: string[] = []) => {
    const propertyIdString = propertyIds.length ? `&property_id=${propertyIds.join(",")}` : "";
    const unitIdString = unitIds.length ? `&unit_id=${unitIds.join(",")}` : "";
      
    const endPoint = `${configJSON.propertiesApiEndPoint}?search=${searchQuery}${propertyIdString}${unitIdString}`;
    
    this.apiGetDataCallId = await this.delinquencyReportApiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.getApiMethodType,
      endPoint: endPoint
    });
  };

  apiSuccessCallBacks = (apiRequestCallID: string, responseJson: Properties) => {

    if (apiRequestCallID === this.apiGetDataCallId) {
      this.setState({
        properties: responseJson.data
      });
    }
  };
  
  // Customizable Area End
}
