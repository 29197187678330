Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "DownloadOptions";
exports.labelBodyText = "DownloadOptions Body";

exports.btnExampleTitle = "CLICK ME";
exports.downloadOptionUrl = "download_options";

exports.dashboardLandloard = "Dashboard";
exports.tenantDashboard = "TenantDashboard";
exports.lease = "Lease"
exports.leaselabel = "DownloadOptions";
exports.paymentHistory = "Payment History";
exports.paymentHistorylabel = "BaselineReporting";
exports.chatLandloard = "Chat with Keasy";
exports.chatlandlabel = "Chat";
exports.chatTenantabel = "Chat";
exports.serviceRequestName = "Service Requests";
exports.servicePath = "ServicerequestDashboard";
exports.servicelable = "ServicerequestDashboard";
exports.getLeaseDataApiEndPoint = 'bx_block_downloadoptions/download_options/tenant_lease';
exports.validationApiMethodType = "GET";
exports.getDownloadPdfApiEndPoint = 'account_block/tenants/generate_pdf';
// Customizable Area End
