import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config.js");
// Customizable Area Start

import { Message } from "../../../framework/src/Message";
const navigation = require("react-navigation");
import { ChangeEvent } from 'react';
import { getStorageData, setStorageData } from "framework/src/Utilities";
import storage from "../../../framework/src/StorageProvider";
interface FormErrors {
  [key: string]: string;
}
interface ValidationRule {
  field: string | undefined | Date | null | FileWithPreview[] | boolean;
  errorField: keyof FormErrors;
  errorMessage: string;
}
interface FormErrors2 {
  [key: string]: string;
}

export interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  token?: string;
  body?: object;
  type?: string;
}

export interface Countries {
  data: Array<AreasCode>;
}

export interface AreasCode {
  type: string;
  id: string;
  attributes: {
    emoji_flag: string;
    name: string;
    country_code: string;
  }
}

interface ValidationRule2 {
  field: string | undefined | FileWithPreview[];
  errorField: keyof FormErrors;
  errorMessage: string;
}
interface FormErrors3 {
  [key: string]: string;
}
interface ValidationRule3 {
  field: string | undefined | Date | null;
  errorField: keyof FormErrors;
  errorMessage: string;
}

interface FileWithPreview extends File {
  preview: string;
}

interface INavigateTo {
  id?: number;
  props: unknown;
  screenName: string;
  raiseMessage?: Message;
}
// Customizable Area End

export interface Props {
  navigation: typeof navigation;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start

  activeStep: number;


  activeMainItems: string;
  openLandlordDrawers: boolean;
  amenTiesData: boolean;
  newPropertyhandleres?: Array<Function>;


  formErrors: {
    amenitiesError?: string;
    propertyNameError?: string;
    propertyAddressError?: string
    propertyCountryError?: string
    propertyOtherPetsAllowedError?: string
    gender1Error?: string;
    dogAllowStateError?: string;
    descriptionsError?: string;
    lastnameError?: string;
    firstnameError?: string;
    dateError?: string;
    managementStartError?: string;
    flioGuardError?: string;


    propertyTypeError?: string;
    propertyPropertyError?: string;
    classStateError?: string;
    renewalStateError?: string;
    marketStateError?: string;
    siteManagerError?: string;
    portfolioError?: string;
    listingTypeError?: string
    areacodeError?: string;
    phonenumberError?: string;

    maximumError?: string
    codeStateError?: string
    entranceStateError?: string;
    floorStateError?: string;
    insuranceStateError?: string;
    whichoneStateError?: string
    mainStateError?: string;
    onlineStateError?: string
    locationStateError?: string;
    descriptionStateError?: string;
    petOtherStateError?: string;
    rentalStateError?: string;
    coverWorError?: string;
    unitEntryError?: string;
    alermEntryError?: string;
    loundryError?: string;
    marketplanError?: string;
    showplanError?: string;
    ongoingError?: string;
    renewalDateError?: string;
    formFilesInsuranceError?: string;
    emailStateError?: string
    photoStateError?: string;
    checkError?: string;
    attachmentStateError?: string



  };

  formData: {

    anmitiesdata: string;
    propertyName: string;
    propertyAddress: string;
    propertyCountry: string;
    propertyOtherpetsAllowed: string;
    dogAllowState: string;
    gender1: string;
    descriPtions: string
    lastName: string;
    firstName: string;
    dateYearbuilt: string;

    manageMentStart: string;
    flioGuard: string;
    selectDataState: string
    propertyTypeState: string;
    classState: string;
    renewalState: string;
    marketState: string;
    sitemanagerState: string;
    portfolioState: string;
    propertyListingState: string

    phonenumberState: string;
    areacodeState: string;

    maximumState: string;
    insuranceState: string;
    whichoneState: string;
    mainState: string;
    onlineState: string;
    descriptionState: string;
    petOtherState: string;
    rentalState: string;
    coverWor: string;
    unitEntry: string;
    alermEntry: string;
    loundryState: string;
    marketplan: string;
    showplan: string;
    ongoingState: string;
    emailState: string;


  }
  vendorTrade: string[];
  anmitiesData: string[];
  formFilesInsurance: FileWithPreview[];
  renewalDate: Date | null;
  manageStartDate: Date | null;
  photoFile: FileWithPreview[];
  insuranceDate: Date | null;
  attachmentFile: FileWithPreview[];
  whichOneData: string[];
  renewalDateData: Date | null;
  sharewithowners: string[];
  sharewithtenants: string;
  attachmentFileCheckboxes: [];
  checkboxStates: { [key: number]: { sharewithtenants: boolean; sharewithowners: boolean } };
  sucessMsg: string;
  isCountriesDropdown: boolean;
  areaCodesState: string;
  countriesCode: Array<{ name: string; emoji_flag: string; country_code: string }>;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}
export default class PortfolioNewpropertymanagementController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apicallNewpropertyID: string = "";
  getAllVendors: any
  updateVendorInfo: any
  getVendorType: any
  updateVendorAttach: any
  getallAttachments: any
  formData: any;
  getAreaCodesApiCallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      activeStep: 0,
      newPropertyhandleres: [],
      activeMainItems: "landlordproperties",
      openLandlordDrawers: false,
      amenTiesData: false,
      formErrors: {

        coverWorError: "",
        alermEntryError: "",
        amenitiesError: "",
        propertyNameError: "",
        propertyAddressError: "",
        propertyOtherPetsAllowedError: "",
        dogAllowStateError: "",
        gender1Error: "",
        descriptionsError: "",
        lastnameError: "",
        firstnameError: "",
        dateError: "",
        managementStartError: "",
        flioGuardError: "",
        propertyTypeError: "",
        propertyPropertyError: "",
        classStateError: "",
        renewalStateError: "",
        marketStateError: "",
        siteManagerError: '',
        portfolioError: "",
        listingTypeError: "",
        areacodeError: "",
        phonenumberError: "",

        maximumError: "",
        codeStateError: "",
        entranceStateError: "",
        floorStateError: "",
        insuranceStateError: "",
        whichoneStateError: "",
        mainStateError: "",
        onlineStateError: "",
        locationStateError: "",
        descriptionStateError: "",
        rentalStateError: "",
        unitEntryError: "",
        loundryError: "",
        marketplanError: "",
        showplanError: "",
        ongoingError: "",
        formFilesInsuranceError: "",
        emailStateError: ""

      },
      formData: {
        alermEntry: "",
        unitEntry: "",
        coverWor: "",
        anmitiesdata: "",
        propertyName: "",
        propertyAddress: "",
        propertyCountry: "",
        propertyOtherpetsAllowed: "",
        dogAllowState: "",
        gender1: "",
        descriPtions: "",
        lastName: "",
        firstName: "",
        dateYearbuilt: "",
        manageMentStart: '',
        flioGuard: '',
        selectDataState: "",
        propertyTypeState: "",
        classState: "",
        renewalState: "",
        marketState: "",
        sitemanagerState: "",
        portfolioState: "",
        propertyListingState: "",
        phonenumberState: "",
        areacodeState: "",
        petOtherState: "",
        maximumState: "",
        insuranceState: "",
        whichoneState: "",
        mainState: "",
        onlineState: "",
        descriptionState: '',
        rentalState: '',
        loundryState: '',
        marketplan: "",
        showplan: "",
        ongoingState: "",
        emailState: "",



      },
      vendorTrade: [],
      anmitiesData: [],
      formFilesInsurance: [],
      renewalDate: null,
      manageStartDate: null,
      photoFile: [],
      insuranceDate: null,
      attachmentFile: [],
      whichOneData: [],
      renewalDateData: null,
      sharewithowners: [],
      sharewithtenants: "",
      attachmentFileCheckboxes: [],
      checkboxStates:{},
      sucessMsg: "",
      isCountriesDropdown: false,
      countriesCode: [],
      areaCodesState: ""
      // Customizable Area End
    };
  }
  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
        this.getAreaCodeForProperty();
    // Customizable Area End
  }

  // Customizable Area Start


  async receive(from: string, message: Message) {

    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
      let responseJson: any = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorResponse: any = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.apicallNewpropertyID) {
        this.newpropertyResponse(responseJson, errorResponse);
      }
      if (!responseJson.errors && responseJson) {
        this.apiSuccessCall(apiRequestCallId, responseJson);
      }


    }
    // Customizable Area End
  }


  doNewpropertyApi = async () => {

    const token = await getStorageData('authToken');

    const headers = {

      "token": token
    };

    const formDatas = new FormData();
    this.state.anmitiesData.forEach((amenity) => {
      formDatas.append('property[amenities][]', amenity);
    });
    formDatas.append('property[property_name]', this.state.formData.propertyName);
    formDatas.append('property[address]', this.state.formData.propertyAddress);
    formDatas.append('property[country]', this.state.formData.propertyCountry);
    formDatas.append('property[other_pets_allowed]', this.state.formData.petOtherState);
    this.state.vendorTrade.forEach((formFile) => {
      formDatas.append('property[other_pets][]', formFile);
    })
    formDatas.append('property[cats_allowed]', this.state.formData.gender1);
    formDatas.append('property[dogs_allowed]', this.state.formData.dogAllowState);
    formDatas.append('property[description]', this.state.formData.descriPtions);
    formDatas.append('property[rental_license]', this.state.formData.rentalState);
    formDatas.append('property[site_manager_last_name]', this.state.formData.lastName);
    formDatas.append('property[site_manager_first_name]', this.state.formData.firstName);
    if(this.state.renewalDate){
      formDatas.append('property[year_built]', this.state.renewalDate.toISOString().split("T")[0]);
    }
    if(this.state.manageStartDate){
      formDatas.append('property[management_start]', this.state.manageStartDate.toISOString().split("T")[0]);
    }
    formDatas.append('property[folio_guard_policy]', this.state.formData.flioGuard);
    formDatas.append('selectDataState', this.state.formData.selectDataState);
    formDatas.append('property[property_type]', this.state.formData.propertyTypeState);
    formDatas.append('property[property_class]', this.state.formData.classState);
    if(this.state.renewalDateData){
      formDatas.append('property[renewal_date]', this.state.renewalDateData.toISOString().split("T")[0]);
    }
    formDatas.append('property[property_marketing_name]', this.state.formData.marketState);
    formDatas.append('property[site_manager_prefix]', this.state.formData.sitemanagerState);
    formDatas.append('property[portfolio]', this.state.formData.portfolioState);
    formDatas.append('property[lising_type]', this.state.formData.propertyListingState);
    formDatas.append('property[vacancy_posting_phone_number]', this.state.formData.phonenumberState);
    formDatas.append('areacodeState', this.state.areaCodesState);
    formDatas.append('property[dog_type]', this.state.formData.selectDataState);

    formDatas.append('property[maximum_approved_maintenance]', this.state.formData.maximumState);
    formDatas.append('property[vacancy_posting_area_code]', this.state.areaCodesState);
    if(this.state.insuranceDate){
    formDatas.append('property[insurance_expiration]', this.state.insuranceDate.toISOString().split("T")[0]);
    }
    formDatas.append('whichoneState', this.state.formData.whichoneState);
    formDatas.append('property[maintenance_notes]', this.state.formData.mainState);
    formDatas.append('property[maintenance_request]', this.state.formData.onlineState);
    formDatas.append('property[marketing_description]', this.state.formData.descriptionState);
    this.state.formFilesInsurance.forEach((formFile) => {
      formDatas.append('property[rental_license_files][]', formFile);
    });
    this.state.photoFile.forEach((formFile) => {
      formDatas.append('property[marketing_information_files][]', formFile);
    });
    formDatas.append('property[vacancy_posting_email_address]', this.state.formData.emailState);

    formDatas.append('property[warranty_coverage]', this.state.formData.coverWor);
    formDatas.append('property[unit_entry_pre_authorized]', this.state.formData.unitEntry);
    formDatas.append('property[alarm_system]', this.state.formData.alermEntry); 
    formDatas.append('property[laundry_room]', this.state.formData.loundryState); 
    formDatas.append('property[marketing_plan]', this.state.formData.marketplan);     
    formDatas.append('property[snow_plan]', this.state.formData.showplan);       
    formDatas.append('property[ongoing_maintenance]', this.state.formData.ongoingState);        
    this.state.whichOneData.forEach((formFile) => {
      formDatas.append('property[other_ongoing_maintenance][]', formFile);
    });
    this.state.attachmentFile.forEach((file, index) => {
      formDatas.append(`property[property_attachment_details_attributes][${index}][file]`, file);
    });
     
    Object.keys(this.state.checkboxStates).forEach((name,index) => {
      const checkboxState = this.state.checkboxStates[index];

      formDatas.append(
        `property[property_attachment_details_attributes][${index}][share_with_tenants]`,
        checkboxState.sharewithtenants ? "1" : "0"
      );
      formDatas.append(
        `property[property_attachment_details_attributes][${index}][share_with_owners]`,
        checkboxState.sharewithowners ? "1" : "0"
      );
    });

    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apicallNewpropertyID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.NewpropertyEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formDatas
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);


  }
  // API'S Responses

  newpropertyResponse = (responseJson: any, errorResponse: any) => {
    this.setState({ newPropertyhandleres: responseJson })
    if(responseJson?.data.attributes){
      this.setState({ sucessMsg: "Property created sucessfully"}, () => {
        this.handleClear();
        this.navigateTo({ props: this.props, screenName: "PortfolioManagement" })
      })
    }
   setStorageData("propertyId", responseJson?.data?.id)

  }
  handleBackMain = () => {
    this.setState(prevState => ({
      activeStep: prevState.activeStep - 1
    }));
  };
  handleMainItemClicks = (labelKey: string) => {
    this.setState({ activeMainItems: labelKey }, () => {
      this.handleDashboardMenu()
    });
  };

  handleDashboardMenu = () => {
    const { activeMainItems } = this.state;
    const toMsg = new Message(getName(MessageEnum.NavigationMessage));
    toMsg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      activeMainItems
    );
    toMsg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(toMsg);
  }


  handleLandlordDrawers = () => {
    this.setState({
      openLandlordDrawers: !this.state.openLandlordDrawers
    });
  };

  validateForm = (): boolean => {
    const {
      portfolioState,
      sitemanagerState,
      renewalState,
      classState,
      anmitiesdata,
      propertyName,
      propertyAddress,
      propertyCountry,
      propertyOtherpetsAllowed,
      gender1,
      descriPtions,
      lastName,
      firstName,
      dateYearbuilt,
      manageMentStart,
      flioGuard,
      selectDataState,
      propertyTypeState,
      dogAllowState,
      petOtherState,
      rentalState
    } = this.state.formData;

    const validations: ValidationRule[] = [
      { field: anmitiesdata, errorField: 'amenitiesError', errorMessage:`${this.state.anmitiesData.length === 0 ? 'The amenities field cannot be empty.' : ""}` },
      { field: propertyName, errorField: 'propertyNameError', errorMessage: 'The property name cannot be empty.' },
      { field: propertyAddress, errorField: 'propertyAddressError', errorMessage: 'The property address cannot be empty.' },
      { field: propertyCountry, errorField: 'propertyCountryError', errorMessage: 'The country field cannot be empty.' },
      { field: propertyOtherpetsAllowed, errorField: 'propertyOtherPetsAllowedError', errorMessage: `${(this.state.vendorTrade.length === 0 && this.state.formData.petOtherState === "1") ?'Other pets allowed field cannot be empty.' : ""}` },
      { field: gender1, errorField: 'gender1Error', errorMessage: 'A cat pet is required.' },
      { field: descriPtions, errorField: 'descriptionsError', errorMessage: 'The description cannot be empty.' },
      { field: lastName, errorField: 'lastnameError', errorMessage: 'The last name is required.' },
      { field: firstName, errorField: 'firstnameError', errorMessage: 'The first name is required.' },
      { field: this.state.renewalDate, errorField: 'dateError', errorMessage: 'The year built is required.' },
      { field: this.state.manageStartDate, errorField: 'managementStartError', errorMessage: 'The management start date is required.' },
      { field: flioGuard, errorField: 'flioGuardError', errorMessage: 'FolioGuard is required.' },
      { field: selectDataState, errorField: 'propertyTypeError', errorMessage: `${this.state.formData.dogAllowState === "1" ? 'The dog type is required.' : ""}` },
      { field: propertyTypeState, errorField: 'propertyPropertyError', errorMessage: 'The property type is required.' },
      { field: classState, errorField: 'classStateError', errorMessage: 'The class is required.' },
      { field: this.state.renewalDateData, errorField: 'renewalStateError', errorMessage: `${this.state.formData.rentalState === "1" ? 'The renewal date is required.' : ""}` },
      { field: sitemanagerState, errorField: 'siteManagerError', errorMessage: 'A site manager is required.' },
      { field: portfolioState, errorField: 'portfolioError', errorMessage: 'The portfolio is required.' },
      { field: dogAllowState, errorField: 'dogAllowStateError', errorMessage: 'A dog pet is required.' },
      { field: petOtherState, errorField: 'petOtherStateError', errorMessage: 'Other pets are required.' },
      { field: rentalState, errorField: 'rentalStateError', errorMessage: 'A rental license is required.' },
      { field: !!this.state.formFilesInsurance.length, errorField: 'formFilesInsuranceError', errorMessage: 'An insurance document is required.' },
    ];

    const errors: FormErrors = validations.reduce((acc, { field, errorField, errorMessage }) => {
      acc[errorField] = !field || (typeof field === 'string' && !field.trim()) ? errorMessage : '';
      return acc;
    }, {} as FormErrors);

    const isValidForm = !Object.values(errors).some(error => error !== '');

    this.setState({ formErrors: errors });

    return isValidForm;
  };


  validateForm2 = (): boolean => {


    const {
      marketState,
      propertyListingState,
      areacodeState,
      phonenumberState,
      descriptionState,
      emailState
    } = this.state.formData;

    const validations: ValidationRule2[] = [
      { field: marketState, errorField: 'marketStateError', errorMessage: 'Property market is required' },
      { field: propertyListingState, errorField: 'listingTypeError', errorMessage: 'List Type is required' },
      { field: areacodeState, errorField: 'areacodeError', errorMessage: 'Area code is required' },
      { field: phonenumberState, errorField: 'phonenumberError', errorMessage: 'Phone number is required' },
      { field: descriptionState, errorField: 'descriptionStateError', errorMessage: 'Description is required' },
      { field: emailState, errorField: 'emailStateError', errorMessage: 'Invalid Email' },
      { field: this.state.photoFile.toString(), errorField: 'photoStateError', errorMessage: 'Photo is required' },
    ];

    const errors: FormErrors2 = validations.reduce((acc, { field, errorField, errorMessage }) => {
      acc[errorField] = !field || (typeof field === 'string' && !field.trim()) ? errorMessage : '';
      return acc;
    }, {} as FormErrors2);

    if (this.state.areaCodesState) {
      errors.areacodeError = '';
    }
    const isValidForm = !Object.values(errors).some(error => error !== '');

    this.setState({ formErrors: errors });

    return isValidForm;
  };

  validateForm3 = (): boolean => {
    const {
      maximumState,
      onlineState,
      insuranceState,
      whichoneState,
      mainState,
      coverWor,
      unitEntry,
      alermEntry,
      loundryState,
      marketplan,
      showplan,
      ongoingState
    } = this.state.formData;

    const validations: ValidationRule3[] = [
      { field: maximumState, errorField: 'maximumError', errorMessage: 'Maximum approved maintenance is required' },
       { field: onlineState, errorField: 'onlineStateError', errorMessage: 'Online maintenance request instructions is required' },
      { field: coverWor, errorField: 'coverWorError', errorMessage: 'Required' },
      { field: alermEntry, errorField: 'alermEntryError', errorMessage: 'Required' },
      { field: unitEntry, errorField: 'unitEntryError', errorMessage: 'Required' },
      { field: loundryState, errorField: 'loundryError', errorMessage: 'Required' },
      { field: marketplan, errorField: 'marketplanError', errorMessage: 'Required' },
      { field: showplan, errorField: 'showplanError', errorMessage: 'Required' },
      { field: ongoingState, errorField: 'ongoingError', errorMessage: 'Required' },
      { field: whichoneState, errorField: 'whichoneStateError', errorMessage: `${this.state.whichOneData.length === 0 ? 'Required' : ""}` },
      { field: mainState, errorField: 'mainStateError', errorMessage: 'Notes is required' },
       { field: this.state.attachmentFile.toString(), errorField: 'attachmentStateError', errorMessage: 'Attachment is required' },
      { field: this.state.insuranceDate, errorField: 'insuranceStateError', errorMessage: 'Insurance date is required' },
    ];

    const errors: FormErrors3 = validations.reduce((acc, { field, errorField, errorMessage }) => {
      acc[errorField] = !field || (typeof field === 'string' && !field.trim()) ? errorMessage : '';
      return acc;
    }, {} as FormErrors3);

    const isValidForm = !Object.values(errors).some(error => error !== '');

    this.setState({ formErrors: errors });

    return isValidForm;
  };

  validateField = (value: string, errorMessage: string) => {
    return value.trim() ? '' : errorMessage;
  };
  
  validatemainStateError = (value: string) => {
    return this.validateField(value, 'Notes is required');
  };
  
  validateonlineStateError = (value: string) => {
    return this.validateField(value, 'Online maintenance request instructions are required');
  };
  
  validatedescriptionError = (value: string) => {
    return this.validateField(value, 'Description is required');
  };

  handleSelectChangetextArea = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    const formData = { ...this.state.formData, [name]: value };
    const formErrors = { ...this.state.formErrors };

    // Validate each field separately and update its error state
    switch (name) {
      case 'mainState':
        formErrors.mainStateError = this.validatemainStateError(value);
        break;
      case 'onlineState':
        formErrors.onlineStateError = this.validateonlineStateError(value);
        break;
      case 'descriptionState':
        formErrors.descriptionStateError = this.validatedescriptionError(value);
        break;

    }
    this.setState({ formData, formErrors });
  };
  validatepropertyTypeError = (value: string) => {
    return value.trim() ? '' : 'propertyTypeError is required';
  }; validatepropepropertyPropertyError = (value: string) => {
    return value.trim() ? '' : 'property is required';
  };
  ; validatepropeproclassStateError = (value: string) => {
    return value.trim() ? '' : 'class is required';
  };
  ; validateprrenewalStateError = (value: string) => {
    return value.trim() ? '' : 'renewal is required';
  };
  validateprsiteManagerError = (value: string) => {
    return value.trim() ? '' : 'portfolio is required';
  };
  validateportfolioStateError = (value: string) => {
    return value.trim() ? '' : 'portfolio is required';
  };
  validateprsiinsuranceStateError = (value: string) => {
    return value.trim() ? '' : 'Insurance is required';
  };
  validateprsiipropertyListingState = (value: string) => {
    return value.trim() ? '' : 'List type is required';
  };
  validateprwhichoneStateError = (value: string) => {
    return value.trim() ? '' : 'required';
  };
  validateEmailError = (value: string) => {
    return value && this.validateEmail(value) ? '' : 'Invalid Email';
  };

  validateEmail = (email: string) => {
    const emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return emailRegex.test(email);
  };
  handleSelectChange = (event: React.ChangeEvent<{ name: string; value: string }>) => {
    const { name, value } = event.target;
    const formData = { ...this.state.formData, [name]: value };
    const formErrors = { ...this.state.formErrors };
    switch (name) {
      case 'selectDataState':
        formErrors.propertyTypeError = this.validatepropertyTypeError(value)
        break;
      case 'propertyTypeState':
        formErrors.propertyPropertyError = this.validatepropepropertyPropertyError(value);
        break;
      case 'classState':
        formErrors.classStateError = this.validatepropeproclassStateError(value)
        break;
      case 'renewalState':
        formErrors.renewalStateError = this.validateprrenewalStateError(value);
        break;
      case 'sitemanagerState':
        formErrors.siteManagerError = this.validateprsiteManagerError(value);
        break;
      case 'portfolioState':
        formErrors.portfolioError = this.validateportfolioStateError(value);
        break;
      case 'insuranceState':
        formErrors.insuranceStateError = this.validateprsiinsuranceStateError(value);
        break;
      case 'propertyListingState':
        formErrors.listingTypeError = this.validateprsiipropertyListingState(value);
        break;
      case 'whichoneState':
        formErrors.whichoneStateError = this.validateprwhichoneStateError(value);
        break;


    }

    this.setState({ formData, formErrors }, () => {
      this.handleAddOneData();
    });
  };


  validateAmenities = (value: string) => {
    return value.trim() ? '' : 'Amenities cannot be empty';
  };

  validatePropertyName = (value: string) => {
    return value.trim() ? '' : 'Property cannot be empty';
  };

  validatePropertyAddress = (value: string) => {
    return value.trim() ? '' : 'Address cannot be empty';
  };

  validatePropertyCountry = (value: string) => {
    return value.trim() ? '' : 'Country cannot be empty';
  };

  validatePetOtherState = (value: string) => {
    return value.trim() ? '' : 'cannot be empty';
  };

  validatePropertyOtherPetsAllowed = (value: string) => {
    return value.trim() ? '' : 'Other pets allowed cannot be empty';
  };

  validateDogAllowState = (value: string) => {
    return value.trim() ? '' : 'cannot be empty';
  };

  validateGender1 = (value: string) => {
    return value.trim() ? '' : 'Other pets cannot be empty';
  };

  validateDescriptions = (value: string) => {
    return value.trim() ? '' : 'Description cannot be empty';
  };

  validateLastName = (value: string) => {
    return value.trim() ? '' : 'Last name is required';
  };

  validateFirstName = (value: string) => {
    return value.trim() ? '' : 'First name is required';
  };

  validateDateYearBuilt = (value: string) => {
    return value.trim() ? '' : 'Date is required';
  };

  validateManagementStart = (value: string) => {
    return value.trim() ? '' : 'Management start date is required';
  };

  validateFlioGuard = (value: string) => {
    return value.trim() ? '' : 'FlioGuard is required';
  };

  validateMarketState = (value: string) => {
    return value.trim() ? '' : 'Property market is required';
  };

  validateAreaCodeState = (value: string) => {
    return value.trim() ? '' : 'Area code is required';
  };

  validatePhoneNumberState = (value: string) => {
    return value && this.validatePhoneNumber(value) ? '' : "Please enter a valid phone number.";
  };

  validatePhoneNumber = (phone: string) => {
    const isOnlyNumbers = /^\d{10}$/
    return isOnlyNumbers.test(phone);
  };

  validateMaximumState = (value: string) => {
    return value.trim() ? '' : 'Maximum approved maintenance is required';
  };

  validateRentalState = (value: string) => {
    return value.trim() ? '' : 'rental license is required';
  };

  validateCodeState = (value: string) => {
    return value.trim() ? '' : 'Code is required';
  };

  validateFloorState = (value: string) => {
    return value.trim() ? '' : 'Floor is required';
  };

  validateEntranceState = (value: string) => {
    return value.trim() ? '' : 'Entrance is required';
  };

  validateInsuranceState = (value: string) => {
    return value.trim() ? '' : 'Insurance is required';
  };
  validatecoverWorError = (value: string) => {
    return value.trim() ? '' : 'Home warrenty is required';
  }
  validateunitEntry = (value: string) => {
    return value.trim() ? '' : 'Unit entry pre-authorized is required';
  }
  validatealermEntry = (value: string) => {
    return value.trim() ? '' : 'Required';
  }
  validateloindryEntry = (value: string) => {
    return value.trim() ? '' : 'Required';
  }
  validatemarketplanErrorEntry = (value: string) => {
    return value.trim() ? '' : 'Required';
  }
  validateshowEntry = (value: string) => {
    return value.trim() ? '' : 'Required';
  }
  validateongoingError = (value: string) => {
    return value.trim() ? '' : 'Required';
  }
  handleChange = (event: { target: { name: string; value: string; }; }) => {
    const { name, value } = event.target;
    let isValid = true;
    if (["propertyName", "propertyCountry", "firstName", "lastName", "marketState"].includes(name)) {
      const isOnlyCharacters = /^[a-zA-Z\s]*$/.test(value); 
      isValid = isOnlyCharacters;
    }

    if (["phonenumberState", "maximumState", "codeState"].includes(name)) {
      const isOnlyNumbers = /^\d*$/.test(value); 
      isValid = isOnlyNumbers && value.length <= 10;
    }

    if (["maximumState"].includes(name)) {
      const regex = /^\d+(\.\d{0,2})?$/.test(value) || value === "";
      isValid = regex
    }
    const formData = { ...this.state.formData, [name]: value };
    const formErrors = { ...this.state.formErrors };


    switch (name) {
      case 'anmitiesdata':
        formErrors.amenitiesError = this.validateAmenities(value);
        break;
      case 'propertyName':
        formErrors.propertyNameError = this.validatePropertyName(value);
        break;
      case 'propertyAddress':
        formErrors.propertyAddressError = this.validatePropertyAddress(value);
        break;
      case 'propertyCountry':
        formErrors.propertyCountryError = this.validatePropertyCountry(value);
        break;
      case 'petOtherState':
        formErrors.petOtherStateError = this.validatePetOtherState(value);
        break;
      case 'propertyOtherpetsAllowed':
        formErrors.propertyOtherPetsAllowedError = this.validatePropertyOtherPetsAllowed(value);
        break;
      case 'dogAllowState':
        formErrors.dogAllowStateError = this.validateDogAllowState(value);
        break;
      case 'gender1':
        formErrors.gender1Error = this.validateGender1(value);
        break;
      case 'descriPtions':
        formErrors.descriptionsError = this.validateDescriptions(value);
        break;
      case 'lastName':
        formErrors.lastnameError = this.validateLastName(value);
        break;
      case 'firstName':
        formErrors.firstnameError = this.validateFirstName(value);
        break;
      case 'dateYearbuilt':
        formErrors.dateError = this.validateDateYearBuilt(value);
        break;
      case 'manageMentStart':
        formErrors.managementStartError = this.validateManagementStart(value);
        break;
      case 'flioGuard':
        formErrors.flioGuardError = this.validateFlioGuard(value);
        break;
      case 'marketState':
        formErrors.marketStateError = this.validateMarketState(value);
        break;
      case 'phonenumberState':
        formErrors.phonenumberError = this.validatePhoneNumberState(value);
        break;
      case 'maximumState':
        formErrors.maximumError = this.validateMaximumState(value);
        break;
      case 'rentalState':
        formErrors.rentalStateError = this.validateRentalState(value);
        break;
      case 'coverWor':
        formErrors.coverWorError = this.validatecoverWorError(value);
        break;
      case 'unitEntry':
        formErrors.unitEntryError = this.validateunitEntry(value);
        break;
      case 'alermEntry':
        formErrors.alermEntryError = this.validatealermEntry(value);
        break;
      case 'loundryState':
        formErrors.loundryError = this.validateloindryEntry(value);
        break;
      case 'marketplan':
        formErrors.marketplanError = this.validatemarketplanErrorEntry(value);
        break;
      case 'showplan':
        formErrors.showplanError = this.validateshowEntry(value);
        break;
      case 'ongoingState':
        formErrors.ongoingError = this.validateongoingError(value);
        break;
      case 'insuranceState':
        formErrors.insuranceStateError = this.validateInsuranceState(value);
        break;

        case 'emailState':
        formErrors.emailStateError = this.validateEmailError(value);
        break;

    }

    if(isValid){

      this.setState({ formData, formErrors });
    }
  };
  handleNextMain = () => {
    const { activeStep } = this.state;

    switch (activeStep) {
      case 0:
        if (this.validateForm()) {
          this.setState(prevState => ({
            activeStep: prevState.activeStep + 1
          }));
        }
        break;

      case 1:
        if (this.validateForm2()) {
          this.setState(prevState => ({
            activeStep: prevState.activeStep + 1
          }));
        }
        break;

      case 2:
        if (this.validateForm3()) {
          this.setState(prevState => ({
            activeStep: prevState.activeStep + 1
          }));
        }
        break;

        


    }
  };
  handleSubmit = async () => {
    if (this.validateForm3()) {
      await this.doNewpropertyApi();
    }
  }

  handleClear = () => {
    this.setState({
      formData: {
        portfolioState: "",
        sitemanagerState: "",
        renewalState: "",
        classState: "",
        anmitiesdata: "",
        propertyName: "",
        propertyAddress: "",
        propertyCountry: "",
        propertyOtherpetsAllowed: "",
        gender1: "",
        descriPtions: "",
        lastName: "",
        firstName: "",
        dateYearbuilt: "",
        manageMentStart: "",
        flioGuard: "",
        selectDataState: "",
        propertyTypeState: "",
        dogAllowState: "",
        petOtherState: "",
        rentalState: "",
        maximumState: "",
        whichoneState: "",
        mainState: "",
        onlineState: "",
        descriptionState: "",
        emailState: "",
        coverWor: "",
        unitEntry: "",
        alermEntry: "",
        loundryState: "",
        marketplan: "",
        showplan: "",
        ongoingState: "",
        marketState: "",
        propertyListingState: "",
        phonenumberState: "",
        areacodeState: "",
        insuranceState:""
      },
      checkboxStates: {}, 
      vendorTrade: [],
      formFilesInsurance: [],
      photoFile: [],
      whichOneData: [],
      attachmentFile: [],
      renewalDate: null,
      manageStartDate: null,
      insuranceDate: null,
      anmitiesData:[]
    });
  }
  handleAddTrade = () => {
    const newTrades = this.state.formData.propertyOtherpetsAllowed
      .split(",")
      .map((trade) => trade.trim())
      .filter((trade) => trade !== "");

    const combinedTrades = [...this.state.vendorTrade, ...newTrades];

    const uniqueTrades = combinedTrades.filter(
      (item, index) => combinedTrades.indexOf(item) === index
    );

    this.setState(
    (prevState) => ({
      vendorTrade: uniqueTrades,
      formData: {
        ...prevState.formData,
        propertyOtherpetsAllowed: "", 
      },
    }));

  };

  handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>, name: string) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if(name === "otherpet"){
        this.handleAddTrade();
      }else{
        this.handleAddAmenities();
      }
    }
  };

  handleDelete = (trade: string) => {
    const updatedTrade = this.state.vendorTrade.filter(
      (item) => item !== trade
    );

    const updatedAnmit = this.state.anmitiesData.filter(
      (item) => item !== trade
    );

    const updatedWhichOne = this.state.whichOneData.filter(
      (item) => item !== trade
    );
    this.setState({ vendorTrade: updatedTrade, anmitiesData: updatedAnmit, whichOneData: updatedWhichOne });
  };

  handleAddAmenities = () => {
    const newanmitiesData = this.state.formData.anmitiesdata
      .split(",")
      .map((trade) => trade.trim())
      .filter((trade) => trade !== "");

    const combinedTrades = [...this.state.anmitiesData, ...newanmitiesData];

    const uniqueTrades = combinedTrades.filter(
      (item, index) => combinedTrades.indexOf(item) === index
    );

    this.setState(
    (prevState) => ({
      anmitiesData: uniqueTrades,
      formData: {
        ...prevState.formData,
        anmitiesdata: "", 
      },
    }));

  };

  handleAddOneData = () => {
    
    const newaOneData = this.state.formData.whichoneState
    .split(",")
    .map((trade) => trade.trim())
    .filter((trade) => trade !== "");
    
    const combinedTrades = [...this.state.whichOneData, ...newaOneData];

    const whichOneData = combinedTrades.filter(
      (item, index) => combinedTrades.indexOf(item) === index
    );

    this.setState(
    (prevState) => ({
      whichOneData: whichOneData,
      formData: {
        ...prevState.formData,
        whichoneState: "", 
      },
    }));
  };

  handleFilesChange = (name: string, files: FileWithPreview[]) => {
    this.setState({
      formFilesInsurance: files,
    } as unknown as Pick<S, keyof S>,
    () => {
      this.fileValidation("formFilesInsurance",files )
      return name
    });
  };

  handleRenewalDateChange = (newdateValue: Date | null) => {
    this.setState({ renewalDate: newdateValue }, () => {
      this.dateValidation("renewalDate", newdateValue)
    });   
  };

  handleManageStartChange = (newdateValue: Date | null) => {
    this.setState({ manageStartDate: newdateValue }, () => {
      this.dateValidation("manageStartDate", newdateValue)
    });
  };

  handlePhotoFilesChange = (name: string, files: FileWithPreview[]) => {
    this.setState({
      photoFile: files,
    } as unknown as Pick<S, keyof S>,
    () => {
      this.fileValidation("photoFile",files )
    });
  };

  handleInsDateChange = (newdateValue: Date | null) => {
    this.setState({ insuranceDate: newdateValue }, () => {
      this.dateValidation("insuranceDate", newdateValue)
    });    
  };

  handleattachmentChange = (name: string, files: FileWithPreview[]) => {
    this.setState({
      attachmentFile: files,
    } as unknown as Pick<S, keyof S>,
    () => {
      this.fileValidation("attachmentFile",files )
      return name
    });
  };
  
  handleRenewalChange = (newdateValue: Date | null) => {
    this.setState({ renewalDateData: newdateValue }, ()=> {
      this.dateValidation("renewalDateData",newdateValue)
    });
  };
  dateValidation = (name: string, newdateValue: Date | null ) => {
    const { formErrors } = this.state;
    switch (name) {
      case 'renewalDate':
        this.state.formErrors.dateError = this.validateRenewalDate(newdateValue);
        break;
      case 'manageStartDate':
        this.state.formErrors.managementStartError = this.validateManageDate(newdateValue);
        break;
      case 'insuranceDate':
        this.state.formErrors.insuranceStateError = this.validateInsuranceDate(newdateValue);
        break;
      case 'renewalDateData':
      this.state.formErrors.renewalStateError = this.validateRenewal(newdateValue);
      break;  
    }
    this.setState({ formErrors });
  }

  validateRenewalDate = (value: Date | null) => {
    if (value) {
      return ''; 
    }
    return 'Year built is required';
  };

  validateManageDate = (value: Date | null) => {
    if (value) {
      return ''; 
    }
    return 'Management start date is required';
  };

  validateInsuranceDate= (value: Date | null) => {
    if (value) {
      return ''; 
    }
    return 'Insurance date is required';
  };
  validateRenewal= (value: Date | null) => {
    if (value) {
      return ''; 
    }
    return 'Renewal date is required';
  };
  fileValidation = (name: string, newfileValue: FileWithPreview[] ) => {
    const { formErrors } = this.state;
    switch (name) {
      case 'formFilesInsurance':
        this.state.formErrors.formFilesInsuranceError = this.validateIns(newfileValue);
        break;
      case 'photoFile':
        this.state.formErrors.photoStateError = this.validatePhoto(newfileValue);
        break;
      case 'attachmentFile':
        this.state.formErrors.attachmentStateError = this.validateAttach(newfileValue);
        break;
    }
    this.setState({ formErrors });
  }

  validateIns = (value: FileWithPreview[]) => {

    if (value.length) {
      return ''; 
    }
    return 'Required';
  };
  validatePhoto = (value: FileWithPreview[]) => {
    if (value.toString()) {
      return ''; 
    }
    return 'Photo is required';
  };
  validateAttach = (value: FileWithPreview[]) => {
    if (value.toString()) {
      return ''; 
    }
    return 'Attachment is required';
  };


  handleCheck = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { name, checked } = event.target;
    this.setState((prevState) => ({
      checkboxStates: {
        ...prevState.checkboxStates,
        [index]: {
          ...prevState.checkboxStates[index],
          [name]: checked === true ? "1" : "0",
        },
      },
    }));
  };

navigateTo = ({
  id,
  props,
  screenName,
  raiseMessage,
  }: INavigateTo) => {
  const message = new Message(getName(MessageEnum.NavigationMessage));
  message.addData(getName(MessageEnum.NavigationPropsMessage), props);
  message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
  id && message.addData(getName(MessageEnum.NavigationScreenNameMessage), id);
  raiseMessage &&
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
  runEngine.sendMessage(message.id, message);
  }
  navigateToAppointments = () => {
    const navigationMessage = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    navigationMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "PortfolioManagement"
    );
    navigationMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    runEngine.sendMessage("MergeEngineUtilities", navigationMessage);
  };

  apiSuccessCall = (apiRequestCallID: string, responseJson: Countries) => {
    if (apiRequestCallID === this.getAreaCodesApiCallId) {
      let countriesCode = responseJson.data.map((item: AreasCode) => {
        return {
          name: item.attributes.name,
          emoji_flag: item.attributes.emoji_flag,
          country_code: item.attributes.country_code,
        };
      });

      this.setState({ countriesCode }, () => {});
    }
  };

  portfolioAdd = async (data: APIPayloadType) => {
    let { contentType, method, endPoint} = data;
    const token = await storage.get("authToken");
    const header = {
      "Content-Type": contentType,
      token: token
    };

    let requestsNewMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestsNewMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestsNewMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestsNewMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    runEngine.sendMessage(requestsNewMessage.id, requestsNewMessage);
    return requestsNewMessage.messageId;
  };

  handleCountryCodeDropdown = () => {
    this.setState({ isCountriesDropdown: true });
  };

  handleCountryDropdownClose = () => {
    this.setState({ isCountriesDropdown: false });
  };

  handleCountryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    
    const areaCodeError = this.validateAreaCodeState(value);
  
    this.setState({
      areaCodesState: value,
      formErrors: { 
        ...this.state.formErrors, 
        areacodeError: areaCodeError || ""
      }
    }, () => {
      this.handleCountryDropdownClose();
    });
  };

  getAreaCodeForProperty = async () => {
    this.getAreaCodesApiCallId = await this.portfolioAdd({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getAreaCodeAPIEndPoint
    });
  };
  // Customizable Area End
}