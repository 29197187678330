import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../../packages/framework/src/Utilities";
import { SelectChangeEvent } from '@mui/material/Select'; 
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { AlertColor } from "@mui/lab";
import JsFileDownloader from "js-file-downloader";
export interface Transaction {
  id: number;
  ledger_type: string;
  amount: number;
  reference_number: string;
  details: string;
  transaction_status: string;
  transaction_date: string;
  property_id: number;
  property_name: string;
  unit_id: number;
  unit_name: string;
  sender:string;
}
interface Property {
  property_id: string,
  unit_id: number,
  name: string,
  tenants: any[],
  vendors: any[]
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  activeOrderItem: string;
  openWorkOrder: boolean;
  startDateOpen: boolean;
  endDateOpen: boolean;
  sortState: {
    [key: number]: {
      sortColumn: string;
      sortDirection: "asc" | "desc";
    };
  },
  startDate: Date | null;
  endDate: Date | null;
  allProperties: any[];
  property: string[];
  selectedDescriptions: string[];
  anchorElement: { [key: number]: HTMLElement | null };
  transactions: Transaction[];
  expanded: number | null;
  messageOpen: boolean;
  messageType: AlertColor;
  message: string;
  selectedProperty: Property;
  selectedTransactions: number[], 
  numSelected: number,
  rowCount: number,
  anchorNewElement: { [key: number]: HTMLElement | null };
  openFinKey: boolean;
  prizingDetails: any;
  action: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Cfdocumentforwarding4Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  searchtApiCallId:string ="";
  multipleReversePaymentId: string = "";
  multipleCompletePaymentId:string="";
  getTransactionlistCallId: string = "";
  shredDocumentCallId: string = "";
  getPrizingDetailsCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      sortState: {},
      activeOrderItem: "Cfdocumentforwarding4",
      openWorkOrder: false,
      startDate:null,
      endDate:null,
      startDateOpen:false,
      endDateOpen:false,
      allProperties:[],
      property: [],
      selectedDescriptions:[],
      anchorElement: {},
      transactions: [],
      numSelected: 0,
      rowCount: 0,
      selectedTransactions:[], 
      expanded: null,
      messageOpen: false,
      messageType: "success",
      message: "",
      selectedProperty: {
        property_id: "",
        unit_id: 0,
        name: "",
        tenants: [],
        vendors: [],
      },
      anchorNewElement: {},
      openFinKey: false,
      prizingDetails: {},
      action: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    ); 
    if (apiRequestCallId === this.getTransactionlistCallId) {
      
      if (!responseJson.errors && responseJson) {
        this.setState({
          transactions:responseJson.data
         });
      } else if (responseJson && responseJson.errors) {
        this.setState({
          messageOpen: true,
          messageType: "error",
          message: responseJson.errors
         });
      }
      };
      if (apiRequestCallId === this.searchtApiCallId) {
        if (!responseJson.errors && responseJson) {
          this.setState({
            transactions:responseJson.data
           });
        } else if (responseJson && responseJson.errors) {
          this.setState({
            messageOpen: true,
            messageType: "error",
            message: responseJson.errors
          });
        }
        };
        if (apiRequestCallId === this.getPrizingDetailsCallId) {
      
          if (!responseJson.errors && responseJson) {
            this.setState({
              prizingDetails: responseJson
             });
          } else if (responseJson && responseJson.errors) {
            this.setState({
              messageOpen: true,
              messageType: "error",
              message: responseJson.errors
             });
          }
          };
          if (apiRequestCallId === this.shredDocumentCallId) {
      
            if (!responseJson.errors && responseJson) {
              this.setState({
                messageOpen: true,
                messageType: "success",
                message: "Document shred successfully"
               });
            } else if (responseJson && responseJson.errors) {
              this.setState({
                messageOpen: true,
                messageType: "error",
                message: responseJson.errors
               });
            }
            };
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount() { 
    this.getTransactionlist();
  }
  getTransactionlist = async () => {
    const token = await getStorageData("authToken")
    const header = {
      "Content-Type": "application/json",
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTransactionlistCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTransactionlistAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleWorkOrderMenu = () => {
    const { activeOrderItem } = this.state;
    const toMsgWork = new Message(getName(MessageEnum.NavigationMessage));
    toMsgWork.addData(
      getName(MessageEnum.NavigationTargetMessage),
      activeOrderItem
    );
    toMsgWork.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(toMsgWork);
  };
  handleWordDrawer = () => {
    this.setState({
      openWorkOrder: !this.state.openWorkOrder
    });
  };
  handleSideNav = (navKey: string) => {
    this.setState({ activeOrderItem: navKey }, () => {
      this.handleWorkOrderMenu();
    });
  };
  handlePopupMessageClose = () => {
    this.setState({
      messageOpen: false,
    });
  };
  handleDateChange =(newDate: Date | null,field:string)=>{
    if(newDate){
       if(field==="startDate"){
      this.setState({ startDate: newDate });
       }else{
      this.setState({ endDate: newDate });
       }
    }
  }
  handleOpen = (field: string) => {
    if (field === "startDate") {
        this.setState({ startDateOpen: true, endDateOpen: false });
    } else if (field === "endDate") {
        this.setState({ startDateOpen: false, endDateOpen: true });
    }
};
handleFilter = async() => {
  const token = await getStorageData("authToken")
  const header = {
    token: token,
   'Content-Type': 'application/json',
  };
  const { startDate, endDate } = this.state;
  
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.searchtApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.getTransactionlistAPIEndPoint}?start_date=${startDate}&end_date=${endDate}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );
 
  runEngine.sendMessage(requestMessage.id, requestMessage);
};

handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
  if (event.target.checked) {
    const selected = this.state.transactions.map((transaction) => transaction.id);
    this.setState({ selectedTransactions: selected });
  } else {
    this.setState({ selectedTransactions: [] });
  }
};
handleSelectClick = (transactionId: number) => {
  const selectedTransactions = [...this.state.selectedTransactions];
  const selectedIndex = selectedTransactions.indexOf(transactionId);

  if (selectedIndex === -1) {
    selectedTransactions.push(transactionId);
  } else {
    selectedTransactions.splice(selectedIndex, 1);
  }
  this.setState({ selectedTransactions });
};
isSelected = (transactionId: number) => {
  return this.state.selectedTransactions.indexOf(transactionId) !== -1;
};
handleSortRequest = (property: string, direction: "asc" | "desc", unitIndex: number) => {
  this.setState(prevState => ({
    sortState: {
      ...prevState.sortState,
      [unitIndex]: {
        sortColumn: property,
        sortDirection: direction
      }
    }
  }));
};
handleMenuClose = (rowId: number) => {
  this.setState({
    anchorNewElement: {
      ...this.state.anchorNewElement,
      [rowId]: null
    },
  });
};
handleMultipleAction = (action: any) => {
  if(this.state.selectedTransactions.length>0) {
    this.setState(
      { openFinKey: true,action: action},
    );
    this.getPrizingDetails(this.state.selectedTransactions,action);
  }
}
handleAction = (ids:any,action: any) => {
  this.setState(
    { openFinKey: true,action: action, selectedTransactions: [ids]},
    () => {
      this.getPrizingDetails([ids],action);
      this.handleMenuClose(ids);
    }
  );
}
getPrizingDetails = async (ids: any, action: any) => {
  let endpoint; 
  const body = {
    ids: ids,
  }

if (action === "forward") {
  endpoint = configJSON.getForwardingPrizeAPIEndPoint;
} else if (action === "scan") {
  endpoint = configJSON.getScanningPrizeAPIEndPoint;
}
  
  const token = await getStorageData("authToken")
    const header = {
      "Content-Type": "application/json",
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPrizingDetailsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
}
handlePayment = () => {
  setStorageData("ActionType",this.state.action)
  setStorageData("DocumentIds",JSON.stringify(this.state.selectedTransactions));
  this.props.navigation.navigate("StripePayments")
}
handleCancel = () => {
  this.handleModalClose();
}
handleShred = async(ids: any) => {
  const token = await getStorageData("authToken")
  const header = {
    token: token,
   'Content-Type': 'application/json',
  };
  
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.shredDocumentCallId = requestMessage.messageId;
  requestMessage.addData(
   getName(MessageEnum.RestAPIRequestHeaderMessage),
   JSON.stringify(header)
 );
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.shredAPIEndPoint
  );
  requestMessage.addData(
   getName(MessageEnum.RestAPIRequestMethodMessage),
   configJSON.patchApiMethodType
 );
 const body = {
    ids : ids,
    document_status: "shred"
};
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(body)
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}
handleOneShred = async (ids:any) => {
  this.setState({
    selectedTransactions: [ids]
  })
  try {
    await this.handleShred([ids]); 
    this.handleMenuClose(ids); 
    setTimeout(async () => {
      await this.getTransactionlist();
  }, 2000);
  } catch (error) {
    this.setState({
      messageOpen: true,
      messageType: "error",
      message: error instanceof Error ? error.message : String(error),
    });
  }
}
handleMultipleShred = async() => {
  if(this.state.selectedTransactions.length>0) {
    try {
      await this.handleShred(this.state.selectedTransactions);
      setTimeout(async () => {
        await this.getTransactionlist();
    }, 2000);
    } catch (error) {
      this.setState({
        messageOpen: true,
        messageType: "error",
        message: error instanceof Error ? error.message : String(error),
       });
    }
  }
}
handleMenuIconClick = (eventIcon: React.MouseEvent<HTMLElement>, rowId: number) => {
  this.setState({
    anchorNewElement: {
      ...this.state.anchorNewElement,
      [rowId]: eventIcon.currentTarget
    },
  });
};
handleModalClose = () => {
  this.setState({
    selectedTransactions: [],
    openFinKey: !this.state.openFinKey
  });
};
handleDownload = async (pdfUrl: string) => { 
  try {
    await new JsFileDownloader({
      url: pdfUrl,
    });
  } catch (error) {
    alert("Failed to download file");
  }
}; 
handleEmptyContainer = (details: any[]) => {
  return details.length > 0 ? "isUnitsDisabled" : "";
};
  // Customizable Area End
}
