// Customizable Area Start
import React, { Component, createRef } from 'react';
import { TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { styled } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { CalendarToday } from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";

const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-input': {
    marginLeft: '25px',
  },
}));

interface CustomDatePickerProps {
  value: Date | null;
  onChange: (date: Date | null) => void;
  required?: boolean;
  maxDate?: Date | undefined;
}

interface CustomDatePickerState {
  open: boolean;
}

// Customizable Area End

class CustomDatePickerData extends Component<CustomDatePickerProps, CustomDatePickerState> {
  datePickerRef = createRef<HTMLDivElement>();
  constructor(props: CustomDatePickerProps) {
    super(props);
    this.state = {
    // Customizable Area Start
      open: false,
    // Customizable Area End
    };
  }

  // Customizable Area Start

  handleTextFieldClick = () => {
    this.setState({ open: true });
  };

  handleCloseDatePicker = () => {
    this.setState({ open: false });
  };

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { value, onChange, required, maxDate } = this.props;
    const { open } = this.state;

    return (
      <ClickAwayListener onClickAway={() => this.setState({ open: false })} mouseEvent="onMouseDown">
      <div ref={this.datePickerRef} style={{ position: "relative", display: "inline-block", width: "100%" }}>
        <CalendarToday
          style={{
            position: "absolute",
            left: "10px",
            top: "-5px",
            transform: "translateY(50%)",
            pointerEvents: "none",
            color: "#757575",
            backgroundColor: "white",
          }}
        />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            value={value}
            onChange={onChange}
            open={this.state.open}
            onClose={() => this.setState({ open: false })}
            maxDate={maxDate}
            renderInput={(params) => (
              <CustomTextField
                required={required}
                size="small"
                fullWidth
                onClick={this.handleTextFieldClick}
                {...params}
                InputProps={{
                  ...params.InputProps,
                  style: { borderRadius: 8 },
                }}
              />
            )}
            inputFormat="MM-dd-yyyy"
          />
        </LocalizationProvider>
        <KeyboardArrowDownIcon
          style={{
            position: "absolute",
            right: "10px",
            top: "50%",
            transform: "translateY(-50%)",
            pointerEvents: "none",
            color: "#BAB1AB",
            backgroundColor: "white",
          }}
        />
      </div>
      </ClickAwayListener>
    );
    // Customizable Area End
  }
}

export default CustomDatePickerData;
