export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const image = require("../assets/c92c90ce1ec69fd7bdd7ec477a4b1b0539ea75ff.png");
export const keasyIcon = require("../assets/keasylogo.svg");
export const dashboardLandLogo = require("../assets/dashboard.png");
export const activeMainDashboard = require("../assets/activeDashboard.png");
export const remindersMain = require("../assets/reminder.png");
export const activeMainReminder = require("../assets/activeReminder.png");
export const propertyMain = require("../assets/property.png");
export const activePropertyMain = require("../assets/activeProperty.png");
export const financialMain = require("../assets/financial.png");
export const activeMainFinancial = require("../assets/activeFinancial.png");
export const keyMainChain = require("../assets/keyChain.png");
export const activeKeyMain = require("../assets/activeKey.png");
export const bottomImageIcon = require("../assets/bottomImage.svg");
