Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "automatedworkflow";
exports.labelBodyText = "automatedworkflow Body";
exports.dashboardLable = "Dashboard";
exports.dashboardPath ="TenantDashboard";
exports.serviderequest = "Service Requests"
exports.serviderequestPath = "servicerequestdashboard"
exports.paymentLable = "Payment History"
exports.paymentBlock="BaselineReporting"
exports.chatLandloard1 = "Check with keasy"
exports.btnExampleTitle = "CLICK ME";
exports.serviderequest2 = "Service Request"
exports.tenentlable = "Lease"
exports.tenentBlock = "DownloadOptions"
exports.selectedCount = "No";
exports.idNamesd = "ID";
exports.createdNamesd = "Requested by";
exports.categoryNamesd = "Category";
exports.workNeedNamesd = "Work needed";
exports.assignVendorNamesd = "Assigned vendor";
exports.estimatedCostNamesd = "Estimated cost";
exports.statusNamesd = "Current status";
exports.descriptionNamesd = "Description";
exports.goingOnName = "What's going on";
exports.propertiesHeadsd = "Properties";
exports.newWorkOrderssd = "New work order";
exports.chatLable = "Chat with keasy"
exports.chatBlocK = "Chat"

exports.dateName = "Date";
exports.updatedName = "Updated by";
exports.viewCompleted = "View completed";
exports.viewScheduled = "View scheduled";
exports.scheduledDate = "Scheduled date";
exports.detailsName = "Details";
exports.costName = "Cost";
exports.statusChangeName = "Status Change";
exports.imagesUpload = "Images";
exports.completed = "Complete";
exports.transfer = "Transfer to another vendor";
exports.orderOpen = "Open work order";
exports.editWork = "Edit work order";
exports.decline = "Decline work order";
exports.descriptionOptions = ["Pending", "Rejected", "Approved"];
exports.getCatagorApiEndPoint="bx_block_categories/sub_categories?slug_name=vendor-types";
exports.addServiceApiEndPoint="account_block/tenant_service_requests";
exports.getServiceRequestAPIEndPoint="account_block/tenant_service_requests/";
exports.getPropertyDetailsAPIEndPoint="account_block/tenants/tenant_info";

// Customizable Area End