// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "framework/src/Utilities";
import { SelectChangeEvent } from '@mui/material/Select';
import storage from "../../../framework/src/StorageProvider";
import JsFileDownloader from "js-file-downloader";

export interface IListItem  {
  id: string,
  type: string,
  attributes:{
    filename: null,
    file:{
      id: number,
      url: string,
      type: string
    },
    file_format: string
  }
}

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  totalItems: number;
  itemsPerPage: number;
}

export interface Countries {
  data: Array<AreaCode>;
}

export interface AreaCode {
  type: string;
  id: string;
  attributes: {
    name: string;
    country_code: string;
    emoji_flag: string;
  }
}

export interface FileWithPreview extends File {
  preview: string;
}

export interface FindLocation {
  floor: string;
  entrance: string;
  code: string;
  location_notes: string
}

export interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string;
  type?: string;
}

export interface ShowAllAttachments {
  attachments: Array<ShowAllAttachments>;
  pagination: {
    current_page: number;
    next_page: number;
    total_pages: number;
    total_count: number;
  }
}

export interface ShowAttachments {
  id: number;
  uploaded_by: string;
  date: string;
  shared: string;
  share_with_tenants: boolean;
  share_with_owners: boolean;
  file_url: string;
}

interface PropertyAttributes {
  property_type: string;
  property_name: string;
  property_marketing_name: string;
  marketing_description: string;
  lising_type: string;
  vacancy_posting_email_address: string;
  warranty_coverage: boolean;
  unit_entry_pre_authorized: boolean;
  amenities: Array<string>;
  alarm_system: boolean | null;
  laundry_room: boolean | null;
  country: string;
  site_manager_prefix: string;
  site_manager_first_name: string;
  dogs_allowed: boolean;
  other_pets_allowed: boolean;
  site_manager_last_name: string;
  year_built: string;
  cats_allowed: boolean;
  other_pets: string[];
  description: string;
  portfolio: string;
  property_class: string;
  management_start: string;
  folio_guard_policy: boolean;
  rental_license: boolean;
  vacancy_posting_area_code: string;
  vacancy_posting_phone_number: string;
  minimum_management_fee: number | null;
  maintenance_type: string | null;
  maintenance_notes: string;
  rental_license_files: string | null;
  maintenance_request: string;
  insurance_expiration: string;
  renewal_date: string;
  marketing_plan: boolean | null;
  snow_plan: boolean | null;
  floor: string | null;
  entrance: string | null;
  code: string | null;
  address: string;
  location_notes: string | null;
  marketing_information_files: string | null;
  property_attachments: string | null;
  units: Unit[];
}
export interface PropertyLabel {
  id: string;
  label: string;
}

export interface Property {
  property_class: string;
  management_start: string;
  folio_guard_policy: boolean;
  rental_license: boolean;
  property_type: string;
  property_name: string;
  country: string;
  cats_allowed: boolean;
  renewal_date: string;
  dogs_allowed: boolean;
  other_pets_allowed: boolean;
  other_pets: string[];
  description: string;
  portfolio: string;
  site_manager_prefix: string;
  site_manager_first_name: string;
  site_manager_last_name: string;
  year_built: string;
  property_marketing_name: string;
  floor: string;
  entrance: string;
  code: string;
  unit_entry_pre_authorized: boolean;
  amenities: Array<string>;
  alarm_system: boolean;
  laundry_room: boolean;
  marketing_plan: boolean;
  location_notes: string;
  rental_license_files: string | null;
  marketing_information_files: string | null;
  minimum_management_fee: number;
  insurance_expiration: string;
  warranty_coverage: boolean;
  snow_plan: boolean;
  marketing_description: string;
  lising_type: string;
  vacancy_posting_email_address: string;
  vacancy_posting_area_code: string;
  vacancy_posting_phone_number: string;
  maintenance_type: string | null;
  maintenance_notes: string;
  maintenance_request: string;
  property_attachments: string | null;
  tenant: TenantData[];
}

export interface TenantData {
  id: number;
  name: string;
  date_of_birth: string;
  address: string;
  email_address: string;
  area_code: string;
  phone_number: string;
  renewal_date: string;
  class_of_vehicle: string;
  model: string;
  vehicle_registration_plate: string;
  landlord_account_id: number;
  property_id: number;
  created_at: string;
  updated_at: string;
}

export interface PropertyData {
  id: string;
  type: string;
  attributes: PropertyAttributes;
}

interface PropertyRes {
  id: string;
  type: string;
  attributes: PropertyAttributes;
}

interface PropertyAttributes {
  property_type: string;
  property_name: string;
  description: string;
  portfolio: string;
  site_manager_last_name: string;
  country: string;
  cats_allowed: boolean;
  dogs_allowed: boolean;
  site_manager_prefix: string;
  site_manager_first_name: string;
  other_pets_allowed: boolean;
  other_pets: string[];
  year_built: string;
  folio_guard_policy: boolean;
  property_class: string;
  management_start: string;
  rental_license: boolean;
  vacancy_posting_area_code: string;

  insurance_expiration: string;
  warranty_coverage: boolean;
  unit_entry_pre_authorized: boolean;
  amenities: Array<string>;
  alarm_system: boolean | null;
  vacancy_posting_phone_number: string;
  minimum_management_fee: number | null;
  laundry_room: boolean | null;
  renewal_date: string;
  property_marketing_name: string;
  marketing_description: string;
  lising_type: string;
  vacancy_posting_email_address: string;
  marketing_plan: boolean | null;
  snow_plan: boolean | null;
  maintenance_type: string | null;
  maintenance_notes: string;
  maintenance_request: string;
  rental_license_files: string | null;
  floor: string | null;
  address: string;
  entrance: string | null;
  code: string | null;
  location_notes: string | null;
  marketing_information_files: string | null;
  property_attachments: string | null;
  units: Unit[];
}

interface ApiResponse {
  data: PropertyRes[];
  errors?: string;
}

export interface TenantInfo {
  tenant: Tenant;
  comment: string[];
}

interface Tenant{
  id: number;
    name: string;
    date_of_birth: string;
    address: string;
    email_address: string;
    area_code: string;
    phone_number: number;
    renewal_date: string;
    class_of_vehicle: string;
    model: string;
    vehicle_registration_plate: string;
    landlord_account_id: number;
    property_id: number;
    created_at: string;
    updated_at: string;
    brand_name: string;
    type_of_pet: string;
    pet_name: string;
    pet_age: string | null;
    unit_id: number;
    bet_0_to_30: number;
    bet_30_plus: number;
    payment_0_to_30_days_late: number;
    payment_over_0_to_30_days_late: number;
    status: string;
    last_payment_date: string;
    lease_start_date: string | null;
    lease_end_date: string | null;
    date_of_first_payment_due: string | null;
    pay_period: string | null;
    rent_amount_per_month: number | null;
    security_deposit: number | null;
    payment_method: string | null;
    collected_by: string | null;
    number_of_occupants: number | null;
}
export interface Unit {
  id: number;
  unit_name: string | null;
  status: string;
  tenants: Data[];
  landlord_work_orders: string[];
}

export interface Data {
  comment: string[];
  tenant: Tenant;
}

interface Tenant {
  vehicle_registration_plate: string;
  landlord_account_id: number;
  property_id: number;
  created_at: string;
  updated_at: string;
  id: number;
  name: string;
  date_of_birth: string;
  address: string;
  model: string;
  brand_name: string;
  type_of_pet: string;
  email_address: string;
  area_code: string;
  security_deposit: number | null;
  payment_method: string | null;
  collected_by: string | null;
  number_of_occupants: number | null;
  phone_number: number;
  renewal_date: string;
  class_of_vehicle: string;
  pet_name: string;
  pet_age: string | null;
  status: string;
  last_payment_date: string;
  lease_start_date: string | null;
  payment_0_to_30_days_late: number;
  payment_over_0_to_30_days_late: number;
  pay_period: string | null;
  rent_amount_per_month: number | null;
  lease_end_date: string | null;
  date_of_first_payment_due: string | null;
  unit_id: number;
  bet_0_to_30: number;
  bet_30_plus: number;
}
export interface PropertyDataOut {
  property_type: string;
  other_pets_allowed: boolean;
  rental_license_files: string | null;
  marketing_information_files: string | null;
  property_attachments: string | null;
  other_pets: string[];
  description: string;
  portfolio: string;
  site_manager_prefix: string;
  property_name: string;
  country: string;
  cats_allowed: boolean;
  dogs_allowed: boolean;
  site_manager_first_name: string;
  site_manager_last_name: string;
  year_built: string;
  renewal_date: string;
  property_marketing_name: string;
  marketing_description: string;
  lising_type: string;
  vacancy_posting_email_address: string;
  vacancy_posting_area_code: string;
  vacancy_posting_phone_number: string;
  minimum_management_fee: number;
  property_class: string;
  management_start: string;
  folio_guard_policy: boolean;
  rental_license: boolean;
  insurance_expiration: string;
  warranty_coverage: boolean;
  unit_entry_pre_authorized: boolean;
  amenities: Array<string>;
  alarm_system: boolean;
  maintenance_request: string;
  floor: string;
  entrance: string;
  code: string;
  address: string | null;
  location_notes: string;
  laundry_room: boolean;
  marketing_plan: boolean;
  snow_plan: boolean;
  maintenance_type: string | null;
  maintenance_notes: string;
  units: Unit[];
}

export interface TenantProp {
  id: number;
  name: string;
  date_of_birth: string;
  address: string;
  email_address: string;
  area_code: string;
  phone_number: number;
  renewal_date: string;
  class_of_vehicle: string;
  model: string;
  vehicle_registration_plate: string;
  landlord_account_id: number;
  property_id: number;
  created_at: string;
  updated_at: string;
  brand_name: string;
  type_of_pet: string;
  pet_name: string;
  pet_age: number | null;
  unit_id: number;
  bet_0_to_30: number;
  bet_30_plus: number;
  payment_0_to_30_days_late: number;
  payment_over_0_to_30_days_late: number;
  status: string;
  last_payment_date: string;
  lease_start_date: string | null;
  lease_end_date: string | null;
  date_of_first_payment_due: string | null;
  pay_period: string | null;
  rent_amount_per_month: number | null;
  security_deposit: number | null;
  payment_method: string | null;
  collected_by: string | null;
  number_of_occupants: number | null;
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  indexArr: IListItem[];
  videoArr: IListItem[];
  audioArr: IListItem[];
  documentsArr: IListItem[];
  imageAll: boolean;
  audioAll: boolean;
  videoAll: boolean;
  documentsAll: boolean;
  activeMyproperty: string;
  openLandlordDrawermypro: boolean;
  isPopupVisible:boolean;
  popupPosition: boolean;
  isPopupVisibletwo:boolean;
  isSecondaryPopupVisible: boolean,
  isOpenDialog: boolean,
  isOpen: boolean;
  unitsName: string;
  currentPage: number;
  valuesOfKey: Object; 
  totalPages: number;
  properties: any,
  token: string;
  searchQuery: string;
  searchData: PropertyLabel[],
  filterData: PropertyData[],
  lastChar: string,
  keyData: any,
  propertiInfoData: PropertyData[],
  editOpen: boolean;
  editContactOpen: boolean;
  description: string;
  openAmenities: boolean;
  openMarketing: boolean;
  folioGuardPolicy: string;
  rentalLicense: string;
  portfolio: string;
  lising: string;
  lisingType: Array<string>;
  siteManager: string;
  classTypes: Array<string>;
  openProperty: boolean;
  classValue: string;
  propertiesType: string;
  yearsBuilt: Date | null;
  managementStartDate: Date | null;
  managementEndDate: Date | null;
  renewalDate: Date | null;
  managementEndReason: string;
  additionalIntrest: string;
  insuranceExpiration: Date | null;
  unitEntry: string;
  homeWarranty: string;
  mainNotes: string;
  onineMain: string;
  editMainOpen: boolean;
  areasCode: string;
  isCountryDropdowns: boolean;
  vendorTrades: string[];
  firstName: string;
  lastName: string;
  totalCount: number;
  addTrades: string;
  phoneNumber: string;
  showAllAttachments: Array<ShowAttachments>;
  openUploads: boolean;
  uploadDocuments: FileWithPreview[];
  siteData: Array<string>;
  checkboxDoc: { [key: number]: { sharewithtenants: boolean; sharewithowners: boolean } };
  countriesCode: Array<{ name: string; emoji_flag: string; country_code: string }>;
  email: string;
  dogsAllowed: string;
  catsAllowed: string;
  propertyMarkName: string;
  marketDesc: string;
  errors: {
    description: boolean;
    email: boolean;
    phoneNumber: boolean;
    vendorTrades: boolean;
    firstName: boolean;
    lastName: boolean;
    address: boolean;
  };
  propertiesId: string;
  openEditFind: boolean;
  unitId: number;
  successLoader: boolean;
  propertyType: Array<string>;
  portfolioDetails: Array<string>;
  address: string;
  selectedDetails: Partial<PropertyAttributes>;
  isUnitOpen: boolean,
  unitName: string,
  propertyId: string,
  queryData: string | undefined,
  maxOccupant: string,
  searchBar: string;
  propertiesName: string;
  chips: { propertyId: number; unitId: number; propertyName: string; unitName: string }[];
  sortState: {
    [key: number]: {
      sortColumn: string;
      sortDirection: "asc" | "desc";
    };
  },
  openDescription: boolean,
  modalDescription: string | null;
  expanded: number | null;
  anchorNewElement: { [key: number]: HTMLElement | null };
  vendorModal: boolean;
  workOrdersId: number;
  openFinKey: boolean,
  findKeyDetails: any,
  anchorEl: null | HTMLElement;
  showAllItems: boolean;
  originalProperties: any, 
  selectedProperty: any;
  subMenuAnchorEl: null | HTMLElement;
  selectedMenuItemIndex: null | number;
  openLoader: boolean;
  messagePopup: boolean;
  messageForPopup: string;
  messageType: 'success' | 'error' | 'warning' | 'info';
  selectedUnit: string | null;
  floorState: any;
  entranceState: any,
  codeState: any,
  locationState: any,
}

interface INavigateTo {
  id?: number;
  props: unknown;
  screenName: string;
  raiseMessage?: Message;
  propertyId?: unknown;
  tenantId?: unknown
}

interface SS {
  id: any;
}

export default class PortfolioManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  indexAPICallID: string="";
  videosAPICallID: string="";
  audiosAPICallID: string="";
  documentsAPICallID: string="";
  getProperties: string="";
  getSearchProperties: string="";
  getPropertiesInfo: string="";
  postUnit: string="";
  getPropertiesApiCallId: string = "";
  postApprovedApiCallId: string = "";
  getOrigionalApiCallId: string = "";
  getAreaCodeCallId: string = "";
  updatePropertyInfoApiCallId: string = "";
  uploadAttachmentsAPiCallId: string = "";
  getAttachmentsAPiCallId: string = "";
  deleteAttachmentApiCallId: string = "";
  updateFindKeyApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials)
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      indexArr: [],
      videoArr: [],
      audioArr: [],
      documentsArr: [],
      imageAll: false,
      audioAll: false,
      videoAll: false,
      documentsAll: false,
      siteManager: "",
      activeMyproperty: "landlordproperties",
      openLandlordDrawermypro: false,
      isPopupVisible:false,
      popupPosition: false,
      isPopupVisibletwo:false,
      isSecondaryPopupVisible: false,
      siteData: [configJSON.mrsName, configJSON.missName, configJSON.msName, configJSON.mrName],
      isOpenDialog:false,
      unitName: "",
      propertiesType: "",
      classValue: "",
      properties: [],
      token: "",
      isOpen: false,
      currentPage: 1,
      totalPages: Math.ceil(props.totalItems / props.itemsPerPage),
      searchQuery: "",
      searchData: [],
      filterData: [],
      lastChar: "",
      keyData: [],
      propertiInfoData: [],
      editOpen: false,
      editContactOpen: false,
      description: "",
      openAmenities: false,
      openMarketing: false,
      openEditFind: false,
      unitId: 0,
      email: "",
      propertyMarkName: "",
      marketDesc: "",
      folioGuardPolicy: "yes",
      rentalLicense: "yes",
      portfolio: "",
      yearsBuilt: null,
      managementStartDate: null,
      managementEndDate: null,
      successLoader: false,
      renewalDate: null,
      managementEndReason: "",
      additionalIntrest: "",
      insuranceExpiration: null,
      totalCount: 0,
      unitEntry: "yes",
      homeWarranty: "yes",
      mainNotes: "",
      onineMain: "",
      editMainOpen: false,
      areasCode: "",
      isCountryDropdowns: false,
      phoneNumber: "",
      countriesCode: [],
      dogsAllowed: "",
      catsAllowed: "",
      vendorTrades: [],
      addTrades: "",
      errors: {
        description: false,
        email: false,
        phoneNumber: false,
        vendorTrades: false,
        firstName: false,
        lastName: false,
        address: false
      },
      propertiesId: "",
      openUploads: false,
      uploadDocuments: [],
      checkboxDoc:{},
      showAllAttachments: [],
      valuesOfKey: {},
      propertyType: [
        "Property 1", "Property 2", "Property 3"
      ],
      portfolioDetails: [
        "Folio 1", "Folio 2", "Folio 3"
      ],
      classTypes: ["Class 1", "Class 2", "Class 3"],
      lising: "",
      lisingType: ["Lising 1", "Lising 2", "Lising 3"],
      openProperty: false,
      address: "",
      firstName: "",
      lastName: "",
      selectedDetails: {},
      isUnitOpen: false,
      unitsName: "",
      propertyId: "",
      queryData: "",
      maxOccupant: "",
      searchBar: "",
      propertiesName: "",
      chips: [],
      sortState: {},
      openDescription: false,
      modalDescription: "",
      expanded: null,
      anchorNewElement: {},
      vendorModal: false,
      workOrdersId: 0,
      openFinKey: false,
      findKeyDetails: [],
      anchorEl: null,
      showAllItems: false,
      originalProperties: [], 
      selectedProperty: null,
      subMenuAnchorEl: null,
      selectedMenuItemIndex: null,
      openLoader: true,
      messagePopup: false,
      messageForPopup: "",
      messageType:  "success",
      selectedUnit: null,
      floorState: "",
      entranceState: "",
      codeState: "",
      locationState: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  getPropertiesApiData = (response: ApiResponse) => {
    if(!response.errors){
      const resData = response?.data.map((property:PropertyRes) => property);
      const filterData = response?.data;
      this.setState({ filterData: filterData, originalProperties: resData})
      return response
    }
  }

  filterApiData = (response: ApiResponse) => {
    if(!response.errors){
      const resSearchData = response?.data?.map((property:PropertyRes) => property);
      this.setState({ properties: resSearchData});
      return response
    }
  }

  getPropertiesInfoApiData = (response: ApiResponse) => {
    if(!response.errors){
     const resData = response?.data.map((property:PropertyRes) => property);
      this.setState({ propertiInfoData: resData, successLoader: false});
     return response
    }
  }

  postUnitApiData= (response: ApiResponse) => {
    if(!response.errors){
    this.getPropertiesData();
    this.getPropertiesDetails("","");
     return response
    }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    const apiRequestCallId1 = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson1 = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId1 && responseJson1) {
      if (apiRequestCallId1 === this.indexAPICallID && !responseJson1.errors) {
        this.setState({
          indexArr: responseJson1.data,
        });
      } else if (
        apiRequestCallId1 === this.videosAPICallID &&
        !responseJson1.errors
      ) {
        this.setState({
          videoArr: responseJson1.data,
        });
      } else if (
        apiRequestCallId1 === this.audiosAPICallID &&
        !responseJson1.errors
      ) {
        this.setState({
          audioArr: responseJson1.data,
        });
      }
      if (
        apiRequestCallId1 === this.documentsAPICallID &&
        !responseJson1.errors
      ) {
        this.setState({
          documentsArr: responseJson1.data,
        });
      }

      switch (apiRequestCallId1) {
        case this.getProperties:
          this.getPropertiesApiData(responseJson1);
          break;
  
        case this.getSearchProperties:
          this.filterApiData(responseJson1);
          break;

        case this.getPropertiesInfo:
          this.getPropertiesInfoApiData(responseJson1);
          break; 
        case this.postUnit:
          this.postUnitApiData(responseJson1)
          break 
      }
    }
    this.handleWorkOrders(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  touchableViewContainer = {
    onPress: () => this.hideKeyboard(),
  };

  videoSeeAllContainer = {
    onPress: () => {
      this.setState({videoAll: true});
      this.props.navigation.navigate("SelectedFile", {
        type: "video",
      });
    },
  };

  imageSeeAllContainer = {
    onPress: () => {
      this.setState({imageAll: true});
      this.props.navigation.navigate("SelectedFile", {
        type: "image",
      });
    },
  };

  audioSeeAllContainer = {
    onPress: () => {
      this.setState({audioAll: true})
      this.props.navigation.navigate("SelectedFile", {
        type: "audio",
      });
    },
  };

  documentSeeAllContainer = {
    onPress: () => {
      this.setState({documentsAll: true});
      this.props.navigation.navigate("SelectedFile", {
        type: "application",
      });
    },
  };

  imageAPI = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.indexAPICallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_portfolio_management/items/?file_format=image`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  audioAPI = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.audiosAPICallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_portfolio_management/items/?file_format=audio`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  documentsAPI = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.documentsAPICallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_portfolio_management/items/?file_format=application`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  videosAPI = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.videosAPICallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_portfolio_management/items/?file_format=video`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };


  async componentDidMount() {
    super.componentDidMount();
    this.imageAPI();
    this.videosAPI();
    this.audioAPI();
    this.documentsAPI();
    const token = await getStorageData('authToken');
    const propertyId = await getStorageData('propertyId');
    this.setState({ token: token, propertyId: propertyId}, () => {
      this.getPropertiesData();
      // this.getFilterData();
      this.getPropertiesDetails("","");
      this.getAreaCode();
    })
  }
  handleMainItemMyproClicks = (labelKey: string) => {
    this.setState({ activeMyproperty: labelKey },() => {
      this.handleDashboardMenu()
    });
  
   
  };

  handleCloseEdit = () => {
    this.setState({
      editOpen: false,
      openAmenities: false,
      openDescription: false,
      editContactOpen: false,
      editMainOpen: false,
      openProperty: false,
      openMarketing: false,
      openEditFind: false
    });
  };

  getHelpersText = (fields: string) => {
    const { errors } = this.state;
    switch (fields) {
      case "address":
        return errors.address ? configJSON.addressError : null;
      case "description":
        return errors.description ? configJSON.addressError : null;
      case "phoneNumber":
        return errors.phoneNumber ? configJSON.phoneError : null;
      case "email":
        return errors.email ? configJSON.emailError : configJSON.emailAddress;
      case "firstName":
        return errors.firstName ? configJSON.addressError : null;
      case "lastName":
        return errors.lastName ? configJSON.addressError : null;
      default:
        return null;
    }
  };

  handleTradesInput = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = event.target;
    this.setState({
      addTrades: value,
    } as unknown as Pick<S, keyof S>);
  };

  handleKeysPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      this.handleAddTrade();
    }
  };

  validateTrade = () => {
    if (this.state.vendorTrades.length === 0) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          vendorTrades: true,
        },
      }));
    } else {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          vendorTrades: false,
        },
      }));
    }
  };

  handleAddTrade = () => {
    const newTrades = this.state.addTrades
      .split(",")
      .map((trade) => trade.trim())
      .filter((trade) => trade !== "");

    const combinedTrades = [...this.state.vendorTrades, ...newTrades];

    const uniqueTrades = combinedTrades.filter(
      (item, index) => combinedTrades.indexOf(item) === index
    );

    this.setState({ vendorTrades: uniqueTrades, addTrades: "" }, () => {
      this.validateTrade();
    });
  };

  handleCountsClose = () => {
    this.setState({ isCountryDropdowns: false });
  };

  handleCountryCodesDropdown = () => {
    this.setState({ isCountryDropdowns: true });
  };

  handleCountryChanges = (event: SelectChangeEvent<string>) => {
    this.setState({ areasCode: event.target.value, isCountryDropdowns: false });
  };

  propertyApiCall = async (data: APIPayloadType) => {
    let { contentType, method, endPoint, body, type } = data;
    const token = await storage.get("authToken");
    const header = {
      "Content-Type": contentType,
      token: token
    };

    let requestingMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestingMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestingMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestingMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestingMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        type == "formData" ? body : JSON.stringify(body)
      );

    runEngine.sendMessage(requestingMessage.id, requestingMessage);
    return requestingMessage.messageId;
  };

  getAreaCode = async () => {
    this.getAreaCodeCallId = await this.propertyApiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getAreaCodeApiEndPoint
    });
  };

  handleFolioGuard = (sendEvent: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ folioGuardPolicy: (sendEvent.target as HTMLInputElement).value });
  };

  handleRental = (sendEvent: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ rentalLicense: (sendEvent.target as HTMLInputElement).value });
  };

  handleHomeWarranty = (homeWarranty: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ homeWarranty: (homeWarranty.target as HTMLInputElement).value });
  };

  handleUnity = (unitEntry: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ unitEntry: (unitEntry.target as HTMLInputElement).value });
  };

  handleCatAllowed = (cats: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ catsAllowed: (cats.target as HTMLInputElement).value });
  };

  handleDogsAllowed = (dogs: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ dogsAllowed: (dogs.target as HTMLInputElement).value });
  };

  handleDashboardMenu = () => {
    const { activeMyproperty } = this.state;
    const toMsg = new Message(getName(MessageEnum.NavigationMessage));
    toMsg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      activeMyproperty
    );
    toMsg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(toMsg);
  };

  handleLandlordMyproDrawers = () => {
    this.setState({
      openLandlordDrawermypro: !this.state.openLandlordDrawermypro
    });
  };
  handleClickpro = () => {
    this.setState((prevState) => ({
      isPopupVisible: !prevState.isPopupVisible
    }));
  }
  handleClickblv = (id: string, label: string) => {
     const newSearchItem = {
        id: id, 
        label: label
      };
     this.setState((prevState) => ({
       popupPosition: !prevState.popupPosition, isPopupVisible:false,
       isPopupVisibletwo:false,
       searchData: [...prevState.searchData, newSearchItem]
      }), () => {
        this.getFilterData()
      });
  }

  handleClickOpendialog = (idData: string) => {
    const allUnits = this.state.properties.flatMap((property: any) => property.attributes.units || []);
   
    const selectedUnit = allUnits.filter((unit: any) => {
      return Number(unit.id) === Number(idData);  
    });
  
    if (selectedUnit) {
      this.setState(
        { isOpenDialog: true, keyData: selectedUnit, unitId: Number(idData) },
        () => {
          this.getPropertiesData(); 
        }
      );
    }
  };
  
  
  handleCloseDialog = () =>{
    this.setState({isOpenDialog:false})
  }
  handleCloseopopup = () =>{
    this.setState({isOpenDialog:false})
  }
  handleClickunitfalse = () =>{
    this.setState({  isPopupVisibletwo:false,isPopupVisible:false})
  }

  handleClickOpen = (idData: string, unitName: string) => {
    const foundData = this.state.properties.find((data: PropertyData) => data.id === idData);
    if (foundData) {
      this.setState({ propertyId: foundData.id }, () => {
        this.handleSetAllDetails(foundData);
      });
    }

    this.setState({ isOpen: true, unitsName: unitName }, () => {
      this.getPropertiesInfoData(idData)
      this.handleAttachments(idData);
    });
  }

  handleClose =()=> {
    this.setState({ isOpen: false });
  };
  handleCloseM = () => {
    this.setState({
      anchorEl: null,
      subMenuAnchorEl: null,
      selectedProperty: null,
      selectedUnit: null
    });
  };

  handleDateFormatting = (fomatting: boolean) => {
    return fomatting ? "yes" : "no"
  };

  handleRadioFormatting = (fomatting: string) => {
    return fomatting ==="yes" ? true : false
  };

  handleEditClick = () => {
    this.setState({
      editOpen: true,
      messagePopup: false
    });
  };

  handlePropUnitClick = () => {
    this.setState({
      openProperty: true,
      messagePopup: false
    });
  };

  handleEditMainClick = () => {
    this.setState({
      editMainOpen: true,
      messagePopup: false
    });
  };

  handleBlankDetails = (empty: string) => {
    return empty ? empty : configJSON.slashs;
  };

  handleEditContactClick = () => {
    this.setState({
      editContactOpen: true,
      messagePopup: false
    });
  };

  handleEditAmenitiesClick = () => {
    this.setState({
      openAmenities: true,
      messagePopup: false,
    });
  };

  handleEditMarketingClick = () => {
    this.setState({
      openMarketing: true,
      messagePopup: false
    });
  };

  handleOpenFind = (keyDetails: FindLocation) => {
    this.setState({
      openEditFind: true,
      floorState: keyDetails.floor,
      entranceState: keyDetails.entrance,
      codeState: keyDetails.code
    });
  };

  handleSetAllDetails = (infoData: PropertyData) => {
    this.setState({
      description: infoData.attributes.description,
      portfolio: infoData.attributes.portfolio,
      siteManager: infoData.attributes.site_manager_prefix,
      firstName: infoData.attributes.site_manager_first_name,
      lastName: infoData.attributes.site_manager_last_name,
      yearsBuilt:  new Date(infoData.attributes.year_built),
      classValue: infoData.attributes.property_class,
      managementStartDate:  new Date(infoData.attributes.management_start),
      folioGuardPolicy: this.handleDateFormatting(infoData.attributes.folio_guard_policy),
      rentalLicense: this.handleDateFormatting(infoData.attributes.rental_license),
      renewalDate:  new Date(infoData.attributes.renewal_date),
      propertiesType: infoData.attributes.property_type,
      address: infoData.attributes.address,
      insuranceExpiration:  new Date(infoData.attributes.insurance_expiration),
      homeWarranty: this.handleDateFormatting(infoData.attributes.warranty_coverage),
      unitEntry: this.handleDateFormatting(infoData.attributes.unit_entry_pre_authorized),
      mainNotes: infoData.attributes.maintenance_notes,
      onineMain: infoData.attributes.maintenance_request,
      email: infoData.attributes.vacancy_posting_email_address,
      phoneNumber: infoData.attributes.vacancy_posting_phone_number,
      areasCode: infoData.attributes.vacancy_posting_area_code,
      catsAllowed: this.handleDateFormatting(infoData.attributes.cats_allowed),
      dogsAllowed: this.handleDateFormatting(infoData.attributes.dogs_allowed),
      propertyMarkName: infoData.attributes.property_marketing_name,
      marketDesc: infoData.attributes.marketing_description,
      lising: infoData.attributes.lising_type,
      vendorTrades: infoData.attributes.amenities
    });
  };

  updatePropertyDetails = async (formData: FormData) => {
    this.updatePropertyInfoApiCallId = await this.propertyApiCall({
      method: configJSON.updateApiMethodType,
      endPoint: configJSON.putApiEndPoint,
      body: formData,
      type: "formData"
    });
  };

  handleUpdatedSuccess = () => {
    this.setState({
      messagePopup: true,
      messageForPopup: "Successfully updated!",
      messageType: "success"
    });
  };

  handleSubmitUpdatedDetails = () => {
    const { propertyId, errors, classValue, propertyMarkName, onineMain, vendorTrades, rentalLicense, unitEntry,mainNotes, marketDesc,email, areasCode, phoneNumber,catsAllowed, dogsAllowed, lising, homeWarranty, renewalDate, insuranceExpiration, folioGuardPolicy, managementStartDate, propertiesType, address, siteManager, firstName, lastName, description, portfolio, yearsBuilt } = this.state;
    const hasErrors = Object.values(errors).some((error) => error);
    if (hasErrors) {
      this.setState({
        messageForPopup: "Please fix the errors before saving.",
        messagePopup: true,
        messageType: "error"
      });
      return;
    }
    const formdata = new FormData();
    formdata.append("property[id]", propertyId);
    formdata.append("property[property_type]", propertiesType);
    formdata.append("property[address]", address);
    formdata.append("property[site_manager_prefix]", siteManager);
    formdata.append("property[site_manager_first_name]", firstName);
    formdata.append("property[site_manager_last_name]", lastName);
    formdata.append("property[description]", description);
    formdata.append("property[portfolio]", portfolio);
    formdata.append("property[year_built]", yearsBuilt ? yearsBuilt.toISOString() : "");
    formdata.append("property[property_class]", classValue);
    formdata.append("property[management_start]", managementStartDate ? managementStartDate.toISOString() : "");
    formdata.append("property[folio_guard_policy]", folioGuardPolicy);
    formdata.append("property[rental_license]", rentalLicense);
    formdata.append("property[renewal_date]", renewalDate ? renewalDate.toISOString() : "");
    formdata.append("property[insurance_expiration]", insuranceExpiration ? insuranceExpiration.toISOString() : "");
    formdata.append("property[warranty_coverage]", homeWarranty);
    formdata.append("property[unit_entry_pre_authorized]", unitEntry);
    formdata.append("property[maintenance_notes]", mainNotes);
    formdata.append("property[maintenance_request]", onineMain);
    formdata.append("property[vacancy_posting_email_address]", email);
    formdata.append("property[vacancy_posting_area_code]", areasCode);
    formdata.append("property[vacancy_posting_phone_number]", phoneNumber);
    formdata.append("property[cats_allowed]", catsAllowed);
    formdata.append("property[dogs_allowed]", dogsAllowed);
    for(let vendors of vendorTrades ){
      formdata.append("property[amenities][]", vendors);
    }
    formdata.append("property[property_marketing_name]", propertyMarkName);
    formdata.append("property[marketing_description]", marketDesc);
    formdata.append("property[lising_type]", lising);
      this.updatePropertyDetails(formdata);
  };

  handleFindLocation = async (formData: Object)=> {
    this.updateFindKeyApiCallId = await this.propertyApiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.putAPiMethod,
      body: formData,
      endPoint: `${configJSON.editFindKeyApiEndPoint}${this.state.unitId}`
    });
  };

  handleSubmitFindLocation = () => {
    const formData = {
      unit: 
        {
        floor: this.state.floorState,
        entrance: this.state.entranceState,
        code: this.state.codeState
        }
    };
    this.handleFindLocation(formData);
  };

  handleSaveDetails = () => {
    const { propertyId, classValue, propertyMarkName, onineMain, vendorTrades, rentalLicense, unitEntry, mainNotes, marketDesc, email, areasCode, phoneNumber, catsAllowed, dogsAllowed, lising, homeWarranty, renewalDate, insuranceExpiration, folioGuardPolicy, managementStartDate, propertiesType, address, siteManager, firstName, lastName, description, portfolio, yearsBuilt } = this.state;
    const updatedPropertyData = {
      property_type: propertiesType,
      address,
      site_manager_prefix: siteManager,
      site_manager_first_name: firstName,
      site_manager_last_name: lastName,
      description,
      portfolio,
      year_built: yearsBuilt ? yearsBuilt.toISOString() : "",
      property_class: classValue,
      management_start: managementStartDate ? managementStartDate.toISOString() : "",
      folio_guard_policy: this.handleRadioFormatting(folioGuardPolicy),
      rental_license: this.handleRadioFormatting(rentalLicense),
      renewal_date: renewalDate ? renewalDate.toISOString() : "",
      insurance_expiration: insuranceExpiration ? insuranceExpiration.toISOString() : "",
      warranty_coverage: this.handleRadioFormatting(homeWarranty),
      unit_entry_pre_authorized: this.handleRadioFormatting(unitEntry),
      maintenance_notes: mainNotes,
      maintenance_request: onineMain,
      vacancy_posting_email_address: email,
      vacancy_posting_area_code: areasCode,
      vacancy_posting_phone_number: phoneNumber,
      cats_allowed: this.handleRadioFormatting(catsAllowed),
      dogs_allowed: this.handleRadioFormatting(dogsAllowed),
      amenities: vendorTrades,
      property_marketing_name: propertyMarkName,
      marketing_description: marketDesc,
      lising_type: lising,
    };
    this.setState((prevState) => ({
      propertiInfoData: prevState.propertiInfoData.map((infoData) =>
        infoData.id === propertyId
          ? { ...infoData, attributes: { ...infoData.attributes, ...updatedPropertyData } }
          : infoData
      )
    }));
  };

  handleSaveFindKeys = () => {
    const { floorState, entranceState, codeState, unitId } = this.state;
    const allUnits = this.state.properties.flatMap((property: PropertyData) => property.attributes.units || []);
    const selectedUnit = allUnits.find((unit: Unit) => Number(unit.id) === unitId);
    if (selectedUnit) {
      const updateKey = {
        floor: floorState,
        entrance: entranceState,
        code: codeState
      };
      this.setState({
        keyData: [updateKey]
      },()=>{
        this.getPropertiesData();
      });
    };
  };

  handleChipDelete = (trade: string) => {
    const updatedTrade = this.state.vendorTrades.filter(
      (item) => item !== trade
    );
    this.setState({ vendorTrades: updatedTrade });
  };

  handleDatePicker = (key: keyof S) => (newValue: Date | null) => {
    this.setState({ [key]: newValue } as unknown as Pick<S, keyof S>);
  };

  handleChanges = (
    events: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = events.target;
    this.setState({
      [name]: value,
    } as unknown as Pick<S, keyof S>);
    if (name === "address") {
      this.validatingName(value);
    }else if (name === "phoneNumber") {
      this.validatingPhone(value);
    }else if (name === "description") {
      this.validatingDescription(value);
    }else if (name === "firstName") {
      this.validatingFirstName(value);
    }else if (name === "lastName") {
      this.validatingLastName(value);
    }else if (name === "email") {
      this.validatingEmail(value);
    }
  };

  handlePopupsClose = () => {
    this.setState({
      messagePopup: false
    });
  };

  validatingName = (name: string) => {
    if (name.length>30) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          address: true,
        },
      }));
    } else {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          address: false,
        },
      }));
    }
  };

  validatingDescription = (descriptions: string) => {
    if (descriptions.length>30) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          description: true,
        },
      }));
    } else {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          description: false,
        },
      }));
    }
  };

  validatingFirstName = (firstName: string) => {
    const nameReg = configJSON.nameRegax
    if (!nameReg.test(firstName)) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          firstName: true,
        },
      }));
    } else {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          firstName: false,
        },
      }));
    }
  };

  handleSplitting = (fetchedData: Array<string>) => {
    return fetchedData.join(",")
  };

  validatingLastName = (lastName: string) => {
    const nameReg = configJSON.nameRegax
    if (!nameReg.test(lastName)) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          lastName: true,
        },
      }));
    } else {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          lastName: false,
        },
      }));
    }
  };

  validatingPhone = (phone: string) => {
    const phoneRegex = configJSON.phoneRegax
    if (!phoneRegex.test(phone)) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          phoneNumber: true,
        },
      }));
    } else {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          phoneNumber: false,
        },
      }));
    }
  };

  validatingEmail = (emails: string) => {
    const emailRegex = configJSON.emailsRegex
    if (!emailRegex.test(emails)) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          email: true,
        },
      }));
    } else {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          email: false,
        },
      }));
    }
  };

  setPortfolio = (portfolio: string) => {
    this.setState({
      portfolio: portfolio
    });
  };

  setLisingType = (lising: string) => {
    this.setState({
      lising: lising
    });
  };

  setSiteManager = (siteManager: string) => {
    this.setState({
      siteManager: siteManager
    });
  };

  setPropertyType = (propertiesType: string) => {
    this.setState({
      propertiesType: propertiesType
    });
  };

  handleUploadDocuments = () => {
    this.setState({
      openUploads: true
    });
  };

  handleUploadDocumentsClose = () => {
    this.setState({
      openUploads: false,
      uploadDocuments: []
    });
  };

  handleAttachmentChange = (name: string, files: FileWithPreview[]) => {
    this.setState({
      [name]: files,
    } as unknown as Pick<S, keyof S>);
  };

  handleCheck = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { name, checked } = event.target;
    this.setState((prevState) => ({
      checkboxDoc: {
        ...prevState.checkboxDoc,
        [index]: {
          ...prevState.checkboxDoc[index],
          [name]: checked === true ? "1" : "0",
        },
      },
    }));
  };

  handleFiles = () => {
    const { uploadDocuments, checkboxDoc, propertyId } = this.state;
    if(uploadDocuments.length>0){
    const formData = new FormData();
    formData.append("id", propertyId);
    uploadDocuments.forEach((file, index) => {
      formData.append(`property[attachments][${index}][file]`, file, file.name);

      formData.append(
        `property[attachments][${index}][share_with_tenants]`,
        checkboxDoc[index]?.sharewithtenants ? "1" : "0"
      );
      formData.append(
        `property[attachments][${index}][share_with_owners]`,
        checkboxDoc[index]?.sharewithowners ? "1" : "0"
      );
    });
    this.handleUploading(formData);
    } else {
    this.setState({
      messagePopup: true,
      messageType: "error",
      messageForPopup: "Please select attachments!"
    });
   }
  };  

  handleUploading = async (formData: FormData) => {
    this.uploadAttachmentsAPiCallId = await this.propertyApiCall({
      method: configJSON.exampleAPiMethod,
      endPoint: configJSON.uploadAttachmentApiEndPoint,
      body: formData,
      type: "formData"
    });
  };

  handleAttachments = async (idData: string) => {
    let propertyId = Number(idData);
    this.getAttachmentsAPiCallId = await this.propertyApiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.getAttachmentApiEndPoint}${propertyId}&page_no=${this.state.currentPage}&limit=5`,
    });
  };

  handleChangePagination = (value: object, page: number) => {
    this.setState({
      currentPage: page,
      successLoader: true,
      valuesOfKey: value
    }, () => {
      this.handleAttachments(this.state.propertyId);
    });
  };

  handlePagination = () => {
    const surveyHistoryListRespFull = this.state.showAllAttachments;
    if (surveyHistoryListRespFull.length < 1) {
      this.setState(prevState => ({
        currentPage: Math.max(1, prevState.currentPage - 1)
      }));
    }
  };

  handleDeleteAttachments = async (fileId: number) => {
    const rawData = {
      id: this.state.propertyId,
      attachment_id: fileId.toString()
    };

    this.deleteAttachmentApiCallId = await this.propertyApiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.deleteAPiMethod,
      endPoint: configJSON.deleteApiEndPoint,
      body: rawData
    });
  };

  handleUpdateBy = (fileSize: string) => {
    return fileSize.length > 10 ? fileSize.substring(0, 10) + "..." : fileSize;
  };

  handlePreview = (fileUrl: string) => {
    window.open(fileUrl, "_blank")
  };

  setClassType = (classType: string) => {
    this.setState({
      classValue: classType
    });
  };

  handleAddTenant = (status:any,props:any, screenName:any, id:any, propertyId:any ) => {
    if(status === "Occupied"){
      this.setState({
        messagePopup: true,
        messageType: "error",
        messageForPopup: "Reached maximum number of occupants, No more tenants can be added!"
      })
    } else{
      this.navigateTo({ props:props, screenName:screenName, id:id, propertyId:propertyId} )
    }
  }
  handlePopupClose = () => {
    this.setState({
      messagePopup: false
    });
  };
  navigateTo = async ({
    id,
    props,
    screenName,
    raiseMessage,
    propertyId,
    tenantId
    }: INavigateTo) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), props);
    message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
    id && message.addData(getName(MessageEnum.NavigationScreenNameMessage), id);
    raiseMessage &&
      message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    runEngine.sendMessage(message.id, message);
    if(propertyId){
      await storage.set("propertyId",propertyId)
    }
    if(tenantId){
      await storage.set("tenantId",tenantId)
    }
    }
    
    getPropertiesData = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProperties = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProperty
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getSearchPropertiesData = () => {
    
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSearchProperties = requestMessage.messageId;

    const endpoint = this.state.searchQuery.length > 0
      ? `${configJSON.getPropertySearch}?property[]=${this.state.searchQuery}`
      : configJSON.getPropertySearch;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
   
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

  }

  formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const monthName = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
  
    return `${day} ${monthName} ${year}`;
  };

  formatYear = (yearString: string) => {
    const date = new Date(yearString);
    const year = date.getFullYear();
  
    return `${year}`;
  };

  handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    const propData = this.state.filterData.map((data:PropertyData)=> data.attributes.property_name)
    const search = propData.find((data: string) => data === query)
    this.setState({ searchQuery: query, queryData: search }, () => {
      this.getSearchPropertiesData();
    });
  };

  getFilterData = () => {
    const params = new URLSearchParams();

    this.state.searchData.forEach((query: PropertyLabel) => {
      params.append('property[]', query.label);
    });

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSearchProperties = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPropertySearch}?${params.toString()}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
   
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleDelete = (keyId: string) => {
    const chipId = this.state.searchData.filter((dataId: PropertyLabel) => {
      return dataId.id !== keyId
    })
    this.setState({ searchData: chipId}, () => {
      this.getFilterData()
    })
  }

  handleAllDelete = () => {
    this.setState({ searchData: []}, () => {
      this.getFilterData()
    })
  }

  getPropertiesInfoData = (idData: string) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPropertiesInfo = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPropertyInfo}?property_id=${idData}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleCloseUnit =()=> {
    this.setState({ isUnitOpen: false, unitName:"", maxOccupant: "" });
  }

  handleClickUnit = (propertyId: any) => {
    this.setState({ isUnitOpen: true,propertyId: propertyId})
  }

  onValueChange = (name: any, value: any) => {
    this.setState({ ...this.state, [name]: value  });
  }

  handleAdd = () => {
  
  this.setState({ isUnitOpen: false, unitName: "", maxOccupant: "" });

   const bodyData = {
      "unit": 
        {
        "property_id": this.state.propertyId,
        "status": "Vacant",
        "name": this.state.unitName,
        "number_of_occupants": this.state.maxOccupant,
        "floor": this.state.floorState,
        "entrance": this.state.entranceState,
        "code": this.state.codeState,
        "location_notes": this.state.locationState,
        },
    }

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token:this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postUnit = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postUnit
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
}

handleClick = (number: string) => {
  this.handleDelete(number)
}
handleProperty = (properties: any) => {
  return properties.length > 0 ? "" : "isUnitsDisabled";
};

handleEmptyProperty = (properties: any) => {
  return properties.length > 0 ? "isUnitsDisabled" : "";
};
generateChipsParam = (): string => {
  const { chips } = this.state;
  return chips.map(chip => `${chip.propertyId}:${chip.unitId}`).join(',');
};

clearChips = () => {
  this.setState({
    chips: []
  }, () => {
    this.getPropertiesDetails("", "");
  });
};
workOrderApiCall = async (data: any) => {
  let { contentType, method, endPoint, body ,type } = data;
  const token = await storage.get("authToken");
  const header = {
    "Content-Type": contentType,
    token: token
  };

  let requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    endPoint
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    method
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  body &&
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    type == "formData" ? body:JSON.stringify(body)
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return requestMessage.messageId;
};
getPropertiesDetails = async (propertiesId: string, searchParams: string) => {
  let endPointdata = configJSON.propertiesApiEndPoint;
  const chipsParam = this.generateChipsParam();
  
  const { chips } = this.state;
    const propertyIds = chips.map(chip => chip.propertyId).join(',');
    const unitIds = chips.map(chip => chip.unitId).join(',');

  if (propertyIds) {
    endPointdata = `${configJSON.propertiesApiEndPoint}/search_filter?property_ids=${propertyIds}&unit_ids=${unitIds}`;  
  } else if (this.state.searchBar) {
    endPointdata = `${configJSON.propertiesApiEndPoint}?search_query=${this.state.searchBar}&${searchParams}=true&property_id=${propertiesId}`;
  }
  this.getPropertiesApiCallId = await this.workOrderApiCall({
    contentType: configJSON.exampleApiContentType,
    method: configJSON.validationApiMethodType,
    endPoint: endPointdata
  });
};
handleSortRequest = (property: string, direction: "asc" | "desc", unitIndex: number) => {
  this.setState(prevState => ({
    sortState: {
      ...prevState.sortState,
      [unitIndex]: {
        sortColumn: property,
        sortDirection: direction
      }
    }
  }));
};

sortData = (newData: any, unitIndex: number) => {
  const { sortState } = this.state;
  const { sortColumn, sortDirection } = sortState[unitIndex] || {};
  return [...newData].sort((sortingA: any, sortingB: any) => {
    const aValue = sortingA[sortColumn as keyof any];
    const bValue = sortingB[sortColumn as keyof any];
    if (bValue === null || bValue === undefined) return -1;
      if (typeof aValue === "string" && typeof bValue === "string") {
      return aValue.localeCompare(bValue) * (sortDirection === "asc" ? 1 : -1);
    } else if (typeof aValue === "number" && typeof bValue === "number") {
      return (aValue - bValue) * (sortDirection === "asc" ? 1 : -1);
    } else {
      return 0;
    }
  });
};
handleDetailsContainer = (unitDetails: any) => {
  return unitDetails.length > 0 ? "" : "isUnitsDisabled";
};
priorityStyleHandler = (status: string) => {
  let styleObjPriority = {
    in_debt: {
      color: "#DC2626",
      background: "#FEE2E2",
      borderColor: "#FEE2E2"
    },
    delinquent : {
      color: "#DC2626",
      background: "#FEE2E2",
      borderColor: "#FEE2E2"
    },
    evicting: {
      background: "#D1FAE5",
      color: "#059669",
      borderColor: "#D1FAE5"
    },
    active: {
      background: "#FEF3C7",
      color: "#D97706",
      borderColor: "#FEF3C7"
    },
    occupied: {
      color: "#DC2626",
      background: "#FEE2E2",
      borderColor: "#FEE2E2"
    },
    vacant: {
      background: "#D1FAE5",
      color: "#059669",
      borderColor: "#D1FAE5"
    },
  }
  switch (status) {
    case "In Debt":
      return styleObjPriority.in_debt
    case "Delinquent":
      return styleObjPriority.delinquent
    case "delinquent":
      return styleObjPriority.delinquent
    case "Evicting":
      return styleObjPriority.evicting
    case "Active":
      return styleObjPriority.active
    case "Occupied": 
      return styleObjPriority.occupied  
    case "Vacant":
      return styleObjPriority.vacant  
    default:
      return styleObjPriority.vacant
  }
};
toggleDescription = (description: string | null) => {
  const charLimit = 11;
  if (description) {
    const truncatedDescription = description.length > charLimit && !this.state.openDescription
      && description.substring(0, charLimit);
    return truncatedDescription;
  }
  return "-";
};
handleDescription = (descriptions: string | null) => {
  this.setState({
    modalDescription: descriptions
  }, () => {
    this.handleModalDescription();
  });
};

handleModalDescription = () => {
  this.setState({
    openDescription: !this.state.openDescription
  });
};
handleMoreDetails = (descriptions: string | null) => {
  if (descriptions) {
    return this.state.openDescription ? configJSON.readLess : configJSON.readMore;
  }
  return "";
};
handleExpandClick = (rowId: number) => {
  this.setState((prevState) => ({
    expanded: prevState.expanded === rowId ? null : rowId
  }));
};
handleEmptyContainer = (unitDetails: any) => {
  return unitDetails.length > 0 ? "isUnitsDisabled" : "";
};
handleModalClose = () => {
  this.setState({
    openFinKey: !this.state.openFinKey
  });
};
handlePropertySlice = () =>{
  return !this.state.showAllItems && this.state.originalProperties.length > 4;
};

handlePropertyData = () =>{
  return this.state.originalProperties.slice(0, this.state.showAllItems ? this.state.originalProperties.length : 4);
};
handlePropertyClick = (propEvent: React.MouseEvent<HTMLElement>, property: any, indexItem: number) => {
  this.setState({
    selectedProperty: property,
    subMenuAnchorEl: propEvent.currentTarget,
    selectedMenuItemIndex: indexItem,
    propertiesName: property.attributes.property_name
  });
};
handleChangeColor = (itemIndex: number) =>{
  return this.state.selectedMenuItemIndex === itemIndex ? "changeColor" : "";
};
toggleShowAll = () => {
  this.setState(prevState => ({
    showAllItems: !prevState.showAllItems
  }));
};
handleCloseMenu = () => {
  this.setState({ subMenuAnchorEl: null });
};
handleUnitClick = (unit: string, unitId: number) => {
  const { selectedProperty, chips } = this.state;

  if (selectedProperty) {
    const newChip = {
      propertyId: parseInt(selectedProperty.id),
      unitId: unitId,
      propertyName: selectedProperty.attributes.property_name,
      unitName: unit
    };

    const chipExists = chips.some(
      chip => chip.propertyId === newChip.propertyId && chip.unitId === newChip.unitId
    );

    if (!chipExists) {
      this.setState((prevState) => ({
        chips: [...prevState.chips, newChip],
        selectedUnit: unit,
        anchorEl: null,
        subMenuAnchorEl: null,
        selectedProperty: null,
        messagePopup: false
      }), () => {
        this.getPropertiesDetails("", "");
      });
    } else {
      this.setState({
        messageForPopup: configJSON.filteredMessage,
        messageType: configJSON.errorMessage,
        messagePopup: true
      });
    }
  }
};
handleHideDetails = () => {
  return this.state.openLoader ? "isUnitsDisabled" : "";
};
handleSearchBar = (searchEvent: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  let searchBar = searchEvent.target.value;
  const maximumLength = 30;
  if (searchBar.length > maximumLength) {
    this.setState({
      messageForPopup: configJSON.searchsError,
      messageType: configJSON.errorMessage,
      messagePopup: true,
    })
  } else {
    this.setState({
      searchBar: searchBar
    }, () => {
      this.getPropertiesDetails("", "")
    });
  };

};
handleProperClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  this.setState({ anchorEl: event.currentTarget }, () => {
    this.getPropertiesDetails("", "");
  });
};
removeChip = (index: number) => {
  this.setState((prevState) => {
    const chips = [...prevState.chips];
    chips.splice(index, 1);
    return { chips };
  }, () => {
    this.getPropertiesDetails("", "");
  });
};
handleWorkOrders(message: Message) {
  if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (!responseJson.errors && responseJson) {
      this.apiSuccessCallBacks(apiRequestCallId, responseJson);
    } else if (responseJson && responseJson.errors) {
      this.apiFailCallBack(apiRequestCallId, responseJson);
    }
  }
};
apiSuccessCallBacks = (apiRequestCallID: string, responseJson: any) => {
  if (apiRequestCallID === this.getPropertiesApiCallId) {
    this.setState({
      properties: responseJson.data,
      openLoader: false,
      originalProperties: responseJson.data,
      successLoader: false
    },()=>{
      this.handleCloseEdit();
    });
  }
  if (apiRequestCallID === this.getAreaCodeCallId) {
    let countriesCode = responseJson.data.map((items: AreaCode) => {
      return {
        country_code: items.attributes.country_code,
        emoji_flag: items.attributes.emoji_flag,
        name: items.attributes.name,
      };
    });

    this.setState({ countriesCode });
  }

  if (apiRequestCallID === this.updatePropertyInfoApiCallId) {
    this.setState({
      successLoader: true,
    },()=>{
      this.handleUpdatedSuccess();
      this.handleSaveDetails();
      this.getPropertiesDetails("","");
      this.handleChipDelete("");
    });
  }

  if (apiRequestCallID === this.uploadAttachmentsAPiCallId) {
    this.setState({
      successLoader: true,
      messagePopup: true,
      messageType: "success",
      messageForPopup: "Attachment uploaded successfully!",
    }, () => {
      this.handleAttachments(this.state.propertyId);
      this.handleUploadDocumentsClose();
    });
  }

  if (apiRequestCallID === this.getAttachmentsAPiCallId) {
    this.setState({
      showAllAttachments: responseJson.attachments,
      currentPage: responseJson.pagination.current_page,
      totalPages: responseJson.pagination.total_pages,
      totalCount: responseJson.pagination.total_count,
      successLoader: false
    }, () => {
      this.handlePagination();
    });
  }

  if (apiRequestCallID === this.deleteAttachmentApiCallId) {
    this.setState({
      successLoader: false,
      messagePopup: true,
      messageForPopup: "Attachment deleted successfully!",
    }, () => {
      this.handleAttachments(this.state.propertyId);
    });
  }

  if (apiRequestCallID === this.updateFindKeyApiCallId) {
    this.setState({
      openEditFind: false
    }, () => {
      this.handleUpdatedSuccess();
      this.handleSaveFindKeys();
      this.getPropertiesDetails("","");
    });
  }
};
apiFailCallBack = (apiRequestCallID: string, responseJSON: any) => {
  if (apiRequestCallID === this.getPropertiesApiCallId) {
    this.setState({
      messagePopup: true,
      messageForPopup: responseJSON.errors,
      messageType: "error"
    });
  }
};
handleDownload = async (pdfUrl: string) => { 
  try {
    await new JsFileDownloader({
      url: pdfUrl,
    });
  } catch (error) {
    alert("Failed to download file");
  }
};  

}

// Customizable Area End
