import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  seeProjectDetails: boolean;
  headers: string[];
  dataProperties: string[];
  workOrderAudits: any[];
  documents: any[];
  projectDetailsRoute: string;
  orderId: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  auditTrailData: AuditTrailDataItem;
  token: string;
  workOrderAudits: any[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}
export interface AuditTrailDataItem {
  // Customizable Area Start
  id: string;
  type: string;
  attributes: {
    action: string;
    account_id: null | string | number;
    auditable_type: string;
    auditable_id: null | string| number;
    new_data: {name:string};
    previous_data: {name:string};
    created_at: string;
    updated_at: string;
    account_holder_name: string;
  };
  // Customizable Area End
}
export default class AuditTrailDetailController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getAuditDataId = "";
  // Customizable Area End
 
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];
    // Customizable Area Start
    this.state = {
      token:'',
      auditTrailData:{
        id: "4",
        type: "audit",
        attributes: {
          action: "Testing",
          account_id: 1,
          auditable_type: "Catalogue",
          auditable_id: 1,
          new_data: {
            name: "Fruite",
          },
          previous_data: {
            name: "Apple",
          },
          created_at: "2023-05-01 08:09:59",
          updated_at: "2023-05-01 08:09:59",
          account_holder_name:'abc',
        },
      },
      workOrderAudits: this.props.workOrderAudits,
    // Customizable Area End

    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
  
  
  
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
   
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const responseData = message.getData(
        getName(MessageEnum.SessionResponseData),
      );
      if (responseData?.auditID) {
            const response = await getStorageData("loginData")
            if(response){ 
                this.setState({token:response})
          }
          this.getAuditDetail(responseData?.auditID);
      } 
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.getAuditDataId) {
        if(responseJson){
          this.setState({ auditTrailData: responseJson.data});  
        }
        if(message.messageId===errorReponse){
          this.parseApiErrorResponse(errorReponse);
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }

    }
    // Customizable Area End
  }


  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    let auditID= null;
    if(this.isPlatformWeb()){
      auditID = await getStorageData('auditID');
    }
  }

  //function to get audittrail data
  getAuditDetail = (auditID:number) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token:this.state.token
    };
    let endPoint = "/"+ auditID;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAuditData}${endPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    this.getAuditDataId = requestMessage.messageId;    
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleSortRequest = (property: string, order: string) => {
    const sorted = this.props.workOrderAudits.sort((a, b) => {
      if (a[property] < b[property]) {
        return order === "asc" ? -1 : 1;
      }
      if (a[property] > b[property]) {
        return order === "asc" ? 1 : -1;
      }
      return 0;
    });
    this.setState({ workOrderAudits: sorted});
  };

  handleProjectDetailsClick = () => {
    const orderId = this.props.orderId
    this.props.navigation.navigate(this.props.projectDetailsRoute, {orderId});
  }

  // Customizable Area End
}
