import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../../packages/framework/src/Utilities";
import { AlertColor } from "@mui/lab";
export interface Transaction {
  id: number;
  ledger_type: string;
  amount: number;
  reference_number: string;
  details: string;
  transaction_status: string;
  transaction_date: string;
  property_id: number;
  property_name: string;
  unit_id: number;
  unit_name: string;
  sender:string;
}
interface Property {
  property_id: string,
  unit_id: number,
  name: string,
  tenants: any[],
  vendors: any[]
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  activeOrderItem: string;
  openWorkOrder: boolean;
  selectedDescriptions: string[];
  anchorElement: { [key: number]: HTMLElement | null };
  cardList: any;
  expanded: number | null;
  messageOpen: boolean;
  messageType: AlertColor;
  message: string;
  selectedTransactions: number[], 
  numSelected: number,
  rowCount: number,
  anchorNewElement: { [key: number]: HTMLElement | null };
  openFinKey: boolean;
  prizingDetails: any;
  action: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class StripeTestController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  searchtApiCallId:string ="";
  multipleReversePaymentId: string = "";
  multipleCompletePaymentId:string="";
  getCardlistCallId: string = "";
  getPrizingDetailsCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      activeOrderItem: "Cfdocumentforwarding4",
      openWorkOrder: false,
      selectedDescriptions:[],
      anchorElement: {},
      cardList: null,
      numSelected: 0,
      rowCount: 0,
      selectedTransactions:[], 
      expanded: null,
      messageOpen: false,
      messageType: "success",
      message: "",
      anchorNewElement: {},
      openFinKey: false,
      prizingDetails: {},
      action: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    ); 
    if (apiRequestCallId === this.getCardlistCallId) {
      
      if (!responseJson.errors && responseJson) {
        this.setState({
          cardList:responseJson.payment_methods
         });
      } else if (responseJson && responseJson.errors) {
        
        alert(responseJson.errors)
      }
      };
      if (apiRequestCallId === this.getPrizingDetailsCallId) {
        if (!responseJson.errors && responseJson) {
          this.setState({
            prizingDetails:responseJson
           });
        } else if (responseJson && responseJson.errors) {
          this.setState({
            messageOpen: true,
            messageType: "error",
            message: responseJson.errors
          });
        }
        };
    // Customizable Area End
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount() { 
    const ids = JSON.parse(await getStorageData("DocumentIds"));
    const action = await getStorageData("ActionType")
    this.setState({
      action: action
    })
    this.getCardlist();
    this.getPrizingDetails(ids, action);
  }
  getCardlist = async () => {
    const token = await getStorageData("authToken")
    const header = {
      "Content-Type": "application/json",
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCardlistCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCardlistAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleWorkOrderMenu = () => {
    const { activeOrderItem } = this.state;
    const toMsgWork = new Message(getName(MessageEnum.NavigationMessage));
    toMsgWork.addData(
      getName(MessageEnum.NavigationTargetMessage),
      activeOrderItem
    );
    toMsgWork.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(toMsgWork);
  };
  handleWordDrawer = () => {
    this.setState({
      openWorkOrder: !this.state.openWorkOrder
    });
  };
  handleSideNav = (navKey: string) => {
    this.setState({ activeOrderItem: navKey }, () => {
      this.handleWorkOrderMenu();
    });
  };
  handlePopupMessageClose = () => {
    this.setState({
      messageOpen: false,
    });
  };
  getPrizingDetails = async (ids: any, action: any) => {
    let endpoint; 
    const body = {
      ids: ids,
    }
  
  if (action === "forward") {
    endpoint = configJSON.getForwardingPrizeAPIEndPoint;
  } else if (action === "scan") {
    endpoint = configJSON.getScanningPrizeAPIEndPoint;
  }
    
    const token = await getStorageData("authToken")
      const header = {
        "Content-Type": "application/json",
        token: token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.getPrizingDetailsCallId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endpoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.confirmPaymentMethod
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  navigateToAppointments = () => {
    this.props.navigation.goBack();
  };
  // Customizable Area End
}
