Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "automaticreminders";
exports.labelBodyText = "automaticreminders Body";

exports.btnExampleTitle = "CLICK ME";

exports.getTechnicianTaskListApiContentType = "application/json";
exports.getTechnicianTaskListApiMethod = "GET";
exports.getTechnicianTaskListApiEndPoint = "/bx_block_automatedprioritisation/priority_tasks/all_task";

exports.getCategoryListApiContentType = "application/json";
exports.getCategoryListApiMethod = "GET";
exports.getCategoryListApiEndPoint = "/bx_block_automatedprioritisation/service_categories";

exports.getSubCategoryListApiContentType = "application/json";
exports.getSubCategoryListApiMethod = "GET";
exports.getSubCategoryListApiEndPoint = "/bx_block_automatedprioritisation/service_sub_categories";

exports.notificationApiContentType = "application/json";
exports.notificationApiMethod = "GET";
exports.notificationApiEndPoint = "/bx_block_automaticreminders/task_reminders/task_assign_notification";
exports.startNotificationApiEndPoint = "/bx_block_automaticreminders/task_reminders/task_started_notification";

exports.changeTaskApiMethod = "GET";
exports.changeTaskApiEndPoint = "/bx_block_automatedprioritisation/priority_tasks/starting_task";

exports.completedTaskApiMethod = "GET";
exports.completedTaskEndPoint = "/bx_block_automatedprioritisation/priority_tasks/completed_task";

exports.getTechnicianListApiMethod = "GET";
exports.getTechnicianListApiContentType = "application/json";
exports.getTechnicianListApiEndPoint = "/account_block/accounts?type=technician";

exports.completeTaskApiContentType = "application/json";
exports.completeTaskApiMethod = "GET";
exports.completeTaskApiEndPoint = "/bx_block_automaticreminders/task_reminders/task_completed_notification";

exports.editTaskApiContentType = "application/json";
exports.editTaskApiMethod = "PUT";
exports.editTaskApiEndPoint = "/bx_block_repeatingtasks/tasks";

exports.getTaskListApiContentType = "application/json";
exports.getTaskListApiMethod = "GET";
exports.getTaskListApiEndPoint = "/bx_block_automatedprioritisation/priority_tasks/all_task";

exports.addTaskApiContentType = "application/json";
exports.addTaskApiMethod = "POST";
exports.addTaskApiEndPoint = "/bx_block_repeatingtasks/tasks";

exports.deleteTaskApiContentType = "application/json";
exports.deleteTaskApiMethod = "PUT";
exports.deleteTaskApiEndPoint = "/bx_block_repeatingtasks/tasks";

exports.dashboardOrder = "Dashboard";
exports.myAccountOrder = "My Account";
exports.workOrder = "Work Orders";
exports.preferredOrder = "Preferred Vendors";
exports.remindersOrder = "Reminders";
exports.mypropOrder = "My Properties";
exports.financiaOrder = "Financial";
exports.reportOrder = "Delinquency Report";
exports.document = "Documents"
exports.chatOrder = "Chat with Keasy";
exports.settingOrder = "Settings";
exports.dashboardOrderlabel = "Dashboard";
exports.myAccountOrderabel = "landlordaccount";
exports.worksOrderlabel = "Appointments";
exports.prevendorOrderlabel = "TaskList";
exports.reminOrderlabel = "AutomatedPrioritization";
exports.mypropOrderlabel = "PortfolioManagement";
exports.financOrderlabel = "Analytics";
exports.reportOrderlabel = "VisualAnalytics";
exports.documentlabel = "Cfdocumentforwarding4"
exports.chatOrderlabel = "Chat";
exports.settingOrderlabel = "Settings2";
exports.propertiesHead = "Properties";
exports.newWorkOrders = "New work order";
exports.appointmentAPiEndPoint = "bx_block_automaticreminders/reminders";
exports.calendar = "Calendar";
exports.filtersHead = "Filters";
exports.clearFilters = "Clear all";
exports.headNames = "12431 BLV";
exports.headMainContent = "144 39th st. Bellevue WA 98005";
exports.viewCompleted = "View completed";
exports.viewRejected = "View rejected";
exports.viewFuturereminders= "View future reminders";
exports.scheduledDate = "Scheduled date";
exports.waitingBill = "Waiting for bill";
exports.approve = "Approve";
exports.findKeys = "Find keys";
exports.goToProperty = "Go to property";
exports.addReminder = "Add reminder";
exports.selectedOrderName = "orders selected";
exports.occupiedName = "OCCUPIED";
exports.unitsName = "Unit 1";
exports.selectedCount = "No";

exports.idName = "ID";
exports.createdName = "Created by";
exports.categoryName = "Category";
exports.SelectedVendor = "Selected vendor";
exports.workNeedName = "Work needed";
exports.resolvedOn = "Resolved on";
exports.assignee = "Assignee";
exports.statusName = "Status";
exports.comments = "Comments";
exports.fileAdded = "File added";
exports.goingOnName = "What's going on";

exports.dateName = "Date";
exports.updatedName = "Updated by";
exports.detailsName = "Details";
exports.status = "Status";
exports.getOpenWorkApiEndPoint = "account_block/landlord_work_orders/";
exports.costName = "Cost";
exports.statusChangeName = "Status Change";
exports.imagesUpload = "Images";
exports.completed = "Complete";
exports.transfer = "Assign to...";
exports.payBill = "Pay bill";
exports.putWorkOrderApiEndPoint = "account_block/landlord_work_orders/";
exports.assignVendorNew = "Assign a Vendor";
exports.submitUpdate = "Submit Update";
exports.orderOpen = "Open Reminders";
exports.editWork = "Edit";
exports.decline = "Reject";
exports.workOrderApiEndPoint = "account_block/landlord_work_orders";
exports.propertiesApiEndPoint = "bx_block_automaticreminders/reminders";
exports.openedName = "Opened";
exports.due = "Due";
exports.hashTagName = "#";
exports.estimatedCost = "$500";
exports.workOrderMissing = "No Reminders Found";
exports.futureReminderMissing = "No Future Reminders Found";
exports.noImages = "No Images";
exports.preview = "Preview";
exports.lowCost = "$250";
exports.payBill = "Pay bill";
exports.workOrders = "Work Order";
exports.backButton = "Back";
exports.updateWork = "Work Order Updates";
exports.assignVendor = "Transfer to other vendor";
exports.completedWork = "Complete work order";
exports.declinedWork = "Decline work order";
exports.orderId = "WORK ORDER #";
exports.auditMissing = "No record found";
exports.postApprovedApiEndPoint = "account_block/landlord_work_orders/approved_by_landlord";
exports.errorMessage = "error";
exports.postWaitingApiEndPoint = "account_block/landlord_work_orders/waiting_for_bill";
exports.keyLocatedMessage = "Keys located in the mailbox, enter code #";
exports.doorClose = ". Keep door closed at all times.";
exports.keyLocation = "Keys Location";
exports.floorName = "Floor:";
exports.entrance = "Entrance:";
exports.codeName = "Code #";
exports.postScheduledApiEndPoint = "account_block/landlord_work_orders/scheduled_date";
exports.putVendorApiEndPoint = "account_block/landlord_work_orders/";
exports.putAppointmentListAPiMethod = "PUT";
exports.noProperty = "Reminders not found, Please create a reminder.";
exports.filteredMessage = "This property is already filtered.";
///
exports.appointmentApiContentType = "application/json";

exports.addAppointmentAPiMethod = "POST";
exports.deleteAllAppointmentsAPiMethod = "DELETE";
exports.getAppointmentListAPiMethod = "GET";
exports.putReminderMethod = "PUT"
exports.getPropertyAcceptanceApiEndPoint = "account_block/properties"
exports.serviceOrderlabel3 = "LandlordServiceRequestsDashboard";
exports.getCatagoryAcceptanceApiEndPoint = "bx_block_categories/categories";
exports.getPropertiesAcceptanceApiEndPoint = "account_block/properties";
exports.getTanentAcceptanceApiEndPoint = "bx_block_automaticreminders/reminders/tenant_list";
exports.serviceOrder3 = "Service Requests";
// Customizable Area End