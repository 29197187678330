import React, { Component } from 'react';
import { AppBar, Toolbar, Button, Avatar, Menu, MenuItem, Typography, Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { styled } from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
const DefaultIcon = require("../../blocks/settings2/assets/user.png");
import storage from "../../framework/src/StorageProvider.web";
import { getStorageData } from "../../framework/src/Utilities";
interface S {
  anchorEl: HTMLElement | null;
  openLogoutModal: boolean;
  username:string;
  usertype:string;
}

export interface Props {
  navigation: any;
  id: string;
  showChatButton?: boolean;  
  showPropertiesButton?: boolean; 
}

class CustomNavbar extends Component<Props, S> {
  constructor(props: Props) {
    super(props);
    this.state = {
      anchorEl: null,
      openLogoutModal: false, 
      username:"",
      usertype:"",
    };
  }
  async componentDidMount() {
    const username = await getStorageData('username');
    const usertype = await getStorageData('usertype');

    this.setState({ username });
    this.setState({ usertype });
  }
  handleMenuClick = (event: any) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  navigateToChat = () => {
    this.props.navigation.navigate("Chat");
  };

  navigateToProperties = () => {
    this.props.navigation.navigate("PortfolioManagement");
  };

  handleLogout = () => {
    this.setState({ openLogoutModal: true });  
  };

  handleCloseModal = () => {
    this.setState({ openLogoutModal: false });
  };

  confirmLogout = () => {
    this.setState({ openLogoutModal: false });
    storage.clearStorage();
    this.props.navigation.navigate("EmailAccountLoginBlock");
  };

  render() {
    const { anchorEl, openLogoutModal,username,usertype } = this.state;
    const { showChatButton, showPropertiesButton } = this.props;
    const open = Boolean(anchorEl);
    return (
      <>
        <AppBar position="static" sx={{ backgroundColor: "white", boxShadow: "none", width: "100%" }}>
          <Toolbar sx={{ display: "flex", justifyContent: "space-between", padding: "0", flexDirection: { xs: 'row', md: 'row' },   gap: { xs: '1rem', md: '0' },  }} disableGutters>
            <Box sx={{ display: "flex", gap: "1rem", flexDirection: { xs: 'column', md: 'row' },   }}>
              {showChatButton && (
                <ChatWithKeasy onClick={this.navigateToChat}>
                  <img className="image-icon" src={require("./black_Key.png")} alt="Chat with Keasy" />
                  <Title>Chat With Keasy</Title>
                </ChatWithKeasy>
              )}
              {showPropertiesButton && (
                <ChatWithKeasy onClick={this.navigateToProperties}>
                  <Title>My Properties</Title>
                </ChatWithKeasy>
              )}
            </Box>
            <Box display="flex" alignItems="center">
              <Box sx={{ display: "flex", flexDirection: "column",  gap:"0.5rem", marginTop: "1rem" }}>
                <Typography sx={{ color: "#000000", fontWeight: 700, fontFamily: "Outfit", fontSize: "18px", lineHeight: "17px" }}>
                 {username}
                </Typography>
                <RoleButton onClick={this.handleMenuClick}>
                  {usertype} <KeyboardArrowDownIcon />
                </RoleButton>
              </Box>
              <Box>
                <Avatar
                  alt="Delia Jiménez"
                  src={DefaultIcon}
                  sx={{ cursor: "pointer", marginLeft: "1rem" }}
                />
              </Box>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={this.handleClose}
                anchorOrigin={{
                  vertical: "bottom",   
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",      
                  horizontal: "center", 
                }}
                sx={{
                  '& .MuiPaper-root': {
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',  
                    borderRadius: '8px',   
                    marginTop:"3px",
                    backgroundColor: "#FFF1CC",             
                  },
                }}
              >
                <MenuItem onClick={this.handleLogout}
                 sx={{ 
                  backgroundColor: "#FFF1CC",
                  color: "#3C3E49",
                  fontWeight: "400",
                  fontSize: "12px",
                  lineHeight: "12px",
                  padding: "6px 16px",
                  textTransform: "capitalize", 
                  '&:hover': {
                    backgroundColor: "#FFF1CC",
                  }
                }}
                >Logout</MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </AppBar>

        {/* Logout Confirmation Modal */}
        <Dialog
          open={openLogoutModal}
          onClose={this.handleCloseModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{
            '& .MuiDialog-paper': {
              borderRadius: '12px',  
              padding: '10px',  
            }
          }}
        >
          <DialogTitle sx={{ color: "#000000", fontWeight: 400, fontFamily: "Outfit", fontSize: "16px", lineHeight: "17px" }} id="alert-dialog-title">Are you sure you want to log out?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" sx={{ color: "#000000", fontWeight: 400, fontFamily: "Outfit", fontSize: "16px", lineHeight: "17px" }}>
              Press 'Yes' to end your session and Log out.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <LogoutButtons onClick={this.handleCloseModal} >
              No
            </LogoutButtons>
            <LogoutButtons onClick={this.confirmLogout} color="primary" autoFocus>
              Yes
            </LogoutButtons>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

const ChatWithKeasy = styled(Box)({
  display: "flex",
  background: "#FFC123",
  alignItems: "center",
  borderRadius: "8px",
  gap: "0.5rem",
  width: "187px",
  height: "44px",
  cursor: "pointer",
  justifyContent: "center",
});

const Title = styled(Typography)({
  color: "#000000",
  fontFamily: 'Outfit',
  fontSize: "16px",
  lineHeight: "24px",
  fontWeight: 700,
});

const RoleButton = styled(Button)({
  backgroundColor: "#FFF1CC",
  color: "#3C3E49",
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "12px",
  borderRadius: "8px",
  padding: "6px 16px",
  textTransform: "capitalize",
  '&:hover': {
    backgroundColor: "#FFF1CC",
  },
});

const LogoutButtons = styled(Button)({
  fontFamily: "Outfit",
  background: "#FFC123",
  borderRadius: "8px",
  cursor: "pointer",
  color: "#000000",
  '&:hover': {
    backgroundColor: "#FFF1CC",
  },
});

export default CustomNavbar;
