import React from "react";

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Pagination
} from "@mui/material";

// Customizable Area Start
import { styled } from "@mui/material/styles";
import AutomatedprioritisationController, {
  Props,
  configJSON,
} from "./AutomatedprioritisationController.web";
import {
  InputBase,
  Checkbox,
  IconButton,
  TableContainer,
  ListItemText,
  Menu,
  MenuItem,
  ListItemIcon,
  Divider
} from '@mui/material';
import NavigationSidebar from "../../../components/src/NavigationSidebar.web";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import DoNotDisturbOnOutlinedIcon from '@mui/icons-material/DoNotDisturbOnOutlined';
import RedoOutlinedIcon from '@mui/icons-material/RedoOutlined';
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { dashboardOrderLogo, activeOrderDashboard, accountsOrder, activeOrderAccount, ordersIcon, activeOrderIcon, vendorOrder, activeOrderVendor, remindersOrder, activeOrderReminder, propertyOrder, activePropertyOrder, financialOrder, activeOrderFinancial, reportOrder, activeOrderReport, keyOrderChain, activeKeyOrder, settingsOrder, activeOrderSetting, keasIcon, searchIcon, filterIcon, findKeys, editIcon, workIcon, declineIcon, transferIcon, doneIcon, hexagon, halfHexagon, keys, keasy,serviceInactive, documentActive,documents,Service } from './assets';
import { MoreVert, ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import PopupMessage from "../../../components/src/PopupMessage.web";
import Modal from '@mui/material/Modal';
import Close from '@mui/icons-material/Close';
import {HomeImg} from "../../visualanalytics/src/assets";
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import  CustomNavbar from "../../../components/src/CustomNavbar.web";
// Customizable Area End

export default class Automatedprioritisation extends AutomatedprioritisationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderSortableTableCell = (property: string, label: string, unitIndex: number) => {
    return (
      <CustomTableCell key={property}>
        <Box display="flex" alignItems="center" justifyContent={"center"} style={{color: "#A3978F"}}>
          {label}
          <Box display="flex" flexDirection="column" marginLeft={"10px"}>
            <CustomIconButtonUp
              data-test-id={`sortUpId,${unitIndex}`}
              onClick={() => this.handleSortRequest(property, "asc", unitIndex)}
            >
              <ArrowDropUp
              />
            </CustomIconButtonUp>
            <CustomIconButtonDown
              data-test-id={`sortDownId,${unitIndex}`}
              onClick={() => this.handleSortRequest(property, "desc", unitIndex)}
            >
              <ArrowDropDown
              />
            </CustomIconButtonDown>
          </Box>
        </Box>
      </CustomTableCell>
    );
  };

  renderFilter = () => {
    return (
      <FilterContainer>
        {this.state.chips.map((chip, chipIndex) => (
          <FilterChips key={chipIndex}>
            <FilterClose data-test-id = {`removedChipId,${chipIndex}`} onClick={() => this.removeChip(chipIndex)} />
            <FilterDetails>
              {chip.propertyName} | {chip.unitName}
            </FilterDetails>
          </FilterChips>
        ))
        }
      </FilterContainer>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
     <WorkOrderStyle>
      <NavArea>
      <NavigationSidebar
        data-test-id={"navId"}
        activeItem={this.state.activeOrderItem}
        sidebarItems={[
          { label: configJSON.dashboardOrder, labelKey: configJSON.dashboardOrderlabel, icon: dashboardOrderLogo, activeIcon: activeOrderDashboard, pathName: configJSON.dashboardOrderlabel },
          { label: configJSON.myAccountOrder, labelKey: configJSON.settingOrderlabel, icon: accountsOrder, activeIcon: activeOrderAccount, pathName: configJSON.myAccountOrderabel },
          { label: configJSON.workOrder, labelKey: configJSON.worksOrderlabel, icon: ordersIcon, activeIcon: activeOrderIcon, pathName: configJSON.worksOrderlabel },
          { label: configJSON.preferredOrder, labelKey: configJSON.prevendorOrderlabel, icon: vendorOrder, activeIcon: activeOrderVendor, pathName: configJSON.prevendorOrderlabel },
          { label: configJSON.serviceOrder3, labelKey: configJSON.serviceOrderlabel3, icon: serviceInactive, activeIcon:Service , pathName: configJSON.serviceOrderlabel3 },
          { label: configJSON.remindersOrder, labelKey: configJSON.reminOrderlabel, icon: remindersOrder, activeIcon: activeOrderReminder, pathName: configJSON.reminOrderlabel },
          { label: configJSON.mypropOrder, labelKey: configJSON.mypropOrderlabel, icon: propertyOrder, activeIcon: activePropertyOrder, pathName: configJSON.mypropOrderlabel },
          { label: configJSON.financiaOrder, labelKey: configJSON.financOrderlabel, icon: financialOrder, activeIcon: activeOrderFinancial, pathName: configJSON.financOrderlabel },
          { label: configJSON.reportOrder, labelKey: configJSON.reportOrderlabel, icon: reportOrder, activeIcon: activeOrderReport, pathName: configJSON.reportOrderlabel },
          { label: configJSON.document, labelKey: configJSON.documentlabel, icon: documents, activeIcon: documentActive, pathName: configJSON.documentlabel },
          { label: configJSON.chatOrder, labelKey: configJSON.chatOrderlabel, icon: keyOrderChain, activeIcon: activeKeyOrder, pathName: configJSON.chatOrderlabel },
        ]}
        onClickSidebar={this.handleSideNav}
        keasylogo={keasIcon}
        openDrawer={this.state.openWorkOrder}
        onClickDrawer={this.handleWordDrawer}
      />
      </NavArea>
      <WorkArea display={"flex"} flexDirection={"column"} justifyContent={"space-between"} width={"100%"}
         >
        <MainWorkContainer>
          <WorkStyles>
            <CustomNavbar
                  {...this.props}
                  showChatButton={true}
                  showPropertiesButton={true}
                />
            <WorkOrderHead data-test-id="seeTextId">
              {configJSON.remindersOrder}
            </WorkOrderHead>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <Box className="search-contents">
                  <img
                    className="search-icons"
                    src={searchIcon} alt="location" />
                  <InputBase
                    data-test-id = "searchId"
                    className="search-inputs"
                    placeholder="Search"
                    inputProps={{ "aria-label": "search" }}
                    value={this.state.searchBar}
                    onChange={(searchEvent) => this.handleSearchBar(searchEvent)}
                    style={{fontFamily: "Outfit"}}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6} justifyContent="flex-start">
                <Box className="search-main-content">
                  <Button data-test-id="propertyClickId" className="properties-dropdown" onClick={this.handleProperClick}>
                    <Typography data-test-id="propertyNameId" className="text-data">{configJSON.propertiesHead}</Typography>
                    <KeyboardArrowDownIcon />
                  </Button>
                  <Box data-test-id="navigationId" className="new-property" onClick={() => this.navigateToCalender()}>
                    <CalendarTodayIcon style={{ color: '#CC9200' }} />
                    <Typography className="new-data">{configJSON.calendar}</Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item md={6} xs={12}>
                <Box className="filter-content">
                  <Box className="filter">
                    <img
                      className="icon"
                      src={filterIcon} alt="filter" />
                    <Typography className="filter-data" data-test-id="headClickId">{configJSON.filtersHead}</Typography>
                  </Box>
                  <FilterWeb>
                    {this.renderFilter()}
                  </FilterWeb>
                  <Typography className="clear-data" data-test-id="clearOrderId" onClick={this.clearChips}>{configJSON.clearFilters}</Typography>
                </Box>
              </Grid>
              <FilterMobile>
                {this.renderFilter()}
              </FilterMobile>
            </Grid>
            <>
            <Box className={this.handleProperty(this.state.properties)}>
              {this.state.properties.map((propertyItems: any, proIndex: number) => (
                <React.Fragment key={proIndex}>
                  <Grid container spacing={2} mt={2} className="wrapper">
                    <GridItem item xl={5} lg={5} md={5} sm={12} display={"flex"} alignItems={"center"}>
                      <HeadItems>
                        <HeadName>{propertyItems.property_name}</HeadName>
                        <MainHeadings>{propertyItems.address}</MainHeadings>
                      </HeadItems>
                    </GridItem>
                    <GridItem item xl={7} lg={7} md={7} sm={12}>
                      <CompletedDetails>
                        <Box data-test-id="viewFutureReminder" className="viewFuture" style={{backgroundColor: this.state.future[propertyItems.property_id] ? "#FFD466": "#FFF1CC"}} onClick={() => this.getPropertiesDetails(propertyItems.property_id, "future")}>
                          <RedoOutlinedIcon />
                          <Typography className="text-data">{configJSON.viewFuturereminders}</Typography>
                        </Box>
                        <Box data-test-id="view-completed" className="view-completed" style={{backgroundColor: this.state.complete[propertyItems.property_id] ? "#FFD466": "#FFF1CC"}}  onClick={() => this.handleCompletedReminders(propertyItems.property_id, "Completed")}>
                          <VisibilityOutlinedIcon />
                          <Typography className="text-data">{configJSON.viewCompleted}</Typography>
                        </Box>
                        <Box data-test-id="view-scheduled" className="view-completed" style={{backgroundColor: this.state.rejected[propertyItems.property_id] ? "#FFD466": "#FFF1CC"}}  onClick={() => this.handleCompletedReminders(propertyItems.property_id, "Rejected")}>
                          <DoNotDisturbOnOutlinedIcon />
                          <Typography className="text-data">{configJSON.viewRejected}</Typography>
                        </Box>
                      </CompletedDetails>
                    </GridItem>
                  </Grid>
                  {propertyItems.units.map((unitDetails: any, unitIndex: number) => (
                    <BorderContainer key={unitIndex}>
                      <Box className="headerFlexBox">
                      <OrderUnitContainer>
                        <OrderUnitName>{unitDetails.name}</OrderUnitName>
                        <OrdersProgress sx={this.priorityStyleHandler(unitDetails.status)}>{unitDetails.status}</OrdersProgress>
                      </OrderUnitContainer>
                        <CustomGrid item xl={6} lg={4} md={4} sm={12} xs={12}>
                          <Box data-test-id = "gotoPropertyId" display={"flex"} gap={1} onClick={() => this.goToProperties()}>
                            <img src={HomeImg} alt="home" style={{width: "14px", height:"14px"}} />
                            <FindKeyName>{configJSON.goToProperty}</FindKeyName>
                          </Box>
                          <Box data-test-id = "findKeysId" display={"flex"}  gap={1} onClick={() => this.handleModalOpen(unitDetails.unit_id)}>
                            <img src={findKeys} alt="findKey" className="image-icon" />
                            <FindKeyName>{configJSON.findKeys}</FindKeyName>
                          </Box>
                          <Box data-test-id = "addReminderId" display={"flex"} gap={1} onClick={() => this.goToNewReminderScreen(propertyItems.property_id,unitDetails.unit_id)}>
                            <AddCircleOutlineOutlinedIcon style={{ color: '#CC9200' }} className="image-icon"/>
                            
                            <FindKeyName>{configJSON.addReminder}</FindKeyName>
                          </Box>
                        </CustomGrid>
                      </Box>
                      {!this.state.future[propertyItems.property_id] &&
                        <TableContainer className={this.handleScrollableContainer(unitDetails)}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                {this.renderSortableTableCell("category", configJSON.categoryName, unitIndex)}
                                {this.renderSortableTableCell("due", configJSON.due, unitIndex)}
                                {this.renderSortableTableCell("resolved_on", configJSON.resolvedOn, unitIndex)}
                                {this.renderSortableTableCell("status", configJSON.statusName, unitIndex)}
                                {this.renderSortableTableCell("assignee", configJSON.assignee, unitIndex)}
                                <CustomTableCell style={{color: "#A3978F"}}>{configJSON.comments}</CustomTableCell>
                                <TableCell/>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.sortData(unitDetails.reminders, unitIndex).map((rowOrder: any, workIndex: number) => {
                                return (
                                  <React.Fragment key={`workOrder-${rowOrder.id}`}>
                                    <TableRow className={this.handleDetailsContainer(unitDetails.reminders)}>
                                      <CustomDetailTableCell data-test-id="createTestId">{rowOrder.category}</CustomDetailTableCell>
                                      <CustomDetailTableCell data-test-id="dueTestId">{new Date(rowOrder.due_date).toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true }).replace(',', '')}</CustomDetailTableCell>
                                      <CustomDetailTableCell>{rowOrder.resolved_on === null ? "" :
                                          new Date(rowOrder.resolved_on).toLocaleString('en-US', { 
                                            month: 'short', 
                                            day: 'numeric', 
                                            year: 'numeric', 
                                            hour: 'numeric', 
                                            minute: 'numeric', 
                                            hour12: true 
                                          }).replace(',', '') 
                                        }</CustomDetailTableCell>
                                      <TableCell>
                                        <OrderStatus data-test-id="statusId" sx={this.priorityStyleHandler(rowOrder.status)}>
                                          {rowOrder.status}
                                        </OrderStatus>
                                      </TableCell>
                                      <CustomDetailTableCell><FindKeyName>Me</FindKeyName></CustomDetailTableCell>
                                      <CustomDetailTableCell>
                                        <DescriptionDetails data-test-id="descTestId">{rowOrder.comments}</DescriptionDetails>
                                      </CustomDetailTableCell>
                                      <TableCell>
                                        <MoreArrow>
                                          <IconButton data-test-id="menuIconId" onClick={(menus) => { this.handleMenuIconClick(menus, rowOrder.id) }}>
                                            <MoreVert />
                                          </IconButton>
                                          <Menu
                                            data-test-id="menuIconCloseId"
                                            anchorEl={this.state.anchorNewElement[rowOrder.id]}
                                            keepMounted
                                            open={Boolean(this.state.anchorNewElement[rowOrder.id])}
                                            onClose={() => this.handleMenuClose(rowOrder.id)}
                                            elevation={0}
                                            anchorOrigin={{
                                              vertical: "bottom",
                                              horizontal: "right"
                                            }}
                                            transformOrigin={{
                                              vertical: "top",
                                              horizontal: "right"
                                            }}
                                            slotProps={{
                                              paper: {
                                                style: { width: "210px", border: "1px solid #E2E8F0", borderRadius: "8px", boxShadow: "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)" }
                                              }
                                            }}
                                          >
                                            <CustomNewMenuItem data-test-id="completedId" onClick={() => this.handleCompleted(rowOrder.id, "Completed")}>
                                              <ListItemIcon>
                                                <img src={doneIcon} alt="editorIcon" />
                                              </ListItemIcon>
                                              <ListItemText primaryTypographyProps={{fontSize: "14px"}} primary={configJSON.completed} />
                                            </CustomNewMenuItem>
                                            <CustomNewMenuItem data-test-id="openWorkId" onClick={()=>this.handleEditReminder(rowOrder.id, "OpenReminders")}>
                                              <ListItemIcon>
                                                <img src={workIcon} alt="editorIcon" />
                                              </ListItemIcon>
                                              <ListItemText data-test-id="openWorksId" primaryTypographyProps={{fontSize: "14px"}} primary={configJSON.orderOpen} />
                                            </CustomNewMenuItem>
                                            <Divider />
                                            <CustomNewMenuItem data-test-id="udateWorkId" onClick={()=>this.handleEditReminder(rowOrder.id, "EditReminders")}>
                                              <ListItemIcon>
                                                <img src={editIcon} alt="editorIcon" />
                                              </ListItemIcon>
                                              <ListItemText data-test-id="updateWorksId" primaryTypographyProps={{fontSize: "14px"}} primary={configJSON.editWork} />
                                            </CustomNewMenuItem>
                                            <CustomNewMenuItem data-test-id="declineStatusId" onClick={()=>this.handleCompleted(rowOrder.id, "Rejected")}>
                                              <ListItemIcon>
                                                <img src={declineIcon} alt="editorIcon" />
                                              </ListItemIcon>
                                              <ListItemText primaryTypographyProps={{fontSize: "14px"}} primary={configJSON.decline} />
                                            </CustomNewMenuItem>
                                          </Menu>
                                        </MoreArrow>
                                      </TableCell>
                                    </TableRow>
                                  </React.Fragment>
                                );
                              })}
                            </TableBody>
                            <TableRow className={this.handleEmptyContainer(unitDetails.reminders)}>
                                  <TableCell colSpan={11} align="center" style={{fontFamily: "Outfit"}}>
                                    {configJSON.workOrderMissing}
                                  </TableCell>
                                  <TableCell style={{width: "70px"}}/>
                                </TableRow>
                          </Table>
                        </TableContainer>
                      }
                      <OrderUnitContainer>
                        <OrderUnitName style={{minWidth: "60px",margin: "20px 0px 20px 0px"}}>Future reminders</OrderUnitName>
                      </OrderUnitContainer>
                      <TableContainer className={this.handleScrollableContainer(unitDetails)}>
                        <Table>
                          <TableHead className="fakeClass">
                            <TableRow>
                              {this.renderSortableTableCell("category", configJSON.categoryName, unitIndex)}
                              {this.renderSortableTableCell("due", configJSON.due, unitIndex)}
                              {this.renderSortableTableCell("resolved_on", configJSON.resolvedOn, unitIndex)}
                              {this.renderSortableTableCell("status", configJSON.statusName, unitIndex)}
                              {this.renderSortableTableCell("assignee", configJSON.assignee, unitIndex)}
                              <CustomTableCell style={{color: "#A3978F"}}>{configJSON.comments}</CustomTableCell>
                              <TableCell className="fakeClass"/>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {this.sortData(unitDetails.future_reminders, unitIndex).map((rowOrder: any, workIndex: number) => {
                              return (
                                <React.Fragment key={`workOrder-${rowOrder.id}`}>
                                  <TableRow className={this.handleDetailsContainer(unitDetails.future_reminders)}>
                                    <CustomDetailTableCell data-test-id="createTestId" className="fakeClass">{rowOrder.category}</CustomDetailTableCell>
                                    <CustomDetailTableCell data-test-id="dueTestId">{new Date(rowOrder.due_date).toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true }).replace(',', '')}</CustomDetailTableCell>
                                    <CustomDetailTableCell className="fakeClass">{rowOrder.resolved_on === null ? "" :
                                        new Date(rowOrder.resolved_on).toLocaleString('en-US', { 
                                          month: 'short', 
                                          day: 'numeric', 
                                          year: 'numeric', 
                                          hour: 'numeric', 
                                          minute: 'numeric', 
                                          hour12: true 
                                        }).replace(',', '') 
                                      }</CustomDetailTableCell>
                                    <TableCell>
                                      <OrderStatus data-test-id="statusId" sx={this.priorityStyleHandler(rowOrder.status)}>
                                        {rowOrder.status}
                                      </OrderStatus>
                                    </TableCell>
                                    <CustomDetailTableCell className="fakeClass"><FindKeyName>Me</FindKeyName></CustomDetailTableCell>
                                    <CustomDetailTableCell>
                                      <DescriptionDetails data-test-id="descTestId">{rowOrder.comments}</DescriptionDetails>
                                    </CustomDetailTableCell>
                                    <TableCell  className="fakeClass">
                                      <MoreArrow>
                                        <IconButton data-test-id="menuIconId" onClick={(menus) => { this.handleMenuIconClick(menus, rowOrder.id) }}>
                                          <MoreVert />
                                        </IconButton>
                                        <Menu
                                          data-test-id="menuIconCloseId"
                                          anchorEl={this.state.anchorNewElement[rowOrder.id]}
                                          keepMounted
                                          className="fakeClass"
                                          open={Boolean(this.state.anchorNewElement[rowOrder.id])}
                                          onClose={() => this.handleMenuClose(rowOrder.id)}
                                          elevation={0}
                                          anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "right"
                                          }}
                                          transformOrigin={{
                                            vertical: "top",
                                            horizontal: "right"
                                          }}
                                          slotProps={{
                                            paper: {
                                              style: { width: "210px", border: "1px solid #E2E8F0", borderRadius: "8px", boxShadow: "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)" }
                                            }
                                          }}
                                        >
                                          <CustomNewMenuItem className="fakeClass" data-test-id="completedId" onClick={() => this.handleCompleted(rowOrder.id, "Completed")}>
                                            <ListItemIcon>
                                              <img src={doneIcon} alt="editorIcon" />
                                            </ListItemIcon>
                                            <ListItemText primaryTypographyProps={{fontSize: "14px"}} primary={configJSON.completed} />
                                          </CustomNewMenuItem>
                                          <CustomNewMenuItem className="fakeClass" data-test-id="openWorkId" onClick={()=>this.handleEditReminder(rowOrder.id, "OpenReminders")}>
                                            <ListItemIcon>
                                              <img src={workIcon} alt="editorIcon" />
                                            </ListItemIcon>
                                            <ListItemText data-test-id="openWorksId" primaryTypographyProps={{fontSize: "14px"}} primary={configJSON.orderOpen} />
                                          </CustomNewMenuItem>
                                          <Divider />
                                          <CustomNewMenuItem data-test-id="udateWorkId" onClick={()=>this.handleEditReminder(rowOrder.id, "EditReminders")}>
                                            <ListItemIcon>
                                              <img src={editIcon} alt="editorIcon" />
                                            </ListItemIcon>
                                            <ListItemText data-test-id="updateWorksId" primaryTypographyProps={{fontSize: "14px"}} primary={configJSON.editWork} />
                                          </CustomNewMenuItem>
                                          <CustomNewMenuItem data-test-id="declineStatusId" onClick={()=>this.handleCompleted(rowOrder.id, "Rejected")}>
                                            <ListItemIcon>
                                              <img src={declineIcon} alt="editorIcon" />
                                            </ListItemIcon>
                                            <ListItemText className="fakeClass" primaryTypographyProps={{fontSize: "14px"}} primary={configJSON.decline} />
                                          </CustomNewMenuItem>
                                        </Menu>
                                      </MoreArrow>
                                    </TableCell>
                                  </TableRow>
                                </React.Fragment>
                              );
                            })}
                          </TableBody>
                          <TableRow className={this.handleEmptyContainer(unitDetails.future_reminders)}>
                                <TableCell colSpan={11} align="center" style={{fontFamily: "Outfit"}}>
                                  {configJSON.futureReminderMissing}
                                </TableCell>
                                <TableCell style={{width: "70px"}}/>
                              </TableRow>
                        </Table>
                      </TableContainer>
                    </BorderContainer>
                  ))}
                </React.Fragment>
              ))}
                  <CustomModal
                    data-test-id="openModalId"
                    open={this.state.openFinKey}
                  >
                    <ParagraphContainer>
                      <Box display={"flex"} flexDirection={"column"} gap={"10px"} height={"100%"}>
                        <CloseModal onClick={this.handleModalClose}>
                          <Close />
                        </CloseModal>
                        <KeyContainer>
                        {this.state.findKeyDetails.map((findKey: any, findIndex: number) => {
                            return (
                              <Box display={"flex"} flexDirection={"column"} gap={"10px"} key={findIndex}>
                                <KeyHeading>
                                  {configJSON.keyLocatedMessage}{findKey.code}{configJSON.doorClose}
                                </KeyHeading>
                                <KeyLocation>{configJSON.keyLocation}</KeyLocation>
                                <KeyPara data-test-id="findKeyDetailsId">{configJSON.floorName} {findKey.floor}</KeyPara>
                                <KeyPara>{configJSON.entrance} {findKey.entrance}</KeyPara>
                                <KeyPara>{configJSON.codeName}{findKey.code}</KeyPara>
                              </Box>
                            )
                          })}
                          <Box>
                            <img src={keys} alt="findKey" />
                          </Box>
                        </KeyContainer>
                      </Box>
                    </ParagraphContainer>
                  </CustomModal>
                  <Menu
                    data-test-id="menuId"
                    id="property-menu"
                    anchorEl={this.state.anchorEl}
                    keepMounted
                    open={Boolean(this.state.anchorEl)}
                    onClose={this.handleClose}
                    elevation={0}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right"
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center"
                    }}
                    slotProps={{
                      paper: {
                        style: { width: "200px", boxShadow: "rgba(0, 0, 0, 0.15) 0px 2px 8px", marginLeft: "-2rem", marginTop: "0.5rem" }
                      }
                    }}
                  >
                    {this.handlePropertyData().map((property: any, propIndex: number) => (
                      <CustomMenuItem
                        data-test-id="propertyMenuId"
                        key={property.property_name}
                        onClick={(propevent) => this.handlePropertyClick(propevent, property, propIndex)}
                        className={this.handleChangeColor(propIndex)}
                      >
                        <CustomListItemText primaryTypographyProps={{ fontSize: "14px" }} primary={property.property_name} />
                        <KeyboardArrowRightIcon />
                      </CustomMenuItem>
                    ))}

                    {this.handlePropertySlice() && (
                      <CustomMenuItem
                        data-test-id="seeAllId"
                        key="seeAll"
                        onClick={this.toggleShowAll}
                      >
                        <CustomListColor primaryTypographyProps={{ fontSize: "14px" }} primary="See all" />
                      </CustomMenuItem>
                    )}
                  </Menu>
                  {this.state.selectedProperty && (
                    <Menu
                      data-test-id="menuItemId"
                      anchorEl={this.state.subMenuAnchorEl}
                      keepMounted
                      open={Boolean(this.state.subMenuAnchorEl)}
                      onClose={this.handleCloseMenu}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      slotProps={{
                        paper: {
                          style: { width: "120px", marginLeft: "10px" }
                        }
                      }}
                    >
                      {this.state.selectedProperty.units && this.state.selectedProperty.units.length > 0 && (
                        this.state.selectedProperty.units.map((unit: any) => (
                          <UnitMenuItem
                            data-test-id="unitMenuId"
                            key={unit.unit_id}
                            onClick={() => this.handleUnitClick(unit.name, unit.unit_id)}
                          >
                            {unit.name}
                          </UnitMenuItem>
                        ))
                      )}
                    </Menu>
                  )}
                </Box>
                  <Box display={"flex"} justifyContent={"center"} alignItems={"center"} mt={10} className={this.handleEmptyProperty(this.state.properties)}>
                    {configJSON.noProperty}
                  </Box>
            </> 
          </WorkStyles>
        </MainWorkContainer>
        <Box className="bgImgWrapper" data-test-id="bgImgId">
          <Box className="backgroundImage">
            <img src={hexagon} alt="hexagon-one" className="hexImg" />
          </Box>
          <Box className="backgroundImage">
            <img src={hexagon} alt="hexagon-two" className="hexImg" />
          </Box>
          <Box className="backgroundImage">
            <img src={hexagon} alt="hexagon-three" className="hexImg" />
          </Box>
          <Box className="backgroundImage">
            <img src={halfHexagon} alt="half-hexagon" className="hexImg" />
          </Box>
        </Box>
      </WorkArea>
      <PopupMessage
        data-test-id="popupId"
        open={this.state.messagePopup}
        type={this.state.messageType}
        message={this.state.messageForPopup}
        handleClose={this.handlePopupClose}
      />
    </WorkOrderStyle>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const NavArea = styled(Box)({
  position: "fixed",
  top: 0,
  left: 0,
  height: "100%",
  overflowY: "auto",
  zIndex: 1000, 
  border: "1px solid rgb(208,203,199)",
  "&::-webkit-scrollbar": {
    display: "none"
  },
  "scrollbar-width": "none",
  "-ms-overflow-style": "none",
  "@media(max-width: 992px)": {
    position: "unset",
    height: "auto",
    overflowY: "hidden",
    border: "none",
  }
});
const WorkArea = styled(Box)({
  marginLeft: "90px", 
  flexGrow: 1,
  overflowY: "auto",
  height: "100vh",
  "@media(max-width: 992px)": {
    marginLeft: "0px", 
    height: "auto",
    overflowY: "unset"
  }
});
const MainWorkContainer = styled(Box)({
  width: "100%",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  "@media(max-width: 992px)": {
    padding: "0px 0px 0px 0px"

  }
});

const WorkOrderStyle = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  fontFamily: "Outfit",
  "@media(max-width: 992px)": {
    flexDirection: "column"
  },
  "&.webStyle": {
    backgroundColor: "red"
  },
  "& .bgImgWrapper": {
    display: "flex",
    justifyContent: "flex-end",
    width: "97%",
    marginTop: "2rem",
    fontFamily: "Outfit",
    "@media (max-width:1220px)": {
      width: "88%"
    },
    "@media (max-width:992px)": {
      display: "none",
    }
  },
  "& .backgroundImage": {
    width: "250px",
    "@media (max-width:1220px)": {
      width: "180px"
    },
  },
  "& .hexImg": {
    objectFit: "cover"
  }
});

const CustomMenuItem = styled(MenuItem)({
  fontSize: "14px",
  fontWeight: 400,
  color: "#000000",
  margin: "0px 5px 0px 5px",
  "@media(max-width: 475px)": {
    fontSize: "10px",
  },
  "&.changeColor": {
    backgroundColor: "#f3f2f0",
    borderRadius: "10px"
  }
});

const CustomListItemText = styled(ListItemText)({
  fontSize: "14px",
  fontWeight: 400,
  "@media(max-width: 475px)": {
    width: "12px"
  }
});

const UnitMenuItem = styled(MenuItem)({
  fontWeight: 400,
  fontSize: "14px",
  "@media(max-width: 475px)": {
    width: "12px"
  }
});

const CustomNewMenuItem = styled(MenuItem)({
  fontSize: "12px",
  fontWeight: 400,
  color: "#000000",
  "&.changeColor": {
    backgroundColor: "rgb(250,227,162)",
    borderRadius: "10px"
  },
  "&:hover": {
    backgroundColor: "rgb(250,227,162)",
    color: "#000000"
  }
});

const WorkOrderHead = styled(Typography)({
  fontSize: "24px",
  fontWeight: 700,
  color: "#000000",
  marginBottom: "20px",
  fontFamily: "Outfit",
});

const WorkStyles = styled(Box)({
  width: "90%",
  "& .properties-dropdown": {
    height: "44px",
    width: "142px",
    backgroundColor: "rgb(253,242,208)",
    display: "flex",
    alignItems: "center",
    padding: "2px 10px 2px 10px",
    justifyContent: "space-around",
    cursor: "pointer",
    textTransform: "none",
    borderRadius: "8px",
    color: "#000000",
    "&:hover": {
      backgroundColor: "rgb(253,242,208)",
      color: "#000000"
    },
    "@media(max-width: 420px)": {
      height: "30px",
      fontSize: "12px",
      width: "105px",
    }
  },
  "& .search-main-content": {
    display: "flex",
    gap: "1rem",
    "@media(max-width:600px)": {
      width: "100%",
      position: "relative",
      height: "100%",
      flexDirection: "row",
      display: "flex",
      "@media(max-width: 420px)": {
        gap: "5px"
      }
    },
  },
  "& .search-contents": {
    border: "1px solid #CBD5E1",
    borderRadius: "50px",
    height: "44px",
    display: "flex",
    paddingLeft: "15px",
    alignItems: "center",
    gap: "13px",
  },
  "& .search-icons": {
    height: "24px",
    width: "24px"
  },
  "& .search-text": {
    fontWeight: 400,
    fontSize: "16px",
    color: "#A3978F",
    "@media(max-width: 420px)": {
      fontSize: "12px"
    }
  },
  "& .search-inputs": {
    width: "100%"
  },
  "& .headerFlexBox": {
    display: "flex", 
    justifyContent: "space-between",
     width: "100%", 
     marginBottom: "20px",
     "@media(max-width: 600px)": {
      display: "block", 
    },
  },
  "& .viewFuture": {
    backgroundColor: "rgb(253,242,208)",
    height: "44px",
    display: "flex",
    alignItems: "center",
    padding: "2px 10px 2px 10px",
    borderRadius: "8px",
    justifyContent: "space-around",
    cursor: "pointer",
    "@media(max-width: 600px)": {
      fontSize: "12px",
      height: "30px",
    },
    "@media(max-width: 347px)": {
      padding: "2px 5px 2px 5px"
    }
  },
  "& .view-completed": {
    backgroundColor: "rgb(253,242,208)",
    height: "44px",
    display: "flex",
    alignItems: "center",
    padding: "2px 10px 2px 10px",
    borderRadius: "8px",
    justifyContent: "space-around",
    cursor: "pointer",
    "@media(max-width: 600px)": {
      fontSize: "12px",
      height: "30px",
    },
    "@media(max-width: 347px)": {
      padding: "2px 5px 2px 5px"
    }
  },
  "& .icon": {
    mixBlendMode: "multiply"
  },
  "& .image-icon": {
    width: "16px",
    height: "16px",
    marginTop: "2px",
  },
  "& .text-data": {
    fontSize: "16px",
    fontWeight: 700,
    fontFamily: "Outfit",
    marginLeft: "8px",
    "@media(max-width: 420px)": {
      fontSize: "12px"
    }
  },
  "& .new-data": {
    fontSize: "18px",
    color: "#CC9200",
    fontWeight: 700,
    fontFamily: "Outfit",
    "@media(max-width: 420px)": {
      fontSize: "12px"
    }
  },
  "& .new-property": {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    borderRadius: "8px",
    justifyContent: "space-around",
    paddingLeft: "5px",
    paddingRight: "5px",
    cursor: "pointer"
  },
  "& .filter-content": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: "20px",
    "@media(max-width: 992px)": {
    paddingRight: "0px",
    }
  },
  "& .filter": {
    display: "flex",
    alignItems: "center",
    gap: "15px",
  },
  "& .filter-data": {
    fontSize: "12px",
    fontWeight: 700,
    color: "#000000",
    paddingRight: "10px",
    fontFamily: "Outfit",
    "@media(max-width: 992px)": {
      paddingRight: "0px"
    }
  },
  "& .clear-data": {
    fontSize: "12px",
    fontWeight: 700,
    whiteSpace: "nowrap",
    color: "#CC9200",
    cursor: "pointer",
    paddingLeft: "10px",
    fontFamily: "Outfit",
    "@media(max-width: 992px)": {
      paddingLeft: "0px"
    }
  },
  "& .isUnitsDisabled": {
    display: "none"
  }
});

const HeadName = styled(Typography)({
  fontSize: "16px",
  fontWeight: 700,
  color: "#000000",
  fontFamily: "Outfit",
  "@media(max-width: 405px)": {
    fontSize: "12px"
  }
});

const MainHeadings = styled(Typography)({
  fontSize: "16px",
  color: "#000000",
  fontWeight: 400,
  fontFamily: "Outfit",
  "@media(max-width: 405px)": {
    fontSize: "12px"
  }
});

const HeadItems = styled(Box)({
  display: "flex",
  gap: "10px",
});

const CompletedDetails = styled(Box)({
  display: "flex",
  gap: "30px",
  justifyContent: "flex-end",
  "@media(max-width: 900px)": {
    flexWrap: "wrap",
    justifyContent: "flex-start",
    gap: "5px",
    width: "100%"
  }
});

const GridItem = styled(Grid)({
  width: "100%"
});

const BorderContainer = styled(Box)({
  border: "1px solid #FFD466",
  borderRadius: "8px 8px 32px 8px",
  padding: "2rem",
  marginTop: "1rem",
  display: "flex",
  flexDirection: "column",
  "@media(max-width: 600px)": {
    padding: "1rem",
  },
  "& .scrollable-container": {
    maxHeight: "300px",
    overflowY: "auto"
  },
  "& .isUnitsDisabled": {
    display: "none"
  }
});

const OrdersProgress = styled(Button)({
  borderRadius: "40px",
  fontSize: "12px",
  fontWeight: 700,
  fontFamily: "Outfit",
  padding: "2px 8px",
});

const OrderUnitContainer = styled(Box)({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: "20px",
  "@media(max-width: 600px)": {
    gap: "10px"
  },
});

const OrderUnitName = styled(Typography)({
  fontSize: "16px",
  fontWeight: 700,
  color: "#000000",
  fontFamily: "Outfit",
});

const FindKeyName = styled(Typography)({
  color: "#CC9200",
  fontSize: "14px",
  fontWeight: 700,
  cursor: "pointer",
  fontFamily: "Outfit"
});

const CustomGrid = styled(Grid)({
  display: "flex",
  justifyContent: "flex-end",
  gap: "10px",
  "@media(max-width: 900px)": {
    justifyContent: "flex-start",
    flexWrap: "wrap",
    marginTop:"15px",
    gap: "10px"
  },
});

const CustomTableCell = styled(TableCell)({
  color: "#1C1917",
  textAlign: "center",
  fontSize: "12px",
  fontWeight: 700,
  padding: "0px 10px 0px 10px !important",
  whiteSpace: "nowrap",
  width: "17%",
  fontFamily: "Outfit"
});


const CustomDetailTableCell = styled(TableCell)({
  fontSize: "12px",
  color: "#1C1917",
  fontWeight: 400,
  textAlign: "center",
  width: "17%",
  fontFamily: "Outfit"
});

const MoreArrow = styled(Box)({
  display: "flex"
});

const OrderStatus = styled(Typography)({
  borderRadius: "50px",
  color: "#059669",
  fontSize: "12px",
  textAlign: "center",
  textTransform: "uppercase",
  whiteSpace: "nowrap",
  padding: "4px 8px",
  fontWeight: 700,
  fontFamily: "Outfit",
});

const DescriptionDetails = styled(Typography)({
  fontSize: "12px",
  fontWeight: 400,
  color: "#1C1917",
  wordBreak: "break-word",
  fontFamily: "Outfit",
});

const InnerTableBg = styled(Box)({
  border: "1px solid #F9FAFC",
  borderRadius: "10px",
  backgroundColor: "#F9FAFC",
  padding: "20px 0px 20px 20px"
});

const StatusTableCells = styled(TableCell)({
  fontSize: "12px",
  fontWeight: 400,
  color: "#000000",
  textAlign: "center"
});

const OngoingName = styled(Typography)({
  fontSize: "16px",
  fontWeight: 700,
  color: "#1C1917"
});

const CustomTableContainer = styled(TableBody)({
  backgroundColor: "#FFFFFF"
});

const CustomCheckbox = styled(Checkbox)({
  color: "rgba(0, 0, 0, 0.54)",
  "&.Mui-checked": {
    color: "rgb(195,149,49)"
  },
  "&.MuiCheckbox-indeterminate": {
    color: "rgb(195,149,49)"
  }
});

const Images = styled(Typography)({
  textAlign: "left",
  fontSize: "16px",
  fontWeight: 700,
  color: "#1C1917"
});

const CustomListColor = styled(ListItemText)({
  color: "#CC9200",
  fontSize: "14px !important",
  fontWeight: 400
});

const CustomIconButtonUp = styled(Typography)({
  marginBottom: "-14px",
  cursor: "pointer"
});

const CustomIconButtonDown = styled(Typography)({
  marginTop: "0px",
  cursor: "pointer"
});

const BulkUploadingFiles = styled(Grid)({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  paddingTop: "20px",
  alignItems: "center",
  gap:"10px"
});

const ImagesVideosFiles = styled(Box)({
  height: "100px",
  width: "100px"
});

const ImageFiles = styled('img')({
  height: "100%",
  width: "100%",
  objectFit: "contain"
});

const CustomModal = styled(Modal)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "20px",
  "& .properties-dropdown": {
    width: "142px",
    height: "44px",
    backgroundColor: "rgb(253,242,208)",
    display: "flex",
    alignItems: "center",
    textTransform: "none",
    borderRadius: "8px",
    padding: "2px 10px 2px 10px",
    justifyContent: "space-around",
    cursor: "pointer",
    color: "#000000",
    "&:hover": {
      backgroundColor: "rgb(253,242,208)",
      color: "#000000"
    },
    "@media(max-width: 420px)": {
      fontSize: "12px",
      width: "105px",
      height: "30px"
    }
  },
});

const ParagraphContainer = styled(Box)({
  backgroundColor: "white",
  width: "30%",
  height: "250px",
  borderRadius: "8px 8px 30px 8px",
  lineHeight: "2rem",
  padding: "25px 20px 80px 20px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "25px",
   "@media(max-width: 1260px)": {
    width: "50%"
  },
  "@media(max-width: 767px)": {
    width: "80%"
  },
  "@media(max-width: 600px)": {
    gap: "15px",
    height: "290px"
  },
  "@media(max-width: 475px)": {
    gap: "12px",
    height: "345px"
  },
  "@media(max-width: 466px)": {
    gap: "10px",
    height: "390px"
  },
  "@media(max-width: 400px)": {
    gap: "10px",
    height: "400px"
  }
});

const KeyHeading = styled(Typography)({
  color: "#CC9200",
  fontWeight: 700,
  fontSize: "20px",
  "@media(max-width: 467px)": {
    fontSize: "16px"
  },
  "@media(max-width: 360px)": {
    fontSize: "15px"
  }
});

const KeyLocation = styled(Typography)({
  color: "#0F172A",
  fontSize: "18px",
  fontWeight: 700
});

const KeyPara = styled(Typography)({
  color: "#0F172A",
  fontSize: "18px",
  fontWeight: 400
});

const KeyContainer = styled(Box)({
  display: "flex",
  "@media(max-width: 467px)": {
    flexDirection: "column",
    gap: "10px"
  }
});

const CloseModal = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  cursor: "pointer"
});

const StatusTableCell = styled(TableCell)({
  fontSize: "12px",
  fontWeight: 400,
  color: "#000000",
  textAlign: "center",
  whiteSpace: "nowrap"
});

const FilterChips = styled(Box)({
width: "fit-content",
display: "flex",
border: "1px solid #000000",
borderRadius: "4px",
padding: "1px 6px 1px 2px",
alignItems: "center",
gap: "5px",
height: "fit-content",
whiteSpace: "nowrap"
});

const FilterDetails = styled(Typography)({
  fontSize: "12px",
  fontWeight: 700,
  color: "#544B45",
  fontFamily: "Outfit"
});

const FilterClose = styled(Close)({
  color: "#544B45",
  cursor: "pointer"
});

const FilterContainer = styled(Box)({
  width: "100%",
  padding: "10px 10px 10px 10px",
  overflow: "auto",
  display: "flex",
  gap: "1rem"
});

const FilterWeb = styled(Box)({
  width: "90%",
  paddingRight: "20px",
  "@media(max-width: 992px)": {
    display: "none"
  }
});

const FilterMobile = styled(Box)({
  display: "none",
  "@media(max-width: 992px)": {
    display: "block",
    width: "93%",
    paddingLeft: "16px"
  }
});

// Customizable Area End
