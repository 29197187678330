import React from "react";

import {
  Box,
  Grid,
  Button,
  Typography,
  styled,

  // Customizable Area Start
  Menu,
  InputBase,
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  Chip,
  Pagination,
  PaginationItem,
  // Customizable Area End

} from "@mui/material";

// Customizable Area Start
import NavigationSidebar from "../../../components/src/NavigationSidebar.web";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
const deleteIcon = require("../../settings2/assets/cross.svg");

import { KeyboardArrowUp, KeyboardArrowDown, ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import {
  propertyMain, 
  activePropertyMain, 
  dashboard_g, 
  financial, 
  chatKey,
  activeOrderFinancial,
  Key_logo,
  filterIcon,
  activeDashboard,
  activeBell,
  bell,
  activeKey,
  hexagon,
  halfHexagon,
} from "./assets";
import  CustomNavbar from "../../../components/src/CustomNavbar.web";

// Customizable Area End

import ServicerequestController, {
  Props,
  configJSON

} from "./ServicerequestController";

export default class Automatedworkflow extends ServicerequestController {

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
renderPagination = () => {
  const { currentPage, totalCount, itemsPerPage } = this.state;
  return (
    <Box sx={{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "10px 0",
    }}>
      <Box></Box>
      <Pagination
        data-test-id="pagination"
        count={Math.ceil(totalCount / itemsPerPage)} 
        page={currentPage}
        onChange={this.handleChangePage} 
        renderItem={(item) => (
          <PaginationItem
            {...item}
            sx={{
              "&.Mui-selected": {
                color: "#CC9200",
                backgroundColor: "unset",
              },
            }}
          />
        )}
      />
      <Typography sx={{ marginRight: "10%",fontFamily:"Outfit",
        fontSize: "12px",
        color: "#A3978F",
        fontWeight: 700,
        alignContent:"center"}}>
        {`${(currentPage - 1) * itemsPerPage + 1}-${Math.min(
          currentPage * itemsPerPage,
          totalCount
        )} of ${totalCount} results`}
      </Typography>
    </Box>
  );
};

  renderSortableTableCell = (property: string, label: string, unitIndex: number) => {
    return (
      <CustomTableCellsd key={property}>
        <Box display="flex" alignItems="center">
        <Typography sx={{
            fontFamily:"Outfit",
            lineHeight:"10px",
            fontSize:"12px",
            color:"#A3978F",
            fontWeight:"700",
          }}>{label}</Typography>
          <Box display="flex" flexDirection="column" marginLeft={"10px"}>
            <CustomIconButtonUp
              data-test-id={`sortUpId,${unitIndex}`}
              onClick={() => this.handleSortRequest(property, "asc", unitIndex)}
            >
              <ArrowDropUp
              />
            </CustomIconButtonUp>
            <CustomIconButtonDown
              data-test-id={`sortDownId,${unitIndex}`}
              onClick={() => this.handleSortRequest(property, "desc", unitIndex)}
            >
              <ArrowDropDown
              />
            </CustomIconButtonDown>
          </Box>
        </Box>
      </CustomTableCellsd>
    );
  };
  screenProps = {
    navigation: this.props.navigation,
    id: "Analytics",
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <WorkOrderStylesd>
        <NavArea>
        <NavigationSidebar
          data-test-id={"navId"}
          activeItem={this.state.activeMainNewrequestdash}
          sidebarItems={[
            { label: configJSON.dashboardLable, labelKey: configJSON.dashboardPath, icon: dashboard_g, activeIcon: activeDashboard, pathName: configJSON.dashboardPath },
            { label: configJSON.serviderequest, labelKey: configJSON.serviderequestPath, icon: bell, activeIcon: activeBell, pathName: configJSON.serviderequestPath },
            { label: configJSON.tenentlable, labelKey: configJSON.tenentBlock, icon:propertyMain , activeIcon: activePropertyMain, pathName: configJSON.tenentBlock },
            { label: configJSON.paymentLable, labelKey: configJSON.paymentBlock, icon:financial , activeIcon: activeOrderFinancial, pathName: configJSON.paymentBlock },
            { label: configJSON.chatLable, labelKey: configJSON.chatBlocK, icon:chatKey , activeIcon: activeKey, pathName: configJSON.chatBlocK }
          ]} 
          onClickSidebar={this.handleMainItemClicknewrequest}
          keasylogo={Key_logo}
          openDrawer={this.state.openTenentDrawersdash}
          onClickDrawer={this.handleTenentrequestDrawers} />
        </NavArea>
          <Box display={"flex"} flexDirection={"column"} justifyContent={"space-between"} width={"100%"}
            sx={{
              padding: {
                flexGrow: 1,
                borderLeft: "1px solid rgb(208,203,199)",
              },
            }}>
            <MainWorkContainersd>
              <WorkStylessd>
                  <CustomNavbar 
                    {...this.screenProps}
                    showChatButton={true}   
                    showPropertiesButton={false} 
                  />
                  <WorkOrderHeadsd data-test-id="seeTextId">
                  Service Requests
                </WorkOrderHeadsd>
                    <Grid item spacing={2}>
                      <Box sx={{display:"flex" , alignItems:"center",gap:"1rem"}} >
                          <Select
                          data-test-id="Category-dropdown"
                          value={this.state.selectedCategoryNames}
                          multiple
                          onChange={(event) => {
                            const { target: { value } } = event;
                            this.setState({
                              selectedCategoryNames: typeof value === 'string' ? value.split(',') : value,
                            }, () => {
                              this.applyFilters();
                            });
                          }}
                          displayEmpty
                          renderValue={(selected) => {
                            return <CustomLableCell>Category</CustomLableCell>;
                          }
                        }
                          sx={{
                            borderRadius: '8px',
                            fontFamily: 'Outfit',
                            boxShadow: 'none',
                            border: 'none',
                            height: '2.7rem',
                            backgroundColor: '#FFF1CC',
                            '& .MuiSelect-select': {
                              display: 'flex',
                              alignItems: 'center',
                              color: '#000',
                              fontFamily: 'Outfit',

                            },
                            '& label': {
                              fontSize: '16px',
                              fontWeight: 'bold',
                              color: '#000',
                            },
                            '& .MuiSvgIcon-root': {
                              color: '#000',
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                              border: 'none',
                            },
                          }}
                          MenuProps={{
                            PaperProps: {
                              sx: {
                                maxHeight: 200,
                                '& .MuiMenuItem-root': {
                                  minHeight: 'auto',
                                  fontFamily: 'Outfit',

                                },
                              },
                            },
                          }}
                          >
                            {this.state.allCatagory.map((cat) => (
                              <MenuItem key={cat.attributes.id} value={cat.attributes.id}>
                                {cat.attributes.name}
                              </MenuItem>
                            ))}
                          </Select>

                          <Select
                                    data-test-id="status-dropdown"
                                    multiple
                                    value={this.state.selectedStatus}
                                    onChange={(event) => {
                                      this.setState({ selectedStatus: event.target.value as string[]}, () => {
                                        this.applyFilters();
                                      });
                                    }}
                                    displayEmpty
                                    renderValue={(selected) => {
                                  return <CustomLableCell>Status</CustomLableCell>;
                                    }}
                                    sx={{
                                      boxShadow: 'none',
                                      fontFamily: 'Outfit',
                                      border: 'none',
                                      backgroundColor: '#FFF1CC',
                                      borderRadius: '8px',
                                      height: "2.7rem",
                                      '& .MuiSvgIcon-root': {
                                        color: '#000',
                                      },
                                      '& .MuiSelect-select': {
                                        display: 'flex',
                                        alignItems: 'center',
                                        fontFamily: 'Outfit',
                                      },
                                      '& label': {
                                        fontSize: '16px',
                                        fontWeight: 'bold',
                                        color: '#000',
                                      },
                                      '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none',
                                      },
                                     
                                    }}
                                    MenuProps={{
                                      PaperProps: {
                                        sx: {
                                          '& .MuiMenuItem-root': {
                                            fontFamily: 'Outfit',
                                            minHeight: 'auto',
                                          },
                                          maxHeight: 200,
                                        },
                                      },
                                    }}
                                  >
                                    {configJSON.descriptionOptions.map((option:any) => (
                                      <MenuItem key={option} value={option}>
                                        {option}
                                      </MenuItem>
                                    ))}
                          </Select>

                          <ScheduledNewWaiting  data-test-id="addServicebtn"  onClick={this.navigateToDashboard}>
                                      <img
                                        className="image-icon"
                                        src={activeBell} alt="bill"
                                        style={{ height: '20px', width: '20px' }} />
                                <ScheduledDate>Request a service</ScheduledDate>
                          </ScheduledNewWaiting>        
                          </Box>
                          <Grid item md={6} xs={12}>
                          <Box className="filter-content">
                            <Box className="filter">
                              <Box sx={{display:"flex"
                              ,gap:"15px",
                              marginTop:"1rem",
                              marginBottom: '1rem',
                              alignItems:"center",
                              "@media (max-width: 900px)": {
                                marginBottom: 0
                              }
                              }}>
                                <img
                                className="icon"
                                src={filterIcon} alt="filter" />
                              <Typography className="filter-data" data-test-id="headClickId">Filters</Typography>
                              </Box>
                              
                              <FilterContentBox data-test-id="chip-filter-box">
                              {this.state.selectedCategoryNames.map((selectedId:any) => {
                                 const category = this.state.allCatagory.find((cat) => cat.attributes.id === selectedId)
                                   if (category) {
                                  return (
                                          <Chip
                                            key={selectedId}
                                            data-test-id="chip-category"
                                            label={category.attributes.name}  
                                            onDelete={() => this.handleDeleteCategory(selectedId)}
                                            deleteIcon={<img src={deleteIcon} style={{  height: '24px',width: '24px'}} />}
                                            sx={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              justifyContent: 'space-between',
                                              padding: '1px 6px 1px 6px',
                                              borderRadius: '4px',
                                              fontSize: '12px',
                                              fontFamily: 'Outfit',
                                              border: '1px solid #000',
                                              flexDirection: 'row-reverse',
                                              fontWeight: '700',
                                              color: '#4A3F35',
                                              backgroundColor: 'white',
                                              textTransform: 'uppercase',
                                              '.MuiChip-deleteIcon': {
                                                marginLeft: '8px',
                                                color: '#4A3F35',
                                              },
                                            }}
                                          />
                                        );
                                      }
                                      return null;
                                              })}
                               {this.state.selectedStatus.map((option) => (
                                  <Chip
                                    data-test-id="chip-status"
                                    key={option}
                                    label={option}
                                    onDelete={() => this.handleDeleteDescription(option)}
                                    deleteIcon={<img src={deleteIcon} alt="delete icon" style={{ width: '24px', height: '24px' }} />}
                                    sx={{
                                      display: "flex",
                                      color: '#4A3F35', 
                                      justifyContent: 'space-between',
                                      textTransform: 'uppercase' ,
                                      padding: '1px 6px 1px 6px', 
                                      border: '1px solid #000', 
                                      backgroundColor: 'white', 
                                      borderRadius: '4px',
                                      fontFamily:"Outfit",
                                      flexDirection: 'row-reverse',
                                      fontWeight: '700', 
                                      alignItems: 'center',
                                      fontSize: '12px', 
                                      '.MuiChip-deleteIcon': {
                                        marginLeft: '8px', 
                                        color: '#4A3F35', 
                                      },
                                    }}
                                  />
                                ))}
                              </FilterContentBox>
                            </Box>
                          </Box>
                          </Grid>
                    </Grid>
                <>
                  <React.Fragment>
                    <Grid container spacing={2} mt={2}>
                      <GridItemsd item xl={6} lg={6} md={6} sm={12}>
                        <HeadItemssd>
                          <HeadNamesd>{this.state.propertyDetails.property_name} | {this.state.propertyDetails.unit_name}</HeadNamesd>
                        </HeadItemssd>
                      </GridItemsd>
                    </Grid>

                    <BorderContainersd>
                       <TableContainer >
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell padding="checkbox" sx={{color:"white"}}>
                                <Checkbox sx={{color:"white"}} />
                              </TableCell>
                              {this.renderSortableTableCell("id", configJSON.idNamesd, 2)}
                              <CustomTableCellsd>{configJSON.createdNamesd}</CustomTableCellsd>
                              {this.renderSortableTableCell("category", configJSON.categoryNamesd, 1)}
                              <CustomTableCellsd>{configJSON.workNeedNamesd}</CustomTableCellsd>
                              {this.renderSortableTableCell("id", "Current Status", 1)}
                              <CustomTableDis>{configJSON.descriptionNamesd}</CustomTableDis>
                              {this.renderSortableTableCell("due_date_date", "Opened", 1)}
                              <TableCell />
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {this.state.serviceRequest && this.state.serviceRequest.length > 0 ? (
                              this.state.serviceRequest.map((request, index) => (
                                <React.Fragment key={index}>
                                  <TableRow>
                                    <TableCell padding="checkbox">
                                      <CustomCheckbox
                                        style={{ color: 'white' }}
                                        data-test-id="checkedClickTestId"
                                      />
                                    </TableCell>
                                    <CustomDetailTableCellsd data-test-id="rowId">
                                      {request.attributes.id}
                                    </CustomDetailTableCellsd>
                                    <CustomDetailTableCellsd data-test-id="rowId">
                                      {request.attributes.created_by}
                                    </CustomDetailTableCellsd>
                                    <CustomDetailTableCellsd>
                                      {request.attributes.sub_category.sub_category || "No Category"}
                                    </CustomDetailTableCellsd>
                                    <CustomDetailTableCellsd>
                                      {request.attributes.work_needed}
                                    </CustomDetailTableCellsd>
                                    <TableCell>
                                      <StatusCell status={request.attributes.status}>
                                        {request.attributes.status}
                                      </StatusCell>
                                    </TableCell>
                                    <CustomDetailTableCellsd>
                                    {request.attributes.description}
                                    </CustomDetailTableCellsd>
                                    <CustomDetailTableCellsd>
                                      {request.attributes.due_date_date}
                                    </CustomDetailTableCellsd>
                                  </TableRow>
                                </React.Fragment>
                              ))
                            ) : (
                              <TableRow>
                                <TableCell colSpan={8} align="center" sx={{
                                  fontFamily:"Outfit",
                                  color: "#A3978F",
                                  fontSize:"14px",
                                }} >
                                  No Service Rrequest found
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                             </Table>
                      </TableContainer>
                    </BorderContainersd>
                    {this.renderPagination()}
                  </React.Fragment>
                </>
              </WorkStylessd>
            </MainWorkContainersd>
            <Box className="bgImgWrapper" data-test-id="bgImgId">
                        <Box className="backgroundImage">
                          <img src={hexagon} alt="hexagon-one" className="hexImg" />
                        </Box>
                        <Box className="backgroundImage">
                          <img src={hexagon} alt="hexagon-two" className="hexImg" />
                        </Box>
                        <Box className="backgroundImage">
                          <img src={hexagon} alt="hexagon-three" className="hexImg" />
                        </Box>
                        <Box className="backgroundImage">
                          <img src={halfHexagon} alt="half-hexagon" className="hexImg" />
                        </Box>
            </Box>
          </Box>
        </WorkOrderStylesd>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const getStatusStyles = (status: string) => {
  switch (status) {
    case "PENDING":
      return {
        color: "#D97706",
        backgroundColor: "#FEF3C7",
      };
    case "APPROVED":
      return {
        color: "#059669",
        backgroundColor: "#D1FAE5",
      };
    case "REJECTED":
      return {
        color: "#DC2626",
        backgroundColor: "#FEE2E2",
      };
    default:
      return {
        color: "#FFA500",
        backgroundColor: "#FFF3E0",
      };
  }
};

const WorkOrderStylesd = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  height:"100%",
  fontFamily: "Outfit",
  "@media(max-width: 992px)": {
    flexDirection: "column"
  },
  "&.webStyle": {
    backgroundColor: "red"
  },
  "& .bgImgWrapper": {
    display: "flex",
    justifyContent: "flex-end",
    width: "97%",
    marginTop: "2rem",
    "@media (max-width:1220px)": {
      width: "88%"
    },
    "@media (max-width:992px)": {
      display: "none",
    }
  },
  "& .backgroundImage": {
    width: "250px",
    "@media (max-width:1220px)": {
      width: "180px"
    },
  },
  "& .hexImg": {
    objectFit: "cover"
  }
});

const MainWorkContainersd = styled(Box)({
  width: "95%",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  marginLeft:"90px",
  "@media(max-width: 992px)": {
    marginLeft:"0px",
    padding: "0px 0px 0px 0px"

  },
  "& .bgImgWrapper": {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "2rem",
    "@media (max-width: 992px)": {
      display: "flex",
      justifyContent: "end", 
      width: "100%",
      marginBottom: "0rem", 
      marginTop:"6rem",
      overflow: "hidden",
    },
    },
});

const WorkOrderHeadsd = styled(Typography)({
  fontfamily:"Outfit",
  fontSize: "24px",
  fontWeight: 700,
  color: "#000000",
  marginBottom: "20px",
  fontFamily: "Outfit, sans-serif",
});

const WorkStylessd = styled(Box)({
  width: "90%",
  "& .search-main-content": {
    display: "flex",
    gap: "1rem",
    "@media(max-width:600px)": {
      width: "100%",
      position: "relative",
      height: "100%",
      flexDirection: "row",
      display: "flex",
      "@media(max-width: 420px)": {
        gap: "5px"
      }
    },
  },
  "& .search-contents": {
    border: "1px solid #CBD5E1",
    borderRadius: "50px",
    height: "44px",
    display: "flex",
    paddingLeft: "15px",
    alignItems: "center",
    gap: "13px",
  },
  "& .search-icons": {
    height: "24px",
    width: "24px"
  },
  "& .search-text": {
    fontWeight: 400,
    fontSize: "16px",
    color: "#A3978F",
    "@media(max-width: 420px)": {
      fontSize: "12px"
    }
  },
  "& .search-inputs": {
    width: "100%"
  },
  "& .properties-dropdown": {
    width: "142px",
    height: "44px",
    backgroundColor: "rgb(253,242,208)",
    display: "flex",
    alignItems: "center",
    textTransform: "none",
    borderRadius: "8px",
    padding: "2px 10px 2px 10px",
    justifyContent: "space-around",
    cursor: "pointer",
    color: "#000000",
    "&:hover": {
      backgroundColor: "rgb(253,242,208)",
      color: "#000000"
    },
    "@media(max-width: 420px)": {
      fontSize: "12px",
      width: "105px",
      height: "30px"
    }
  },
  "& .view-completed": {
    width: "180px",
    backgroundColor: "rgb(253,242,208)",
    height: "44px",
    display: "flex",
    alignItems: "center",
    padding: "2px 10px 2px 10px",
    borderRadius: "8px",
    justifyContent: "space-around",
    cursor: "pointer",
    "@media(max-width: 460px)": {
      fontSize: "12px",
      height: "35px",
      width: "100%"
    },
    "@media(max-width: 347px)": {
      padding: "2px 5px 2px 5px"
    }
  },
  "& .icon": {
    mixBlendMode: "multiply"
  },
  "& .image-icon": {
    width: "16px",
    height: "16px"
  },
  "& .text-data": {
    fontSize: "16px",
    fontWeight: 700,
    "@media(max-width: 420px)": {
      fontSize: "12px"
    }
  },
  "& .new-data": {
    fontSize: "18px",
    color: "#CC9200",
    fontWeight: 700,
    "@media(max-width: 420px)": {
      fontSize: "12px"
    }
  },
  "& .new-property": {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    borderRadius: "8px",
    justifyContent: "space-around",
    paddingLeft: "5px",
    paddingRight: "5px",
    cursor: "pointer"
  },
  "& .filter-content": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: "20px"
  },
  "& .filter": {
    display: "flex",
    alignItems: "center",
    gap: "15px",
  },
  "& .filter-data": {
    fontFamily:"Outfit",
    fontSize: "12px",
    fontWeight: 700,
    color: "#000000"
  },
  "& .clear-data": {
    fontSize: "12px",
    fontWeight: 700,
    color: "#CC9200",
    cursor: "pointer"
  }
});

const HeadNamesd = styled(Typography)({
  fontFamily:"Outfit",
  fontSize: "16px",
  fontWeight: 700,
  color: "#000000",
  "@media(max-width: 405px)": {
    fontSize: "12px"
  }
});


const HeadItemssd = styled(Box)({
  display: "flex",
  gap: "10px"
});

const GridItemsd = styled(Grid)({
  width: "100%"
});

const BorderContainersd = styled(Box)({
  border: "1px solid #FFD466",
  borderRadius: "8px 8px 32px 8px",
  padding: "1rem",
  marginTop: "1rem",
  display: "flex",
  flexDirection: "column",
  "& .scrollable-container": {
    height: "300px",
    overflowY: "auto"
  },
  "& .isUnitsDisabled": {
    display: "none"
  }
});
const CustomTableCell = styled(TableCell)({
  paddingleft: "25px",
  textAlign: "start",
  fontSize: "12px",
  fontFamily:"Outfit",
  color: "#A3978F",
  fontWeight: 700,
});
const CustomTableDis = styled(TableCell)({
  fontWeight: 700,
  color: "#A3978F",
  fontSize: "12px",
  textAlign: "start",
  paddingleft: "75px",
  fontFamily:"Outfit",
});

const CustomTableCellsd = styled(TableCell)({
  fontFamily:"Outfit",
  color: "#A3978F",
  textAlign: "start",
  fontSize: "12px",
  fontWeight: 700,
  padding: "0px 10px 0px 10px !important"
});

const StatusCell = styled(TableCell)<{ status: string }>(({ status }) => ({
  fontFamily: "Outfit",
  width: "100%",
  fontWeight: 700,
  fontSize: "12px",
  padding: "2px 8px",
  borderRadius: "40px",
  textAlign: "center",
  textTransform: "capitalize",
  borderBottom: "none",
  ...getStatusStyles(status),
}));
const CustomDetailTableCellsd = styled(TableCell)({
  fontFamily:"Outfit",
  fontSize: "12px",
  color: "#A3978F",
  fontWeight: 400,
  alignContent:"center"
});
const CustomLableCell = styled(Typography)({
  alignContent:"center",
  color: "#0F172A",
  fontSize: "16px",
  fontWeight: 700,
  fontFamily:"Outfit",
});
const CustomCheckbox = styled(Checkbox)({
  color: "rgba(0, 0, 0, 0.54)",
  "&.Mui-checked": {
    color: "rgb(195,149,49)"
  },
  "&.MuiCheckbox-indeterminate": {
    color: "rgb(195,149,49)"
  }
});

const FilterContentBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row', 
  alignItems: 'center',
  gap: '1rem',
  marginTop: '1rem',
  marginBottom: '1rem',
  flexWrap: 'wrap',
  "@media (max-width: 600px)": {
    flexDirection: 'column',
    alignItems: 'flex-start', 
    gap: '0.5rem', 
  },
});
const CustomIconButtonUp = styled(Typography)({
  marginBottom: "-14px",
  cursor: "pointer"
});

const CustomIconButtonDown = styled(Typography)({
  marginTop: "0px",
  cursor: "pointer"
});

const NavArea = styled(Box)({
  position: "fixed",
  top: 0,
  left: 0,
  height: "100%",
  overflowY: "auto",
  zIndex: 1000, 
  border: "1px solid rgb(208,203,199)",
  "&::-webkit-scrollbar": {
    display: "none"
  },
  "scrollbar-width": "none",
  "-ms-overflow-style": "none",
  "@media(max-width: 992px)": {
    position: "unset",
    height: "auto",
    overflowY: "hidden",
    border: "none",
  }
});
const ScheduledNewWaiting = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap:"0.5rem", 
  width:"13rem",
  cursor: "pointer",
  justifyContent: "center",
});
const ScheduledDate = styled(Typography)({
  color: "#CC9200",
  fontFamily: 'Outfit', 
  fontSize: "15px",
  fontWeight: 700
});
// Customizable Area End

