import React from "react";
// Customizable Area Start
import { Box, Grid, FormControl, Select, MenuItem, TextField, Divider, Paper, Typography, Button, styled, StepLabel, Stepper, Step } from "@mui/material";
import {
  createTheme,
  ThemeProvider,
} from "@mui/material/styles";
import {
  keylogoLogo, bottomImage, addIcon, downArrow
} from "./assets";
import { activeMainDashboard, activeMainSetting, activeMainAccount, activeMainOrder, activeMainVendor, activeMainReminder, activePropertyMain, activeMainFinancial, activeMainReport, keyMainChain, activeKeyMain, settingsMain, accountsMain, dashboardLandLogo, financialMain, ordersMain, propertyMain, remindersMain, reportMain, vendorMain } from "../../dashboard/src/assets"
import NavigationSidebar from "../../../components/src/NavigationSidebar.web";
import EventIcon from '@mui/icons-material/Event';
import FileUpload from "../../accountgroups/src/FileUpload";
import CustomYearPicker from "../../../components/src/CustomYearPicker.web";
import CustomDatePickerData from "../../../components/src/CustomDatePickerData.web";
import Check from "@mui/icons-material/Check";
import PopupMessage from "../../../components/src/PopupMessage.web";
import  CustomNavbar from "../../../components/src/CustomNavbar.web";
import {documentActive,documents } from '../../automaticreminders/src/assets';
// Customizable Area End
import NewTenantController, {
  Props,
  configJSON
} from "./NewTenantController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#6200ee",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const NewTenantStyle = styled(Box)({
  "& .paperContent": {
    "@media(max-width:600px)": {
      border: "none !important",
      marginTop: "0rem !important"
    }
  },
  "& .nameText": {
    "@media(max-width:600px)": {
      color: "#4f4545 !important", 
      fontSize: "14px",
      lineHeight: "15px", 
    }
  },
  "& .textField": {
    "@media(max-width:600px)": {
      marginTop: "5px"
    }
  },
  "& .dateTextLabel": {
    "@media(max-width:600px)": {
      display: "none"
    }
  },
  "& .newTenantContent": {
    "@media(max-width:600px)": {
      padding: "0rem !important"
    }
  },
  "& .fieldContainer": {
    padding: "2rem", marginTop: "1rem",
    "@media(max-width:600px)": {
      padding: "0rem !important",
    }
  },
  "& .phoneText": {
    "@media(max-width:600px)": {
      display: "none"
    }
  },
  "& .fileUpload": {
    "@media(max-width:600px)": {
      paddingLeft: "0rem !important",
      marginTop: "5px !important"
    }
  },
  "& .addBtn": {
    marginLeft: "73%",
    "@media(max-width:600px)": {
      marginLeft: "67%",
      marginTop: "5px"
    }
  },
  "& .vehicleContent": {
    "@media(max-width:600px)": {
      borderRadius: "8px", marginTop: "1rem !important"
    }
  },
  "& .submitBtn": {
    marginLeft: "82%",
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    gap: "31px",
    "@media(max-width:600px)": {
      marginLeft: "74%",
    }
  },
  "& .fieldContainerArea": {
    padding: "2rem",
    "@media(max-width:600px)": {
      padding: "0rem !important",
      marginTop: "-3rem !important"
    }
  },
  "& .headingTextTenant": {
    "@media(max-width:600px)": {
      marginLeft: "24px"
    }
  },
  "& .icon-down": {
    width: "12px",
    position: "absolute",
    top: "25px",
    left: "97%",
    height: "9px",
  }
})

const CustomStepLabel = styled(StepLabel)({
  "& .MuiStepLabel-label": {
    fontWeight: 700,
    fontSize: "16px",
    fontFamily: "Outfit",
    "&.Mui-active": {
      fontWeight: 700,
      fontSize: "16px",
    },
    "&.Mui-completed": {
      fontWeight: 700,
      fontSize: "16px",
    },
  },
});

const NavArea = styled(Box)({
  position: "fixed",
  top: 0,
  left: 0,
  height: "100%",
  overflowY: "auto",
  zIndex: 1000, 
  border: "1px solid rgb(208,203,199)",
  "&::-webkit-scrollbar": {
    display: "none"
  },
  "scrollbar-width": "none",
  "-ms-overflow-style": "none",
  "@media(max-width: 992px)": {
    position: "unset",
    height: "auto",
    overflowY: "hidden",
    border: "none",
  }
});
interface StepIconProps {
  active: boolean;
  completed: boolean;
  icon: React.ReactNode;
}
export default class NewTenant extends NewTenantController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  handleName = () => {
    if (this.state.name.length === 0) {
      return <Typography style={webStyle.errColor}>{this.state.validationErrors.name}</Typography>;
    }
    return null;
  }

  handleDate = () => {
   if( this.state.dateOfBirthDate){
    return null
   } 
   return <Typography style={webStyle.errColor}>{this.state.validationErrors.dateOfBirthDate}</Typography>
  }

  handleAddr = () => {
    if(this.state.address.length === 0){
      return <Typography style={webStyle.errColor}>{this.state.validationErrors.address}</Typography>
    }
    return null;
  }

  handleEmail = () => {
    if(this.state.email.length > 0 && !this.state.validationErrors.email){
      return null
    }
    return <Typography style={webStyle.errColor}>{this.state.validationErrors.email}</Typography>; 
  }

  handleCode = () => {
    if(this.state.areaCode.length > 0){
      return null
    }
    return <Typography style={webStyle.errColor}>{this.state.validationErrors.areaCode}</Typography>
  }

  handlePhone = () => {
    if(this.state.phoneNumber){
      return null
    }
    return <Typography style={webStyle.errColor}>{this.state.validationErrors.phoneNumber}</Typography>
  }

  handleInsu = () => {
    if(this.state.formFilesInsurance.length > 0){
      return null;
    }
    return <Typography style={webStyle.errColor}>{this.state.validationErrors.formFilesInsurance}</Typography>
  }

  renewalDate = () => {
    if(this.state.renewalDate){
      return null;
    }
    return <Typography style={webStyle.errColor}>{this.state.validationErrors.renewalDate}</Typography>
  }

  handleoccupent = () => {
    if(this.state.occupent?.length > 0){
      return null;
    }
    return <Typography style={webStyle.errColor}>{this.state.validationErrors.occupent}</Typography>
  }

  handledateOfPayment = () => {
    if( this.state.dateOffirstPayment){
     return null
    } 
    return <Typography style={webStyle.errColor}>{this.state.validationErrors.dateOffirstPayment}</Typography>
   }

   handlepayPeriod = () => {
    if (this.state.payPeriod?.length === 0) {
      return <Typography style={webStyle.errColor}>{this.state.validationErrors.payPeriod}</Typography>;
    }
    return null;
  }

  handleRent = () => {
    if (this.state.rent?.length === 0) {
      return <Typography style={webStyle.errColor}>{this.state.validationErrors.rent}</Typography>;
    }
    return null;
  }

  handleSecurityDeposit = () => {
    if (this.state.SecurityDeposit?.length === 0) {
      return <Typography style={webStyle.errColor}>{this.state.validationErrors.SecurityDeposit}</Typography>;
    }
    return null;
  }

  handleLateCharges = () => {
    if (this.state.LateCharges?.length === 0) {
      return <Typography style={webStyle.errColor}>{this.state.validationErrors.LateCharges}</Typography>;
    }
    return null;
  }

  handleTypePayment = () => {
    if(this.state.payType?.length > 0){
      return null;
    }
    return <Typography style={webStyle.errColor}>{this.state.validationErrors.payType}</Typography>
  }

  handleCollect = () => {
    if(this.state.collectedBy?.length > 0){
      return null;
    }
    return <Typography style={webStyle.errColor}>{this.state.validationErrors.collectedBy}</Typography>
  }

  handleLeaseDate = () => {
    if( this.state.LeaseStartDate){
     return null
    } 
    return <Typography style={webStyle.errColor}>{this.state.validationErrors.LeaseStartDate}</Typography>
   }

  leaseaddress = () => {
    if (this.state.leaseaddress?.length === 0) {
      return <Typography style={webStyle.errColor}>{this.state.validationErrors.leaseaddress}</Typography>;
    }
    return null;
  }

  // Customizable Area End

  render() {
    // Customizable Area Start

    const vehicleData = ["Vehicle 1", "Vehicle 2", "Vehicle 3"];
    const petData = ["Pet 1", "Pet 2", "Pet 3"];
    const steps = [
      "Tenant Information",
      "Lease Information",
    ];
    const paymentData = ["Check", "Cash", "Credit Card"];

    const CustomStepIconRoot = styled("div")<{
      ownerState: { active?: boolean; completed?: boolean };
    }>(({ ownerState }) => ({
      backgroundColor: ownerState.active ? "#CC9200" : "#eaeaf0",
      color: ownerState.active ? "#fff" : "#000",
      display: "flex",
      height: 32,
      width: 32,
      marginRight: 10,
      borderRadius: "50%",
      justifyContent: "center",
      alignItems: "center",
      ...(ownerState.completed && {
        backgroundColor: "#00e676",
        color: "#fff",
      }),
    }));
    function CustomStepIcon(props: StepIconProps) {
      const { active, completed, icon } = props;
    
      return (
        <CustomStepIconRoot ownerState={{ active, completed }}>
          {completed ? <Check /> : icon}
        </CustomStepIconRoot>
      );
    }

    function DividerEnrolfirst (){
      return (
        <>
          <Grid container>
            <Grid item md={12}>
              <Box>
                <Divider/>
              </Box>
            </Grid>
          </Grid>
        </>
      )
    }
    return (
      <ThemeProvider theme={theme}>
        <NewTenantStyle sx={webStyle.maincontent}>
          <img
            src={bottomImage}
            alt="Description"
            style={{
              position: "absolute",
              bottom: "-66px",
              right: "0"
            }}
          />
          <Box>
            <Grid container>
              <Grid item md={1} sm={2} xs={2}>
                <NavArea>
                <NavigationSidebar
                  data-testId= "sidebarMenuIds"
                  activeItem={this.state.activeMyproperty}
                  sidebarItems={[
                    { label: configJSON.dashboardLandloard1, icon: dashboardLandLogo, labelKey: configJSON.dashboardLandlabel, activeIcon: activeMainDashboard, pathName: configJSON.dashboardLandlabel },
                    { label: configJSON.myAccountlandloard1, icon: accountsMain, labelKey: configJSON.settinglandlabel,  activeIcon: activeMainAccount, pathName: configJSON.settinglandlabel },
                    { label: configJSON.worklandloard1, icon: ordersMain, labelKey: configJSON.workOrderslandlabel, activeIcon: activeMainOrder, pathName: configJSON.workOrderslandlabel },

                    { label: configJSON.preferredlandloard1, icon: vendorMain, labelKey: configJSON.prevendorlandlabel, activeIcon: activeMainVendor, pathName: configJSON.prevendorlandlabel },
                    { label: configJSON.reminderslandloard1, icon: remindersMain, labelKey: configJSON.reminlandlabel, pathName: configJSON.reminlandlabel, activeIcon: activeMainReminder },
                    { label: configJSON.myproplandloard1, icon: propertyMain, labelKey: configJSON.myproplandlabel1, activeIcon: activePropertyMain, pathName: configJSON.myproplandlabel1 },

                    { label: configJSON.financialandloard, labelKey: configJSON.financlandlabel, icon: financialMain, activeIcon: activeMainFinancial, pathName: configJSON.financlandlabel },
                    { label: configJSON.reportlandloard1, icon: reportMain, labelKey: configJSON.reportlandlabel, pathName: configJSON.reportlandlabel, activeIcon: activeMainReport },
                    { labelKey: configJSON.docsLandlordsLabel, icon: documents, activeIcon: documentActive, label: configJSON.documentsLandlord, pathName: configJSON.documentsLandlord },
                    { label: configJSON.chatLandloard1, icon: keyMainChain, labelKey: configJSON.chatlandlabel, pathName: configJSON.chatlandlabel, activeIcon: activeKeyMain },

                    { label: configJSON.settingLandlord1, icon: settingsMain, labelKey: configJSON.settingLandlord1, pathName: configJSON.settingLandlord1,  activeIcon: activeMainSetting }
                  ]}
                  keasylogo={keylogoLogo}
                  onClickSidebar={this.handleMainItemClick}
                  onClickDrawer={this.handleLandlordDrawer} 
                  openDrawer={this.state.openLandlordDrawer}
                  />
                  </NavArea>
              </Grid>
              
              <Grid item md={11} xs={12} lg={11} style={webStyle.newTenant} className="newTenantContent">
                <CustomNavbar
                  {...this.props}
                  showChatButton={true}
                  showPropertiesButton={true}
                />
                <Box  style={{display: "flex", justifyContent: "space-between", marginTop: "1rem"}}>
                <label data-test-id="new-tenant" onClick={this.getVehicleData} style={webStyle.headingtextData} className="headingTextTenant">New Tenant</label>
                <Button sx={{
                  textTransform: 'none',
                  width: "80px",
                  height: "32px",
                  fontWeight: 700,
                  backgroundColor: "#FFC123",
                  borderRadius: "8px",
                  color: "#000000", 
                  fontSize: "14px",
                  padding: "0px",
                  letterSpacing: 0,
                  cursor: "pointer",
                  fontFamily: "Outfit, sans-serif",
                  "&:hover": {
                    backgroundColor: "#FFD700", 
                  },
                }} data-test-id="backBtnTest" onClick={this.navigateToAppointments}>Go Back</Button>
                </Box>
                <Stepper
                  data-testId="stepper"
                  activeStep={this.state.activeStep}
                  orientation="horizontal"
                  sx={{
                    display: "flex",
                    marginRight: { xs: "0px", md: "48px" },
                    marginTop: "50px",
                    gap: "8rem",
                    "& .MuiStepConnector-root": {
                      display: "none",
                    },
                    "@media (max-width: 600px)": {
                      gap: "1.5rem",
                      justifyContent: "center", 
                      "& .MuiStep-root": {
                        display: "flex", 
                        margin: "0", 
                        padding: "0",
                      },
                    },
                    "& .MuiStep-root": {
                      position: "relative",
                      "@media (min-width: 600px)": {
                        "&:not(:last-child)::after": {
                          content: '""',
                          position: "absolute",
                          right: "-4rem", 
                          top: "50%",
                          transform: "translateY(-50%)",
                          height: "4rem", 
                          borderRight: "1px solid #A3978F",
                        },
                      },
                    },
                  }}
                >
                  {steps.map((label, index) => {
                    const stepProps: { completed?: boolean } = {};

                    const steprwords = label.split(" ");
                    return (
                      <Step key={label} {...stepProps}
                      sx={{
                        position: "relative",
                        "@media (min-width: 600px)": {
                          "&:not(:last-child)::after": {
                            content: '""',
                            position: "absolute",
                            right: "-4rem",
                            top: "50%",
                            transform: "translateY(-50%)",
                            height: "4rem", 
                            borderRight: "1px solid #A3978F",
                          },
                        },
                      }}
                      >
                        <CustomStepLabel
                          StepIconComponent={(props) => (
                            <CustomStepIcon {...props} />
                          )}
                        >
                          {steprwords.map((steprwords, i) => (
                            <span key={i} style={{ display: "block"}}>
                              {steprwords}
                            </span>
                          ))}
                        </CustomStepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
                <Paper style={webStyle.paper} className="paperContent">

                {this.state.activeStep === 0 && (
                  <>
                  <Grid container className="fieldContainer">
                    <Grid item md={3} xs={12}>
                      <Box data-testId="tenant-data" onClick={this.handleAddTenant}>
                        <label data-test-id="name-field" onClick={this.getPetData} style={webStyle.labeltext} className="nameText">Name</label>
                      </Box>
                    </Grid>
                    <Grid item md={7} xs={12} >
                      <Box>
                        <TextField
                          className="textField"
                          sx={webStyle.marginManagefieldData}
                          variant="outlined"
                          fullWidth
                          size="small"
                          value={this.state.name}
                          data-testId="name-field-value"
                          onChange={(event) => {
                            this.onValueChange("name", event.target.value)
                          }}
                        />
                        {this.handleName()}
                      </Box>
                    </Grid>
                  </Grid>
                  <DividerEnrolfirst/>

                  <Grid container className="fieldContainer" >
                    <Grid item md={3} xs={12}>
                      <Box  >
                        <label className="nameText" style={webStyle.labeltext}>Date of birth</label>
                    </Box>
                    </Grid>
                    <Grid item md={7} xs={12}>
                      <Box >
                        <label style={webStyle.datelabeltext} className="dateTextLabel">Date</label>
                        <CustomDatePickerData
                            value={this.state.dateOfBirthDate}
                            required={false}
                            onChange={this.handleDateChange} 
                            maxDate={new Date(new Date().setDate(new Date().getDate() - 1))}
                          />
                          {this.handleDate()}
                      </Box>
                    </Grid>
                  </Grid>
                  <DividerEnrolfirst/>

                  <Grid container className="fieldContainer">
                    <Grid item md={3} xs={12}>
                      <Box>
                        <label style={webStyle.labeltext} className="nameText">Address</label>
                      </Box>
                    </Grid>
                    <Grid item md={7} xs={12} >
                      <Box>
                        <TextField
                          className="textField"
                          sx={webStyle.marginManagefieldData}
                          data-testId="address-field-value"
                          variant="outlined"
                          size="small"
                          fullWidth
                          value={this.state.address}
                          onChange={(event) => {
                            this.onValueChange("address", event.target.value)
                          }}
                        />
                        {this.handleAddr()}
                      </Box>
                    </Grid>
                  </Grid>
                  <DividerEnrolfirst/>

                  <Grid container className="fieldContainer" >
                    <Grid item md={3} xs={12}>
                      <Box  >
                        <label style={webStyle.labeltext} className="nameText">Email</label>
                      </Box>
                    </Grid>
                    <Grid item md={7} xs={12} >
                      <Box>
                        <TextField
                          className="textField"
                          variant="outlined"
                          data-testId="email-field-value"
                          fullWidth
                          sx={webStyle.marginManagefieldData}
                          size="small"
                          value={this.state.email}
                          onChange={(event) => {
                            this.onValueChange("email", event.target.value);
                          }}
                        />
                        <label style={webStyle.emailtext}>example@example.com</label>
                        {this.handleEmail()}
                      </Box>
                    </Grid>
                  </Grid>
                  <DividerEnrolfirst/>

                  <Grid container spacing={4} className="fieldContainerArea">
                    <Grid item md={3} xs={12}>
                      <Box>
                        <label style={webStyle.labeltext} className="phoneText">Phone number</label>
                      </Box>
                    </Grid>

                    <Grid item md={2} xs={4}>
                     <Box >
                        <label style={webStyle.datelabeltext}>Area Code</label>
                        <TextField
                          fullWidth
                          variant="outlined"
                          sx={webStyle.marginManageData}
                          data-testId="area-code"
                          size="small"
                          value={this.state.areaCode}
                          onChange={(event) => {
                            this.onValueChange("areaCode", event.target.value);
                          }}
                        />
                        {this.handleCode()}
                      </Box>
                    </Grid>

                    <Grid item md={5} xs={8}>
                    <Box >
                        <label style={webStyle.datelabeltext}>Phone number</label>
                        <TextField
                          variant="outlined"
                          fullWidth
                          sx={webStyle.marginManageData}
                          data-testId="phone-number"
                          size="small"
                          value={this.state.phoneNumber}
                          onChange={(event) => {
                            this.onValueChange("phoneNumber", event.target.value);
                          }}
                        />
                        {this.handlePhone()}
                      </Box>
                    </Grid>
                  </Grid>
                  <DividerEnrolfirst/>

                  <Grid container className="fieldContainer" >
                    <Grid item md={3} xs={12}>
                      <Box  >
                        <label style={webStyle.labeltext} className="nameText">Insurance</label>
                      </Box>
                    </Grid>
                    <Grid item md={7} xs={12} >
                      <Grid item md={12} xs={12} className="fileUpload">
                      <FileUpload
                        label=""
                        name="formW9Files"
                        files={this.state.formFilesInsurance}
                        onFilesChange={this.handleFilesChange}
                        />
                        {this.handleInsu()}
                      </Grid>
                    </Grid>
                  </Grid>
                  <DividerEnrolfirst/>

                  <Grid container className="fieldContainer" >
                    <Grid item md={3} xs={12}>
                      <Box  >
                        <label style={webStyle.labeltext} className="nameText">Renewal date</label>
                    </Box>
                    </Grid>
                    <Grid item md={7} xs={12}>
                      <Box >
                        <label className="dateTextLabel" style={webStyle.datelabeltext}>Date</label>
                        <CustomDatePickerData
                          value={this.state.renewalDate}
                          required={false}
                          onChange={this.handleRenewalDateChange} 
                        />
                        {this.renewalDate()}
                      </Box>
                    </Grid>
                  </Grid>
                  <DividerEnrolfirst/>

                  <Grid container className="fieldContainer">
                    <Grid item md={3} xs={12}>
                      <Box  >
                        <label style={webStyle.labeltext}>Vehicles</label>
                      </Box>
                    </Grid>
                    <Grid item md={7} xs={12} >
                      <Box style={webStyle.marginBorder} className="vehicleContent">
                        <label style={webStyle.datelabeltext}>Class of vehicle</label>
                        <FormControl fullWidth sx={webStyle.marginManageData} size="small">
                          <Select
                            labelId="property-type-label"
                            sx={webStyle.hideDefaultIcon}
                            value={this.state.vehicleType} 
                            data-testId="vehicle-drop"
                            onChange={(event) => this.handleSelect(event.target.value)} 
                          >
                            {this.state.vehicleList?.map((nameData: {id: number, name: string}) => (
                              <MenuItem
                                data-testId="vehicle-data"
                                value={nameData.id}
                              >
                                {nameData.name}
                              </MenuItem>
                            ))}
                          </Select>
                          <img className="icon-down" src={downArrow} alt="down" />
                        </FormControl>
                        {this.state.errors.vehicleType && (
                          <Typography style={webStyle.errColor}>{this.state.errors.vehicleType}</Typography>
                        )}
                      </Box>

                      <Box style={webStyle.marginstyle} className="vehicleContent">
                        <label style={webStyle.datelabeltext}>Brand</label>
                        <TextField
                          variant="outlined"
                          fullWidth
                          sx={webStyle.marginManageData}
                          data-testId="brand-name"
                          size="small"
                          value={this.state.brand}
                          onChange={(event) => {
                            this.onValueChange("brand", event.target.value)
                          }}
                        />
                        {this.state.errors.brand && (
                          <Typography style={webStyle.errColor}>{this.state.errors.brand}</Typography>
                        )}
                      </Box>

                      <Grid container style={webStyle.marginstyle} className="vehicleContent">
                        <Grid item md={12} xs={12}>
                          <Box >
                            <label style={webStyle.datelabeltext}>Model</label>
                            <CustomYearPicker
                            value={this.state.modelYear}
                            required={false}
                            onChange={this.handleModelYearChange} 
                          />
                          {this.state.errors.modelYear && (
                              <Typography style={webStyle.errColor}>{this.state.errors.modelYear}</Typography>
                            )}
                          </Box>
                        </Grid>
                      </Grid>

                      <Box style={webStyle.marginstyle} className="vehicleContent">
                        <label style={webStyle.datelabeltext}>Vehicle registration plate</label>
                        <TextField
                          variant="outlined"
                          fullWidth
                          sx={webStyle.marginManageData}
                          data-testId="vehicle-plate"
                          size="small"
                          value={this.state.vehicleReg}
                          onChange={(event) => {
                            this.onValueChange("vehicleReg", event.target.value)
                          }}
                        />
                        {this.state.errors.vehicleReg && (
                              <Typography style={webStyle.errColor}>{this.state.errors.vehicleReg}</Typography>
                            )}
                      </Box>
                    </Grid>
                  </Grid>
                  <Box style={{}} className="addBtn">

                  <Button style={{ fontSize: "14px",
                                  fontWeight: 700,
                                  lineHeight: "22px",
                                  fontFamily: "Outfit",
                                  color: "#CC9200",
                                  textTransform: "capitalize", 
                                  gap: "5px"}}>
                  <img src={addIcon}/>Add vehicle</Button>
                  </Box>
                  <DividerEnrolfirst/>

                  <Grid container className="fieldContainer">
                    <Grid item md={3} xs={12}>
                      <Box  >
                        <label style={webStyle.labeltext}>Pets Information</label>
                      </Box>
                    </Grid>
                    <Grid item md={7} xs={12} >
                      <Box style={webStyle.marginBorder} className="vehicleContent">
                        <label style={webStyle.datelabeltext}>Type of pet</label>
                        <FormControl fullWidth sx={webStyle.marginManageData} size="small">
                          <Select
                            labelId="property-type-label"
                            sx={webStyle.hideDefaultIcon}
                            value={this.state.petType} 
                            data-testId="pet-drop"
                            onChange={(event) => this.handlePet(event.target.value)} 
                          >
                            {this.state.petList?.map((nameData: {id: number, name: string}) => (
                              <MenuItem
                                data-testId="pet-data"
                                value={nameData.id} 
                                key={nameData.id} 
                              >
                                {nameData.name}
                              </MenuItem>
                            ))}
                          </Select>
                          <img className="icon-down" src={downArrow} alt="down" />
                        </FormControl>
                        {this.state.errors.petType && (
                          <Typography style={webStyle.errColor}>{this.state.errors.petType}</Typography>
                        )}
                      </Box>

                      <Box style={webStyle.marginstyle} className="vehicleContent">
                        <label style={webStyle.datelabeltext}>Pet Name</label>
                        <TextField
                          variant="outlined"
                          fullWidth
                          sx={webStyle.marginManageData}
                          data-testId="pet-name"
                          size="small"
                          value={this.state.petName}
                          onChange={(event) => {
                            this.onValueChange("petName", event.target.value)
                          }}
                        />
                        {this.state.errors.petName && (
                          <Typography style={webStyle.errColor}>{this.state.errors.petName}</Typography>
                        )}
                      </Box>

                      <Grid container style={webStyle.marginstyle} className="vehicleContent">
                        <Grid item md={12} xs={12}>
                          <Box >
                            <label style={webStyle.datelabeltext}>Age</label>
                            <CustomYearPicker
                              value={this.state.ageYear}
                              required={false}
                              onChange={this.handleAgeYearChange} 
                            />
                            {this.state.errors.ageYear && (
                              <Typography style={webStyle.errColor}>{this.state.errors.ageYear}</Typography>
                            )}
                          </Box>
                        </Grid>
                      </Grid>

                      <Box style={webStyle.marginstyle}>
                        <label style={webStyle.datelabeltext}>Pet ID</label>
                          <Grid item md={12} className="fileUpload" xs={12}>
                            <FileUpload
                              label=""
                              name="formW9Files"
                              files={this.state.formFilesPet}
                              onFilesChange={this.handleFilesPetChange}
                            />
                            {this.state.errors.formFilesPet && (
                              <Typography style={webStyle.errColor}>{this.state.errors.formFilesPet}</Typography>
                            )}
                          </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                  <Box style={{ marginLeft: "73%"}}>
                    <Button style={{ fontSize: "14px",
                                    fontWeight: 700,
                                    lineHeight: "22px",
                                    fontFamily: "Outfit",
                                    color: "#CC9200",
                                    textTransform: "capitalize", 
                                    gap: "5px"}}>
                    <img src={addIcon}/>Add pet</Button>
                  </Box>



                  <Grid container className="fieldContainer">
                    <Grid item md={3} xs={12}>
                      <Box>
                        <label style={webStyle.labeltext} className="nameText">Number of Occupants</label>
                      </Box>
                    </Grid>
                    <Grid item md={7} xs={12} >
                      <Box>
                      <TextField
                        className="textField"
                        sx={webStyle.marginManagefieldData}
                        variant="outlined"
                        fullWidth
                        size="small"
                        value={this.state.occupent}
                        data-testId="Occupantsfield-value"
                        onChange={(event) => {
                          const value = event.target.value;

                          // Ensure only numbers between 1 and 10 are accepted
                          if (value === '' || (Number(value) >= 1 && Number(value) <= 10)) {
                            this.onValueChange("occupent", value);
                          }
                        }}
                        inputProps={{
                          inputMode: 'numeric', // Mobile devices will show the numeric keypad
                          pattern: '[0-9]*',    
                        }}
                      />

                        {this.handleoccupent()}
                      </Box>
                    </Grid>
                  </Grid>
                </>
                )}
                {this.state.activeStep === 1 && (
                  <>
                  <Grid container className="fieldContainer">
                    <Grid item md={3} xs={12}>
                      <Box  >
                        <label style={webStyle.labeltext}>Rental Information</label>
                      </Box>
                    </Grid>
                    <Grid item md={7} xs={12} >
                      <Box  style={webStyle.marginBorder} className="vehicleContent">
                        <label style={webStyle.datelabeltext} className="dateTextLabel">Date of first payment due</label>
                        <CustomDatePickerData
                            value={this.state.dateOffirstPayment}
                            required={false}
                            onChange={this.handledateOffirstPayment} 
                          />
                          {this.handledateOfPayment()}
                      </Box>

                      <Box style={webStyle.marginstyle} className="vehicleContent">
                        <label style={webStyle.datelabeltext} className="nameText">Pay Period</label>
                        <TextField
                          className="textField"
                          sx={webStyle.marginManagefieldData}
                          variant="outlined"
                          fullWidth
                          size="small"
                          value={this.state.payPeriod}
                          data-testId="PayPerriode-field-value"
                          onChange={(event) => {
                            const newValue = event.target.value;
                            if (/^\d*$/.test(newValue)) { 
                              this.onValueChange("payPeriod", newValue);
                            }
                          }}
                        />
                        {this.handlepayPeriod()}
                      </Box>

                      <Box style={webStyle.marginstyle} className="vehicleContent">
                        <label style={webStyle.datelabeltext} className="nameText">Rent amount per month</label>
                        <TextField
                          className="textField"
                          sx={webStyle.marginManagefieldData}
                          variant="outlined"
                          fullWidth
                          size="small"
                          value={this.state.rent}
                          data-testId="rent-per-month"
                          onChange={(event) => {
                            this.onValueChange("rent", event.target.value)
                          }}
                        />
                        {this.handleRent()}
                      </Box>

                      <Box style={webStyle.marginstyle} className="vehicleContent">
                        <label style={webStyle.datelabeltext}>Security deposit</label>
                        <TextField
                          variant="outlined"
                          fullWidth
                          sx={webStyle.marginManageData}
                          data-testId="security-deposit"
                          size="small"
                          value={this.state.SecurityDeposit}
                          onChange={(event) => {
                            this.onValueChange("SecurityDeposit", event.target.value)
                          }}
                        />
                        {this.handleSecurityDeposit()}
                      </Box>

                      <Box style={webStyle.marginstyle} className="vehicleContent">
                        <label style={webStyle.datelabeltext}>Late Charges</label>
                        <TextField
                          variant="outlined"
                          fullWidth
                          sx={webStyle.marginManageData}
                          data-testId="late-charges"
                          size="small"
                          value={this.state.LateCharges}
                          onChange={(event) => {
                            this.onValueChange("LateCharges", event.target.value)
                          }}
                        />
                        {this.handleLateCharges()}
                      </Box>

                      <Box style={webStyle.marginBorderPay} className="vehicleContent">
                        <label style={webStyle.datelabeltext}>Payment type</label>
                        <FormControl fullWidth sx={webStyle.marginManageData} size="small">
                          <Select
                            labelId="property-type-label"
                            sx={webStyle.hideDefaultIcon}
                            value={this.state.payType}
                            data-testId="payment-drop"
                            onChange={(event) => this.handlePayment(event.target.value)}
                          >
                            {paymentData.map((nameData: string) => (
                              <MenuItem
                                data-testId="payment-data"
                                value={nameData}
                                key={nameData}
                              >
                                {nameData}
                              </MenuItem>
                            ))}
                          </Select>
                          <img className="icon-down" src={downArrow} alt="down" />
                        </FormControl>
                        {this.handleTypePayment()}
                      </Box>

                      <Box style={webStyle.marginstyle} className="vehicleContent">
                        <label style={webStyle.datelabeltext}>Collected by</label>
                        <TextField
                          variant="outlined"
                          fullWidth
                          sx={webStyle.marginManageData}
                          data-testId="collectedBy"
                          size="small"
                          value={this.state.collectedBy}
                          onChange={(event) => {
                            this.onValueChange("collectedBy", event.target.value)
                          }}
                        />
                        {this.handleCollect()}
                      </Box>
                    </Grid>
                  </Grid>
                  <DividerEnrolfirst/>

                  <Grid container className="fieldContainer">
                    <Grid item md={3} xs={12}>
                      <Box  >
                        <label style={webStyle.labeltext}>Lease details</label>
                      </Box>
                    </Grid>
                    <Grid item md={7} xs={12} >
                      <Box  style={webStyle.marginBorder} className="vehicleContent">
                        <label style={webStyle.datelabeltext} className="dateTextLabel">Lease start date</label>
                        <CustomDatePickerData
                            value={this.state.LeaseStartDate}
                            required={false}
                            onChange={this.handleLeaseDateChange} 
                          />
                          {this.handleLeaseDate()}
                      </Box>

                      <Box style={webStyle.marginstyle} className="vehicleContent">
                        <label style={webStyle.datelabeltext} className="nameText">Address</label>
                        <TextField
                          data-testId="leaseaddress-field"
                          className="textField"
                          sx={webStyle.marginManagefieldData}
                          variant="outlined"
                          fullWidth
                          size="small"
                          value={this.state.leaseaddress}
                          onChange={(event) => {
                            this.onValueChange("leaseaddress", event.target.value)
                          }}
                        />
                        {this.leaseaddress()}
                      </Box>
                    </Grid>
                  </Grid>
                  <Box>
                    <Typography style={{ color: "green", display:"flex", alignItems:"center", justifyContent:"center"}}>{this.state.sucessMsg}</Typography>
                  </Box>
                  </>
                )}
                 <Box className="submitBtn">
                    <Button
                      data-testId = "backbuttonId"
                        variant="contained"
                        style={{
                            width: "84px",
                            height: "44px",
                            fontFamily: "outfit",
                            fontSize: "16px",
                            color: "#000000",
                            fontWeight: 600,
                            textAlign: "center",
                            backgroundColor: "#FFF1CC",
                            textTransform: "none",
                            marginTop: "60px",
                            display: this.state.activeStep > 0 ? 'inline' : 'none'

                        }}
                        onClick={this.handleBackMain}
                    >
                      {configJSON.backbutton}
                    </Button>

                    <Button 
                      style={{ fontSize: "14px",
                              fontWeight: 700,
                              lineHeight: "22px",
                              fontFamily: "Outfit",
                              color: "black",
                              textTransform: "capitalize", 
                              backgroundColor: "#FFC123",
                              width: "84px",
                              height: "44px",
                              marginTop: "60px",
                              gap: "5px"}}
                      onClick={this.state.activeStep === steps.length - 1
                        ? this.handleSubmit
                        : this.handleNext}
                      data-testId="submit-btn">
                        {this.state.activeStep === steps.length - 1
                        ? "Submit"
                        : "Next"}
                    </Button>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </NewTenantStyle>
        <PopupMessage
          data-test-id="popupId"
          open={this.state.messagePopup}
          type={this.state.messageType}
          message={this.state.messageForPopup}
          handleClose={this.handlePopupClose}
        />
   
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  marginManageData:{
    '& .MuiOutlinedInput-root': {
      borderRadius: 3,
      marginTop: "7px"
    }
  },
  marginManagefieldData:{
    '& .MuiOutlinedInput-root': {
      borderRadius: 3,
    }
  },
  maincontent: {
    position: "relative", 
    paddingBottom: "10px"
  },
  headingtextData: {
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "17px",
    fontFamily: "Outfit",
    color: "#000000"
  },
  labeltext: {
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "17px",
    fontFamily: "Outfit",
    color: "#BAB1AB",
  },
  paper: {
    border: "1px solid #FFD466", 
    padding: "1.5rem",
    marginTop: "65px"
  },
  borderstyle: {
    borderRadius: "8px", 
    marginTop: "2rem"
  },
  datelabeltext: {
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    fontFamily: "Outfit",
    color: "#000000",
    "@media(max-width:600px)": {
      display: "none"
    }
  },
  emailtext: {
    borderRadius: "8px", 
    marginTop: "2rem",
    fontSize: "14px",
    fontWeight: 700,
    color: "#A3978F",
    fontFamily: "Outfit",
  },
  marginBorder: {
    borderRadius: "8px"
  },
  marginstyle: {
    borderRadius: "8px", marginTop: "2rem"
  },
  newTenant: {
    padding: "0rem 2rem 2rem 2rem"
  },
  hideDefaultIcon: {
    '& .MuiSelect-icon': {
      display: 'none',
    },
  },
  errColor: {
    color: "red",
    fontSize: "12px",
    marginTop: "4px"
  },
  marginBorderPay: {
    borderRadius: "8px",
    marginTop: "2rem",
  },
};
// Customizable Area End
