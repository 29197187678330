Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "settings2";
exports.labelBodyText = "settings2 Body";
exports.getCountriesApiMethod = "GET";
exports.getCountriesAPIEndPoint = "/account_block/landlord_accounts/countries_listing";
exports.getUserListApiContentType = "application/json";
exports.getSettingsDataMethod = "GET";
exports.getSettingsDataAPIEndPoint = "/account_block/landlord_accounts";
exports.getSettingsDataApiContentType = "application/json";
exports.getPropertyListMethod = "GET";
exports.getPropertyListAPIEndPoint = "/account_block/properties/show_all_properties";
exports.getPropertyListApiContentType = "application/json";
exports.setSettingsMethod = "PUT";
exports.setSettingsAPIEndPoint = "account_block/landlord_accounts/landlord_account_setting";
exports.setSettingsApiContentType = "application/json";
exports.btnExampleTitle = "CLICK ME";
exports.dashboardOrder = "Dashboard";
exports.myAccountOrder = "My Account";
exports.workOrder = "Work Orders";
exports.preferredOrder = "Preferred Vendors";
exports.remindersOrder = "Reminders";
exports.mypropOrder = "My Properties";
exports.financiaOrder = "Financial";
exports.reportOrder = "Delinquency Report";
exports.chatOrder = "Chat with Keasy";
exports.settingOrder = "Settings";
exports.dashboardOrderlabel = "Dashboard";
exports.myAccountOrderabel = "landlordaccount";
exports.worksOrderlabel = "Appointments";
exports.prevendorOrderlabel = "TaskList";
exports.reminOrderlabel = "AutomatedPrioritization";
exports.mypropOrderlabel = "PortfolioManagement";
exports.financOrderlabel = "Analytics";
exports.reportOrderlabel = "VisualAnalytics";
exports.chatOrderlabel = "Chat";
exports.settingOrderlabel = "Settings2";
exports.settings = "Settings";
exports.errorMessage = "error";
exports.successMessage = "success";
exports.documentlabel = "Cfdocumentforwarding4";
exports.serviceLable5 = "LandlordServiceRequestsDashboard";
exports.document = "Documents";
exports.serviceOrder5 = "Service Requests";
// Customizable Area End