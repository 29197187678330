import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { FormEvent } from "react";
import { AlertColor } from "@mui/material";
import { SelectChangeEvent } from '@mui/material/Select';
let host = require('../../../framework/src/config').baseURL;

export interface IAttribute {
  name: string;
  title: string;
  field_type: string;
  is_enable: boolean;
  is_required: boolean;
  value: string | number;
}

export interface IAttributeValues {
  name: string;
  value: string;
}

type FieldType =
  | "string"
  | "file"
  | "textarea"
  | "text"
  | "date"
  | "datetime"
  | "integer"
  | "number"
  | "boolean"
  | "float"
  | "checkbox";
type FieldValue = string | number | boolean | Date | null;
export interface IField {
  name: string;
  title: string;
  field_type: FieldType;
  is_enable: boolean;
  is_required: boolean;
}

interface IProfileDataWeb {
  attributes?: { user_profile_data: { [key: string]: FieldValue } };
}

export interface IProfileData {
  [key: string]: FieldValue;
}

export interface IProfileValues {
  value: string;
}

interface FileWithPreview extends File {
  preview: string;
}

interface FileObject {
  id: number;
  file_name: string;
  file_url: string;
  uploaded_date: string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  formType: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  profile: { [key: string]: FieldValue };
  fields: IField[];
  currentProfile?: { [key: string]: FieldValue };
  profileImageUrl: string;
  saveEnabled?: boolean;
  cancelEnabled?: boolean;

  txtInputValue: string;
  txtSavedValue: string;

  first_name: string;
  lastName: string;
  fullName: string;
  email: string;
  phoneNumber: string;
  city: string;
  address: string;
  attributesValues: IProfileValues[];
  countryOpen: boolean;
  postalCode: string;
  profilePicture: string;
  profileRole: string;
  aboutMe: string;
  gender: string;
  user_name: string;
  dateOfBirth: string;
  currentPassword: string;
  newPassword: string;
  reNewPassword: string;
  instagram: string;
  facebook: string;
  youtube: string;
  qrCode: string;
  profileId: string;
  user_type: string;
  imageModalVisible: boolean;
  userAge: number;
  biography: string;
  video: string;
  attributes: IAttribute[];

  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  enableNewPasswordField: boolean;

  edtEmailEnabled: boolean;
  llDoChangePwdContainerVisible: boolean;
  llChangePwdDummyShowContainerVisible: boolean;
  isDatePickerVisible: boolean;

  edtMobileNoEnabled: boolean;
  countryCodeEnabled: boolean;

  saveButtonDisable: boolean;
  enableField: boolean;
  currentForm: string;
  activeVendorItem: string;
  openVendorDrawer: boolean;
  trade: string;
  isCountryDropdownActive: boolean;
  vendorData: {
    name: string;
    vendor_type: string;
    vendor_trade: string[];
    minimum_cost: number;
    mon_fri_working_hours: string;
    sat_working_hours: string;
    sun_working_hours: string;
    country_code: string;
    phone_number: number;
    email_address: string;
    address: string;
    taxpayer_name: string;
    taxpayer_id: number;
    send_1099: boolean;
    online_payables: boolean;
    vendor_bank_routing_number: number;
    vendor_bank_account_number: number;
    upload_website_link: string;
    payment_type: string
    venmo_account_number: string;
    zelle_account_number: number;
    notes: string;
    comments: string;
    business_license_exp_date: string;
    business_insurance_exp_date: string;
    epa_license_exp_date: string;
    other_certificate_exp_date: string;
    state_license_exp_date: string;
    state_insurance_exp_date: string;
    state_licenses: FileObject[];
    form_w9: FileObject[];
    voided_checks: FileObject[];
    business_licenses: FileObject[];
    business_insurances: FileObject[];
    epa_certification_licenses: FileObject[];
    epa_other_certificates: FileObject[];
    state_insurances: FileObject[];
  };
  formW9Files: FileWithPreview[];
  voidedCheckFiles: FileWithPreview[];
  businessLicense: FileWithPreview[];
  businessInsurance: FileWithPreview[];
  EpaCertificationLicense: FileWithPreview[];
  otherCertificate: FileWithPreview[];
  stateLicense: FileWithPreview[];
  stateInsurance: FileWithPreview[];
  licenseExpDate: Date | null;
  insuranceExpDate: Date | null;
  EpaCertLicenseExpDate: Date | null;
  otherCertificateExpDate: Date | null;
  stateLicenseExpDate: Date | null;
  stateInsuranceExpDate: Date | null;
  send1099: string;
  onlinePayables: string;
  messageOpen: boolean;
  messageType: AlertColor;
  message: string;
  errors: {
    vendorName: boolean;
    vendorTrade: boolean;
    minCost: boolean;
    monFriTime: boolean;
    satTime: boolean;
    sunTime: boolean;
    phone: boolean;
    email: boolean;
    formW9Files: boolean;
    vendor_bank_routing_number: boolean;
    vendor_bank_account_number: boolean;
    businessLicense: boolean;
    licenseExpDate: boolean;
    businessInsurance: boolean;
    insuranceExpDate: boolean;
  };
  countries: Array<{ name: string; emoji_flag: string; country_code: string }>;
  allVendorTypes: string[];
  minCost: string;
  monFriStartTime: string;
  monFriEndTime: string;
  satStartTime: string;
  satEndTime: string;
  sunStartTime: string;
  sunEndTime: string;
  
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomisableUserProfilesController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getCustomizableProfileCallId: string = "";
  getCustomizableProfileFieldsCallId: string = "";
  updateCustomizableProfileCallId: string = "";
  getProfileAccountID: string = "";
  updateProfileID: string = "";
  getAttributesID: string = "";
  token: string = "";
  intervalId: number = 0;

  labelEmail: string = "";

  btnTextCancelPasswordChange: string = "";
  btnTextSaveChanges: string = "";
  labelHeader: string = "";
  btnTextChangePassword: string = "";

  apiCallMessageUpdateProfileRequestId: string = "";
  validationApiCallId: string = "";
  apiChangePhoneValidation: string = "";
  registrationAndLoginType: string = "";
  authToken: string = "";
  uniqueSessionRequesterId: string = "";
  userProfileGetApiCallId: string = "";
  getContriesListApiCallId: string = "";
  updateVendorDetailsApiId: string = "";
  getVendorTypesApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      profile: {},
      profileImageUrl: "",
      fields: [],

      attributes: [],
      attributesValues: [],
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      first_name: "",
      lastName: "",
      fullName: "",
      email: "",
      city: "",
      address: "",
      postalCode: "",
      profilePicture: "",
      profileRole: "jobseeker",
      aboutMe: "",
      gender: "",
      user_name: "",
      dateOfBirth: "",
      biography: "",
      currentPassword: "",
      newPassword: "",
      reNewPassword: "",
      youtube: "",
      instagram: "",
      facebook: "",
      qrCode: "",
      profileId: "",
      countryOpen: false,
      user_type: "",
      imageModalVisible: false,
      userAge: 0,
      phoneNumber: "",
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      enableNewPasswordField: true,
      video: "",

      edtEmailEnabled: true,
      llDoChangePwdContainerVisible: false,
      llChangePwdDummyShowContainerVisible: false,
      isDatePickerVisible: false,

      edtMobileNoEnabled: true,
      countryCodeEnabled: true,
      saveButtonDisable: false,
      currentForm: "VendorInformation",
      activeVendorItem: "UserProfileBasicBlock",
      openVendorDrawer: false,
      trade: "",
      isCountryDropdownActive: false,
      vendorData: {
        name: "",
        vendor_type: "",
        vendor_trade: [],
        minimum_cost: 0,
        mon_fri_working_hours: "",
        sat_working_hours: "",
        sun_working_hours: "",
        country_code: "",
        phone_number: 0,
        email_address: "",
        address: "",
        taxpayer_name: "",
        taxpayer_id: 0,
        send_1099: false,
        online_payables: false,
        vendor_bank_routing_number: 0,
        vendor_bank_account_number: 0,
        upload_website_link: "",
        payment_type: "",
        venmo_account_number: "",
        zelle_account_number: 0,
        notes: "",
        comments: "",
        business_license_exp_date: "",
        business_insurance_exp_date: "",
        epa_license_exp_date: "",
        other_certificate_exp_date: "",
        state_license_exp_date: "",
        state_insurance_exp_date: "",
        state_licenses: [],
        form_w9: [],
        voided_checks: [],
        business_licenses: [],
        business_insurances: [],
        epa_certification_licenses: [],
        epa_other_certificates: [],
        state_insurances: []
      },
      formW9Files: [],
      voidedCheckFiles: [],
      businessLicense: [],
      businessInsurance: [],
      EpaCertificationLicense: [],
      otherCertificate: [],
      stateLicense: [],
      stateInsurance: [],
      licenseExpDate: null,
      insuranceExpDate: null,
      EpaCertLicenseExpDate: null,
      otherCertificateExpDate: null,
      stateLicenseExpDate: null,
      stateInsuranceExpDate: null,
      send1099: "",
      onlinePayables: "",
      messageOpen: false,
      messageType: "success",
      message: "Form submitted successfully.",
      errors: {
        vendorName: false,
        vendorTrade: false,
        minCost: false,
        monFriTime: false,
        satTime: false,
        sunTime: false,
        phone: false,
        email: false,
        formW9Files: false,
        vendor_bank_routing_number: false,
        vendor_bank_account_number: false,
        businessLicense: false,
        licenseExpDate: false,
        businessInsurance: false,
        insuranceExpDate: false,
      },
      countries: [],
      allVendorTypes: [],
      minCost: "",
      monFriStartTime: "",
      monFriEndTime: "",
      satStartTime: "",
      satEndTime: "",
      sunStartTime: "",
      sunEndTime: "",

      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    this.getVendorProfile();
    this.getCountries();
    this.getVendorTypes();
    // Customizable Area End
  }

  getToken = () => {
    const message: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(message);
  };

  onCustomizableProfileData = (data: IProfileDataWeb | null) => {
    if (data === null) return this.setState({ currentProfile: { ...this.state.profile } });
    if (!data) return;
    if (!data.attributes || !data.attributes.user_profile_data)
      return this.setState({ currentProfile: { ...this.state.profile } });

    const newProfile = data.attributes.user_profile_data;
    this.setState({ profile: newProfile, currentProfile: { ...newProfile } });
  };

  checkSession = (message: Message) => {
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      if (
        !message.getData(getName(MessageEnum.SessionResponseToken)) ||
        message.getData(getName(MessageEnum.SessionResponseToken)) === "null"
      )
        this.token =
          "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6NDY3LCJleHAiOjE2NzkwNDI3NTAsInRva2VuX3R5cGUiOiJsb2dpbiJ9.zi-zzVPBT-4TQm8cQK8uK6qsoDqTIL_rT-LZX1sptxseOUYhWeHtibNbbmdRUJTSz2THXUUuNlBfkIH6QCFGgg";
      else this.token = message.getData(getName(MessageEnum.SessionResponseToken));

      const testToken = window.localStorage.getItem("testToken");
      if (testToken) this.token = testToken;
      runEngine.debugLog("TOKEN1", this.token);
      this.getCustomizableProfile();
      this.getCustomizableProfileFields();
      return true;
    }
  };

  checkProfileAccount = (message: Message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId === this.getProfileAccountID) {
      if (!responseJson.data || !responseJson.data.attributes) return;
      const dataResponse = responseJson;
      const userProfile = dataResponse.data.attributes.user_profile_data || {};
      const about_me =
        dataResponse.data.attributes.profile_bio &&
        dataResponse.data.attributes.profile_bio.about_me;
      const qr_code =
        dataResponse.data.attributes.qr_code && dataResponse.data.attributes.qr_code.qr_code;
      this.setState({
        address: dataResponse.data.attributes.address,
        user_name: userProfile.user_name,
        aboutMe: about_me,
        qrCode: qr_code,
        instagram: dataResponse.data.attributes.instagram,
        city: dataResponse.data.attributes.city,
        postalCode: dataResponse.data.attributes.postal_code,
        fullName: dataResponse.data.attributes.name,
        first_name: dataResponse.data.attributes.first_name,
        lastName: dataResponse.data.attributes.last_name,
        profilePicture: userProfile.profile_pic,
        user_type: userProfile.user_type,
        dateOfBirth: dataResponse.data.attributes.dob,
        video: dataResponse.data.attributes.video,
        youtube: dataResponse.data.attributes.youtube,
        facebook: dataResponse.data.attributes.facebook,
        biography: dataResponse.data.attributes.bio,
        attributesValues: Object.keys(dataResponse.data.attributes.user_profile_data).map(
          (item) => {
            return {
              ...this.state.attributes[(item as unknown) as number],
              value: dataResponse.data.attributes.user_profile_data[item]
            };
          }
        )
      });
      this.setState({
        attributes: this.state.attributesValues.map((item, index) => {
          return {
            ...this.state.attributes[index],
            value: item.value
          };
        })
      });
      return true;
    }
  };

  checkOtherResponses = (message: Message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (apiRequestCallId === this.getCustomizableProfileCallId) {
      this.onCustomizableProfileData(responseJson.data);
    } else if (apiRequestCallId === this.getCustomizableProfileFieldsCallId) {
      if (!responseJson.data) return;
      const fields = responseJson.data;
      this.setState({ fields });
    } else if (apiRequestCallId === this.updateCustomizableProfileCallId) {
      this.setState({
        currentProfile: { ...this.state.profile },
        saveEnabled: false,
        cancelEnabled: false
      });
    } else if (apiRequestCallId === this.updateProfileID) {
      this.fetchProfileData();
      alert("Profile Updated Successfully");
    } else if (apiRequestCallId === this.getAttributesID) {
      this.fetchProfileData();
      this.setState({
        attributes: responseJson.data.map((item: object) => {
          return {
            ...item,
            value: ""
          };
        })
      });
    }
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (this.checkSession(message)) return;
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));


      this.handleGetVendorDataResponse(message);
      this.handleGetCountriesAndVendorTypesResponse(message);
      this.handleFormSubmitResponse(message);

      if (responseJson) {
        if (responseJson.errors) return;
        this.checkOtherResponses(message);
        this.checkProfileAccount(message);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  handleChangeAttributeValue = (value: string, index: number) => {
    const temporaryValue = this.state.attributes;
    temporaryValue[index].value = value;
    this.setState({ attributes: temporaryValue });
  };

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  checkRequiredFields = () => {
    if (!this.state.saveEnabled) {
      for (const field of this.state.fields) {
        if (field.is_required && !this.state.profile[field.name]) return;
      }
      this.setState({ saveEnabled: true });
    } else {
      for (const field of this.state.fields) {
        if (field.is_required && this.state.profile[field.name] === undefined)
          return this.setState({ saveEnabled: false });
      }
    }
  };

  async componentDidUpdate() {
    if (this.state.currentProfile) {
      if (JSON.stringify(this.state.currentProfile) !== JSON.stringify(this.state.profile)) {
        if (!this.state.cancelEnabled) this.setState({ cancelEnabled: true });
        this.checkRequiredFields();
      }
    }
    // this.fetchAttributes();
  }

  changeFormValue = (
    valueKey: string,
    value: string | Date | number | boolean | null,
    fieldType: FieldType
  ) => {
    if (value !== undefined) {
      if (fieldType === "float") value = parseFloat(value as string);
      else if (fieldType === "integer") value = parseInt(value as string);
      else if (fieldType === "date") value = (value as Date).toDateString();
      else if (fieldType === "boolean") value = value === "true";
      else if (fieldType === "checkbox") value = !!value;
    }
    this.setState({ profile: { ...this.state.profile, [valueKey]: value } });
  };

  onKeyPress = (event: React.KeyboardEvent, field: IField) => {
    if (field.field_type === "integer" && (event.key === "." || event.key === ",")) {
      event.preventDefault();
    }
  };

  getCustomizableProfileFields = () => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCustomizableProfileFieldsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCustomizableProfileFieldsEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getCustomizableProfile = () => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCustomizableProfileCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCustomizableProfileEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  checkForRequiredFields = () => {
    const RequiredField = this.state.attributes.map((item: IAttribute) => {
      if (item.is_required && !(item.value as string).trim() && item.is_enable) {
        alert(`Please Enter ${item.name}`);
        return false;
      }
      return true;
    });
    if (RequiredField?.includes(false)) {
      return false;
    } else {
      this.updateProfile();
    }
  };

  cancelChanges = () => {
    if (this.state.currentProfile)
      this.setState({
        profile: { ...this.state.currentProfile },
        saveEnabled: false,
        cancelEnabled: false
      });
  };

  updateCustomizableProfile = () => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.token
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateCustomizableProfileCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateCustomizableProfileEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(this.state.profile)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchProfileData = async () => {
    const header = {
      "Content-Type": "application/json",
      token: configJSON.temporaryToken
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getProfileAccountID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseURLGetProfileAccount
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchAttributes = async () => {
    const header = {
      "Content-Type": "application/json",
      token: configJSON.temporaryToken
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getAttributesID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFields
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateProfile = async () => {
    const header = {
      "Content-Type": "application/json",
      token: configJSON.temporaryToken
    };

    const deliveredData = this.state.attributes
      .map((item) => {
        if (item && item.is_enable) {
          return {
            [item.name]: item.value
          };
        }
      })
      .filter((item) => item != undefined);

    const filteredPosts: IProfileData = {};

    deliveredData.map((item) => {
      filteredPosts[Object.keys(item as object)[0]] = Object.values(item as object)[0];
    });

    const dataToBeUsed = {
      profile: filteredPosts
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.updateProfileID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateProfileURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.putMethod);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(dataToBeUsed)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getVendorTypes = () => {
    const header = {
      token: localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getVendorTypesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getVendorTypesAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getVendorProfile = () => {
    const header = {
      token: localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userProfileGetApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getVendorProfileAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getCountries = () => {
    const header = {
      token: localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getContriesListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCountriesAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleVendoritemClick = (vendorKey: string) => {
    this.setState({ activeVendorItem: vendorKey }, () => {
      this.handleWorkOrderMenu();
    });
  };

  handleVendorDrawer = () => {
      this.setState({
          openVendorDrawer: !this.state.openVendorDrawer
      });
  };

  handleWorkOrderMenu = () => {
    const { activeVendorItem } = this.state;
    const toMsgWork = new Message(getName(MessageEnum.NavigationMessage));
    toMsgWork.addData(
      getName(MessageEnum.NavigationTargetMessage),
      activeVendorItem
    );
    toMsgWork.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(toMsgWork);
  };

  handlePopupMessageClose = () => {
    this.setState({
      messageOpen: false,
    });
  };

  handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    const phoneRegex = /^\d{0,10}$/;
    const accountNumberRegex = /^\d{0,25}$/;
    const taxpayerNameRegex = /^[a-zA-Z]*(?: [a-zA-Z]+)* ?$/;
    const taxpayerIdRegex = /^[0-9]*$/;

    if(name === "phone_number" && (!phoneRegex.test(value))) {
      return;
    }
    if(name === "venmo_account_number" && (!accountNumberRegex.test(value))) {
      return;
    }
    if(name === "zelle_account_number" && (!accountNumberRegex.test(value))) {
      return;
    }
    if(name === "taxpayer_name" && (!taxpayerNameRegex.test(value))) {
      return;
    }
    if(name === "taxpayer_id" && (!taxpayerIdRegex.test(value))) {
      return;
    }

    this.setState((prevState) => ({
      vendorData: {
        ...prevState.vendorData,
        [name]: value,
      },
    }));
    if(name === "send1099" || "onlinePayables") {
      this.setState({
        [name]: value,
      } as unknown as Pick<S, keyof S>);
    }
    if (name === "name") {
      this.validateName(value);
    } else if (name === "phone_number") {
      this.validatePhone(value);
    } else if (name === "vendor_bank_routing_number") {
      this.validateBankRoutingNumber(value);
    } else if (name === "email_address") {
      this.validateEmail(value);
    } else if (name === "vendor_bank_account_number") {
      this.validateBankAccountNumber(value);
    } else if(name === "minCost") {
      this.validateMinCost(value);
    } else if(name === "monFriStartTime") {
      this.compareTimes(value, this.state.monFriEndTime, "monFri");
    } else if(name === "monFriEndTime") {
      this.compareTimes(this.state.monFriStartTime, value, "monFri");
    } else if(name === "satStartTime") {
      this.compareTimes(value, this.state.satEndTime, "sat");
    } else if(name === "satEndTime") {
      this.compareTimes(this.state.satStartTime, value, "sat");
    }  else if(name === "sunStartTime") {
      this.compareTimes(value, this.state.sunEndTime, "sun");
    } else if(name === "sunEndTime") {
      this.compareTimes(this.state.sunStartTime, value, "sun");
    }
  };

  handleTradeInput = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = event.target;
    this.setState({
      trade: value,
    } as unknown as Pick<S, keyof S>);
  };

  handleAddTrade = () => {
    // Split the input trades string by commas, trim whitespace, and remove empty strings
    const newTrades = this.state.trade
      .split(",")
      .map((trade) => trade.trim())
      .filter((trade) => trade !== "");

    // Combine the new trades with the existing trades
    const combinedTrades = [...this.state.vendorData.vendor_trade, ...newTrades];

    // Remove duplicate values
    const uniqueTrades = combinedTrades.filter(
      (item, index) => combinedTrades.indexOf(item) === index
    );

    this.setState((prevState) => ({
      vendorData: {
        ...prevState.vendorData,
        vendor_trade: uniqueTrades, // Assuming comma-separated values
      },
    }), () => {
      this.validateTrade();
    });
    this.setState({trade: ""});
  };

  handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      this.handleAddTrade();
    }
  };

  handleDelete = (trade: string) => {
    const updatedTrade = this.state.vendorData.vendor_trade.filter(
      (item) => item !== trade
    );
    this.setState((prevState) => ({
      vendorData: {
        ...prevState.vendorData,
        vendor_trade: updatedTrade,
      },
    }), () => {
      this.validateTrade();
    });
  };

  handleCountryCodeDropdown = () => {
    this.setState({ isCountryDropdownActive: true })
  }

  handleCountryChange = (event: SelectChangeEvent<string>) => {
    this.setState((prevState) => ({
      vendorData: {
        ...prevState.vendorData,
        country_code: event.target.value,
      },
    }));
    this.setState({ isCountryDropdownActive: false });
  }

  handleFilesChange = (name: string, files: FileWithPreview[]) => {
    this.setState({
      [name]: files,
    } as unknown as Pick<S, keyof S>,
    () => {
      this.validateFiles(name);
    });
  };

  handleDateChange = (key: keyof S) => (newValue: Date | null) => {
    this.setState({ [key]: newValue } as unknown as Pick<S, keyof S>);
  };

  convertTo12HourFormat(time24: string) {
    // Split the input time string into hours and minutes
    let [hours, minutes] = time24.split(':');
    
    // Convert hours to a number
    let parsedHours = parseInt(hours, 10);
    
    // Determine AM/PM
    const period = parsedHours >= 12 ? 'PM' : 'AM';
    
    // Convert hours from 24-hour to 12-hour format
    parsedHours = parsedHours % 12 || 12;
    
    // Pad minutes with leading zero if necessary
    minutes = minutes.padStart(2, '0');
    
    // Return the formatted time string
    return `${parsedHours}:${minutes} ${period}`;
  };

  compareTimes = (start: string, end: string, slot: string) => {
    if(slot === "monFri" && start !== "" && end !== "") {
      const startTime = new Date(`1970-01-01T${start}:00`);
      const endTime = new Date(`1970-01-01T${end}:00`);

      if (endTime <= startTime) {
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            monFriTime: true,
          },
        }));
      } else {
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            monFriTime: false,
          },
        }));
      }

    } else if (slot === "sat" && start !== "" && end !== "") {
      const startTime = new Date(`1970-01-01T${start}:00`);
      const endTime = new Date(`1970-01-01T${end}:00`);

      if (endTime <= startTime) {
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            satTime: true,
          },
        }));
      } else {
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            satTime: false,
          },
        }));
      }
    } else if (slot === "sun" && start !== "" && end !== "") {
      const startTime = new Date(`1970-01-01T${start}:00`);
      const endTime = new Date(`1970-01-01T${end}:00`);

      if (endTime <= startTime) {
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            sunTime: true,
          },
        }));
      } else {
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            sunTime: false,
          },
        }));
      }
    }
  };

  validateName = (name: string) => {
    const regex = /^[a-zA-Z]+(?: [a-zA-Z]+)* ?$/;
    if (!regex.test(name)) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          vendorName: true,
        },
      }));
    } else {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          vendorName: false,
        },
      }));
    }
  }

  validatePhone = (phone: string) => {
    const phoneRegex = /^\d{8,15}$/;
    if (!phoneRegex.test(phone)) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          phone: true,
        },
      }));
    } else {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          phone: false,
        },
      }));
    }
  }

  validateBankRoutingNumber = (number: string) => {
    const routingRegex = /^[a-zA-Z0-9]{9,34}$/;
    if (!routingRegex.test(number)) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          vendor_bank_routing_number: true,
        },
      }));
    } else {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          vendor_bank_routing_number: false,
        },
      }));
    }
  }

  validateTrade = () => {
    if (this.state.vendorData.vendor_trade.length === 0) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          vendorTrade: true,
        },
      }));
    } else {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          vendorTrade: false,
        },
      }));
    }
  }

  validateEmail = (email: string) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(email)) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          email: true,
        },
      }));
    } else {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          email: false,
        },
      }));
    }
  };

  validateBankAccountNumber = (number: string) => {
    const accountRegex = /^[a-zA-Z0-9]{9,34}$/;
    if (!accountRegex.test(number)) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          vendor_bank_account_number: true,
        },
      }));
    } else {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          vendor_bank_account_number: false,
        },
      }));
    }
  }

  validateMinCost = (cost: string) => {
    const costRegex = /^\d+$/;
    if (!costRegex.test(cost)) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          minCost: true,
        },
      }));
    } else {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          minCost: false,
        },
      }));
    }
  }

  validateFiles = (name: string) => {
    if(name === "formW9Files") {
      if (this.state.formW9Files?.length === 0) {
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            formW9Files: true,
          },
        }));
      } else {
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            formW9Files: false,
          },
        }));
      }
    }
    else if(name === "businessLicense") {
      if (this.state.businessLicense?.length === 0) {
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            businessLicense: true,
          },
        }));
      } else {
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            businessLicense: false,
          },
        }));
      }
    }
    else if(name === "businessInsurance") {
      if (this.state.businessInsurance?.length === 0) {
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            businessInsurance: true,
          },
        }));
      } else {
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            businessInsurance: false,
          },
        }));
      }
    } 
  }

  validateForm = () => {
    const formType = this.props.formType || window.location.href.split('/').pop();
    switch (formType) {
      case "vendorinfo":
        this.validateFiles("formW9Files");
        this.validateTrade();
        if (
          this.state.vendorData.vendor_trade.length == 0 ||
          this.state.formW9Files?.length == 0 ||
          this.state.errors.vendorName ||
          this.state.errors.phone ||
          this.state.errors.email ||
          this.state.errors.minCost ||
          this.state.errors.monFriTime ||
          this.state.errors.satTime ||
          this.state.errors.sunTime
        ) {
          return false;
        }
        return true
      case "onlinepayables":
        if (
          this.state.errors.vendor_bank_routing_number ||
          this.state.errors.vendor_bank_account_number
        ) {
          return false;
        }
        return true
      case "complianceinfo":
        this.validateFiles("businessLicense");
        this.validateFiles("businessInsurance");
        if (
          this.state.businessLicense?.length == 0 ||
          this.state.businessInsurance?.length == 0
        ) {
          return false;
        }
        return true;
      }
  }

  getHelperText = (field: string) => {
    const { errors } = this.state;

    if(field === "name" && errors.vendorName) {
        return "Please enter a valid name using letters and spaces only.";
    }
    if(field === "phone_number" && errors.phone) {
      return "Please enter a valid phone number.";
    }
    if(field === "vendor_bank_routing_number" && errors.vendor_bank_routing_number) {
      return "The input must be between 9 and 34 characters long and contain only letters and numbers.";
    }
    if(field === "email_address") {
      if(errors.email){
        return "Please enter a valid email address.";
      } else {
        return "example@example.com";
      }
    }
    if(field === "vendor_bank_account_number" && errors.vendor_bank_account_number) {
      return "The input must be between 9 and 34 characters long and contain only letters and numbers.";
    }
    if(field === "minCost" && errors.minCost) {
      return "Please enter a valid minimum cost.";
    }
    return null;
  };

  getWorkingHours = (slot: string) => {
    if(slot === "mon-fri" && this.state.monFriStartTime !== "" && this.state.monFriEndTime !== ""){
      return `${this.state.monFriStartTime} - ${this.state.monFriEndTime}`
    }
    else if(slot === "sat" && this.state.satStartTime !== "" && this.state.satEndTime !== ""){
      return `${this.state.satStartTime} - ${this.state.satEndTime}`
    }
    else if(slot === "sun" && this.state.sunStartTime !== "" && this.state.sunEndTime !== ""){
      return `${this.state.sunStartTime} - ${this.state.sunEndTime}`
    }
    return ""
  }

  validateField = (field: string) => {
    switch (field) {
      case "vendorTrade":
        return this.state.errors.vendorTrade;
      case "formW9Files":
        return this.state.errors.formW9Files;
      case "businessLicense":
        return this.state.errors.businessLicense;
      case "businessInsurance":
        return this.state.errors.businessInsurance;
      case "monFri":
        return this.state.errors.monFriTime;
      case "sat":
        return this.state.errors.satTime;
      case "sun":
        return this.state.errors.sunTime;
      default:
        return false;
    }
  };

  fetchBlobWithXHR(url: string | null, callback: (arg0: any) => void) {
    if(url !== null){
      const xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.responseType = 'blob';
      xhr.onload = function() {
        if (xhr.status >= 200 && xhr.status < 300) {
          const blob = xhr.response;
          callback(blob);
        } else {
          console.error('Failed to fetch blob:', xhr.statusText);
        }
      };
      xhr.onerror = function() {
        console.error('Request error');
      };
      xhr.send();
    }
  };

  getFilenameFromUrl(url: string | null) {
    if(url !== null){
      // Parse the URL
      const parsedUrl = new URL(url);
      
      // Get the query parameters
      const params = new URLSearchParams(parsedUrl.search);
      
      // Get the 'response-content-disposition' parameter
      const contentDisposition = params.get('response-content-disposition');
      
      if (contentDisposition) {
        // Use a regular expression to find the filename
        const filenameRegex = /filename\*?=['"]?([^;]*)['"]?/i;
        const matches = filenameRegex.exec(contentDisposition);
        
        if (matches && matches[1]) {
          // Decode the filename
          const decodedFilename = decodeURIComponent(matches[1].replace(/['"]/g, ''));
          return decodedFilename;
        }
      }
    }
    return "attachment";
  }

  setDateIfExists(dateString: string | null) {
    let convertedDate = null;
    if (dateString !== null) {
      convertedDate = new Date(dateString);
    }
    return convertedDate;
  };

  handleGetVendorDataResponse = (message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.userProfileGetApiCallId) {
      if (responseJson?.data?.attributes) {
        //On Vendor Profile Success
        this.setState({vendorData: responseJson.data.attributes});
        const [monFriStartTime, monFriEndTime] = this.state.vendorData.mon_fri_working_hours.split(' - ');
        const [satStartTime, satEndTime] = this.state.vendorData.sat_working_hours.split(' - ');
        const [sunStartTime, sunEndTime] = this.state.vendorData.sun_working_hours.split(' - ');
        this.setState({
          minCost: String(this.state.vendorData.minimum_cost),
          monFriStartTime, monFriEndTime, satStartTime, satEndTime, sunStartTime, sunEndTime 
        })

        const send1099 = this.state.vendorData.send_1099 ? "yes" : "no";
        this.setState({ send1099 });
        const onlinePayables = this.state.vendorData.online_payables ? "yes" : "no";
        this.setState({ onlinePayables });

        this.fetchBlobWithXHR(this.state.vendorData.form_w9[0].file_url, (blob) => {
          const formW9File: FileWithPreview = Object.assign(new File([blob], this.state.vendorData.form_w9[0].file_name, { type: blob.type }), {
            preview: URL.createObjectURL(blob),
          });
  
          this.setState({ formW9Files: [formW9File]});
        });

        this.fetchBlobWithXHR(this.state.vendorData.business_licenses[0].file_url, (blob) => {
          const businessLicense: FileWithPreview = Object.assign(new File([blob], this.state.vendorData.business_licenses[0].file_name, { type: blob.type }), {
            preview: URL.createObjectURL(blob),
          });
  
          this.setState({ businessLicense: [businessLicense]});
        });

        this.fetchBlobWithXHR(this.state.vendorData.business_insurances[0].file_url, (blob) => {
          const businessInsurance: FileWithPreview = Object.assign(new File([blob], this.state.vendorData.business_insurances[0].file_name, { type: blob.type }), {
            preview: URL.createObjectURL(blob),
          });
  
          this.setState({ businessInsurance: [businessInsurance]});
        });

        const businessLicenseDate = new Date(this.state.vendorData.business_license_exp_date);
        const businessInsuranceDate = new Date(this.state.vendorData.business_insurance_exp_date);

        this.setState({ licenseExpDate: businessLicenseDate, insuranceExpDate: businessInsuranceDate});

        this.fetchBlobWithXHR(this.state.vendorData.voided_checks[0].file_url, (blob) => {
          const voidedCheck: FileWithPreview = Object.assign(new File([blob], this.state.vendorData.voided_checks[0].file_name, { type: blob.type }), {
            preview: URL.createObjectURL(blob),
          });
  
          this.setState({ voidedCheckFiles: [voidedCheck]});
        });
        
        this.fetchBlobWithXHR(this.state.vendorData.epa_certification_licenses[0].file_url, (blob) => {
          const epaLicense: FileWithPreview = Object.assign(new File([blob], this.state.vendorData.epa_certification_licenses[0].file_name, { type: blob.type }), {
            preview: URL.createObjectURL(blob),
          });
  
          this.setState({ EpaCertificationLicense: [epaLicense]});
        });
      
        this.fetchBlobWithXHR(this.state.vendorData.epa_other_certificates[0].file_url, (blob) => {
          const otherCertificate: FileWithPreview = Object.assign(new File([blob], this.state.vendorData.epa_other_certificates[0].file_name, { type: blob.type }), {
            preview: URL.createObjectURL(blob),
          });
  
          this.setState({ otherCertificate: [otherCertificate]});
        });
      
        this.fetchBlobWithXHR(this.state.vendorData.state_licenses[0].file_url, (blob) => {
          const stateLicense: FileWithPreview = Object.assign(new File([blob], this.state.vendorData.state_licenses[0].file_name, { type: blob.type }), {
            preview: URL.createObjectURL(blob),
          });
  
          this.setState({ stateLicense: [stateLicense]});
        });
      
        this.fetchBlobWithXHR(this.state.vendorData.state_insurances[0].file_url, (blob) => {
          const stateInsurance: FileWithPreview = Object.assign(new File([blob], this.state.vendorData.state_insurances[0].file_name, { type: blob.type }), {
            preview: URL.createObjectURL(blob),
          });
  
          this.setState({ stateInsurance: [stateInsurance]});
        });

        const epaLicenseDate = this.setDateIfExists(this.state.vendorData.epa_license_exp_date);
        this.setState({ EpaCertLicenseExpDate: epaLicenseDate });

        const otherCertificateDate = this.setDateIfExists(this.state.vendorData.other_certificate_exp_date);
        this.setState({ otherCertificateExpDate: otherCertificateDate });

        const stateLicenseDate = this.setDateIfExists(this.state.vendorData.state_license_exp_date);
        this.setState({ stateLicenseExpDate: stateLicenseDate });

        const stateInsuranceDate = this.setDateIfExists(this.state.vendorData.state_insurance_exp_date);
        this.setState({ stateInsuranceExpDate: stateInsuranceDate });

      } else {
        //Check Error Response
        if (
          responseJson?.errors[0]?.token
        ) {
          this.showAlert("Session Expired", "Please Log in again.");
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      }
    }
  };

  handleGetCountriesAndVendorTypesResponse = (message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(apiRequestCallId === this.getContriesListApiCallId) {
      let countries = responseJson?.data?.map((item: any) => {
        return {
          name: item.attributes.name,
          emoji_flag: item.attributes.emoji_flag,
          country_code: item.attributes.country_code,
        };
      });

      this.setState({ countries }, () => {});
    }
    if (apiRequestCallId === this.getVendorTypesApiCallId) {
      this.setState({ allVendorTypes: responseJson?.vendor_types});
    } 
  };

  handleFormSubmitResponse = (message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    
    if(apiRequestCallId === this.updateVendorDetailsApiId) {
      if (responseJson?.data?.attributes) {
        //On Vendor Onboarding Successfully

        this.setState({
          messageOpen: true,
          messageType: "success",
          message: "Form submitted successfully."
        });
        const toSendMessage = new Message(getName(MessageEnum.NavigationMessage));
        toSendMessage.addData(
          getName(MessageEnum.NavigationTargetMessage),
          "UserProfileBasicBlock"
        );
        toSendMessage.addData(
          getName(MessageEnum.NavigationPropsMessage),
          this.props
        );
        setTimeout(() => {
          this.send(toSendMessage);
        }, 2000);
      } else {
        //Check Error Response
        if (responseJson?.errors[0]?.token) {
          this.setState({
            messageOpen: true,
            messageType: "error",
            message: "Session Expired, Please Log in again."
          });
        } else {
          this.setState({
            messageOpen: true,
            messageType: "error",
            message: "An error occurred while processing your request. Please try again later."
          });
        }
      }
    }
  };

  handleFileFormSubmit = (formData: FormData) => {
    if(this.state.voidedCheckFiles?.length) {
      this.state.voidedCheckFiles.forEach((file) => {
        formData.append("vendor_account[voided_checks][]", file as Blob);
      });
    }
    if(this.state.businessLicense?.length) {
      this.state.businessLicense.forEach((file) => {
        formData.append("vendor_account[business_licenses][]", file as Blob);
      });
    }
    if (this.state.businessInsurance.length) {
      this.state.businessInsurance.forEach((file) => {
        formData.append("vendor_account[business_insurances][]", file as Blob);
      });
    }
    if(this.state.EpaCertificationLicense.length) {
      this.state.EpaCertificationLicense.forEach((file) => {
        formData.append("vendor_account[epa_certification_licenses][]", file as Blob);
      });
    }
    if(this.state.otherCertificate.length) {
      this.state.otherCertificate.forEach((file) => {
        formData.append("vendor_account[epa_other_certificates][]", file as Blob);
      });
    }
    if(this.state.stateLicense.length) {
      this.state.stateLicense.forEach((file) => {
        formData.append("vendor_account[state_licenses][]", file as Blob);
      });
    }
    if(this.state.stateInsurance.length) {
      this.state.stateInsurance.forEach((file) => {
        formData.append("vendor_account[state_insurances][]", file as Blob);
      });
    }
  };

  handleDateFormSubmit = (formData: FormData) => {
    formData.append("vendor_account[business_license_exp_date]", this.state.licenseExpDate ? this.state.licenseExpDate.toISOString().split("T")[0] : "");
    formData.append("vendor_account[business_insurance_exp_date]", this.state.insuranceExpDate ? this.state.insuranceExpDate.toISOString().split("T")[0] : "");
    formData.append("vendor_account[epa_license_exp_date]", this.state.EpaCertLicenseExpDate ? this.state.EpaCertLicenseExpDate.toISOString().split("T")[0] : "");
    formData.append("vendor_account[other_certificate_exp_date]", this.state.otherCertificateExpDate ? this.state.otherCertificateExpDate.toISOString().split("T")[0] : "");
    formData.append("vendor_account[state_license_exp_date]", this.state.stateLicenseExpDate ? this.state.stateLicenseExpDate.toISOString().split("T")[0] : "");
    formData.append("vendor_account[state_insurance_exp_date]", this.state.stateInsuranceExpDate ? this.state.stateInsuranceExpDate.toISOString().split("T")[0] : "");
  }

  handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (this.validateForm()) {
      const header = {
        token: localStorage.getItem("authToken"),
      };
  
      const formData = new FormData();
      formData.append(
        "vendor_account[name]",
        this.state.vendorData.name
      );
      formData.append(
        "vendor_account[vendor_type]",
        this.state.vendorData.vendor_type
      );
      formData.append(
        "vendor_account[minimum_cost]",
        String(this.state.minCost)
      );
      formData.append(
        "vendor_account[mon_fri_working_hours]",
        this.getWorkingHours("mon-fri")
      );
      formData.append(
        "vendor_account[sat_working_hours]",
        this.getWorkingHours("sat")
      );
      formData.append(
        "vendor_account[sun_working_hours]",
        this.getWorkingHours("sun")
      );
      this.state.vendorData.vendor_trade.forEach((trade) => {
        formData.append("vendor_account[vendor_trade][]", trade);
      });
      formData.append("vendor_account[country_code]", this.state.vendorData.country_code);
      formData.append("vendor_account[phone_number]", String(this.state.vendorData.phone_number));
      formData.append("vendor_account[email_address]", this.state.vendorData.email_address);
      formData.append("vendor_account[address]", this.state.vendorData.address);
      formData.append("vendor_account[taxpayer_name]", this.state.vendorData.taxpayer_name);
      formData.append("vendor_account[taxpayer_id]", String(this.state.vendorData.taxpayer_id));
      formData.append("vendor_account[send_1099]", this.state.send1099 === "yes" ? '1' : '0' );
      this.state.formW9Files.forEach((file) => {
        formData.append("vendor_account[form_w9][]", file as Blob);
      });

      formData.append("vendor_account[online_payables]", this.state.onlinePayables === "yes" ? '1' : '0' );
      formData.append("vendor_account[vendor_bank_routing_number]", String(this.state.vendorData.vendor_bank_routing_number));
      formData.append("vendor_account[vendor_bank_account_number]", String(this.state.vendorData.vendor_bank_account_number));
      formData.append("vendor_account[payment_type]", this.state.vendorData.payment_type);
      formData.append("vendor_account[upload_website_link]", this.state.vendorData.upload_website_link);
      formData.append("vendor_account[venmo_account_number]", this.state.vendorData.venmo_account_number);
      formData.append("vendor_account[zelle_account_number]", String(this.state.vendorData.zelle_account_number));
      formData.append("vendor_account[comments]", this.state.vendorData.comments);

      this.handleFileFormSubmit(formData);
      this.handleDateFormSubmit(formData);
      formData.append("vendor_account[notes]", this.state.vendorData.notes);
      
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.updateVendorDetailsApiId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.updateVendorDetailsApiEndPoint
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPutMethod
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  navigateToProfile = () => {
    const navigationMessage = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    navigationMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "UserProfileBasicBlock"
    );
    navigationMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    runEngine.sendMessage("MergeEngineUtilities", navigationMessage);
  };
  // Customizable Area End
}
