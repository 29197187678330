Object.defineProperty(exports, '__esModule', {
  value: true
});

// Customizable Area Start
exports.apiContentType = 'application/json';
exports.apiFormContentType = 'multipart/form-data';
exports.getApiMethod = 'GET';
exports.postApiMethod = 'POST';
exports.putApiMethod = 'PUT';

exports.getChatListApiEndPoint = 'chat/chats';
exports.showChatApiEndPoint = 'chat/chats';
exports.getChatHistoryApiEndPoint = 'chat/chats/history';
exports.searchMessageApiEndPoint = 'chat/chats/search_messages';
exports.searchChatGroupApiEndPoint = 'chat/chats/search';
exports.createChatRoomApiEndPoint = 'chat/chats';
exports.addUserToChatApiEndPoint = 'chat/chats/add_user';
exports.leaveChatApiEndPoint = 'chat/chats/leave';
exports.sendMessageApiEndPoint = 'chat/chats';
exports.updateReadMessageApiEndPoint = 'chat/chats/read_messages';
exports.updateChatApiEndPoint = 'chat/chats';

exports.getMyChatsApiEndpoint = 'chat/chats/mychats';

exports.createButtonText = 'Create';
exports.createRoomButtonText = 'Create Room'
exports.addButtonText = 'Add';
exports.closeButtonText = 'Close';
exports.addAccountText = 'Add Account';
exports.leaveChatRoomText = 'Leave Chat';
exports.sendText = 'Send';
exports.cancelText = 'Cancel';
exports.errorTitle = 'Error';
exports.errorAllFieldsAreMandatory = 'All fields are mandatory.';
exports.unreadMessageText = "Unread Messages";
exports.lastMessageText = "Last Message:";
exports.writeMessagePlaceholder = "Write message here";
exports.usersText = "Users:";
exports.accountIdPlaceholder = "Account ID";
exports.namePlaceholder = "Name";

exports.dashboardOrder = "Dashboard";
exports.myAccountOrder = "My Account";
exports.workOrder = "Work Orders";
exports.preferredOrder = "Preferred Vendors";
exports.remindersOrder = "Reminders";
exports.mypropOrder = "My Properties";
exports.financiaOrder = "Financial";
exports.reportOrder = "Delinquency Report";
exports.chatOrder = "Chat with Keasy";
exports.settingOrder = "Settings";
exports.dashboardOrderlabel = "Dashboard";
exports.myAccountOrderabel = "landlordaccount";
exports.worksOrderlabel = "Appointments";
exports.prevendorOrderlabel = "TaskList";
exports.reminOrderlabel = "AutomatedPrioritization";
exports.mypropOrderlabel = "PortfolioManagement";
exports.financOrderlabel = "Analytics";
exports.reportOrderlabel = "VisualAnalytics";
exports.chatOrderlabel = "Chat";
exports.settingOrderlabel = "Settings2";
exports.settings = "Settings";
exports.serviceRequests = "Service Requests";
exports.lease = "Lease";
exports.paymentHistory = "Payment History";
exports.tenantDashboard = "TenantDashboard";
exports.servicelabel = "ServicerequestDashboard";
exports.leaselabel = "DownloadOptions";
exports.paymentHistorylabel = "BaselineReporting";
exports.vendorProfile  = "Vendor Profile";
exports.projects = "Project History";
exports.vendorLabelProfile = "UserProfileBasicBlock";
exports.vendorProjects = "Tasks";
exports.invoiceLable = "Sent Invoices";
exports.invoiceBlock="InvoiceBilling";
exports.ApprovedOwnersLable="Approved Owners"
exports.ApprovedOwnersBlock = "AutomaticFormCreation";
exports.service1="Service Requests";
exports.getChatListApiEndPoint = "/bx_block_chat/chats/";
exports.getSearchedUsersApiEndPoint = "/bx_block_chat/chats/users_list?query=";
exports.serviceLable1="LandlordServiceRequestsDashboard";
exports.putReadChatMessagesApiEndPoint = "/bx_block_chat/chats/read_messages?chat_id=";
  // Customizable Area End