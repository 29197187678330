Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpPutMethod = "PUT";
exports.searchApiContentType = "application/json";
exports.getSearchApiEndPoint = "search/users";
exports.errorTitle = "Error";
exports.getCustomizableProfileEndPoint = "bx_block_profile/profile/current_user_profile";
exports.updateCustomizableProfileEndPoint = "bx_block_profile/profile/update_user_profile";
exports.getCustomizableProfileFieldsEndPoint =
"bx_block_profile/profile/custom_user_profile_fields";
exports.vendorProfile  = "Vendor Profile";
exports.projects = "Project History";
exports.vendorLabelProfile = "UserProfileBasicBlock";
exports.vendorProjects = "Tasks";
exports.invoiceLable = "Sent Invoices";
exports.invoiceBlock="InvoiceBilling";
exports.ApprovedOwnersLable="Approved Owners"
exports.ApprovedOwnersBlock = "AutomaticFormCreation";
exports.chatOrder = "Chat with Keasy";
exports.chatOrderLabel = "Chat";
exports.getVendorProfileAPIEndPoint = "/account_block/vendor_accounts/show_vendor";
exports.getCountriesAPIEndPoint = "/account_block/landlord_accounts/countries_listing";
exports.updateVendorDetailsApiEndPoint = "/account_block/vendor_accounts/update_vendor_information";
exports.getVendorTypesAPIEndPoint = "/account_block/vendor_accounts/filter_types";
// Customizable Area End
