import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import moment, { Moment } from "moment";
import { TimeSlot } from "./types";
import storage from "../../../framework/src/StorageProvider";

export interface WorkOrderDetails {
    id: number;
    work_needed: string;
    permission_to_enter: boolean;
    permission_description: null;
    availability: null;
    cost: boolean;
    description: string | null;
    status: string;
    scheduled_date: null;
    created_at: string;
    updated_at: string;
    created_by: null;
    sub_category: null;
    assigned_vendor: string;
    property: null;
    due_date_date: string;
    due_date_time: string;
    remind_me_date: string;
    remind_me_time: string;
    vendor_account_id: number;
    property_name: string;
    documents: Array<WorkDescriptions>;
    work_order_audits: Array<Updates>;
  }

export interface WorkDescriptions {
  id: number;
  file_name: string;
  file_url: string;
}

export interface Updates {
  date: string;
  updated_by: string;
  description: string;
  cost: boolean;
  status: string;
}
export interface Property {
  name: string;
  units: string[];
}

export interface InvalidResponseType {
  errors: string;
}

export interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  token?: string;
  body?: object;
  type?: string;
}

export interface Properties {
  data: Array<PropertiesDetails>;
}

export interface PropertiesDetails {
  id: string;
  type: string;
  attributes: {
    property_type: string;
    property_name: string;
    address: string;
    units: Array<Units>;
  }
}

export interface Units {
  id: number;
  unit_name: string;
  status: string;
  floor: string;
  entrance: string;
  code: string;
  tenants: [];
  landlord_work_orders: Array<WorkOrderDetails>;
}

export interface ApprovedDetails {
  message: string;
}

export interface VendorInformation {
  data: {
    id: string;
    type: string;
    attributes: {
      preferred_vendors: Array<PreferredVendor>;
    }
  }
}

export interface PreferredVendor {
  id: number;
  name: string;
  company_name: string;
  vendor_type: string;
}

// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  available_date: Moment;
  start_time: Moment | null;
  end_time: Moment | null;
  id: string | number;
  token: string;
  appointmentsList: TimeSlot[];
  showPicker: boolean;
  activeOrderItem: string;
  openWorkOrder: boolean;
  expanded: number | null;
  selected: {[unitId: number]: number[]};
  workOrderData: Array<WorkOrderDetails>;
  anchorEl: null | HTMLElement;
  selectedProperty: PropertiesDetails | null;
  selectedUnit: string | null;
  subMenuAnchorEl: null | HTMLElement;
  properties: PropertiesDetails[];
  selectedMenuItemIndex: null | number;
  approveDetails:  string[];
  openApprove:{[key: number]: boolean };
  selectedApprove: {[unitId: number]: string};
  selecteValueApprove: {[unitId: number]: string};
  anchorElement: { [key: number]: HTMLElement | null };
  showAllItems: boolean;
  itemsToShow: number;
  sortColumn: string;
  sortDirection: "asc" | "desc";
  anchorNewElement: { [key: number]: HTMLElement | null };
  sortState: {
    [key: number]: {
      sortColumn: string;
      sortDirection: "asc" | "desc";
    };
  },
  reloadLoader: boolean;
  messagePopup: boolean;
  messageForPopup: string;
  openFinKey: boolean;
  findKeyDetails: Units[];
  scheduledDate: Date | null;
  isDatePickerOpen: boolean;
  modifyDate: string;
  messageType: 'success' | 'error' | 'warning' | 'info';
  searchBar: string;
  propertiesName: string;
  chips: { propertyId: number; unitId: number; propertyName: string; unitName: string }[];
  originalProperties: PropertiesDetails[];
  words: Array<string>;
  tensValue: Array<string>; 
  modalDescription: string | null;
  openDescription: boolean;
  openLoader: boolean;
  vendorModal: boolean;
  workOrdersId: number;
  vendorsShowAll: PreferredVendor[];
  openNewVendors: boolean;
  anchorVendorEl: null | HTMLElement;
  // Customizable Area End
}

export interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AppointmentsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAppointmentsListApiCallId?: string;
  getWorkOrderApiCallId: string = "";
  getPropertiesApiCallId: string = "";
  postApprovedApiCallId: string = "";
  postWaitingApiCallId: string = "";
  postScheduledApiCallId: string = "";
  postVendorApiCallId: string = "";
  getOrigionalApiCallId: string = "";
  getPreferredVendorApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    let endTime = new Date();
    endTime.setMinutes(endTime.getMinutes() + 30);

    this.state = {
      // Customizable Area Start
      id: 0,
      start_time: moment(new Date()),
      end_time: moment(endTime),
      available_date: moment(new Date()),
      appointmentsList: [],
      token: "",
      showPicker: false,
      activeOrderItem: configJSON.worksOrderlabel,
      openWorkOrder: false,
      expanded: null,
      selected: {},
      workOrderData: [],
      anchorEl: null,
      selectedProperty: null,
      selectedUnit: null,
      subMenuAnchorEl: null,
      selectedMenuItemIndex: null,
      properties: [],
      approveDetails: ["Assign a vendor","Complete work", "Decline"],
      openApprove: {},
      selectedApprove: {},
      selecteValueApprove: {},
      anchorElement: {},
      showAllItems: false,
      itemsToShow: 4,
      sortColumn: "id",
      sortDirection: "asc",
      anchorNewElement: {},
      sortState: {},
      reloadLoader: false,
      messagePopup: false,
      messageForPopup: "",
      openFinKey: false,
      findKeyDetails: [],
      scheduledDate: null,
      isDatePickerOpen: false,
      modifyDate: "",
      messageType:  "success",
      searchBar: "",
      propertiesName: "",
      chips: [],
      originalProperties: [], 
      words: [
        "Zero", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine", "Ten",
        "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"
      ],
      tensValue: ["", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"],
      modalDescription: "",
      openDescription: false,
      openLoader: true,
      vendorModal: false,
      workOrdersId: 0,
      vendorsShowAll: [],
      openNewVendors: false,
      anchorVendorEl: null
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    await super.componentDidMount();
    this.getToken();
    if (!this.isPlatformWeb()) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.getPropertiesDetails("","");
    this.getOrigionalPropertiesDetails();
    this.getPreferredVendors();
    // Customizable Area End
  }

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  receive = async (from: String, message: Message) => {
    // Customizable Area Start
    runEngine.debugLog("Message Received", message);

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
      token && this.getAppointmentList(token);
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getAppointmentsListApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors && responseJson.data) {
        this.setState({
          appointmentsList: responseJson.data.attributes.time_slots,
        });
      } else {
        const errorResponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        this.parseApiCatchErrorResponse(errorResponse);

        this.setState({ appointmentsList: [] });
      }
    }
    this.handleWorkOrders(message);
    // Customizable Area End
  };

  // Customizable Area Start

  getAppointmentList = (token: string) => {
    const header = {
      "Content-Type": configJSON.appointmentApiContentType,
      token,
    };

    const searchParams = {
      service_provider_id: "1",
      availability_date: this.state.available_date.format(
        configJSON.serverFormat
      ),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAppointmentsListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.appointmentAPiEndPoint}?${new URLSearchParams(searchParams).toString()}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAppointmentListAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  update(value: Partial<{ [K in keyof S]: S[K] }>) {
    this.setState((state) => ({ ...state, ...value, showPicker: false }));
  }

  getDate(value: string, format: string) {
    return moment(value).format(format);
  }

  toMomentDate(value?: string | Date | null, format?: string) {
    return moment(value, format);
  }

  toDateFromMoment(value: Moment) {
    return value.toDate();
  }

  navigateToAddAppointment() {
    // Merge Engine - Navigation - Start
    const navigationMessage = new Message(getName(MessageEnum.NavigationMessage));
    navigationMessage.addData(getName(MessageEnum.NavigationTargetMessage), "AddAppointment");
    navigationMessage.addData(
      getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigationMessage);
    // Merge Engine - Navigation - End
  }

  handleSideNav = (navKey: string) => {
    this.setState({ activeOrderItem: navKey }, () => {
      this.handleWorkOrderMenu();
    });
  };

  handleWordDrawer = () => {
    this.setState({
      openWorkOrder: !this.state.openWorkOrder
    });
  };

  handleExpandClick = (rowId: number) => {
    this.setState((prevState) => ({
      expanded: prevState.expanded === rowId ? null : rowId
    }));
  };

  handleSelectAllClick = (eventClick: React.ChangeEvent<HTMLInputElement>, unitId: number) => {
    const { checked } = eventClick.target;
    const { properties } = this.state;
    const unit = properties
      .flatMap(property => property.attributes.units)
      .find(unit => unit.id === unitId);
    if (unit) {
      const selectedWorkOrderIds = checked
        ? unit.landlord_work_orders.map(order => order.id)
        : [];
      
      this.setState(prevState => ({
        selected: {
          ...prevState.selected,
          [unitId]: selectedWorkOrderIds,
        },
      }));
    }
  };

  handleSelectClick = (selectedId: number, orderId: number) => {
   this.setState(prevState => {
      const selectedUnit = prevState.selected[selectedId] || [];
      const selectedIndex = selectedUnit.indexOf(orderId);
      let newSelected: number[] = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selectedUnit, orderId);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selectedUnit.slice(1));
      } else if (selectedIndex === selectedUnit.length - 1) {
        newSelected = newSelected.concat(selectedUnit.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selectedUnit.slice(0, selectedIndex),
          selectedUnit.slice(selectedIndex + 1)
        );
      }

      return {
        selected: {
          ...prevState.selected,
          [selectedId]: newSelected
        },
      };
    });
  };

  isSelected = (unitId: number, orderId: number) => {
    const selectedUnit = this.state.selected[unitId] || [];
    return selectedUnit.includes(orderId);
  };

  handleWorkOrderMenu = () => {
    const { activeOrderItem } = this.state;
    const toMsgWork = new Message(getName(MessageEnum.NavigationMessage));
    toMsgWork.addData(
      getName(MessageEnum.NavigationTargetMessage),
      activeOrderItem
    );
    toMsgWork.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(toMsgWork);
  };


  handleApproveMenu = (unitIndex: number) => {
    this.setState((prevState) => ({
      openApprove: {
        ...prevState.openApprove,
        [unitIndex]: false,
      },
      anchorElement: {
        ...prevState.anchorElement,
        [unitIndex]: null
      }
    }));
  };

  handleApproveClick = (event: React.MouseEvent<HTMLElement>, unitId: number) => {
    const { openApprove, anchorElement } = this.state;
    const isCurrentlyOpen = openApprove[unitId];
    this.setState({
      openApprove: {
        ...openApprove,
        [unitId]: !isCurrentlyOpen
      },
      anchorElement: {
        ...anchorElement,
        [unitId]: event.currentTarget
      }
    });
  };


  handleMenuClick = (item: string, unitId: number) => {
    this.setState((prevState) => ({
      selectedApprove: {
        ...prevState.selectedApprove,
        [unitId]: item
      },
      selecteValueApprove: {
        ...prevState.selectedApprove,
        [unitId]: this.getStatus(item)
      },
      anchorElement: {
        ...prevState.anchorElement,
        [unitId]: null
      }
    }), () => {
      this.handleApprovedStatus(unitId);
    });
  };

  getStatus = (item: string) => {
    switch (item) {
      case "Assign a vendor":
        return "assigned_to_vendor";
      case "Complete work":
        return "completed";
      case "Decline":
        return "declined";
      default:
        return "";
    }
  };

  handleProperClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorEl: event.currentTarget }, () => {
      this.getPropertiesDetails("", "");
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
      subMenuAnchorEl: null,
      selectedProperty: null,
      selectedUnit: null
    });
  };

  handlePropertyClick = (propEvent: React.MouseEvent<HTMLElement>, property: PropertiesDetails, indexItem: number) => {
    this.setState({
      selectedProperty: property,
      subMenuAnchorEl: propEvent.currentTarget,
      selectedMenuItemIndex: indexItem,
      propertiesName: property.attributes.property_name
    });
  };

  handleUnitClick = (unit: string, unitId: number) => {
    const { selectedProperty, chips } = this.state;

    if (selectedProperty) {
      const newChip = {
        propertyId: parseInt(selectedProperty.id),
        unitId: unitId,
        propertyName: selectedProperty.attributes.property_name,
        unitName: unit
      };

      const chipExists = chips.some(
        chip => chip.propertyId === newChip.propertyId && chip.unitId === newChip.unitId
      );

      if (!chipExists) {
        this.setState((prevState) => ({
          chips: [...prevState.chips, newChip],
          selectedUnit: unit,
          anchorEl: null,
          subMenuAnchorEl: null,
          selectedProperty: null,
          messagePopup: false
        }), () => {
          this.getPropertiesDetails("", "");
        });
      } else {
        this.setState({
          messageForPopup: configJSON.filteredMessage,
          messageType: configJSON.errorMessage,
          messagePopup: true
        });
      }
    }
  };

  handleCloseMenu = () => {
    this.setState({ subMenuAnchorEl: null });
  };

  handleMenuIconClick = (eventIcon: React.MouseEvent<HTMLElement>, rowId: number) => {
    this.setState({
      anchorNewElement: {
        ...this.state.anchorNewElement,
        [rowId]: eventIcon.currentTarget
      },
    });
  };

  handleMenuClose = (rowId: number) => {
    this.setState({
      anchorNewElement: {
        ...this.state.anchorNewElement,
        [rowId]: null
      },
    });
  };

  async handleWorkOrders(message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (!responseJson.errors && responseJson) {
        this.apiSuccessCallBacks(apiRequestCallId, responseJson);
      } else if (responseJson && responseJson.errors) {
        this.apiFailCallBack(apiRequestCallId, responseJson);
      }
    }
  };

  workOrderApiCall = async (data: APIPayloadType) => {
    let { contentType, method, endPoint, body ,type } = data;
    const token = await storage.get("authToken");
    const header = {
      "Content-Type": contentType,
      token: token
    };

    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    body &&
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      type == "formData" ? body:JSON.stringify(body)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  apiSuccessCallBacks = (apiRequestCallID: string, responseJson: Properties & ApprovedDetails & VendorInformation) => {
    if (apiRequestCallID === this.getPropertiesApiCallId) {
      this.setState({
        properties: responseJson.data,
        openLoader: false
      });
    }

    if (apiRequestCallID === this.postApprovedApiCallId) {
      this.setState({
        messagePopup: true,
        messageType: "success",
        messageForPopup: "Status updated successfully for selected work orders"
      }, () => {
        this.getPropertiesDetails("", "");
      });
    }

    if (apiRequestCallID === this.postWaitingApiCallId) {
      this.setState({
        messagePopup: true,
        messageType: "success",
        messageForPopup: "Status updated to 'Pay Bill' successfully for selected work orders"
      }, () => {
        this.getPropertiesDetails("", "");
      });
    }

    if (apiRequestCallID === this.postVendorApiCallId) {
      this.setState({
        messagePopup: true,
        messageType: "success",
        messageForPopup: "Status updated successfully for selected work orders"
      }, () => {
        this.getPropertiesDetails("", "");
      });
    }

    if (apiRequestCallID === this.getOrigionalApiCallId) {
      this.setState({
        originalProperties: responseJson.data
      });
    }

    if (apiRequestCallID === this.getPreferredVendorApiCallId) {
      this.setState({
        vendorsShowAll: responseJson.data.attributes.preferred_vendors
      });
    }

  };

  getPropertiesDetails = async (propertiesId: string, searchParams: string) => {
    let endPointdata = configJSON.propertiesApiEndPoint;
    const chipsParam = this.generateChipsParam();
  
    if (propertiesId && searchParams !== "") {
      endPointdata = `${configJSON.propertiesApiEndPoint}?${searchParams}=true&property_id=${propertiesId}&search_query=${this.state.searchBar}`;  
      if (chipsParam) {
        endPointdata += `&property_unit_filter=${chipsParam}`;
      }
    } else if (this.state.searchBar) {
      endPointdata = `${configJSON.propertiesApiEndPoint}?search_query=${this.state.searchBar}&${searchParams}=true&property_id=${propertiesId}`;
      if (chipsParam) {
        endPointdata += `&property_unit_filter=${chipsParam}`;
      }
    } else if (chipsParam) {
      endPointdata = `${configJSON.propertiesApiEndPoint}?property_unit_filter=${chipsParam}`;
    }
    this.getPropertiesApiCallId = await this.workOrderApiCall({
      contentType: configJSON.appointmentApiContentType,
      method: configJSON.getAppointmentListAPiMethod,
      endPoint: endPointdata
    });
  };

  formatDate = (dateStr: string) => {
    const [datePart] = dateStr.split(" ");
    const [monthM, daysD, yearY] = datePart.split("-");
    const newDateFormat = [monthM, daysD, yearY];
    let newMonth = newDateFormat[0];
    let newDay = newDateFormat[1];
    let newYear = newDateFormat[2];
    let formattedDate = `${newDay}/${newMonth}/${newYear}`;
    return formattedDate
  };

  toggleShowAll = () => {
    this.setState(prevState => ({
      showAllItems: !prevState.showAllItems
    }));
  };

  handlePropertySlice = () =>{
    return !this.state.showAllItems && this.state.originalProperties.length > 4;
  };

  handlePropertyData = () =>{
    return this.state.originalProperties.slice(0, this.state.showAllItems ? this.state.originalProperties.length : 4);
  };

  handleSortRequest = (property: string, direction: "asc" | "desc", unitIndex: number) => {
    this.setState(prevState => ({
      sortState: {
        ...prevState.sortState,
        [unitIndex]: {
          sortColumn: property,
          sortDirection: direction
        }
      }
    }));
  };

  sortData = (newData: WorkOrderDetails[], unitIndex: number) => {
    const { sortState } = this.state;
    const { sortColumn, sortDirection } = sortState[unitIndex] || {};
    return [...newData].sort((sortingA: WorkOrderDetails, sortingB: WorkOrderDetails) => {
      const aValue = sortingA[sortColumn as keyof WorkOrderDetails];
      const bValue = sortingB[sortColumn as keyof WorkOrderDetails];
      if (bValue === null || bValue === undefined) return -1;
        if (typeof aValue === "string" && typeof bValue === "string") {
        return aValue.localeCompare(bValue) * (sortDirection === "asc" ? 1 : -1);
      } else if (typeof aValue === "number" && typeof bValue === "number") {
        return (aValue - bValue) * (sortDirection === "asc" ? 1 : -1);
      } else {
        return 0;
      }
    });
  };

  sortAuditData = (updatedData: Updates[], auditIndex: number) => {
    const { sortState } = this.state;
    const { sortColumn, sortDirection } = sortState[auditIndex] || {};
    return [...updatedData].sort((sortingA: Updates, sortingB: Updates) => {
      const sortA = sortingA[sortColumn as keyof Updates];
      const sortB = sortingB[sortColumn as keyof Updates];
      if (sortB === null || sortB === undefined) return -1;
      if (typeof sortA === "string" && typeof sortB === "string") {
        return sortA.localeCompare(sortB) * (sortDirection === "asc" ? 1 : -1);
      } else if (typeof sortA === "number" && typeof sortB === "number") {
        return (sortA - sortB) * (sortDirection === "asc" ? 1 : -1);
      } else {
        return 0;
      }
    });
  };

  handleDetailsContainer = (unitDetails: WorkOrderDetails[]) => {
    return unitDetails.length > 0 ? "" : "isUnitsDisabled";
  };

  handleEmptyContainer = (unitDetails: WorkOrderDetails[]) => {
    return unitDetails.length > 0 ? "isUnitsDisabled" : "";
  };

  handleAuditContainer = (rowOrder: Updates[]) => {
    return rowOrder.length > 0 ? "" : "isUnitsDisabled";
  };

  handleEmptyUpdates = (rowOrder: Updates[]) => {
    return rowOrder.length > 0 ? "isUnitsDisabled" : "";
  };

  handleChangeColor = (itemIndex: number) =>{
    return this.state.selectedMenuItemIndex === itemIndex ? "changeColor" : "";
  };

  handleUpdateContainer = (updateDetail: WorkDescriptions[]) => {
    return updateDetail.length > 0 ? "" : "isUnitsDisabled";
  };

  handleWorkEmpty = (updateDetails: WorkDescriptions[]) => {
    return updateDetails.length > 0 ? "isUnitsDisabled" : "";
  };

  getApprovedStatusChange = async (formData: FormData) => {
    this.postApprovedApiCallId = await this.workOrderApiCall({
      method: configJSON.addAppointmentAPiMethod,
      endPoint: configJSON.postApprovedApiEndPoint,
      body: formData,
      type: "formData"
    });
  };

  apiFailCallBack = (apiRequestCallID: string, responseJSON: InvalidResponseType) => {
    if (apiRequestCallID === this.getPropertiesApiCallId) {
      this.setState({
        messagePopup: true,
        messageForPopup: responseJSON.errors,
        messageType: "error"
      });
    }

    if (apiRequestCallID === this.postApprovedApiCallId) {
      this.setState({
        messagePopup: true,
        messageType: "error",
        messageForPopup: "Something went wrong!"
      });
    }

    if (apiRequestCallID === this.postWaitingApiCallId) {
      this.setState({
        messagePopup: true,
        messageForPopup: "Something went wrong!",
        messageType: "error"
      });
    }

    if (apiRequestCallID === this.postVendorApiCallId) {
      this.setState({
        messagePopup: true,
        messageForPopup: "Something went wrong!",
        messageType: "error"
      });
    }

  };

  handlePopupClose = () => {
    this.setState({
      messagePopup: false
    });
  };

  handleApprovedStatus = (unitId: number) => {
    const { selected, selecteValueApprove } = this.state;
    if (selected[unitId] && selected[unitId].length > 0) {
      const formData = new FormData();
      for (let unit of selected[unitId]) {
        formData.append("landlord_work_order[landlord_work_order_ids][]", unit.toString());
      };
      formData.append("landlord_work_order[new_status]", selecteValueApprove[unitId]);
      this.getApprovedStatusChange(formData);
    } else {
      this.setState({
        messagePopup: true,
        messageType: "error",
        messageForPopup: "Please select work order"
      });
    }
  };

  postWaitingBillStatusChange = async (formData: FormData) => {
    this.postWaitingApiCallId = await this.workOrderApiCall({
      method: configJSON.addAppointmentAPiMethod,
      endPoint: configJSON.postWaitingApiEndPoint,
      body: formData,
      type: "formData"
    });
  };

  handleWaitingBill = (unitsId: number) => {
    const { selected } = this.state;
    if (selected[unitsId] && selected[unitsId].length > 0) {
      const formData = new FormData();
      for (let units of selected[unitsId]) {
        formData.append("landlord_work_order[landlord_work_order_ids][]", units.toString());
      }
      this.postWaitingBillStatusChange(formData);
    } else {
      this.setState({
        messageForPopup: "Please select work order",
        messageType: "error",
        messagePopup: true
      });
    }
  };

  handleModalOpen = (findKey: number) => {
    const allUnits = this.state.properties.flatMap((property: PropertiesDetails) => property.attributes.units || []);
    const findKeyDetails = allUnits.filter((unit: Units) => {
      return Number(unit.id) === findKey;  
    });
    this.setState({
      findKeyDetails: findKeyDetails
    }, () => {
      this.handleModalClose();
    });
  };

  handleModalClose = () => {
    this.setState({
      openFinKey: !this.state.openFinKey
    });
  };

  handleCompleted = (orderId: number, status: string) => {
    const formData = new FormData();
    formData.append("landlord_work_order[landlord_work_order_ids][]", orderId.toString());
    formData.append("landlord_work_order[new_status]", status);
    this.getApprovedStatusChange(formData);
    this.handleMenuClose(orderId);
  };

  convertDate = (newDate: Date): string => {
    const yearDate = newDate.getFullYear();
    const monthDate = String(newDate.getMonth() + 1).padStart(2, '0');
    const dayDate = String(newDate.getDate()).padStart(2, '0');
    return `${dayDate}-${monthDate}-${yearDate}`;
  };

  postTransferToVendorStatus = async (formData: FormData, orderId: number) => {
    this.postVendorApiCallId = await this.workOrderApiCall({
      method: configJSON.putAppointmentListAPiMethod,
      endPoint: `${configJSON.putVendorApiEndPoint}${orderId}/assign_vendor`,
      body: formData,
      type: "formData"
    });
  };

  handleVendorStatus = (orderId: number) => {
    this.setState({
      vendorModal: true,
      workOrdersId: orderId
    }, () => {
      this.handleMenuClose(orderId);
    });
  };

  handleSelectedVendor = (vendorId: number) => {
    const { workOrdersId } = this.state;
    const formData = new FormData();
    if (vendorId !== null) {
      formData.append("vendor_account_id", vendorId.toString());
      this.postTransferToVendorStatus(formData, workOrdersId);
      this.handleCloseVendors();
    } else {
      this.setState({
        messageType: "error",
        messagePopup: true,
        messageForPopup: "Vendor profile Id is not available!"
      });
    }
  };
  
  handleCloseVendor = () => {
    this.setState({
      vendorModal: false
    });
  };

  priorityStyleHandler = (status: string) => {
    let styleObjPriority = {
      awaiting: {
        color: "#D97706",
        background: "#FEF3C7",
        borderColor: "#FEF3C7"
      },
      pay_bill: {
        color: "#DC2626",
        background: "#FEE2E2",
        borderColor: "#FEE2E2"
      },
      vendor: {
        background: "#D1FAE5",
        color: "#059669",
        borderColor: "#D1FAE5"
      },
      completed: {
        background: "#ADD8E6",
        color: "#00008B",
        borderColor: "#ADD8E6"
      },
      declined: {
        background: "#A3978F",
        borderColor: "#A3978F",
        color: "#413f3e"
      }
    }
    switch (status) {
      case "WAITING LANDLORD APPROVAL":
        return styleObjPriority.awaiting
      case "PAY BILL":
      case "VACANT":
      case "Vacant":
        return styleObjPriority.pay_bill
      case "ASSIGNED TO VENDOR":
      case "OCCUPIED":
      case "Occupied":
        return styleObjPriority.vendor
      case "COMPLETED":
        return styleObjPriority.completed
      case "DECLINED":
        return styleObjPriority.declined
      default:
        return styleObjPriority.awaiting
    }
  };

  handleProperty = (properties: PropertiesDetails[]) => {
    return properties.length > 0 ? "" : "isUnitsDisabled";
  };
  
  handleEmptyProperty = (properties: PropertiesDetails[]) => {
    return properties.length > 0 ? "isUnitsDisabled" : "";
  };

  handleHideDetails = () => {
    return this.state.openLoader ? "isUnitsDisabled" : "";
  };

  handleOpenWork = async(openWorkId: number, openKey: string) => {
    await storage.set("openWorkId",openWorkId)
    await storage.set("openStatus","open")
    const toOpenWork = new Message(getName(MessageEnum.NavigationMessage));
    toOpenWork.addData(
      getName(MessageEnum.NavigationTargetMessage),
      openKey
    );
    toOpenWork.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(toOpenWork);
  };

  handleSearchBar = (searchEvent: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let searchBar = searchEvent.target.value;
    const maximumLength = 30;
    if (searchBar.length > maximumLength) {
      this.setState({
        messageForPopup: configJSON.searchsError,
        messageType: configJSON.errorMessage,
        messagePopup: true,
      })
    } else {
      this.setState({
        searchBar: searchBar
      }, () => {
        this.getPropertiesDetails("", "")
      });
    };
 
  };

  removeChip = (index: number) => {
    this.setState((prevState) => {
      const chips = [...prevState.chips];
      chips.splice(index, 1);
      return { chips };
    }, () => {
      this.getPropertiesDetails("", "");
    });
  };

  getOrigionalPropertiesDetails = async () => {
    this.getOrigionalApiCallId = await this.workOrderApiCall({
      contentType: configJSON.appointmentApiContentType,
      method: configJSON.getAppointmentListAPiMethod,
      endPoint: configJSON.propertiesApiEndPoint
    });
  };

  generateChipsParam = (): string => {
    const { chips } = this.state;
    return chips.map(chip => `${chip.propertyId}:${chip.unitId}`).join(',');
  };

  clearChips = () => {
    this.setState({
      chips: []
    }, () => {
      this.getPropertiesDetails("", "");
    });
  };

  numberToWords = (numValue: number): string => {
    const {words, tensValue} = this.state;
    if (numValue < 20) return words[numValue];
    const digit = numValue % 10;
    if (numValue < 100) return tensValue[Math.floor(numValue / 10)] + (digit ? "-" + words[digit] : "");
    return numValue.toString();
  };

  toggleDescription = (description: string | null) => {
    const charLimit = 11;
    if (description) {
      const truncatedDescription = description.length > charLimit && !this.state.openDescription
        && description.substring(0, charLimit);
      return truncatedDescription;
    }
    return "-";
  };

  handleDescription = (descriptions: string | null) => {
    this.setState({
      modalDescription: descriptions
    }, () => {
      this.handleModalDescription();
    });
  };

  handleModalDescription = () => {
    this.setState({
      openDescription: !this.state.openDescription
    });
  };

  handleMoreDetails = (descriptions: string | null) => {
    if (descriptions) {
      return this.state.openDescription ? configJSON.readLess : configJSON.readMore;
    }
    return "";
  };

  getPreferredVendors = async () => {
    this.getPreferredVendorApiCallId = await this.workOrderApiCall({
      contentType: configJSON.appointmentApiContentType,
      method: configJSON.getAppointmentListAPiMethod,
      endPoint: configJSON.getShowVendorsApiEndPoint
    });
  };

  handleVendorClick = (vendorEvent: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({
      anchorVendorEl: vendorEvent.currentTarget,
      openNewVendors: true
    }, () => {
      this.getPreferredVendors();
    });
  };

  handleCloseVendors = () => {
    this.setState({
      openNewVendors: false
    }, () => {
      this.handleCloseVendor();
    });
  };

  handleEmptyFindKeys = (findLocation: string) => {
    return findLocation ? findLocation : "-"
  };
  // Customizable Area End
}
