Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "BaselineReporting";
exports.labelBodyText = "BaselineReporting Body";
exports.line = "Line"
exports.bar = "Bar"
exports.pie = "Pie"
exports.region = "Region"
exports.label = "label"
exports.value = "value"
exports.select = "Select"
exports.today = "today"
exports.week = "week"
exports.month = "month"


exports.btnExampleTitle = "CLICK ME";
exports.baselineApi = "bx_block_baselinereporting/baseline_reportings/total_sos_count"
exports.dashboardLandloard = "Dashboard";
exports.serviceRequests = "Service Requests";
exports.lease = "Lease";
exports.paymentHistory = "Payment History";
exports.chatLandloard = "Chat with Keasy";
exports.tenantDashboard = "TenantDashboard";
exports.servicelabel = "ServicerequestDashboard";
exports.leaselabel = "DownloadOptions";
exports.paymentHistorylabel = "BaselineReporting";
exports.chatTenantabel = "Chat";

exports.getPaymentDetailsApiEndPoint = "/bx_block_baselinereporting/tenant_payment_history/payment_details";
exports.getPaymentHistoryApiEndPoint = "/bx_block_baselinereporting/tenant_payment_history";
// Customizable Area End