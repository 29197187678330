import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React, { MouseEvent } from 'react';
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "framework/src/Utilities";
interface FileWithPreview extends File {
  preview: string;
}

interface FormErrors {
  name: string;
  dateOfBirthDate: string;
  email: string;
  address: string;
  areaCode: string;
  phoneNumber: string;
  formFilesInsurance: string;
  renewalDate: string;
  brand: string;
  modelYear: string;
  vehicleReg: string;
  petName: string;
  ageYear: string;
  formFilesPet: string;
  vehicleType: string;
  petType: string;
  occupent: string;
  dateOffirstPayment: string;
  payPeriod: string;
  rent: string;
  SecurityDeposit: string;
  LateCharges: string;
  payType: string;
  collectedBy: string;
  LeaseStartDate:string;
  leaseaddress:string;
}

export interface DropData {
  id: number;
  name: string
}

interface INavigateToPage {
  id?: number;
  props: unknown;
  screenName: string;
  raiseMessage?: Message;
}
type ValidatedFieldValue = Date | null | FileWithPreview[];
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  activeMyproperty: string;
  openLandlordDrawer: boolean;
  formFilesInsurance: FileWithPreview[];
  formFilesPet: FileWithPreview[];
  modelYear: Date | null;
  ageYear: Date | null;
  dateOfBirthDate: Date | null;
  renewalDate: Date | null;
  name: string;
  address: string;
  email: string;
  areaCode: string;
  phoneNumber: string;
  errMsg: string;
  brand: string;
  vehicleReg: string;
  petName: string;
  vehicleType: string;
  petType: string;
  occupent: string;
  dateOffirstPayment: Date | null;
  payPeriod: string;
  rent: string;
  SecurityDeposit: string;
  LateCharges: string;
  payType: string;
  collectedBy: string;
  LeaseStartDate: Date | null;
  leaseaddress: string;
  validationErrors: {
    name?: string;
    dateOfBirthDate?: string;
    address?: string;
    email?: string;
    areaCode?: string;
    phoneNumber?: string;
    vehicleRegistrationPlate?: string;
    petType?: string;
    petName?: string;
    ageYear?: string;
    petID?: string;
    formFilesInsurance?: string;
    renewalDate?: string;
    brand?: string;
    modelYear?: string;
    vehicleReg?: string;
    formFilesPet?: string;
    vehicleType?: string;
    occupent?: string;
    dateOffirstPayment?: string;
    payPeriod?: string;
    rent?: string;
    SecurityDeposit?: string;
    LateCharges?: string;
    payType?: string;
    collectedBy?: string;
    LeaseStartDate?: string;
    leaseaddress?: string;
  };
  activeStep: number;
  token: string;
  unitId: string;
  propertyId: string;
  vehicleList: DropData[];
  petList: DropData[];
  sucessMsg: string;
  formData: any,
  tenantId: any,
  editTenant: any,
  messagePopup: boolean;
  messageForPopup: string;
  messageType: 'success' | 'error' | 'warning' | 'info';
  errors: any,
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class NewTenantController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
     postTenant: string="";
     getVehicle: string="";
     getPetType: string="";
     tenantCallId: string="";
  // Customizable Area End
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      activeMyproperty: "landlordproperties",
      openLandlordDrawer: false,
      formFilesInsurance: [],
      formFilesPet: [],
      modelYear: null,
      ageYear: null,
      dateOfBirthDate: null,
      renewalDate: null,
      name: "",
      address: "",
      email: "",
      areaCode: "",
      errMsg: "",
      dateOffirstPayment: null,
      validationErrors: {},
      phoneNumber: "",
      brand: "",
      vehicleReg: "",
      petName: "",
      vehicleType: "",
      petType: "",
      activeStep: 0,
      occupent: "",
      payPeriod: "",
      rent: "",
      SecurityDeposit: "",
      LateCharges: "",
      payType: "",
      collectedBy: "",
      LeaseStartDate: null,
      leaseaddress: "",
      token: "",
      unitId: "",
      propertyId: "",
      vehicleList: [],
      petList: [],
      sucessMsg: '',
      formData: [],
      tenantId: "",
      editTenant: false,
      messagePopup: false,
      messageForPopup: "",
      messageType:  "success",
      errors:{},
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
   
    // Customizable Area Start
    const token = await getStorageData('authToken');
    const propertyId = await getStorageData('propertyId');
    const tenantId = await getStorageData('tenantId');
    const currentUrl = window.location.href;
    const idData = this.props.navigation.getParam("navigationBarTitleText");
    this.setState({ token: token, unitId: idData, propertyId: propertyId,tenantId: tenantId, editTenant: currentUrl.includes("EditTenant")});
    this.getVehicleData();
    this.getPetData();
    if(currentUrl.includes("EditTenant")){
      this.getTenantData();
    }
    // Customizable Area End
  }
  
  // Customizable Area Start

  async receive(from: string, message: Message) {

    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
      let responseJson: any = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.postTenant) {
        if(responseJson.data){
          
          this.setState({ sucessMsg: "Tenant added sucessfully"}, () => {
            this.clearData()
            this.navigateToPage({ props: this.props, screenName: "PortfolioManagement" })
          })
        } else if(responseJson.errors) {
          this.setState({
            messagePopup: true,
            messageType: "error",
            messageForPopup: responseJson.errors[0]
          })
        }
        return responseJson
      }
      if (apiRequestCallId === this.getVehicle) {
        this.setState({ vehicleList: responseJson?.data})
        return responseJson
      }
      if (apiRequestCallId === this.getPetType) {
        this.setState({ petList: responseJson?.data})
        return responseJson
      }
      if (apiRequestCallId === this.tenantCallId) {
        this.setState({ formData: responseJson.data})
        if (responseJson.data.attributes?.insurances?.length) {
          const formFilesInsurance = responseJson.data.attributes.insurances.map((insurance: any) => ({                                    // Wrap the object inside a 'file' key
              preview: insurance.file_url,             
              name: insurance.file_name,                
              lastModified: Date.now(),                 
          }));
         
          this.handleFilesChange("formW9Files",formFilesInsurance);
        }
        if(responseJson?.data?.attributes?.tenant_pets){
          const formFilesPet = responseJson?.data.attributes.tenant_pets.data.map((pets: any) => ({                                    // Wrap the object inside a 'file' key
            preview: pets.attributes.pet_id.url,
            name: "abc",                
            lastModified: Date.now(),   
        }));
        
        // Set the state with the preloaded files
        this.handleFilesPetChange("formW9Files",formFilesPet);
        }
        
        this.setState({
          name: responseJson?.data.attributes.name,
          modelYear: responseJson?.data.attributes.tenant_vehicles.data[0]?.attributes.model,
          ageYear: responseJson?.data.attributes.tenant_pets.data[0]?.attributes.age,
          dateOfBirthDate: responseJson?.data.attributes.date_of_birth,
          renewalDate: responseJson?.data.attributes.renewal_date,
          address: responseJson?.data.attributes.address,
          email: responseJson?.data.attributes.email_address,
          areaCode: responseJson?.data.attributes.area_code,
          dateOffirstPayment: responseJson?.data.attributes.date_of_first_payment_due,
          phoneNumber: responseJson?.data.attributes.phone_number,
          brand: responseJson?.data.attributes?.tenant_vehicles.data[0]?.attributes.brand_name,
          vehicleReg: responseJson?.data.attributes.tenant_vehicles.data[0]?.attributes.vehicle_registration_plate,
          petName: responseJson?.data.attributes.tenant_pets.data[0]?.attributes.pet_name,
          vehicleType: responseJson?.data.attributes.tenant_vehicles.data[0]?.attributes.class_of_vehicle.id,
          petType: responseJson?.data.attributes.tenant_pets.data[0]?.attributes.type_of_pet.id,
          occupent: responseJson?.data.attributes.number_of_occupants,
          payPeriod: responseJson?.data.attributes.pay_period,
          rent: responseJson?.data.attributes.rent_amount_per_month,
          SecurityDeposit: responseJson?.data.attributes.security_deposit,
          LateCharges: responseJson?.data.attributes.late_charges,
          payType: responseJson?.data.attributes.payment_method,
          collectedBy: responseJson?.data.attributes.collected_by,
          LeaseStartDate: responseJson?.data.attributes.lease_start_date,
          leaseaddress: responseJson?.data.attributes.lease_address,
        })
        return responseJson
      }
    }
    // Customizable Area End
  }

  handleMainItemClick = (labelKey: string) => {
    this.setState({ activeMyproperty: labelKey }, () => {
      this.handleDashboardMenu();
    });
  };

  handleDashboardMenu = () => {
    const { activeMyproperty } = this.state;
    const toMsg = new Message(getName(MessageEnum.NavigationMessage));
    toMsg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      activeMyproperty
    );
    toMsg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(toMsg);
  };

  handleLandlordDrawer = () => {
    this.setState({
      openLandlordDrawer: !this.state.openLandlordDrawer
    });
  };

  handleDateChange = (newValue: Date | null) => {
    this.setState({ dateOfBirthDate: newValue }, ()=> {
      this.validateDateField("dateOfBirthDate", newValue)
    });
  };

  handleLeaseDateChange = (newValue: Date | null) => {
    this.setState({ LeaseStartDate: newValue }, () => {
      this.validateDateField("LeaseStartDate",newValue)
    });
  };

  handledateOffirstPayment = (newValue: Date | null) => {
    this.setState({ dateOffirstPayment: newValue },() => {
      this.validateDateField("dateOffirstPayment", newValue)
    });
  };

  handleModelYearChange = (newYearValue: Date | null) => {
    this.setState({ modelYear: newYearValue }, () => {
      this.validateDateField("modelYear",newYearValue )
    });
  };

  handleFilesChange = (name: string, files: FileWithPreview[]) => {  
    this.setState({
      formFilesInsurance: files,
    } as unknown as Pick<S, keyof S>,
    () => {
      this.validateDateField("formFilesInsurance",files )
      return name
    });
  };

  handleFilesPetChange = (name: string, files: FileWithPreview[]) => {
    this.setState({
      formFilesPet: files,
    } as unknown as Pick<S, keyof S>,
    () => {
      this.validateDateField("formFilesPet",files )
      return name
    });
  };

  handleAgeYearChange = (newAgeYearValue: Date | null) => {
    this.setState({ ageYear: newAgeYearValue }, () => {
      this.validateDateField("ageYear", newAgeYearValue)
    });
  };

  handleRenewalDateChange = (newdateValue: Date | null) => {
    this.setState({ renewalDate: newdateValue }, ()=> {
      this.validateDateField("renewalDate",newdateValue)
    });
  };

  handleSelect = (vehicle: string) => {
    this.setState({ vehicleType: vehicle})
  }

  handlePet = (petName: string) => {
    this.setState({ petType: petName})
  }

  handlePayment = (payName: string) => {
    this.setState({ payType: payName})
  }
  
   onValueChange = (name: string, value: string) => {

    let isValid = true;

    if (["name", "petName"].includes(name)) {
      const isOnlyCharacters = /^[a-zA-Z\s]*$/.test(value); 
      isValid = isOnlyCharacters;
    }
  
    if (["areaCode", "phoneNumber", "occupent", "rent", "SecurityDeposit", "LateCharges"].includes(name)) {
      const isOnlyNumbers = /^\d*$/.test(value); 
      isValid = isOnlyNumbers && value.length <= 10;
    }

    if (["occupent"].includes(name)) {
      const isOnlyNumbers = /^\d*$/.test(value); 
      isValid = isOnlyNumbers && value.length <= 2;
    }
  
    if (isValid) {
      this.setState({ ...this.state, [name]: value  }, () => {
        this.validateField(name, value);
      });
    }
  };


  validateEmail = (email: string) => {
    const emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return emailRegex.test(email);
  };

  validateField = (field: string, value: string) => {
    const { validationErrors } = this.state;
    switch (field) {
      case 'name':
        validationErrors.name = value ? '' : 'Name is required';
        break;
      case 'address':
        validationErrors.address = value ? '' : 'Address is required';
        break;
      case 'email':
        validationErrors.email = value && this.validateEmail(value) ? '' : 'Valid email is required';
        break;
      case 'areaCode':
        validationErrors.areaCode = this.areaCode(validationErrors.areaCode, value);
        break;
      case 'phoneNumber':
        validationErrors.phoneNumber = this.phoneNumber(validationErrors.phoneNumber, value);
        break;
      case 'brand':
        validationErrors.brand = this.brand(validationErrors.brand, value);
        break;
      case 'vehicleReg':
        validationErrors.vehicleReg = this.vehicleReg(validationErrors.vehicleReg, value);
        break;
      case 'petName':
        validationErrors.petName = this.petName(validationErrors.petName, value);
        break;
      case 'occupent':
        validationErrors.occupent = this.occupent(validationErrors.occupent, value);
        break;
      case 'payPeriod':
        validationErrors.payPeriod = this.payPeriod(validationErrors.payPeriod, value);
        break;
      case 'rent':
        validationErrors.rent = this.rent(validationErrors.rent, value);
        break;
      case 'SecurityDeposit':
        validationErrors.SecurityDeposit = this.SecurityDeposit(validationErrors.SecurityDeposit, value);
        break;
      case 'LateCharges':
        validationErrors.LateCharges = this.LateCharges(validationErrors.LateCharges, value);
        break;
      case 'collectedBy':
        validationErrors.collectedBy = this.collectedBy(validationErrors.collectedBy, value);
        break;
      case 'leaseaddress':
        validationErrors.leaseaddress = this.leaseaddressData(validationErrors.leaseaddress, value);
        break;
      default:
        break;
    }
    this.setState({ validationErrors });
  };

  areaCode = (validationErrors: string | undefined, value: string): string => {
    if (value) {
      return ''; 
    }
    return 'Area code is required';
  }
  phoneNumber = (validationErrors: string | undefined, value: string): string => {
    if (value) {
      return ''; 
    }
    return 'Phone number is required';
  }
    brand = (validationErrors: string | undefined, value: string): string => {
      if (value) {
        return ''; 
      }
      return 'Brand is required';
    }
    vehicleReg = (validationErrors: string | undefined, value: string): string => {
      if (value) {
        return ''; 
      }
      return 'Vehicle registration plate is required';
    }
    petName = (validationErrors: string | undefined, value: string): string => {
      if (value) {
        return ''; 
      }
      return 'Pet name is required';
    }
    occupent = (validationErrors: string | undefined, value: string): string => {
      if (value) {
        return ''; 
      }
      return 'Number of occupent is required';
    }
    payPeriod = (validationErrors: string | undefined, value: string): string => {
      if (value) {
        return ''; 
      }
      return 'Pay Period is required';
    }
    rent = (validationErrors: string | undefined, value: string): string => {
      if (value) {
        return ''; 
      }
      return 'rent is required';
    }
    SecurityDeposit = (validationErrors: string | undefined, value: string): string => {
      if (value) {
        return ''; 
      }
      return 'Security Deposit is required';
    }
    LateCharges = (validationErrors: string | undefined, value: string): string => {
      if (value) {
        return ''; 
      }
      return 'Late Charges is required';
    }
    collectedBy = (validationErrors: string | undefined, value: string): string => {
      if (value) {
        return ''; 
      }
      return 'Collectedby is required';
    }
    leaseaddressData = (validationErrors: string | undefined, value: string): string => {
      if (value) {
        return ''; 
      }
      return 'Lease address is required';
    }

    validateForm = () => {
      const errors: FormErrors = this.getInitialErrors();
      const { activeStep } = this.state;
    
      if (activeStep === 0) {
        this.validateStep0(errors);
      } else if (activeStep === 1) {
        this.validateStep1(errors);
      }
    
      this.setState({ validationErrors: errors });
    
      return this.isValid(errors);
    };
    
    getInitialErrors = (): FormErrors => ({
      name: "",
      dateOfBirthDate: "",
      email: "",
      address: "",
      areaCode: "",
      phoneNumber: "",
      formFilesInsurance: "",
      renewalDate: "",
      brand: "",
      modelYear: "",
      vehicleReg: "",
      petName: "",
      ageYear: "",
      formFilesPet: "",
      vehicleType: "",
      petType: "",
      occupent: "",
      dateOffirstPayment: "",
      payPeriod: "",
      rent: "",
      SecurityDeposit: "",
      LateCharges: "",
      payType: "",
      collectedBy: "",
      LeaseStartDate: "",
      leaseaddress: ""
    });
    
    validateStep0 = (errors: FormErrors) => {
      const {
        name, dateOfBirthDate, address, email, areaCode, phoneNumber,
        formFilesInsurance, renewalDate, brand, modelYear, vehicleReg,
        petName, ageYear, formFilesPet, vehicleType, petType, occupent
      } = this.state;
    
      this.checkRequiredField(errors, 'name', name, 'Name is required');
      this.checkRequiredField(errors, 'dateOfBirthDate', dateOfBirthDate, 'Date of birth is required');
      this.checkRequiredField(errors, 'address', address, 'Address is required');
      this.checkEmailField(errors, 'email', email);
      this.checkRequiredField(errors, 'areaCode', areaCode, 'Area code is required');
      this.checkRequiredField(errors, 'phoneNumber', phoneNumber, 'Phone number is required');
      this.checkRequiredArrayField(errors, 'formFilesInsurance', formFilesInsurance, 'Insurance file is required');
      this.checkRequiredField(errors, 'renewalDate', renewalDate, 'Renewal date is required');
      this.occupant(occupent, errors);
      const isAnyPetFieldFilled = petName || ageYear || formFilesPet.length > 0 || petType;
  if (isAnyPetFieldFilled) {
    this.checkRequiredField(errors, 'petType', petType, 'Pet type is required');
    this.checkRequiredField(errors, 'petName', petName, 'Pet name is required');
    this.checkRequiredField(errors, 'ageYear', ageYear, 'Pet age is required');
    this.checkRequiredArrayField(errors, 'formFilesPet', formFilesPet, 'Pet file is required');
  }

  const isAnyVehicleFieldFilled = brand || modelYear || vehicleReg || vehicleType;
  if (isAnyVehicleFieldFilled) {
    this.checkRequiredField(errors, 'vehicleType', vehicleType, 'Vehicle type is required');
    this.checkRequiredField(errors, 'brand', brand, 'Vehicle brand is required');
    this.checkRequiredField(errors, 'modelYear', modelYear, 'Vehicle model year is required');
    this.checkRequiredField(errors, 'vehicleReg', vehicleReg, 'Vehicle registration is required');
  }
  this.setState({errors})
    };
    
    validateStep1 = (errors: FormErrors) => {
      const {
        dateOffirstPayment, payPeriod, rent, SecurityDeposit, LateCharges,
        payType, collectedBy, LeaseStartDate, leaseaddress
      } = this.state;
    
      this.checkRequiredField(errors, 'dateOffirstPayment', dateOffirstPayment, 'Date of first payment is required');
      this.checkRequiredField(errors, 'payPeriod', payPeriod, 'Pay Period is required');
      this.checkRequiredField(errors, 'rent', rent, 'Rent is required');
      this.checkRequiredField(errors, 'SecurityDeposit', SecurityDeposit, 'Security Deposit is required');
      this.checkRequiredField(errors, 'LateCharges', LateCharges, 'Late Charges is required');
      this.checkRequiredField(errors, 'payType', payType, 'Payment type is required');
      this.checkRequiredField(errors, 'collectedBy', collectedBy, 'Collected by is required');
      this.checkRequiredField(errors, 'LeaseStartDate', LeaseStartDate, 'Lease Start Date is required');
      this.checkRequiredField(errors, 'leaseaddress', leaseaddress, 'Lease address is required');
    };
    
    checkRequiredField = (errors: FormErrors, field: keyof FormErrors, value: string | Date | null, errorMessage: string) => {
      if (!value) errors[field] = errorMessage;
    };
    
    checkRequiredArrayField = (errors: FormErrors, field: keyof FormErrors, value: string | FileWithPreview[], errorMessage: string) => {
      if (!value.length) errors[field] = errorMessage;
    };
    
    checkEmailField = (errors: FormErrors, field: keyof FormErrors, value: string) => {
      if (!value && !this.validateEmail(value)) errors[field] = 'Valid email is required';
    };
    
    
    isValid = (errors: FormErrors) => {
      return (Object.keys(errors) as Array<keyof FormErrors>).every((key) => !errors[key]);
    };
  assignError = (errors: { [key: string]: string }, key: string, message: string) => {
    errors[key] = message;
  };
  
  petId = (formFilesPet: FileWithPreview[], errors: { formFilesPet: string }) => {
    if (formFilesPet.length === 0) {
      this.assignError(errors, 'formFilesPet', 'Pet ID is required');
    }
  };

  vehicleType = (data: string, errors: { vehicleType: string }) => {
    if (data.length === 0) {
      this.assignError(errors, 'vehicleType', 'Vehicle type is required');
    }
  };
  
  petType = (data: string, errors: { petType: string }) => {
    if (data.length === 0) {
      this.assignError(errors, 'petType', 'Pet type is required');
    }
  };

  occupant = (data: string, errors: { occupent: string }) => {
    if (data.length === 0) {
      this.assignError(errors, 'occupent', 'Number of occupent is required');
    }
  };
  handleSubmit = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if(this.validateForm()){
      this.handleAddTenant()
    }
  };

  handleNext = () => {
    const isValid = this.validateForm();
    
    if (isValid) {
      this.setState((prevState) => ({
        activeStep: prevState.activeStep + 1,
      }));
    }
  };

  handleBackMain = () => {
    this.setState(prevState => ({
      activeStep: prevState.activeStep - 1
    }));
  };

  validateDateField = (field: string, value: ValidatedFieldValue) => {
    const { validationErrors } = this.state;
    switch (field) {
      case 'dateOfBirthDate':
        validationErrors.dateOfBirthDate = this.dateOfBirthDate(validationErrors.dateOfBirthDate, value);
        break;
      case 'renewalDate':
        validationErrors.renewalDate = value ? '' : 'Renewal date is required';
        break; 
      case 'modelYear':
        validationErrors.modelYear = this.modelYear(validationErrors.modelYear, value);
        break; 
      case 'ageYear':
        validationErrors.ageYear = this.ageYear(validationErrors.ageYear, value);
        break; 
      case 'dateOffirstPayment':
        validationErrors.dateOffirstPayment = this.dateOffirstPayment(validationErrors.dateOffirstPayment, value);
        break; 
      case 'LeaseStartDate':
        validationErrors.LeaseStartDate = this.LeaseStartDate(validationErrors.LeaseStartDate, value);
        break; 
      case 'formFilesInsurance':
        validationErrors.formFilesInsurance = this.formFilesInsurance(validationErrors.formFilesInsurance, value);
        break; 
      case 'formFilesPet':
        validationErrors.formFilesPet = this.formFilesPet(validationErrors.formFilesPet, value);
        break; 
      default:
        break;
    }
    this.setState({ validationErrors });
  };

  dateOfBirthDate = (validationErrors: string | undefined, value: ValidatedFieldValue): string => {
    if (value) {
      return ''; 
    }
    return 'Date of birth is required';
  }

  modelYear = (validationErrors: string | undefined, value: ValidatedFieldValue): string => {
    if (value) {
      return ''; 
    }
    return 'Model year is required';
  }

  ageYear = (validationErrors: string | undefined, value: ValidatedFieldValue): string => {
    if (value) {
      return ''; 
    }
    return 'Age year is required';
  }

  dateOffirstPayment = (validationErrors: string | undefined, value: ValidatedFieldValue): string => {
    if (value) {
      return ''; 
    }
    return 'Date of first payment is required';
  }

  LeaseStartDate = (validationErrors: string | undefined, value: ValidatedFieldValue): string => {
    if (value) {
      return ''; 
    }
    return 'Lease Start Date is required';
  }

  formFilesInsurance = (validationErrors: string | undefined, value: ValidatedFieldValue): string => {
    if (value?.toString()) {
      return ''; 
    }
    return 'Insurance file is required';
  }

  formFilesPet = (validationErrors: string | undefined, value: ValidatedFieldValue): string => {
    if (value?.toString()) {
      return ''; 
    }
    return 'Pet ID is required';
  }

  handleAddTenant = async () => {
    const headers = {
      "token": this.state.token
    };

    const formData = new FormData();
    formData.append('tenant[unit_id]', this.state.unitId);
    if(this.state.editTenant){
      formData.append('tenant[id]', this.state.tenantId);
    }
    formData.append('tenant[property_id]', this.state.propertyId);
    formData.append('tenant[name]', this.state.name);
    if (this.state.dateOfBirthDate) {
          const date = this.state.dateOfBirthDate instanceof Date
        ? this.state.dateOfBirthDate
        : new Date(this.state.dateOfBirthDate);

      if (!isNaN(date.getTime())) {
        const formattedDate = date.toISOString().split('T')[0];
        formData.append("tenant[date_of_birth]", formattedDate);
      }
    }
    formData.append('tenant[address]', this.state.address);
    formData.append('tenant[email_address]', this.state.email);
    formData.append('tenant[area_code]', this.state.areaCode);
    formData.append('tenant[phone_number]', this.state.phoneNumber);
    
    if(this.state.editTenant){
      const documentFile= this.state.formData.attributes.insurances;
      const fileDoc = this.state.formFilesInsurance;
      const documentFilePet = this.state.formData.attributes.tenant_pets.data;
      const petDoc = this.state.formFilesPet;
      const mergedArray = [...documentFile, ...fileDoc];
      const mergedArrayPet = [...documentFilePet, ...petDoc];
      for (const items of mergedArray) {
        if ("id" in items) {
          const blob = new Blob([JSON.stringify(items)], { type: 'application/json' });
          formData.append("tenant[insurances][]", blob, items.file_name);
        } else {
          const file = new File([items.preview], items.name, { type: items.type });
          formData.append("tenant[insurances][]", file);
        }
      }
      for (const items of mergedArrayPet) {
        if ("id" in items) {
          const blob = new Blob([JSON.stringify(items)], { type: 'application/json' });
          formData.append("tenant[tenant_pets_attributes][][pet_id][]", blob, items.file_name);
        } else {
          const file = new File([items.preview], items.name, { type: items.type });
          formData.append("tenant[tenant_pets_attributes][][pet_id][]", file);
        }
      }
    } else {
      this.state.formFilesInsurance.forEach((formFile) => {
        formData.append('tenant[insurances][]', formFile);
      })
      this.state.formFilesPet.forEach((formFile) => {
        formData.append('tenant[tenant_pets_attributes][][pet_id][]', formFile);
      })
    }
    
    if (this.state.renewalDate) {
          const renewalDate = this.state.renewalDate instanceof Date
          ? this.state.renewalDate
          : new Date(this.state.renewalDate);

      if (!isNaN(renewalDate.getTime())) {
          
          const formattedRenewalDate = renewalDate.toISOString().split('T')[0];
          formData.append('tenant[renewal_date]', formattedRenewalDate);
      }
    }
    if(this.state.vehicleType)
    formData.append('tenant[tenant_vehicles_attributes][][class_of_vehicle_id]', this.state.vehicleType);
    if(this.state.brand)
    formData.append('tenant[tenant_vehicles_attributes][][brand_name]', this.state.brand);
    formData.append('tenant[pay_period]', this.state.payPeriod);
    formData.append('tenant[rent_amount_per_month]', this.state.rent);
    formData.append('tenant[security_deposit]', this.state.SecurityDeposit);
    formData.append('tenant[late_charges]', this.state.LateCharges);
    formData.append('tenant[payment_method]', this.state.payType);
    formData.append('tenant[collected_by]', this.state.collectedBy);
    formData.append('tenant[lease_address]', this.state.leaseaddress);
    if(this.state.modelYear){
        const modelYear = this.state.modelYear instanceof Date
          ? this.state.modelYear
          : new Date(this.state.modelYear);

      
      if (!isNaN(modelYear.getTime())) {
          const year = modelYear.getFullYear();
          formData.append('tenant[tenant_vehicles_attributes][][model]', year.toString());
      }
    }
    if(this.state.vehicleReg)
    formData.append('tenant[tenant_vehicles_attributes][][vehicle_registration_plate]', this.state.vehicleReg);
    if(this.state.petType)
    formData.append('tenant[tenant_pets_attributes][][type_of_pet_id]', this.state.petType);
    if(this.state.petName)
    formData.append('tenant[tenant_pets_attributes][][pet_name]',this.state.petName);
    if(this.state.ageYear){
        const ageYear = this.state.ageYear instanceof Date
          ? this.state.ageYear
          : new Date(this.state.ageYear); 

      
      if (!isNaN(ageYear.getTime())) {
          const year = ageYear.getFullYear();
          formData.append('tenant[tenant_pets_attributes][][age]', year.toString());
      }
    }
      
    formData.append('tenant[number_of_occupants]', this.state.occupent);
    if(this.state.dateOffirstPayment){
        const dateOfFirstPayment = this.state.dateOffirstPayment instanceof Date
          ? this.state.dateOffirstPayment
          : new Date(this.state.dateOffirstPayment);

      if (!isNaN(dateOfFirstPayment.getTime())) {
          // Convert the Date object to a string in the format 'YYYY-MM-DD'
          const formattedDateOfFirstPayment = dateOfFirstPayment.toISOString().split('T')[0];
          formData.append('tenant[date_of_first_payment_due]', formattedDateOfFirstPayment);
      } 
    }  
    if(this.state.LeaseStartDate){
        const leaseStartDate = this.state.LeaseStartDate instanceof Date
          ? this.state.LeaseStartDate
          : new Date(this.state.LeaseStartDate);

      if (!isNaN(leaseStartDate.getTime())) {
          // Convert the Date object to a string in the format 'YYYY-MM-DD'
          const formattedLeaseStartDate = leaseStartDate.toISOString().split('T')[0];
          formData.append('tenant[lease_start_date]', formattedLeaseStartDate);
      }
    }
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postTenant = requestMessage.messageId;
    const endpoint = this.state.editTenant ? configJSON.putTenant : configJSON.postTenant;
    const method = this.state.editTenant ? configJSON.putAPiMethod : configJSON.exampleAPiMethod;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  clearData = () => {
    this.setState({
      unitId: "",
      propertyId: "",
      name: "",
      dateOfBirthDate: null,
      address: "",
      email: "",
      areaCode: "",
      phoneNumber: "",
      formFilesInsurance: [],
      renewalDate: null,
      vehicleType: "",
      brand: "",
      modelYear: null,
      vehicleReg: "",
      petType: "",
      petName: "",
      ageYear: null,
      formFilesPet: [],
      occupent: "",
      dateOffirstPayment: null,
      payPeriod: "",
      rent: "",
      SecurityDeposit: "",
      LateCharges: "",
      payType: "",
      collectedBy: "",
      LeaseStartDate: null,
      leaseaddress: ""
    });
  }

  getTenantData = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.tenantCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTenantApi}/${this.state.tenantId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getVehicleData = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getVehicle = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getVehicleData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getPetData = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPetType = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPetTypeData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  navigateToPage = ({
    id,
    screenName,
    raiseMessage,
    props,
    }: INavigateToPage) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), props);
    message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
    id && message.addData(getName(MessageEnum.NavigationScreenNameMessage), id);
    raiseMessage &&
      message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    runEngine.sendMessage(message.id, message);
    }
    navigateToAppointments = () => {
      this.props.navigation.goBack();
    };
    handlePopupClose = () => {
      this.setState({
        messagePopup: false
      });
    };
  // Customizable Area End

}
