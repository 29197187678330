import React from "react";
import { Message } from "../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../framework/src/Messages/MessageEnum";
import { getStorageData } from "../../framework/src/Utilities";
import { loadStripe, Stripe} from "@stripe/stripe-js";
import { BlockComponent } from "../../framework/src/BlockComponent";

import StripePaymentView from "./StripePaymentView.web";

export interface ViewProps {
  stripePromise?: Promise<Stripe | null>;
  goToLoginScreen:()=> void;
  handleFailDialogBox: () => void;
  handleSuccessDialogBox: ()=> void;
  payAmount:string;
  email:string;
  payment_id:string;
  type:string;
  price:string;
  ids: any;
  payId:number | undefined;
  payType:string | undefined;
  payPlan:string | undefined;
  cards: any;
}

interface Props {
  navigation: any;
  id?: string;
  payAmount:string;
  handleFailDialogBox:() => void;
  handleSuccessDialogBox: ()=> void;
  payId?:number | undefined;
  payType:string | undefined;
  payPlan:string | undefined;
  cards: any;
}

interface S {
  stripePromise?: Promise<Stripe | null>;
  payAmount:string;
  email:string;
  payment_id:string;
  type:string;
  price:string;
  ids:any;
  handleFailDialogBox:() => void;
  handleSuccessDialogBox: ()=> void;
  payId:number | undefined;
  payType:string|undefined;
  payPlan:string | undefined;
  cards: any;
}

export interface SS {
  id: any;
}

class StripePayments extends BlockComponent<Props, S, SS> {
  private addstripeCallId: string;

  constructor(props: Props) {
    super(props);
    this.addstripeCallId = "";
    
    this.state = {
      stripePromise: loadStripe("pk_test_51OvoQtRtblvwat7m7ajlhYqGluEjjKFxJt8TSP0p4rWG4Pe5FZHZmW355Byf4WjpXt0pReZGYlG29IOuIBNzIJBX00TVCBTQLA"),
      payAmount: this.props.payAmount,
      email:"",
      payment_id:"",
      type:"",
      ids: [],
      handleFailDialogBox:this.props.handleFailDialogBox,
      handleSuccessDialogBox: this.props.handleSuccessDialogBox,
      price:"",
      payId:this.props.payId,
      payType:this.props.payType,
      payPlan:this.props.payPlan,
      cards: this.props.cards,
    };
  }
  async componentDidMount() {  
    super.componentDidMount(); 
    let email = await getStorageData("email");
    let payment_id = await getStorageData("payment_id");
    let type =await getStorageData("ActionType")
    let price =await getStorageData("payment_price")
    let ids =JSON.parse(await getStorageData("DocumentIds"));
    this.setState({email:email,payment_id:payment_id,type:type,price:price,ids:ids})
  }  

  async componentDidUpdate(prevProps: Props) {
    if (prevProps.payAmount !== this.props.payAmount) {
      this.setState({ payAmount: this.props.payAmount });
    }
    if (prevProps.payId !== this.props.payId) {
      this.setState({ payId: this.props.payId });
    }
    if (prevProps.payType !== this.props.payType) {
      this.setState({ payType: this.props.payType });
    }
    if (prevProps.payPlan !== this.props.payPlan) {
      this.setState({ payPlan: this.props.payPlan });
    }
  }

  goToLoginScreen = () => {
    this.props.navigation.navigate("Cfdocumentforwarding4");
  };

  render() {
    const viewProps: ViewProps = {
      stripePromise: this.state.stripePromise,
      goToLoginScreen: this.goToLoginScreen,
      payAmount: this.state.payAmount,
      payId: this.state.payId,
      payType:this.state.payType,
      payPlan:this.state.payPlan,
      email:this.state.email,
      payment_id:this.state.payment_id,
      type:this.state.type,
      price:this.state.price,
      ids:this.state.ids,
      cards:this.state.cards,
      handleFailDialogBox:this.state.handleFailDialogBox,
      handleSuccessDialogBox: this.state. handleSuccessDialogBox
    };

    return <StripePaymentView {...viewProps} />;
  }
}

export default StripePayments;