import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ITask, ITaskList } from "./types";
import storage from "../../../framework/src/StorageProvider";
import { SelectChangeEvent } from '@mui/material/Select';

export interface VendorInformation {
  data: {
    id: string;
    type: string;
    attributes: {
      preferred_vendors: Array<PreferredVendor>;
    }
  }
}

export interface Countries {
  data: Array<AreasCode>;
}

export interface AreasCode {
  id: string;
  type: string;
  attributes: {
    name: string;
    emoji_flag: string;
    country_code: string;
  }
}

export interface PreferredVendor {
  id: number;
  name: string;
  company_name: string;
  vendor_type: string;
  vendor_trade: string[],
  phone_number: string;
  email_address: string;
  status: string;
  address: string;
  portal_activated: boolean;
  taxpayer_name: string;
  taxpayer_id: string;
  send_1099: boolean;
  payment_type: string;
  use_online_payables: boolean;
  account_type: null;
  vendor_bank_routing_number: string;
  vendor_bank_account_number: string;
  last_payment_preference_updated_at: boolean;
  last_payment_preference_updated_by: boolean;
  notes: string;
  epa_certificate_exp_date: string;
  minimum_cost: number;
  contact_name: null;
  form_w9: Array<FormW>;
  voided_checks: Array<FormW>;
  business_license_exp_date: string;
  business_insurance_exp_date: string;
  epa_license_exp_date: string;
  other_certificate_exp_date: string;
  state_license_exp_date: string;
  state_insurance_exp_date: string;
  business_licenses: Array<FormW>;
  business_insurances: Array<FormW>;
  epa_certification_licenses: Array<FormW>;
  epa_other_certificates: Array<FormW>;
  state_insurances: Array<FormW>;
  state_licenses: Array<FormW>; 
  vendor_work_orders: Array<VendorWorks>;
  attachments: Array<AttachmentsForm>;
}

export interface AttachmentsForm  {
  id: number;
  name: string;
  file_url: string;
  date: string;
  uploaded_by: string;
  shared: string;
}

export interface FormW  {
  id: number;
  name: string;
  file_url: string;
  date: string;
  uploaded_by: string;
  shared: string;
}

export interface VendorWorks {
  id: number;
  status: string;
  location: {
      unit: string;
      property: string;
  }
  description: string;
  cost: string;
  due_date: string;
  work_needed: string;
}

export interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  token?: string;
  body?: object;
  type?: string;
}

export interface InvalidResponseType {
  errors: string;
}

export interface Category {
  data: Array<CatType>;
}

export interface CatType {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    sub_categories: Array<SubCategory>;
  }
}

export interface SubCategory {
  id: number;
  name: string;
}

export interface ExistingList {
  vendors: Array<VendorList>;
}

export interface VendorList {
  id: number;
  name: string;
  vendor_type: string;
  status: string;
  email_address: string;
  company_name: string;
  phone_number: string;
}

interface DisabledVendorStates {
  [vendorId: number]: boolean;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  id: number;
  // Customizable Area Start
  name: string;
  editMode: boolean;
  token: string;
  taskLists: ITaskList[];
  tasksData: ITask[];
  selectedTasks: ITask[];
  isVisibleModal: boolean;
  dropdownTasks: boolean;
  activeVendorItem: string;
  openVendorDrawer: boolean;
  expanded: number | null;
  open:boolean;
  vendorTypes: Array<CatType>;
  anchorEl: null | HTMLElement;
  openFilter: boolean;
  vendorShowAll: PreferredVendor[];
  editable: string;
  sortData: {
    sortColumn: string;
    sortDirection: "asc" | "desc";
  };
  vendorInformation: PreferredVendor[];
  vendorId: number | null;
  editModalOpen: boolean;
  selectedBoxDetails: Partial<PreferredVendor>;
  fieldsToEdit: string[];
  vendorNewType: string;
  vendorTrades: string[];
  errors: {
    address: boolean;
    phones: boolean;
    emails: boolean;
  };
  phoneNumber: string;
  email: string;
  address: string;
  areasCode: string;
  isCountryDropdown: boolean;
  countriesCodes: Array<{ name: string; emoji_flag: string; country_code: string }>;
  portalActive: string;
  editId: number;
  searchVendor: string;
  chips: string[];
  messagesPopup: boolean;
  messagesForPopup: string;
  messageType: 'success' | 'error' | 'warning' | 'info';
  anchorStatusEl: null | HTMLElement;
  openStatusFilter: boolean;
  vendorStatus: string[];
  statusChips: string[];
  openTaskLoader: boolean;
  preferredVendorModal: boolean;
  openExistingVendors: boolean;
  anchorsVendorEl: null | HTMLElement;
  existingVendors: Array<VendorList>; 
  disabledVendorStates: DisabledVendorStates;
  vendorsType: Array<string>;
  origionalVendor: Array<PreferredVendor>;
  pages: number;
  pageEvent: Object;
  vendorLength: number;
  // Customizable Area End
}

export interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class TaskListController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getTaskListsApiCallId = "";
  postTaskListApiCallId = "";
  putTaskListApiCallId = "";
  deleteTaskListApiCallId = "";
  getTasksApiCallId = "";
  getShowVendorApiCallId: string = "";
  getVendorTypeApiCallId: string = "";
  getAreasCodeApiCallId: string = "";
  updateVendorApiCallId: string = "";
  getExistingVendorApiCallId: string = "";
  postAddExistingApiCallId: string = "";
  getOrigionalsApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      id: 0,
      name: "",
      editMode: false,
      token: "",
      taskLists: [],
      tasksData: [],
      selectedTasks: [],
      isVisibleModal: false,
      dropdownTasks: false,
      activeVendorItem: configJSON.prevendorOrderlabel,
      openVendorDrawer: false,
      expanded: null,
      open:false,
      vendorTypes: [],
      anchorEl: null,
      openFilter: false,
      sortData: {
        sortColumn: "",
        sortDirection: "desc"
      },
      editable: "",
      vendorShowAll: [],
      vendorInformation: [],
      vendorId: null,
      editModalOpen: false,
      selectedBoxDetails: {},
      fieldsToEdit: [],
      vendorNewType: "",
      vendorTrades: [],
      errors: {
        address: false,
        phones: false,
        emails: false,
      },
      phoneNumber: "",
      email: "",
      address: "",
      areasCode: "",
      isCountryDropdown: false,
      countriesCodes: [],
      portalActive: "yes",
      editId: 0,
      searchVendor: "",
      chips: [],
      messagesPopup: false,
      messagesForPopup: "",
      messageType: configJSON.errorValue,
      anchorStatusEl: null,
      openStatusFilter: false,
      vendorStatus: [configJSON.uploadDocuments, configJSON.renewal, configJSON.actives],
      statusChips: [],
      openTaskLoader: true,
      preferredVendorModal: false,
      openExistingVendors: false,
      anchorsVendorEl: null,
      existingVendors: [],
      disabledVendorStates: {},
      vendorsType: [],
      origionalVendor: [],
      pages: 1,
      pageEvent: {},
      vendorLength: 0
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);
    this.receive = this.receive.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    this.getVendorType();
    this.getAreaCode();
    this.getExistingDetails();
    this.getVendors();
  }

  receive = async (from: string, message: Message) => {
    runEngine.debugLog("Message Received", message);

    // Condition for fetching task list.
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getTaskListsApiCallId != null &&
      this.getTaskListsApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (!responseJson.errors && responseJson.data) {
        this.setState({
          taskLists: responseJson.data.sort(
            (a: ITaskList, b: ITaskList) => a.id - b.id
          ),
        });
      } else {
        this.showAlert("Alert", "No Data", "");
        this.setState({ taskLists: [] });

        const errorResponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        this.parseApiCatchErrorResponse(errorResponse);
      }
    }

    // Condition for creating new task list.
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.postTaskListApiCallId != null &&
      this.postTaskListApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (!responseJson.errors && responseJson.data) {
        this.getTaskLists(this.state.token);
        this.setState({ isVisibleModal: false });
      } else {
        const errorResponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorResponse);
      }
    } else if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      runEngine.debugLog("Message Received", message);

      const token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
    }

    // Condition for edit task list.
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.putTaskListApiCallId != null &&
      this.putTaskListApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (!responseJson.errors && responseJson.data) {
        this.getTaskLists(this.state.token);
        this.setState({ isVisibleModal: false });
      } else {
        const errorResponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }

    // Condition for delete task list.
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.deleteTaskListApiCallId != null &&
      this.deleteTaskListApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (!responseJson.errors && responseJson.message) {
        this.getTaskLists(this.state.token);
      } else {
        const errorResponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }

    // Condition for fetch tasks.
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getTasksApiCallId != null &&
      this.getTasksApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (!responseJson.errors && responseJson.data) {
        let data = responseJson.data.map((task: ITask, i: number) => {
          if (this.state.selectedTasks.length > 0) {
            let itemExist = this.state.selectedTasks.findIndex(
              (task1: ITask) => task1.id == task.id
            );
            if (itemExist != -1) {
              return { ...task, isSelected: true };
            } else {
              return { ...task, isSelected: false };
            }
          } else {
            return { ...task, isSelected: false };
          }
        });
        this.setState({
          tasksData: data,
        });
      } else {
        this.showAlert("Alert", "No Data", "");
        this.setState({ tasksData: [] });

        const errorResponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
    this.handleVendors(message);
  };

  getResults = (counting: number) => {
    if (this.state.chips.length>0 || this.state.statusChips.length>0) {
      this.setState({
        vendorLength: counting
      });
    }else{
      this.setState({
        vendorLength: 0
      });
    }
  };

  getToken = async() => {
    const formattedChips = this.state.chips.map(this.handleKeyValues).filter(value => value !== "").join(", ");
    const formattedStatusChips = this.state.statusChips.map(this.handleKeyValues).filter(value => value !== "").join(", ");  
    this.getShowVendorApiCallId = await this.vendorApiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.getApiMethod,
      endPoint: `${configJSON.getShowVendorApiEndPoint}${this.state.searchVendor}&vendor_type=${formattedChips}&status=${formattedStatusChips}`
    });
  };

  isStringNullOrBlank = (str: string) => {
    return str === null || str.length === 0;
  };

  hideModal = () => {
    this.setState({ isVisibleModal: !this.state.isVisibleModal });
  };

  showAddModal = () => {
    this.getTasks(this.state.token);
    this.setState({
      name: "",
      dropdownTasks: false,
      isVisibleModal: !this.state.isVisibleModal,
      editMode: false,
    });
  };

  handleInputName = (name: string) => {
    this.setState({ name });
  };

  expandTasksView = () => {
    this.setState({ dropdownTasks: !this.state.dropdownTasks });
  };

  showEditModal = () => {
    this.setState({
      isVisibleModal: !this.state.isVisibleModal,
      editMode: true,
    });
  };

  handleEditSelect = (item: ITaskList) => {
    this.getTasks(this.state.token);
    this.setState({
      id: item.id,
      name: item.attributes.name,
      selectedTasks: item.attributes.tasks,
      dropdownTasks: false,
    });
    this.showEditModal();
  };

  handleTasksSelect = (dataId: string) => {
    let newData = this.state.tasksData.map((task: ITask) => {
      if (task.id === dataId) {
        return { ...task, isSelected: !task.isSelected };
      }
      return task;
    });
    this.setState({ tasksData: newData });
  };

  // Function to fetch task list from the API
  getTaskLists = (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTaskListsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.tasksListApiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Function to add new task list and send it to API
  addTaskList = () => {
    if (this.isStringNullOrBlank(this.state.name)) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory,
        ""
      );
    } else {
      const header = {
        "Content-Type": configJSON.apiContentType,
        token: this.state.token,
      };
      let taskIds: string[] = [];
      this.state.tasksData.map((task: ITask) => {
        if (task.isSelected) {
          taskIds.push(task.id);
        }
      });
      const httpBody = {
        name: this.state.name,
        task_ids: taskIds,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.postTaskListApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.tasksListApiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postApiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  // Function to edit task list and send it to API
  editTaskList = (tasklistId: number) => {
    if (this.isStringNullOrBlank(this.state.name)) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory,
        ""
      );
    } else {
      const header = {
        "Content-Type": configJSON.apiContentType,
        token: this.state.token,
      };
      let taskIds: string[] = [];
      this.state.tasksData.map((task: ITask) => {
        if (task.isSelected) {
          taskIds.push(task.id);
        }
      });
      const httpBody = {
        name: this.state.name,
        task_ids: taskIds,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.putTaskListApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.tasksListApiEndPoint + "/" + `${tasklistId}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.putApiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  // Function to delete task list and send it to API
  deleteTaskList = (tasklistId: number) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteTaskListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.tasksListApiEndPoint + "/" + `${tasklistId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Function to fetch tasks from the API
  getTasks = (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTasksApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.tasksApiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleVendoritemClick = (vendorKey: string) => {
    this.setState({ activeVendorItem: vendorKey },()=>{
      this.handleTaskList();
    });
  };

  handleVendorDrawer = () => {
    this.setState({
      openVendorDrawer: !this.state.openVendorDrawer
    });
  };

  handleTaskList = () => {
    const { activeVendorItem } = this.state;
    const toListWork = new Message(getName(MessageEnum.NavigationMessage));
    toListWork.addData(
      getName(MessageEnum.NavigationTargetMessage),
      activeVendorItem
    );
    toListWork.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(toListWork);
  };

  handleFilterClick = (filterEvent: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({
      anchorEl: filterEvent.currentTarget,
      openFilter: true
    }, () => {
      this.getVendorType();
    });
  };

  handleFilterClose = () => {
    this.setState({
      openFilter: false
    });
  };

  statusHandler = (status: string) => {
    let styleObjPriority = {
      upload: {
        color: "#D97706",
        background: "#FEF3C7",
        borderColor: "#FEF3C7"
      },
      renewal_needed: {
        color: "#DC2626",
        background: "#FEE2E2",
        borderColor: "#FEE2E2"
      },
      active: {
        background: "#D1FAE5",
        color: "#059669",
        borderColor: "#D1FAE5"
      },
      done: {
        background: "rgb(233,228,228)",
        color: "rgb(120,114,110)",
        borderColor: "#ADD8E6"
      },
      defaults: {
        background: "none",
        color: "#000000"
      }
    }
    switch (status) {
      case "UPLOAD NEW DOCUMENT":
        return styleObjPriority.upload
      case "RENEWAL NEEDED":
        return styleObjPriority.renewal_needed
      case "ACTIVE":
        return styleObjPriority.active
      case "DONE":
        return styleObjPriority.done
      default:
        return styleObjPriority.defaults
    }
  };

  handleExpandVendor = (vendorId: number) => {
    this.setState((prevState) => ({
      expanded: prevState.expanded === vendorId ? null : vendorId
    }));
  };

  handleVendorInfo = (edit: string, vendorId: number) => {
    if (vendorId === 0) {
      this.setState({ open: false, vendorInformation: [], editable: edit });
    } else {
      const selectedVendor = this.state.vendorShowAll.find(vendor => vendor.id === vendorId);
      this.setState({ open: true, vendorInformation: selectedVendor ? [selectedVendor] : [], editable: edit });
    }
  };

  handleVendorAudit = (vendorAudit: PreferredVendor) => {
    return vendorAudit.vendor_work_orders.length > 4 ? "scrollable-container" : "";
  };

  handleAuditEnable = (audits: VendorWorks[]) => {
    return audits.length > 0 ? "" : "isUnitsDisabled";
  };

  handleEmptyDisable = (audits: VendorWorks[]) => {
    return audits.length > 0 ? "isUnitsDisabled" : "";
  };

  handleVendorSort = (property: string, direction: "asc" | "desc") => {
    this.setState({
      sortData: {
        sortColumn: property,
        sortDirection: direction
      }
    });
  };

  sortingData = (newData: PreferredVendor[]) => {
    const { sortData } = this.state;
    const { sortColumn, sortDirection } = sortData || {};
    return [...newData].sort((sortingA: PreferredVendor, sortingB: PreferredVendor) => {
      const aTopValue = sortingA[sortColumn as keyof PreferredVendor];
      const bDownValue = sortingB[sortColumn as keyof PreferredVendor];
      if (bDownValue === null || bDownValue === undefined) return -1;
      if (typeof aTopValue === "string" && typeof bDownValue === "string") {
        return aTopValue.localeCompare(bDownValue) * (sortDirection === "asc" ? 1 : -1);
      } else if (typeof aTopValue === "number" && typeof bDownValue === "number") {
        return (aTopValue - bDownValue) * (sortDirection === "asc" ? 1 : -1);
      } else {
        return 0;
      }
    });
  };

  sortAuditingData = (updatedData: VendorWorks[]) => {
    const { sortData } = this.state;
    const { sortColumn, sortDirection } = sortData || {};
    return [...updatedData].sort((sortingA: VendorWorks, sortingB: VendorWorks) => {
      const sortUpA = sortingA[sortColumn as keyof VendorWorks];
      const sortDownB = sortingB[sortColumn as keyof VendorWorks];
      if (sortDownB === null || sortDownB === undefined) return -1;
      if (typeof sortUpA === "string" && typeof sortDownB === "string") {
        return sortUpA.localeCompare(sortDownB) * (sortDirection === "asc" ? 1 : -1);
      } else if (typeof sortUpA === "number" && typeof sortDownB === "number") {
        return (sortUpA - sortDownB) * (sortDirection === "asc" ? 1 : -1);
      } else {
        return 0;
      }
    });
  };

  handleAddNewVendor = () => {
    const toNewVendor = new Message(getName(MessageEnum.NavigationMessage));
    toNewVendor.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "VendorDashboardEdit"
    );
    toNewVendor.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(toNewVendor);
  };

  async handleVendors(message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiVendorRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (!responseJson.errors && responseJson) {
        this.apiSuccessCallBacks(apiVendorRequestCallId, responseJson);
      } else if (responseJson && responseJson.errors) {
        this.apiFailCallBack(apiVendorRequestCallId, responseJson);
      }
    }
  };

  vendorApiCall = async (data: APIPayloadType) => {
    let { contentType, method, endPoint, body, type } = data;
    const token = await storage.get("authToken");
    const header = {
      "Content-Type": contentType,
      token: token
    };

    let requestsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    ); 
    body &&
    requestsMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      type == "formData" ? body:JSON.stringify(body)
    );

    runEngine.sendMessage(requestsMessage.id, requestsMessage);
    return requestsMessage.messageId;
  };

  apiSuccessCallBacks = (apiRequestCallID: string, responseJson: VendorInformation & Category & Countries & ExistingList) => {
    if (apiRequestCallID === this.getShowVendorApiCallId) {
      this.setState({
        vendorShowAll: responseJson.data.attributes.preferred_vendors,
        openTaskLoader: false
      },()=>{
        this.getResults(responseJson.data.attributes.preferred_vendors.length);
      });
    }

    if (apiRequestCallID === this.getVendorTypeApiCallId) {
      this.setState({
        vendorTypes: responseJson.data
      });
    }

    if (apiRequestCallID === this.getAreasCodeApiCallId) {
      let countriesCodes = responseJson.data.map((items: AreasCode) => {
        return {
          country_code: items.attributes.country_code,
          name: items.attributes.name,
          emoji_flag: items.attributes.emoji_flag
        };
      });

      this.setState({ countriesCodes });
    }

    if (apiRequestCallID === this.updateVendorApiCallId) {
      this.setState({
        editModalOpen: false,
        openTaskLoader: true
      }, () => {
        this.getToken();
        this.handleDesignUpdate();
        this.getVendors();
      });
    }

    if (apiRequestCallID === this.getExistingVendorApiCallId) {
      this.setState({
        existingVendors: responseJson.vendors
      });
    }

    if (apiRequestCallID === this.postAddExistingApiCallId) {
      this.setState({
        messageType: "success",
        messagesPopup: true,
        messagesForPopup: "The vendor has been successfully added to your preferred vendor list."
      }, () => {
        this.getToken();
        this.getVendors();
      });
    }

    if (apiRequestCallID === this.getOrigionalsApiCallId) {
      let vendorInfo = responseJson.data.attributes.preferred_vendors;

      const getVendorTypes = (vendors: PreferredVendor[]): string[] => {
        return vendors.map((vendor: PreferredVendor) => vendor.vendor_type);
      };

      const vendorTypesArray = getVendorTypes(vendorInfo);
      const uniqueVendorTypesArray = Array.from(
        new Set(vendorTypesArray.filter(vendorType => vendorType !== null && vendorType.trim() !== ''))
      );
      this.setState({
        origionalVendor: responseJson.data.attributes.preferred_vendors,
        vendorsType: uniqueVendorTypesArray
      });
    }

  };

  handleDetailsContainer = (vendorDetails: PreferredVendor[]) => {
    return vendorDetails.length > 0 ? "" : "isUnitsDisabled";
  };

  handleEmptyContainer = (vendorDetails: PreferredVendor[]) => {
    return vendorDetails.length > 0 ? "isUnitsDisabled" : "";
  };

  getVendorType = async() => {
    this.getVendorTypeApiCallId = await this.vendorApiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.getApiMethod,
      endPoint: configJSON.getVendorTypeApiEndPoint
    });
  };

  handleSend = (sendVlue: boolean) => {
    return sendVlue === false ? "No" : "Yes"
  };

  formatNumber(number: string) {
    const numStr = number.toString();
    const lastFourDigits = numStr.slice(-4);
    return `XX-XXX-${lastFourDigits}`;
  };

  maskNumber = (numbers: string) =>{
    if(numbers){
      const numStr = numbers.toString();
      const lastFourDigits = numStr.slice(-4);
      const maskedPart = numStr.slice(0, -4).replace(/\d/g, 'X');
      return maskedPart + lastFourDigits;
    }
    return configJSON.slash
  };

  handleEmptyDetails = (empty: boolean | string) => {
    return empty ? empty : configJSON.slash
  };

  handleVendorTrade = (trades: string[]) => {
    return trades.join(",")
  };

  handleViewWorks = async(vendorId: number) => {
    await storage.set("openWorkId",vendorId)
    await storage.set("openStatus","view")
    const toViewWork = new Message(getName(MessageEnum.NavigationMessage));
    toViewWork.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "OpenWorkOrder"
    );
    toViewWork.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(toViewWork);
  };

  handleUpdateBoxDetails = () => {
    this.handleUpdateSuccess();
    const { address, email, phoneNumber, areasCode, editId } = this.state;
    const formData = new FormData();
    formData.append("vendor_account[vendor_account_id]", editId.toString());
    formData.append("vendor_account[phone_number]", areasCode + phoneNumber);
    formData.append("vendor_account[email_address]", email);
    formData.append("vendor_account[address]", address);
    formData.append("vendor_account[activated]", this.state.portalActive === "yes" ? "1" : "0");
    this.updateVendorDetails(formData);
  };

  handleUpdateSuccess = () => {
    this.setState({
      messagesForPopup: "Successfully updated!",
      messagesPopup: true,
      messageType: "success"
    });
  };

  handleDesignUpdate = () => {
    const { address, email, phoneNumber, areasCode, editId, portalActive } = this.state;
    const vendorIndex = this.state.vendorInformation.findIndex(vendor => vendor.id === editId);

    if (vendorIndex !== -1) {
      const updatedVendorInformation = [...this.state.vendorInformation];
      updatedVendorInformation[vendorIndex] = {
        ...updatedVendorInformation[vendorIndex],
        phone_number: areasCode + phoneNumber,
        email_address: email,
        address: address,
        portal_activated: portalActive === "1" ? true : false,
      };

      this.setState({
        vendorInformation: updatedVendorInformation,
      });
    }
  };

  updateVendorDetails = async (formData: FormData) => {
    this.updateVendorApiCallId = await this.vendorApiCall({
      method: configJSON.patchApiMethod,
      endPoint: configJSON.updateVendorApiEndPoint,
      body: formData,
      type: "formData"
    });
  };

  handleCloseEditModal = () => {
    this.setState({ editModalOpen: false });
  };

  handleEditClick = (boxDetails: PreferredVendor) => {
    const fullPhoneNumber = boxDetails.phone_number || "";
    const sanitizePhoneNumber = fullPhoneNumber.startsWith('+')
      ? fullPhoneNumber.substring(1)
      : fullPhoneNumber;
    let matchingAreaCode = "";
    for (const country of this.state.countriesCodes) {
      const codesLength = country.country_code.length;
      const areaCodesCandidate = sanitizePhoneNumber.substring(0, codesLength);

      if (areaCodesCandidate === country.country_code && codesLength > matchingAreaCode.length) {
        matchingAreaCode = areaCodesCandidate;
      }
    }
    const phoneNumber = sanitizePhoneNumber.substring(matchingAreaCode.length);
    const portal = boxDetails.portal_activated ?  "yes": "no"
    this.setState({
      editModalOpen: true,
      selectedBoxDetails: boxDetails,
      phoneNumber: phoneNumber,
      email: boxDetails.email_address,
      address: boxDetails.address,
      areasCode: matchingAreaCode,
      editId: boxDetails.id,
      portalActive: portal
    });
  };

  formatAndExtractPhoneNumber = (boxDetails: string) =>{
  const fullPhoneNumber = boxDetails || "";
    const sanitizedPhoneNumber = fullPhoneNumber.startsWith('+')
      ? fullPhoneNumber.substring(1)
      : fullPhoneNumber;
    let matchingAreaCode = "";
    for (const country of this.state.countriesCodes) {
      const codeLength = country.country_code.length;
      const areaCodeCandidate = sanitizedPhoneNumber.substring(0, codeLength);

      if (areaCodeCandidate === country.country_code && codeLength > matchingAreaCode.length) {
        matchingAreaCode = areaCodeCandidate;
      }
    }
    const phoneNumber = sanitizedPhoneNumber.substring(matchingAreaCode.length);
     const formattedNumber = `(${matchingAreaCode}) ${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
     return formattedNumber;
  };

  handleCountClose = () => {
    this.setState({ isCountryDropdown: false });
  };

  handleChange = (
    events: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = events.target;
    this.setState({
      [name]: value,
    } as unknown as Pick<S, keyof S>);
    if (name === "address") {
      this.validatesName(value);
    }else if (name === "phoneNumber") {
      this.validatesPhone(value);
    } else if (name === "email") {
      this.validatesEmail(value);
    }
  };

  validatesName = (name: string) => {
    if (name==="") {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          address: true,
        },
      }));
    } else {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          address: false,
        }
      }));
    }
  };

  validatesPhone = (phone: string) => {
    const phoneRegex = configJSON.vendorsPhone
    if (!phoneRegex.test(phone)) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          phones: true,
        },
      }));
    } else {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          phones: false,
        },
      }));
    }
  };

  validatesEmail = (email: string) => {
    const emailRegex = configJSON.emailRegex
    if (!emailRegex.test(email)) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          emails: true,
        },
      }));
    } else {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          emails: false,
        },
      }));
    }
  };

  getHelpersText = (fields: string) => {
    const { errors } = this.state;
    switch (fields) {
      case "address":
        return errors.address ? configJSON.addressValue : null;
      case "phoneNumber":
        return errors.phones ? configJSON.phoneValue : null;
      case "email":
        return errors.emails ? configJSON.emailValue : configJSON.exampleValue;
      default:
        return null;
    }
  };

  handleCountryCodeDropdown = () => {
    this.setState({ isCountryDropdown: true });
  };

  handleCountryChange = (event: SelectChangeEvent<string>) => {
    this.setState({ areasCode: event.target.value, isCountryDropdown: false });
  };

  getAreaCode = async () => {
    this.getAreasCodeApiCallId = await this.vendorApiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.getApiMethod,
      endPoint: configJSON.getCountriesCodesAPIEndPoint
    });
  };

  handleVendorSearchBar = (searchEvent: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let searchBar = searchEvent.target.value;
    const maxLength = 30;
    if (searchBar.length > maxLength) {
      this.setState({
        messagesForPopup: configJSON.searchError,
        messageType: configJSON.errorValue,
        messagesPopup: true,
      })
    } else {
      this.setState({
        searchVendor: searchBar
      }, () => {
        this.getToken();
        this.getVendors()
      });
    };
  };

  handleKeyValues = (items: string) => {
    switch (items) {
      case "Appliance Repair":
        return "appliance_repair";
      case "Electrical":
        return "electrical";
      case "General":
        return "general";
      case "HVAC":
      case "Hvac":
        return "hvac";
      case "Landscaping":
        return "landscaping";
      case "Pest Control":
        return "pest_control";
      case "Plumbing":
        return "plumbing";
      case "Property Management":
        return "property_management";
      case "Upload new document":
        return "upload_new_document";
      case "Renewal needed":
        return "renewal_needed";
      case "Active":
        return "active"
      case "Roofing":
        return "roofing"
      default:
        return "";
    }
  };

  clearChip = () => {
    this.setState({
      chips: [],
      statusChips: [],
      openTaskLoader: true
    }, () => {
      this.getToken();
      this.getVendors();
    });
  };

  handlePopupClose = () => {
    this.setState({
      messagesPopup: false
    });
  };

  handleStatusFilter = (filterEvent: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({
      anchorStatusEl: filterEvent.currentTarget,
      openStatusFilter: true
    }, () => {
      this.getVendorType();
    });
  };

  handleStatusFilterClose = () => {
    this.setState({
      openStatusFilter: false
    });
  };

  handleFilters = (chipValue: string) => {
    const chipAlreadyExists = this.state.chips.includes(chipValue);
    if (!chipAlreadyExists) {
      this.setState(
        (prevState) => ({
          chips: [...prevState.chips, chipValue],
        }),
        () => {
          this.handleFilterClose();
          this.getToken();
          this.getVendors();
        }
      );
    } else {
      this.setState({
        messagesForPopup: configJSON.vendorError,
        messageType: configJSON.errorValue,
        messagesPopup: true,
      });
    }
  };

  handleStatusFilters = (chipValue: string) => {
    const chipAlreadyExists = this.state.statusChips.includes(chipValue);
    if (!chipAlreadyExists) {
      this.setState(
        (prevState) => ({
          statusChips: [...prevState.statusChips, chipValue],
        }),
        () => {
          this.handleStatusFilterClose();
          this.getToken();
          this.getVendors();
        }
      );
    } else {
      this.setState({
        messagesForPopup: configJSON.statusError,
        messageType: configJSON.errorValue,
        messagesPopup: true,
      });
    }
  };

  removeChip = (chipIndex: number) => {
    if (chipIndex < this.state.statusChips.length) {
      this.setState((prevState) => {
        const newStatusChips = [...prevState.statusChips];
        newStatusChips.splice(chipIndex, 1);
        return { statusChips: newStatusChips, openTaskLoader: true };
      }, this.getToken);
    } else {
      const adjustedChipIndex = chipIndex - this.state.statusChips.length;
      this.setState((prevState) => {
        const newChips = [...prevState.chips];
        newChips.splice(adjustedChipIndex, 1);
        return { chips: newChips, openTaskLoader: true };
      }, this.getToken);
    }
  };

  handleEditDetails = (formDetails: FormW[]) => {
    return formDetails.length > 0 ? "" : "isUnitsDisabled";
  };

  handleEmptyDisabled = (formDetails: FormW[]) => {
    return formDetails.length > 0 ? "isUnitsDisabled" : "";
  };

  handleAttachmentsEnable = (attachments: AttachmentsForm[]) => {
    return attachments.length > 0 ? "" : "isUnitsDisabled";
  };

  handleEmptyAttachDisable = (attachments: AttachmentsForm[]) => {
    return attachments.length > 0 ? "isUnitsDisabled" : "";
  };

  handleClosePreferredVendor = () => {
    this.setState({
      preferredVendorModal: !this.state.preferredVendorModal
    });
  };

  handleVendorClick = (vendorEvent: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({
      anchorsVendorEl: vendorEvent.currentTarget,
      openExistingVendors: true
    },()=>{
      this.getExistingDetails();
    });
  };

  handleCloseExistingVendors = () => {
    this.setState({
      openExistingVendors: false
    });
  };

  handleAddExistingVendor = (vendorId: number) => {
    const formData = new FormData();
    if (vendorId !== null) {
      this.setState(prevState => ({
        disabledVendorStates: {
          ...prevState.disabledVendorStates,
          [vendorId]: true
        }
      }));
      formData.append("vendor_account_id", vendorId.toString());
      this.addExistingVendor(formData);
    } else {
      this.setState({
        messageType: "error",
        messagesPopup: true,
        messagesForPopup: "Vendor profile Id is not available!"
      });
    }
  };

  getExistingDetails = async() => {
    this.getExistingVendorApiCallId = await this.vendorApiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.getApiMethod,
      endPoint: configJSON.getAllVendorsEntPoint
    });
  };

  addExistingVendor = async (formData: FormData) => {
    this.postAddExistingApiCallId = await this.vendorApiCall({
      method: configJSON.postApiMethod,
      endPoint: configJSON.postApprovedApiEndPoint,
      body: formData,
      type: "formData"
    });
  };

  apiFailCallBack = (apiRequestCallID: string, responseJSON: InvalidResponseType) => {
    if (apiRequestCallID === this.updateVendorApiCallId) {
      this.setState({
        messageType: "error",
        messagesPopup: true,
        messagesForPopup: "Server error.!"
      });
    }

    if (apiRequestCallID === this.postAddExistingApiCallId) {
      this.setState({
        messageType: "error",
        messagesPopup: true,
        messagesForPopup: responseJSON.errors
      });
    }

  };

  handlePortalsChange = (sendEvent: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ portalActive: (sendEvent.target as HTMLInputElement).value });
  };

  handleDocSize = (fileSize: string) => {
    return fileSize.length > 7 ? fileSize.substring(0, 7) + "..." : fileSize;
  };

  handleUpdatedBy = (updateSize: string) => {
    return updateSize.length > 10 ? updateSize.substring(0, 7) + "..." : updateSize;
  }; 

  getVendors = async() => {
    this.getOrigionalsApiCallId = await this.vendorApiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.getApiMethod,
      endPoint: configJSON.getShowVendorApiEndPoint
  });
};

  handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    this.setState({ pages: value, pageEvent: event });
  };

  getPaginatedAttachments = (attachments: AttachmentsForm[]) => {
    const { pages } = this.state;
    const itemPerPage = 5;
    return attachments.slice((pages - 1) * itemPerPage, pages * itemPerPage);
  };

  handleTotalPage = (vendorInfo: PreferredVendor) => {
    const totalPages = Math.ceil(vendorInfo.attachments.length / 5);
    return totalPages;
  };
  // Customizable Area End
}
